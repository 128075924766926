import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";

interface Interviewee {
  participant_id: string;
  interviewee_name: string;
  interviewee_title: string;
}
interface IPLAttendee {
  partcipant_id: string;
  contact_id: string;
  name: string;
  title: string;
  email: string;
}
export interface CallSchedule {
  created_at?: Date;
  call_scheduled_date?: Date;
  call_implementation_date?: Date;
  ipl_attendees: IPLAttendee[];
  interviewees: Interviewee[];
  reference: string;
}

/**
 * Creates an API instance for handling risk-related operations.
 *
 * @returns The risk API instance.
 */
export const risk = createApi({
  reducerPath: "risk",
  tagTypes: ["CALL_SCHEDULE"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/maps`,
  }),
  endpoints: (builder) => ({
    getCallSchedule: builder.query<
      CallSchedule,
      {
        map_id: string;
        account_type: string;
      }
    >({
      query: ({ map_id, account_type }) => ({
        url: `/${map_id}/${account_type}/call-schedule`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CALL_SCHEDULE"],
    }),

    /** call_scheduled_date?: string;
          call_implementation_date?: string;**/
    editCallSchedule: builder.mutation<
      void,
      {
        map_id: string;
        account_type: string;
        data: {
          call_scheduled_date?: string;
          call_implementation_date?: string;
          reference?: string;
        };
      }
    >({
      query({ map_id, account_type, data }) {
        return {
          url: `/${map_id}/${account_type}/call-schedule`,
          method: "patch",
          data,
        };
      },
      invalidatesTags: () => ["CALL_SCHEDULE"],
    }),
    addCallAttendee: builder.mutation<
      void,
      {
        map_id: string;
        account_type: string;
        data: {
          /** contact_id of the internal_user**/
          contact_id: string;
        }[];
      }
    >({
      query({ map_id, account_type, data }) {
        return {
          url: `/${map_id}/${account_type}/ipl-attendee`,
          method: "patch",
          data,
        };
      },
      invalidatesTags: () => ["CALL_SCHEDULE"],
    }),
    addInterViewee: builder.mutation<
      void,
      {
        map_id: string;
        account_type: string;
        data: {
          interviewee_name: string;
          interviewee_title: string;
        };
      }
    >({
      query({ map_id, account_type, data }) {
        return {
          url: `/${map_id}/${account_type}/interviewee`,
          method: "post",
          data,
        };
      },
      invalidatesTags: () => ["CALL_SCHEDULE"],
    }),
    editInterviewee: builder.mutation<
      void,
      {
        participant_id: string;
        data: {
          interviewee_name?: string;
          interviewee_title?: string;
        };
      }
    >({
      query({ participant_id, data }) {
        return {
          url: `/interviewee/${participant_id}`,
          method: "patch",
          data,
        };
      },
      invalidatesTags: () => ["CALL_SCHEDULE"],
    }),
    deleteParticipant: builder.mutation<void, string>({
      query(participant_id) {
        return {
          url: `/participant/${participant_id}`,
          method: "delete",
        };
      },
      invalidatesTags: () => ["CALL_SCHEDULE"],
    }),
  }),
});

export const {
  useGetCallScheduleQuery,
  useEditCallScheduleMutation,
  useAddCallAttendeeMutation,
  useAddInterVieweeMutation,
  useDeleteParticipantMutation,
  useEditIntervieweeMutation,
} = risk;
