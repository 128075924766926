import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "@hello-pangea/dnd";

import { Theme, styled } from "@mui/material";
import React, { HTMLAttributes } from "react";
import InvoiceStatus from "./TaskTypeListGenerator/InvoiceStatus";
import MapConfiguration from "./TaskTypeListGenerator/MapConfiguration";
import Ticketing from "./TaskTypeListGenerator/Ticketing";
import type { Id, TASK_TYPES, TaskTypes } from "./types";

const primaryButton = 0;
const grid = 10;

type Props = {
  task: TaskTypes[TASK_TYPES];
  index: number;
  isSelected: boolean;
  isGhosting: boolean;
  selectionCount: number;
  toggleSelection: (taskId: Id) => void;
  toggleSelectionInGroup: (taskId: Id) => void;
  multiSelectTo: (taskId: Id) => void;
  taskType: TASK_TYPES;
  action?: (taskId: Id) => void;
  isDragDisabled?: boolean;
  stackable?: boolean;
  containerProps?: {
    main?: HTMLAttributes<HTMLDivElement>;
    column?: HTMLAttributes<HTMLDivElement>;
    task?: HTMLAttributes<HTMLDivElement>;
  };
};

type GetBackgroundColorArgs = {
  isSelected: boolean;
  isGhosting: boolean;
  theme: Theme;
};

const getBackgroundColor = ({
  isSelected,
  isGhosting,
  theme,
}: GetBackgroundColorArgs): string => {
  if (isGhosting) {
    return theme.palette.info.main;
  }

  if (isSelected) {
    return theme.palette.tertiary.main;
  }

  return theme.palette.background.default;
};

const getColor = ({
  isSelected,
  isGhosting,
  theme,
}: {
  isSelected: boolean;
  isGhosting: boolean;
  theme: Theme;
}): string => {
  if (isGhosting) {
    return "darkgrey";
  }
  if (isSelected) {
    return theme.palette.background.default;
  }
  return theme.palette.primary.main;
};

const Container = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "theme" &&
    prop !== "isSelected" &&
    prop !== "isGhosting" &&
    prop !== "isDragging" &&
    prop !== "containerProps",
})<{
  isSelected: boolean;
  isGhosting: boolean;
  isDragging: boolean;
  containerProps?: {
    main?: HTMLAttributes<HTMLDivElement>;
    column?: HTMLAttributes<HTMLDivElement>;
    task?: HTMLAttributes<HTMLDivElement>;
  };
}>(({ theme, isSelected, isGhosting, isDragging, containerProps }) => ({
  backgroundColor: getBackgroundColor({ isSelected, isGhosting, theme }),
  color: getColor({ isSelected, isGhosting, theme }),
  padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  marginBottom: `${theme.spacing(1)}`,
  borderRadius: theme.shape.borderRadius,
  fontSize: theme.typography.body1.fontSize,
  border: `1px solid ${theme.palette.primary.main}`,
  ...(isDragging
    ? {
        boxShadow: `2px 2px 1px ${theme.palette.secondary.main}`,
        transform: "rotate(-2deg)",
      }
    : {
        boxShadow: `2px 2px 1px ${theme.palette.secondary.main}26`,
        transform: "",
      }),
  ...(isGhosting ? { opacity: 0.8 } : {}),
  "&:focus": {
    outline: "none",
    borderColor: theme.palette.secondary.main,
  },
  "&:hover": {
    boxShadow: `2px 2px 8px 3px ${theme.palette.secondary.main}57`,
  },
  height: "26vh",
  [theme.breakpoints.down("sm")]: {
    height: "27vh",
  },
  ...containerProps?.task?.style,
}));

const size: number = 30;

const SelectionCount = styled("div")(({ theme }) => ({
  right: `-${theme.spacing(1)}`,
  top: `-${theme.spacing(1)}`,
  color: theme.palette.background.default,
  background: theme.palette.success.main,
  borderRadius: "50%",
  height: "24px",
  width: "24px",
  lineHeight: "24px",
  position: "absolute",
  textAlign: "center",
  fontSize: "0.8rem",
}));

const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9,
};

const Task = ({
  task,
  index,
  isSelected,
  selectionCount,
  isGhosting,
  action,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  taskType,
  isDragDisabled,
  stackable = false,
  containerProps,
}: Props) => {
  const onKeyDown = (
    event: KeyboardEvent,
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot
  ) => {
    if (event.defaultPrevented) {
      return;
    }

    if (snapshot.isDragging) {
      return;
    }

    if (event.keyCode !== keyCodes.enter) {
      return;
    }

    event.preventDefault();

    performAction(event);
  };

  const onClick = (event: any) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== primaryButton) {
      return;
    }

    event.preventDefault();

    performAction(event);
  };

  const onTouchEnd = (event: any) => {
    if (event.defaultPrevented) {
      return;
    }

    event.preventDefault();
    toggleSelectionInGroup(task.id);
  };

  const wasToggleInSelectionGroupKeyUsed = (
    event: MouseEvent | KeyboardEvent
  ) => {
    const isUsingWindows = navigator.platform.indexOf("Win") >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  const wasMultiSelectKeyUsed = (event: MouseEvent | KeyboardEvent) =>
    event.shiftKey;

  const performAction = (event: MouseEvent | KeyboardEvent) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(task.id);
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(task.id);
      return;
    }
    action ? action(task.id) : toggleSelection(task.id);
  };
  const GENERATOR_INDEX = {
    InvoiceStatus,
    Ticketing,
    MAP_CONFIGURATION: MapConfiguration,
  };

  if (task?.id)
    return (
      <Draggable
        draggableId={task.id}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
          const shouldShowSelection: boolean =
            snapshot.isDragging && selectionCount > 1;
          const TASK_TYPE = taskType as keyof TaskTypes;

          return (
            <Container
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={onClick}
              onTouchEnd={onTouchEnd}
              onKeyDown={(event: any) => onKeyDown(event, provided, snapshot)}
              isDragging={snapshot.isDragging}
              isSelected={isSelected}
              isGhosting={isGhosting}
              id="task-container"
              containerProps={containerProps}
            >
              {React.createElement(
                GENERATOR_INDEX[taskType] as any, //component
                (task as TaskTypes[typeof TASK_TYPE]).content //content
              )}
              {shouldShowSelection ? (
                <SelectionCount>{selectionCount}</SelectionCount>
              ) : null}
            </Container>
          );
        }}
      </Draggable>
    );
  return <></>;
};

export default Task;
