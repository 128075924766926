import { FormHelperText, Grid, useTheme } from "@mui/material";

import { useVerifyTotpMutation } from "api/api-auth";
import { DASHBOARD, IS_LOGIN, IS_VERIFYING_TOTP } from "codes";
import LoginHeader from "components/Login/CustomHeader";
import { AuthScreens } from "components/Login/LoginScreens";
import styles from "components/Login/LoginScreens/login.module.scss";
import MfaCodeForm from "components/Login/MfaCodeForm";
import { FormikHelpers } from "formik";
import history from "helpers/history";
import i18n from "helpers/i18n";
import setWithExpiry from "helpers/setLocalStorage";
import useIsMobile from "hooks/useIsMobile";
import { useState } from "react";
import { toast } from "react-toastify";

const Totp = () => {
  const { location, replace } = history;
  const state: Record<string, any> = location;
  //Route protection
  if (state?.state?.authState !== IS_VERIFYING_TOTP)
    replace("/login", IS_LOGIN);
  const [currentState, setCurrentState] = useState(IS_VERIFYING_TOTP);
  const [error, setError] = useState("");
  const language = i18n.language?.split("-")?.[0];
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [confirmTotp, { isLoading }] = useVerifyTotpMutation();

  const formContainerSx = {
    marginTop: !isMobile ? "10vh" : 2,
    maxWidth: isMobile ? "auto" : "100vw",
  };

  const handleTotpConfirmation = (
    values: {
      mfaCode: string;
    },
    formikHelpers: FormikHelpers<{
      mfaCode: string;
    }>
  ) => {
    confirmTotp({
      email: state?.state?.email ?? "",
      code: values.mfaCode,
      session: state?.state?.session ?? "",
    })
      .unwrap()
      .then((resp) => {
        setWithExpiry(
          "token",
          resp.data.access_token,
          import.meta.env.VITE_APP_TIMEOUT
        );
      })
      .finally(() => {
        history.push(`${DASHBOARD}?lang=${language}`);
      })
      .catch((e) => {
        setError(e?.message);
        toast.error(e?.message);
        if (e?.message === "Invalid MFA code") {
          setCurrentState(IS_VERIFYING_TOTP);
        }
      });
  };

  return (
    <Grid
      container
      spacing={isMobile ? 0 : 1}
      direction="column"
      margin={"auto"}
      width={"100%"}
      height={"100%"}
    >
      <Grid item xs={12}>
        <LoginHeader setCurrentState={setCurrentState} />
        <Grid
          container
          margin={"auto"}
          spacing={isMobile ? 0 : 1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={isMobile ? 0 : 1}
              direction="column"
              margin={"auto"}
              justifyContent="center"
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={isMobile ? 0 : 2}
                  alignItems={"center"}
                  justifyContent={"center"}
                  direction="column"
                  marginTop="2vh"
                  sx={formContainerSx}
                  maxWidth={isMobile ? "100vw" : "38vw"}
                >
                  <AuthScreens state={currentState} />
                  <Grid item xs={6} margin="auto">
                    <MfaCodeForm
                      onSubmit={handleTotpConfirmation}
                      labelClassName={styles.labelClass}
                      className={styles.container}
                      primaryButtonOptions={{
                        loading: isLoading,
                        sx: {
                          boxShadow: "none",
                          [theme.breakpoints.down("sm")]: {
                            height: "2.8em",
                            ">div": {
                              height: "2.8em",
                              ">input": {
                                height: "0.8em",
                              },
                            },
                          },
                        },
                      }}
                      inputFieldProps={{
                        labelClassName: styles.labelClass,
                        sx: {
                          borderRadius: "10px",
                          ">div": {
                            borderRadius: "10px",
                          },
                          [theme.breakpoints.down("sm")]: {
                            height: "2.8em",
                            ">div": {
                              height: "2.8em",
                              ">input": {
                                height: "0.8em",
                              },
                            },
                          },
                        },
                      }}
                    />
                  </Grid>
                  {error !== "" && (
                    <Grid item>
                      <FormHelperText error={error !== ""}>
                        {error}
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Totp;
