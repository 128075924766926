import groupBy from "./groupBy";

interface IChartStructure {
  member_layout?: string[];
  id: string;
  name: string;
  level: number;
  title?: string;
  color?: string;
}

const LEVEL_MAP = {
  LEVEL_0: {
    level: 0,
    color: "grey",
  },
  LEVEL_1: {
    level: 1,
    color: "#359154",
  },
  LEVEL_2: {
    level: 2,
    color: "purple",
  },
  LEVEL_3: {
    level: 3,
    color: "#980104",
  },
  LEVEL_4: {
    level: 4,
    color: "#007ad0",
  },
  LEVEL_5: {
    level: 5,
    color: "orange",
  },
  LEVEL_6: {
    level: 6,
    color: "#007ad0",
  },
  LEVEL_7: {
    level: 7,
    color: "orange",
  },
  LEVEL_8: {
    level: 8,
    color: "#007ad0",
  },
  LEVEL_9: {
    level: 9,
    color: "orange",
  },
  LEVEL_10: {
    level: 10,
    color: "orange",
  },
  LEVEL_11: {
    level: 11,
    color: "#007ad0",
  },
  LEVEL_12: {
    level: 12,
    color: "orange",
  },
  LEVEL_13: {
    level: 13,
    color: "#007ad0",
  },
  LEVEL_14: {
    level: 14,
    color: "orange",
  },
} as Record<string, { level: number; color: string }>;

const createData = (data: IChartStructure[]) => {
  return Object.entries(groupBy(data, "id"))
    .map(([key, value]) => {
      return value[0].member_layout?.map((member) => [key, member]);
    })
    .filter(Boolean)
    .flat() as any;
};

const levels = Object.values(LEVEL_MAP);

export { createData, levels };
