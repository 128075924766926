import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { accountrtk } from "api/api-accounts";
import { aiForm } from "api/api-aiform";
import { auth } from "api/api-auth";
import { banks } from "api/api-banks";
import { comments } from "api/api-comments";
import { companiesrtk } from "api/api-company";
import { compliance } from "api/api-compliance";
import { creditCommittee } from "api/api-credit-committee";
import { emailsrtk } from "api/api-emails";
import { finance } from "api/api-finance";
import { human_resources } from "api/api-human-resources";
import { it } from "api/api-it";
import { legal } from "api/api-legal";
import { maps } from "api/api-maps";
import { marketing } from "api/api-marketing";
import { marketplace } from "api/api-marketplace";
import { misc } from "api/api-misc";
import { notifications } from "api/api-notifications";
import { operations } from "api/api-operations";
import { origination } from "api/api-origination";
import { risk } from "api/api-risk";
import { sales } from "api/api-sales";
import { support } from "api/api-support";
import { users } from "api/api-users";
import { users_v2 } from "api/api-users-v2";
import { vendors } from "api/api-vendors";
import { vendorsv2 } from "api/api-vendorsv2";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { unauthenticatedMiddleware } from "./middleware/unauthenticatedMiddleware";
import appStatesReducer from "./reducers/appStatesReducer";
import cargoReducer from "./reducers/cargoReducer";
import companyReducer from "./reducers/companyReducer";
import countriesReducer from "./reducers/countriesReducer";
import creditLimitsReducer from "./reducers/creditLimitsReducer";
import experimentalFeaturesReducer from "./reducers/experimentalFeaturesReducer";
import invoicesReducer from "./reducers/invoicesReducer";
import notifReducer from "./reducers/notifReducer";
import stepsReducer from "./reducers/stepsReducer";

const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  companies: companyReducer,
  creditLimits: creditLimitsReducer,
  countries: countriesReducer,
  steps: stepsReducer,
  appState: appStatesReducer,
  invoicesState: invoicesReducer,
  notification: notifReducer,
  cargo: cargoReducer,
  experimentalFeatures: experimentalFeaturesReducer,
  aiform: aiForm.reducer,
  sales: sales.reducer,
  misc: misc.reducer,
  users: users.reducer,
  users_v2: users_v2.reducer,
  companiesrtk: companiesrtk.reducer,
  accountrtk: accountrtk.reducer,
  banks: banks.reducer,
  vendors: vendors.reducer,
  origination: origination.reducer,
  vendorsv2: vendorsv2.reducer,
  marketplace: marketplace.reducer,
  creditCommittee: creditCommittee.reducer,
  it: it.reducer,
  finance: finance.reducer,
  marketing: marketing.reducer,
  legal: legal.reducer,
  risk: risk.reducer,
  operations: operations.reducer,
  support: support.reducer,
  auth: auth.reducer,
  maps: maps.reducer,
  compliance: compliance.reducer,
  emailsrtk: emailsrtk.reducer,
  comments: comments.reducer,
  human_resources: human_resources.reducer,
  notifications: notifications.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      actionCreatorCheck: false,
    }).concat([
      unauthenticatedMiddleware,
      aiForm.middleware,
      sales.middleware,
      origination.middleware,
      misc.middleware,
      users.middleware,
      users_v2.middleware,
      companiesrtk.middleware,
      accountrtk.middleware,
      banks.middleware,
      notifications.middleware,
      vendors.middleware,
      vendorsv2.middleware,
      marketplace.middleware,
      creditCommittee.middleware,
      it.middleware,
      finance.middleware,
      marketing.middleware,
      legal.middleware,
      risk.middleware,
      operations.middleware,
      support.middleware,
      auth.middleware,
      maps.middleware,
      compliance.middleware,
      emailsrtk.middleware,
      comments.middleware,
      human_resources.middleware,
    ]),
  devTools: import.meta.env.VITE_ENV !== "main",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
