import {
  Avatar,
  Chip,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { currencyFormatter } from "helpers/currencyFormatter";
import useWidth from "helpers/useWidth";
import moment from "moment";
import { useRef } from "react";

import { InvoiceStatus as IInvoiceStatus } from "types";

const InvoiceStatus = (data: IInvoiceStatus) => {
  const theme = useTheme();

  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 500;
  const small = width && width < 150;
  const chipStyle = {
    fontSize: "0.5em",
    height: small ? "2.5ch" : "4ch",
    lineHeight: "1.5ch",
    borderRadius: small ? 1 : 3,
    color: theme.palette.background.default,
    width: "100%",
  };

  return (
    <Stack spacing={{ lg: 0.5, xs: 0 }} ref={ref} height={"100%"}>
      <Grid item xs={12}>
        <Link
          href={`${import.meta.env.VITE_SF_BASE_URL}${data.id}`}
          target={"_blank"}
          color={"error"}
          fontWeight={"bold"}
        >
          Invoice #{data.name}
        </Link>
      </Grid>
      <Tooltip title={data.buyer_name}>
        <Stack
          direction={"row"}
          spacing={{ lg: 0.5, xs: 0 }}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <Avatar
            variant="square"
            sx={{
              bgcolor: `${data.color}`,
              width: "1em",
              height: "1em",
              fontSize: "0.9em",
              mt: { lg: "3px", md: "1px" },
            }}
          >
            {" "}
          </Avatar>

          <Typography
            fontSize={"0.7rem"}
            sx={{
              maxWidth: "90%",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",

              [theme.breakpoints.down("md")]: {
                fontSize: "0.6em !important",
              },
            }}
          >
            {data.buyer_name}
          </Typography>
        </Stack>
      </Tooltip>

      <Typography
        fontWeight={"bold"}
        fontSize={"0.7rem"}
        sx={{
          lineHeight: small ? "1.5em" : "1.5em",
          [theme.breakpoints.down("md")]: {
            fontSize: "0.6em !important",
          },
        }}
      >
        {currencyFormatter({
          amount: data.requested_amount_usd,
          currency: data.ccy,
        })}
      </Typography>

      <Tooltip title={data.supplier_name}>
        <Grid item xs={12}>
          <Typography
            fontSize={"0.7rem"}
            sx={{
              maxWidth: "90%",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              cursor: "pointer",
              [theme.breakpoints.down("md")]: {
                fontSize: "0.5em !important",
              },
            }}
          >
            {`${data.supplier_name}`}
          </Typography>
        </Grid>
      </Tooltip>
      <Grid item xs={12}>
        <Grid
          container
          spacing={small ? 0 : 1}
          alignItems={"center"}
          justifyContent={"start"}
        >
          <Grid item height={"1.5ch"} xs={small ? 12 : 4}>
            <Tooltip title={`Insurer: ${data.insurer}`}>
              <Chip
                label={`${data.insurer ?? "Insurer Not Found"}`}
                sx={chipStyle}
                color="warning"
              />
            </Tooltip>
          </Grid>

          <Grid item height={"1.5ch"} xs={small ? 12 : 4}>
            <Tooltip title={`Product: ${data.product}`}>
              <Chip
                label={`${data.product ?? "Product Not Found"}`}
                sx={{ ...chipStyle, bgcolor: "#9542ee" }}
              />
            </Tooltip>
          </Grid>

          <Grid item height={"1.5ch"} xs={small ? 12 : 4}>
            <Tooltip
              title={`Expected Repayment Date: ${data.expected_payment_date}`}
            >
              <Chip
                label={`${
                  data.due_date
                    ? moment(data.expected_payment_date).format("DD/MM/YY")
                    : "Not Found"
                }`}
                sx={{ ...chipStyle, bgcolor: "#ff5044" }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          overflow: "hidden",
          border: "1px solid #d6d4d4",
          borderRadius: "6px",
          height: "auto",
        }}
      >
        <Tooltip title={`${data.funding_comments}`}>
          <Typography
            fontSize={"0.9em"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "90%",
              whiteSpace: "nowrap",
              padding: "2px",
              [theme.breakpoints.down("md")]: {
                fontSize: "0.5em !important",
              },
            }}
          >
            {`${
              data.funding_comments !== null
                ? data.funding_comments
                : "--No Comments--"
            }`}
          </Typography>
        </Tooltip>
      </Grid>
    </Stack>
  );
};
export default InvoiceStatus;
