import { Box, Slide } from "@mui/material";
import DepartmentScreen from "components/Dashboard/DepartmentScreen";

function DashboardInternal() {
  return (
    <Slide in direction="left">
      <Box sx={{ display: "flex", flexShrink: "0 !important" }} flexGrow={1}>
        <DepartmentScreen />
      </Box>
    </Slide>
  );
}

export default DashboardInternal;
