import { Box, Grid, Slide, Stack, Typography, useTheme } from "@mui/material";
import { useGetEmailQuery, useGetEmailsQuery } from "api/api-emails";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import Modal from "components/Common/Modal";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useRef, useState } from "react";
import styles from "./sales.module.scss";

const Emails = () => {
  const isMobile = useIsMobile();

  const { data: emailsList, isFetching, refetch } = useGetEmailsQuery(null);
  const [email_id, setEmailId] = useState<string>("");
  const { data: email } = useGetEmailQuery(email_id, { skip: !email_id });

  const theme = useTheme();
  const modalOverlaySx = {
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const { data: curr_user, isLoading } = useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const headers = Object.keys(emailsList?.[0] ?? {});
  const headerLength = headers?.length - 2.15;
  const defaultColumnWidth = width ? width / headerLength : 200;

  const refactorList =
    emailsList?.map((item) => {
      return {
        ...item,
        recipients: item.recipients?.join(", "),
        bcc: item.bcc?.join(", "),
      };
    }, []) ?? [];

  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={`Emails`}
          backArrow={false}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.5em" : "1.5em",
          }}
          headerConfig={{
            syncAction: refetch,
            left: 8,
            mid: 0,
            right: 2,
            xs: {
              left: 12,
              mid: 0,
              right: 0,
            },
            alignItems: "center",
          }}
          className={styles.layout}
          mode="default"
        >
          <Grid item xs={12} mt={2} px={3}>
            {isFetching && <SkeletonLoad bars={10} />}
            {!isFetching && emailsList && Boolean(emailsList?.length) ? (
              <DataTablev2
                defaultColumnWidth={defaultColumnWidth}
                key={refactorList?.length}
                data={refactorList}
                customColumns={{}}
                rowsPerPage={rowsPerPage}
                onRowClicked={(row) => setEmailId(row?.id)}
                sx={{
                  table: {
                    minWidth: "100% !important",
                  },
                }}
              />
            ) : (
              <NoData
                text="No emails found"
                style={{ margin: "1em auto", width: "100%" }}
              />
            )}
          </Grid>
          <Modal
            message={
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Stack
                    direction={"row"}
                    justifyContent={"start"}
                    alignItems={"center"}
                    spacing={2}
                  >
                    <Typography fontWeight={"bold"}>From</Typography>
                    <Typography>{email?.sender}</Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"start"}
                    alignItems={"center"}
                    spacing={2}
                  >
                    <Typography fontWeight={"bold"}>To</Typography>
                    <Typography>{email?.recipients?.join(", ")}</Typography>
                    <Typography fontWeight={"bold"}>BCC</Typography>
                    <Typography>{email?.bcc?.join(", ")}</Typography>
                  </Stack>
                  <Stack
                    dangerouslySetInnerHTML={{ __html: email?.body ?? "" }}
                    margin={"auto"}
                  />
                </Stack>
              </Grid>
            }
            sx={modalSx}
            Header={
              <Typography fontSize={"1.5em"} fontWeight={"bold"}>
                {email?.subject}
              </Typography>
            }
            width={isMobile ? "90%" : "40%"}
            height={"80vh"}
            open={email_id !== ""}
            modalOverlaySx={modalOverlaySx}
            modalFooterSx={{ maxWidth: "100%" }}
            onClose={() => setEmailId("")}
            closeButton
            modalHeaderSx={{ backgroundColor: "transparent" }}
          />
        </Layout>
      </Box>
    </Slide>
  );
};

export default Emails;
