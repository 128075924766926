import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { INotification } from "types";
import type { RootState } from "../store";

// Define a type for the slice state
interface NotifState {
  value: INotification[];
  unreadCount: number;
  notifIsActive: boolean | undefined;
  token: string;
}
// Define the initial state using that type
const initialState: NotifState = {
  value: [],
  unreadCount: 0,
  notifIsActive: true,
  token: "",
};

export const notifSlice = createSlice({
  name: "notification",
  // `createSlice` will infer the state type from the `initialState` argument
  // Use the PayloadAction type to declare the contents of `action.payload`
  initialState,
  //CRUD
  reducers: {
    setNotification: (state, action: PayloadAction<INotification[]>) => {
      state.value = action.payload;
    },
    setUnreadCount: (state, action: PayloadAction<number>) => {
      state.unreadCount = action.payload;
    },
    setNotifIsActive: (state, action: PayloadAction<boolean | undefined>) => {
      state.notifIsActive = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    updateNotifisRead: (state, action: PayloadAction<INotification>) => {
      state.value = [
        ...state.value.map((notif) => {
          if (notif.id === action.payload.id) {
            const item = action.payload;
            return {
              ...notif,
              notif_is_read: true,
              action: { notif_is_read: true, id: item.id },
              item,
            };
          }
          return notif;
        }),
      ];
    },
    clearNotification: () => initialState,
  },
});

export const {
  setNotification,
  setUnreadCount,
  setNotifIsActive,
  updateNotifisRead,
  setToken,
  clearNotification,
} = notifSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectNotification = (state: RootState) =>
  state.notification.value;

export default notifSlice.reducer;
