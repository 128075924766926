import { BrowserHistory, createBrowserHistory } from "history";

const history = createBrowserHistory() as unknown as BrowserHistory & {
  previousRoute: string;
};

// Add a custom property to store the previous route
(history as any).previousRoute = "";

// Listen for route changes and update previousRoute
history.listen((update) => {
  (history as any).previousRoute = update.location.pathname;
});

export default history;
