import { Grid } from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";
import { AgentProps } from "./constants";

const AgentDetails = () => {
  const formikProps = useFormikContext<AgentProps>();
  return (
    <MainCard title={"Agent Details"} content boxShadow>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Drop
            name="accountAgent"
            label="Account Agent"
            value={formikProps.values.accountAgent}
            keyValue={"name"}
            data={[
              { name: "Incomlend", value: "Incomlend" },
              { name: "Marketing", value: "Marketing" },
            ]}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            name="agentName"
            label="Agent Name"
            value={formikProps.values.agentName}
            onChange={(e) => {
              formikProps.setFieldValue("agentName", e.target.value);
            }}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            name="address"
            label="Address"
            value={formikProps.values.address}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            name="city"
            label="City"
            value={formikProps.values.city}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            name="postCode"
            label="Post Code"
            value={formikProps.values.postCode}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            name="country"
            label="Country"
            value={formikProps.values.country}
            {...formikProps}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};
export default AgentDetails;
