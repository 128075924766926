import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useEditAccountCommentsMutation,
  useEditMapRiskParametersMutation,
  useGetAccountCommentsQuery,
  useGetMapRiskParametersQuery,
  useGetTemplatesQuery,
} from "api/api-maps";
import { BUYER, SUPPLIER } from "codes";
import { InputType } from "components/Common/FormFields/InputField";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import useTradeTableValueGenerator from "hooks/useTradeTableValueGenerator";
import { MapContext } from "pages/maps/map";
import React from "react";
import styles from "./company-background.module.scss";

interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    data: string | number | string[];
  };
}

interface CRITERIA_TYPE extends Record<string, any> {
  [key: string]: MapTemplate;
}

interface CompanyBackgroundProps {
  background_extra_info: string;
  background: CRITERIA_TYPE;
}

const CompanyBackground = ({
  accountType,
  disabled,
}: {
  accountType: string;
  disabled: boolean;
}) => {
  const id = React.useContext(MapContext);
  const theme = useTheme();
  const item_type = "background";
  const { data: companyBackgroundTemplate, isFetching } =
    useGetTemplatesQuery(item_type);
  const [editComments] = useEditAccountCommentsMutation();
  const { data: accountComments } = useGetAccountCommentsQuery(
    { map_name: id ?? "", account_type: accountType },
    { skip: !id }
  );

  const BACKGROUND_BY_CRITERIA_ENUM = [
    `As per ${accountType?.toLowerCase() === BUYER?.toLowerCase() ? BUYER : SUPPLIER} Call`,
    "As per Credit Report",
    "As per Financial Statements",
  ];

  const [editBackground] = useEditMapRiskParametersMutation();
  const {
    data: mapRiskParams,
    isFetching: isFetchingMapRiskParams,
    refetch: refetchValues,
  } = useGetMapRiskParametersQuery(
    {
      map_name: id ?? "",
      account_type: accountType as "supplier" | "buyer",
      item_type,
    },
    { skip: !id }
  );

  const backgroundPerCriteria = useTradeTableValueGenerator(
    BACKGROUND_BY_CRITERIA_ENUM,
    mapRiskParams,
    companyBackgroundTemplate,
    isFetchingMapRiskParams
  );

  const initialValues: CompanyBackgroundProps = {
    background_extra_info: accountComments?.background_extra_info ?? "",
    background: backgroundPerCriteria,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={console.log}
      enableReinitialize
    >
      {(formikProps) => {
        const { background_extra_info, background } = formikProps.values;
        return (
          <Form>
            <Stack spacing={2}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1em",
                  p: 2,
                  bgcolor: theme.palette.primary.main,
                  borderRadius: 1,
                  color: theme.palette.background.default,
                }}
              >
                Company Summary
              </Typography>
              <VariableInput
                sx={{
                  ".MuiInputBase-root": {
                    height: "15vh",
                    backgroundClip: "border-box",
                    borderRadius: "10px",
                    textarea: {
                      height: "13vh !important",
                      overflow: "scroll !important",
                    },
                  },
                }}
                labelClassName={styles.labelClass}
                name={"background_extra_info"}
                placeholder={"Enter your observations here..."}
                label={""}
                handleSave={(value) => {
                  id &&
                    editComments({
                      map_name: id,
                      account_type: accountType as "buyer" | "supplier",
                      data: {
                        background_extra_info: value as string,
                      },
                    });
                }}
                value={background_extra_info ?? ""}
                multiline
                style={{ width: "100%" }}
                fullWidth
                disabled={disabled}
                noHover={disabled}
              />
              <Table component={Paper}>
                <TableHead
                  sx={{
                    borderBottom: `3px solid ${theme.palette.error.main}`,
                  }}
                >
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <Typography fontSize={"1em"} fontWeight={"bold"}>
                        As per{" "}
                        {`${accountType?.toLowerCase() === BUYER?.toLowerCase() ? BUYER : SUPPLIER}`}{" "}
                        Call
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={"1em"} fontWeight={"bold"}>
                        As per Credit Report
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={"1em"} fontWeight={"bold"}>
                        As per Financial Statements
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isFetching && !companyBackgroundTemplate && (
                    <SkeletonLoad bars={10} />
                  )}
                  {!isFetching &&
                    companyBackgroundTemplate &&
                    Object.entries(companyBackgroundTemplate).map(
                      ([key, keyParams]) => (
                        <TableRow key={key}>
                          <TableCell>
                            <Typography fontSize={"1em"} fontWeight={"bold"}>
                              {key}
                            </Typography>
                          </TableCell>
                          {Object.entries(background).map(
                            ([asPerCategory, observation]) => {
                              const fieldName = `background.[${asPerCategory}].${toCamelCase(key)}`;
                              return (
                                <TableCell key={asPerCategory}>
                                  {keyParams.type === "formula" ? (
                                    <LabelViewOnly label={"formula"} />
                                  ) : (
                                    <VariableInput
                                      name={fieldName}
                                      label={undefined}
                                      value={
                                        observation[toCamelCase(key)] as any
                                      }
                                      type={keyParams?.type as InputType}
                                      handleSave={(value) => {
                                        id &&
                                          editBackground({
                                            map_name: id,
                                            account_type: accountType as
                                              | "buyer"
                                              | "supplier",
                                            data: {
                                              map_metadata_id: keyParams?.id,
                                              source: asPerCategory,
                                              value,
                                            },
                                          });
                                      }}
                                      disabled={disabled}
                                      noHover={disabled}
                                      {...formikProps}
                                    />
                                  )}
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
export default CompanyBackground;
