import { Box, Grid, Slide } from "@mui/material";
import { useGetPlatformUsageQuery } from "api/api-marketing";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS } from "codes";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CumulativeChart from "components/PlatformUsage/CumulativeChart";
import CumulativeTable from "components/PlatformUsage/CumulativeTable";
import MonthlyChart from "components/PlatformUsage/MonthlyChart";
import MonthlyTable from "components/PlatformUsage/MonthlyTable";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useRef } from "react";

const PlatformUsage = () => {
  const isMobile = useIsMobile();
  const {
    data: platformUsage,
    isFetching,
    isLoading,
    refetch,
  } = useGetPlatformUsageQuery(null);
  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();

  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref, 1000) : 1000;
  const headerLength = Object.keys(platformUsage?.[0] ?? {})?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.2 : 200;

  if (isFetching) return <SkeletonLoad bars={10} />;
  if (!isFetching && platformUsage)
    return (
      <Slide in direction={"left"}>
        <Box
          sx={{ display: "flex", flexShrink: "0 !important" }}
          flexGrow={1}
          id="platform-usage-statistics"
        >
          <Layout
            title={"Platform Usage Statistics"}
            textHeadingOptions={{
              fontWeight: "bolder",
              level: 2,
              fontSize: isMobile ? "1.2em" : "1.5em",
            }}
            headerConfig={{
              syncAction: refetch,
              left: 8,
              mid: 0,
              right: 2,
              xs: {
                left: 12,
                mid: 0,
                right: 0,
              },
              alignItems: "center",
            }}
            mode="default"
          >
            <Grid container spacing={3} sx={{ width: "100%", flexGrow: 1 }}>
              <Grid item xs={12} md={6} id="cumulative-chart-container">
                <CumulativeChart />
              </Grid>
              <Grid item xs={12} md={6} id="monthly-chart-container">
                <MonthlyChart />
              </Grid>
              <Grid item xs={12} md={12} id="cumulative-table-container">
                <CumulativeTable />
              </Grid>
              <Grid item xs={12} md={12} id="monthly-table-container">
                <MonthlyTable />
              </Grid>
            </Grid>
          </Layout>
        </Box>
      </Slide>
    );
  return <></>;
};
export default PlatformUsage;
