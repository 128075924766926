import { Box, Chip, Drawer, Stack, useMediaQuery } from "@mui/material";
import { useColorScheme, useTheme } from "@mui/material/styles";

import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";
import packageInfo from "../../../../package.json";

import MenuList from "./MenuList";

import SocialMedia from "components/Navigation/SocialMedia";
import { Dispatch, SetStateAction } from "react";
import LogoSection from "../Header/LogoSection";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({
  drawerOpen,
  drawerToggle,
}: {
  drawerOpen: boolean;
  drawerToggle: Dispatch<SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const { mode } = useColorScheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const drawerWidth = 260;
  const light = mode === "light";

  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box sx={{ display: "flex", p: 2, mx: "auto" }}>
          <LogoSection light={light} />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? "calc(100vh - 56px)" : "calc(100vh - 88px)",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <Stack justifyContent={"space-between"} height={"100%"}>
            <Stack direction="column" justifyContent="center" sx={{ mb: 2 }}>
              <MenuList />
            </Stack>
            <Stack direction="column" justifyContent="center" sx={{ mb: 2 }}>
              <SocialMedia drawerOpen={drawerOpen} />
              <Chip
                label={`v ${packageInfo.version}`}
                disabled
                color="primary"
                size="small"
                sx={{ maxWidth: 100, margin: "auto", opacity: "1 !important" }}
              />
            </Stack>
          </Stack>
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <Stack justifyContent={"space-between"} height={"100%"}>
            <Stack direction="column" justifyContent="center" sx={{ mb: 2 }}>
              <MenuList />
            </Stack>
            <Stack direction="column" justifyContent="center" sx={{ mb: 2 }}>
              <SocialMedia drawerOpen={drawerOpen} />
              <Chip
                label={`v ${packageInfo.version}`}
                disabled
                color="primary"
                size="small"
                sx={{ maxWidth: 100, margin: "auto", opacity: "1 !important" }}
              />
            </Stack>
          </Stack>
        </Box>
      </MobileView>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? drawerWidth : "auto",
        bgcolor: theme.palette.background.default,
      }}
      aria-label="sidebar-box"
    >
      <Drawer
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            bgcolor: theme.palette.background.default,
            color: theme.palette.primary.main,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "88px",
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
