import { Stack } from "@mui/material";
import CargoDisplay from "components/Cargo/CargoDisplay";
import CargoTracking from "components/Cargo/CargoTracking";
import TrackingList from "components/Cargo/TrackingList";
import { useState } from "react";

const CargoTrackerPage = () => {
  const [selectedShipmentId, setSelectedShipmentId] = useState<string>("");
  return (
    <Stack spacing={1}>
      <CargoTracking />
      <TrackingList setSelectedShipmentId={setSelectedShipmentId} />
      {Boolean(selectedShipmentId) && (
        <CargoDisplay shipmentId={selectedShipmentId} />
      )}
    </Stack>
  );
};
export default CargoTrackerPage;
