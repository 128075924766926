import {
  ClickAwayListener,
  Stack,
  SxProps,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import styles from "./vi.module.scss";

import { FormikProps } from "formik";
import moment from "moment";

import DateInput, { DateInputProps } from "../DateInput";
import LabelViewOnly from "../FormFields/LabelViewOnly";
import UtilityBar from "../UtilityBar";

const VariableDiv = styled("div")<{ open: boolean }>`
  ${({ open }) => (open ? `padding-bottom: 16px` : `padding-bottom: 0`)};
  padding-left: 8px;
  padding-top: 4px;
  ${({ open }) => (open ? `height: 0; padding: 0` : `height: auto`)};
  ${({ open }) => (open ? `width: 0` : `width: 100%`)};
  border-radius: 6px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) =>
      alpha(theme.palette.secondary.main, 0.5)};
    cursor: text;
  }
`;

type T = DateInputProps;
const VariableDate = (
  props: T & {
    handleSave?: (value: T["value"]) => void;
    sx?: SxProps;
    containerSx?: SxProps;
  } & Partial<FormikProps<any>>
) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const theme = useTheme();

  const handleClose = () => {
    props?.handleSave &&
      props.value !== undefined &&
      props?.handleSave(props.value);
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const { helpText, ...rest } = props;
  return (
    <div onClick={handleClick}>
      {!toggle && (
        <>
          {props.label && (
            <LabelViewOnly
              labelClassName={`${styles.labelClass} ${props.labelClassName}`}
              label={props.label}
              helpText={helpText}
              sx={{
                letterSpacing: "0.01em !important",
                fontWeight: "bold",
                paddingLeft: "4px",
                "&:hover": {
                  cursor: "default",
                },
              }}
            />
          )}

          <VariableDiv
            open={toggle}
            id={`variable-div-${props?.name}`}
            sx={props?.containerSx}
            onClick={() => setToggle(true)}
          >
            <LabelViewOnly
              {...rest}
              label={
                `${props?.value ? moment(props?.value).format("DD/MM/YYYY") : "None"}` ||
                "None"
              }
              sx={{
                ...props.sx,
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
                color: Boolean(props.value)
                  ? theme.palette.primary.main
                  : `#5a5a5a !important`,
                cursor: "text",
                fontWeight: Boolean(props.value)
                  ? "500 !important"
                  : `300 !important`,
                letterSpacing: "0.01em !important",
                textTransform: "initial",
              }}
            />
          </VariableDiv>
        </>
      )}

      {toggle && (
        <ClickAwayListener onClickAway={handleClose}>
          <Stack
            direction={"row"}
            spacing={1}
            justifyContent={"start"}
            alignItems={"end"}
          >
            <DateInput labelClassName={styles.labelClass} {...props} />
            <UtilityBar
              anchorEl={anchorEl}
              setToggle={setToggle}
              handleSave={props.handleSave}
              value={props.value}
              id={`variable-date-${props?.name}`}
            />
          </Stack>
        </ClickAwayListener>
      )}
    </div>
  );
};
export default VariableDate;
