import { TableCell, useTheme } from "@mui/material";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import valueCleaner from "helpers/valueCleaner";
import React from "react";

interface MapRiskParamValues {
  [source: string]: {
    [field_name: string]: any;
  };
}

interface FinancialGrowthIndicatorProps {
  values: MapRiskParamValues;
  fieldName: string;
}

const FinancialGrowthIndicator: React.FC<FinancialGrowthIndicatorProps> = ({
  values,
  fieldName,
}) => {
  const numbers = Object.values(
    Object.entries(values)
      ?.map(([year, value]) => {
        const financial = value[fieldName] as unknown as
          | string
          | number
          | undefined;

        return financial === undefined ||
          (typeof financial === "string" &&
            financial === "Can not calculate because of no value") ||
          (typeof financial === "string" &&
            financial.startsWith("Need input for this field"))
          ? { [Number(year)]: 0 }
          : { [Number(year)]: Number(valueCleaner(`${financial}`)) };
      })
      .reduce(
        (acc, curr) => {
          const year = Number(Object.keys(curr)[0]);
          const value = Object.values(curr)[0];
          acc[year] = value;
          return acc;
        },
        {} as Record<number, number>
      )
  )?.reverse();

  const calculateGrowth = (values: number[]) =>
    ((values[0] - values[1]) / (values[1] > 0 ? values[1] : 1)) * 100;

  const growth = calculateGrowth(numbers);
  const theme = useTheme();
  const [symbol, color] =
    growth > 0
      ? [`▲`, theme.palette.success.main]
      : growth === 0
        ? [``, theme.palette.text.primary]
        : [`▼`, theme.palette.error.main];

  return (
    <TableCell sx={{ textAlign: "left" }}>
      <LabelViewOnly
        label={`${symbol} ${growth?.toFixed(2) ?? 0}%`}
        sx={{ color, minWidth: 80 }}
      />
    </TableCell>
  );
};

export default FinancialGrowthIndicator;
