import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V1_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { SalesLead } from "types";

export const sales = createApi({
  reducerPath: "sales",
  tagTypes: ["CREDIT_LIMITS"],
  baseQuery: axiosBaseQuery({
    baseUrl: VITE_API_V1_URL,
  }),
  endpoints: (builder) => ({
    fetchRm: builder.query<{ url: string; rm_name: string }, string>({
      query: (company_id) => ({
        url: `/companies/${company_id}/rm`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),

    getSFOpportunity: builder.query<SalesLead[], string>({
      query: (salesforceOpportunityId) => ({
        url: `/sf-opportunity/${salesforceOpportunityId}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const { useFetchRmQuery, useGetSFOpportunityQuery } = sales;
