import Loader from "components/Common/Loader";
import { ComponentType, Suspense } from "react";

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P) => (
    <Suspense fallback={<Loader open />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;
