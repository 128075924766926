import type { BankAccount } from "types";
import * as Yup from "yup";

export const initialValues: BankAccount = {
  name: "",
  bank_name: "",
  bank_account_number: "",
  beneficiary_name: "",
  currency: "",

  bank_address: {
    address_line1: "",
    address_line2: "",
    city: "",
    country: "",
    postcode: "",
  },
  swift_code: "",
};

export const bankAccountvalidationSchema = Yup.object().shape({
  bank_name: Yup.string().required("Please fill the name of your bank"),
  bank_account_number: Yup.string().required(
    "Please fill the number/IBAN of your bank account"
  ),
  beneficiary_name: Yup.string().required("Please fill a beneficiary name"),
  currency: Yup.string().required("Please select the currency of your bank"),
  country: Yup.string().required("Please select the country of your bank"),
  bank_address: Yup.object().shape({
    address_line1: Yup.string().required(
      "Please fill the address of your bank"
    ),
    address_line2: Yup.string().required(
      "Please fill the address of your bank"
    ),
    city: Yup.string().required("Please fill the city of your bank"),
    postcode: Yup.string().required("Please fill the postcode of your bank"),
  }),
  swift_code: Yup.string()
    .required("Please fill the swift/BIC code of your bank")
    .matches(/^[A-Za-z0-9]{8,11}$/, "Swift/BIC is between 8 to 11 characters"),
});
