import moment from "moment";
import isObjectEmpty from "./isObjectEmpty";

export default function tokenValidate() {
  const token: Record<string, any> = JSON.parse(
    localStorage.getItem("token") ?? "{}"
  );
  if (isObjectEmpty(token)) return { loggedIn: false, expired: false };
  const { expiry } = token;
  const timeNow = moment();
  const stillValid = moment(expiry).isAfter(timeNow);
  return { loggedIn: true, expired: stillValid };
}
