import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { InvoiceStatus } from "types";

interface InvoiceStats {
  funding_power: {
    total_limit: number;
    total_limit_used: number;
  };
  origination_power: {
    total_credit_limit: number;
    credit_limit_available: number;
  };
}

export interface MonthlyInvoiceStats {
  total_amount_financed: number;
  financed_per_buyer: {
    buyer: string;
    total_financed_amount: number;
    percentage: number;
  }[];
}

export interface DailyInvoiceStats {
  date: string;
  financed_per_buyer: {
    buyer: string;
    amount: number;
  }[];
}

export interface PlatformInvoiceStats {
  status: string;
  amount: number;
}

interface TaxInvoice {
  sf_tax_invoice_id: string;
  sf_tax_invoice_name: string;
  invoice_reference_number: string;
  invoice_date: Date;
  transaction_type: string;
  currency: string;
  tax_rate_percentage: number;
  exchange_rate: number;
  invoice_amount: number;
  invoice_amount_sgd_equiv: number;
  total_amount: number;
  total_amount_sgd_equiv: number;
  gst_amount: number;
  gst_amount_sgd_equiv: number;
  tax_invoice_type: string;
}

interface TaxInvoiceFees {
  amount_exclude_gst: number;
  description: string;
  quantity: number;
  unit_price: number;
}

interface Payee {
  customer_name: string;
  customer_address: string;
  city: string;
  postal_code: string;
  country: string;
  contact_name: string;
  contact_email: string;
  contact_phone_number: string;
  alternative_email: string;
}
interface TaxInvoiceData {
  sf_tax_invoice_id: string;
  sf_tax_invoice_name: string;
  invoice_reference_number: string;
  invoice_date: Date;
  transaction_type: string;
  currency: string;
  tax_rate_percentage: number;
  exchange_rate: number;
  invoice_amount: number;
  invoice_amount_sgd_equiv: number;
  total_amount: number;
  total_amount_sgd_equiv: number;
  gst_amount: number;
  gst_amount_sgd_equiv: number;
  tax_invoice_type: string;
  sf_supplier_buyer_map_name: string;
  supplier_invoice_reference_number: string;
  buyer: Payee;
  supplier: Payee;
  tax_invoice_fees: TaxInvoiceFees[];
}

interface GenerateInvoice {
  customer: Payee;
  invoice_date: Date;
  invoice_reference_number: string;
  sf_tax_invoice_id: string;
  currency: string;
  tax_rate_percentage: number;
  exchange_rate: number;
  invoice_amount: number;
  gst_amount: number;
  total_amount: number;
  balance_due: number;
  tax_invoice_type: string;
  transaction_type: string;
  tax_invoice_fees: TaxInvoiceFees[];
  supplier_invoice_reference_number: string;
}

interface GeneratedInvoice {
  id: string;
  sf_invoice_name: string;
  sf_tax_invoice_id: string;
  invoice_reference_number: string;
  internal_name: string;
  currency: string;
  tax_rate_percentage: number;
  exchange_rate: number;
  invoice_amount: number;
  total_amount: number;
  gst_amount: number;
  balance_due: number;
  tax_invoice_type: string;
  transaction_type: string;
  invoice_date: Date;
  customer: Payee;
  tax_invoice_fees: TaxInvoiceFees[];
  supplier_invoice_reference_number: string;
}

export const operations = createApi({
  reducerPath: "operations",
  tagTypes: [
    "INVOICE_STATUS",
    "ROLLS",
    "TAX_INVOICES",
    "SF_TAX_INVOICES",
    "TAX_INVOICE",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/operations`,
  }),
  endpoints: (builder) => ({
    getInvoiceStatuses: builder.query<
      {
        [statusName: string]: InvoiceStatus[];
      },
      null
    >({
      query: () => ({ url: "/invoice-statuses", method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["INVOICE_STATUS"],
    }),
    getRolls: builder.query<
      {
        [statusName: string]: InvoiceStatus[];
      },
      null
    >({
      query: () => ({ url: "/invoice-rolls", method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["ROLLS"],
    }),
    getInvoiceStats: builder.query<InvoiceStats, string>({
      query: (invoice_id) => ({ url: `/invoice/${invoice_id}`, method: "get" }),
      transformResponse: (response) => response?.data,
    }),
    getMonthlyInvoiceStats: builder.query<MonthlyInvoiceStats, null>({
      query: () => ({
        url: `/invoices/monthly-financed`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getDailyInvoiceStats: builder.query<DailyInvoiceStats[], null>({
      query: () => ({
        url: `/invoices/monthly-financed-by-day`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getPlatformInvoiceStats: builder.query<PlatformInvoiceStats[], null>({
      query: () => ({
        url: `/invoices/available-on-platform`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),

    getTaxInvoices: builder.query<TaxInvoice[], string>({
      query: (sf_invoice_name) => ({
        url: `/invoices/${sf_invoice_name}/tax-invoices`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SF_TAX_INVOICES"],
    }),
    getTaxInvoice: builder.query<
      TaxInvoiceData,
      { sf_invoice_name: string; sf_tax_invoice_id: string }
    >({
      query: ({ sf_invoice_name, sf_tax_invoice_id }) => ({
        url: `/invoices/${sf_invoice_name}/tax-invoices/${sf_tax_invoice_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    generateTaxInvoice: builder.mutation<
      any,
      GenerateInvoice & { sf_invoice_name: string }
    >({
      query: (payload) => {
        const { sf_invoice_name, ...data } = payload;
        return {
          url: `/invoices/${sf_invoice_name}/tax-invoices`,
          method: "post",
          data,
        };
      },
      invalidatesTags: ["TAX_INVOICES", "SF_TAX_INVOICES"],
    }),
    getGeneratedTaxInvoices: builder.query<GeneratedInvoice[], void>({
      query: () => ({ url: `/tax-invoices`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["TAX_INVOICES"],
    }),
    getGeneratedTaxInvoice: builder.query<GeneratedInvoice, string>({
      query: (internal_name) => ({
        url: `/tax-invoices/${internal_name}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TAX_INVOICE"],
    }),
  }),
});

export const {
  useGetInvoiceStatusesQuery,
  useGetRollsQuery,
  useGetInvoiceStatsQuery,
  useGetMonthlyInvoiceStatsQuery,
  useGetDailyInvoiceStatsQuery,
  useGetPlatformInvoiceStatsQuery,
  useGetTaxInvoicesQuery,
  useGetTaxInvoiceQuery,
  useGenerateTaxInvoiceMutation,
  useGetGeneratedTaxInvoicesQuery,
  useGetGeneratedTaxInvoiceQuery,
} = operations;
