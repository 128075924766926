import valueCleaner from "helpers/valueCleaner";
import moment from "moment";
import type { UploadDocs } from "types";
import * as Yup from "yup";
//eslint-disable-next-line prefer-regex-literals
const dateRegex = new RegExp(/\d{1,2}\/\d{1,2}\/\d{2,4}$/);
export interface RegistrationStep {
  companyType: string;
  country: string;
  name: string;
  registrationNumber: string;
  registrationFileOcr: any;
  incorporation_date: Date | undefined;
  userIDocr: any;
  companyNameInitial: string;
  kycAutomation: boolean;
  caseCommonId: string;
  idFile: UploadDocs;
  address_line1: string;
  address_line2: string;
  postcode: string;
  website: string;
  industry: string;
  goods_category: string;
  city: string;
  iso3: string;
  companyId: string;
  uploaded?: {
    [documentName: string]: boolean;
  };
  docs: {
    [documentName: string]: UploadDocs;
  };
  call_error?: string;
  //IPS-893
  firstName: string;
  lastName: string;
  nationality: string;
  dateOfBirth: Date;
  expiryDate: Date;
  idNumber: string;
  is_identified: boolean;
  annual_revenue: string;
  [x: string]: any;
}
const minimumDate = moment().subtract(18, "years");
const minimumExpiry = moment().add(90, "days");

export const registrationStepValues: RegistrationStep = {
  companyType: "",
  country: "",
  name: "",
  website: "",
  registrationNumber: "",
  incorporation_date: undefined,
  registrationFileOcr: {},
  userIDocr: {
    "Date of birth": {
      type: "date",
      value: minimumDate.toDate(),
    },
    "Expiry Date": {
      type: "date",
      value: minimumExpiry.toDate(),
    },
    "First Name": {
      type: "text",
      value: "",
    },
    "Last Name": {
      type: "text",
      value: "",
    },
    Nationality: {
      type: "text",
      value: "",
    },
    "Passport Number": {
      type: "text",
      value: "",
    },
  },
  kycAutomation: false,
  caseCommonId: "",
  companyNameInitial: "",
  address_line1: "",
  address_line2: "",
  postcode: "",
  city: "",
  industry: "",
  goods_category: "",
  iso3: "",
  docs: {},
  companyId: "",
  idFile: {
    id: "",
    name: "",
    url: "",
    empty: true,
  },
  firstName: "",
  lastName: "",
  nationality: "",
  dateOfBirth: new Date(minimumDate.toDate()),
  expiryDate: new Date(minimumExpiry.toDate()),
  idNumber: "",
  is_identified: false,
  annual_revenue: "0",
};

export const validationSchemaRegistrationStep = Yup.object().shape({
  companyType: Yup.string(),
  country: Yup.string().required("Country is Required"),
  name: Yup.string()
    .required("Company Name is Required")
    .min(3, "Company Name should be atleast 3 characters")
    .max(100, "Maximum character limit reached!"),
  incorporation_date: Yup.date()
    .required("Company Incorporation Date is required")
    .test({
      name: "incorporation_date",
      exclusive: false,
      params: {},
      message:
        "Please ensure your Company Incorporation Date is of a valid format",
      test: (value: string) =>
        //eslint-disable-next-line prefer-regex-literals
        dateRegex.test(moment(value).format("DD/MM/YYYY")),
    } as any)
    .nullable()
    .typeError("Please enter Company Incorporation Date")
    .max(
      moment().add(100, "years").toDate(),
      () => `Please enter a valid Company Incorporation Date`
    ),
  registrationNumber: Yup.string()
    .required("Registration Number is Required")
    .matches(
      /^[0-9A-Za-z- (./_\\)]{3,100}$/,
      "Please ensure your Registration Number is of a valid format"
    ),

  industry: Yup.string().required("Industry is Required"),
  goods_category: Yup.string().required("Goods Category is Required"),
  registrationFileOcr: Yup.mixed(),
  registrationFile: Yup.mixed(),
  idFile: Yup.mixed(),
  userIDocr: Yup.mixed(),
  companyNameInitial: Yup.string().required("Please enter your Company Name"),
  kycAutomation: Yup.boolean(),
  caseCommonId: Yup.string(),
  address_line1: Yup.string().required("Please enter your Address"),
  address_line2: Yup.string().nullable(),
  postcode: Yup.string().nullable(),
  city: Yup.string().nullable(),
  iso3: Yup.string(),
  website: Yup.string().nullable(),
  companyId: Yup.string(),
  docs: Yup.mixed(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  nationality: Yup.string(),
  dateOfBirth: Yup.date()
    .nullable()
    .typeError("Please enter your Date Of Birth"),
  expiryDate: Yup.date().nullable().typeError("Please enter ID Expiry Date"),
  idNumber: Yup.string().min(
    3,
    "Minimum 3 characters are required for ID Number"
  ),

  annual_revenue: Yup.string()
    .required("Annual Revenue is Required")
    .test({
      name: "annual_revenue",
      exclusive: false,
      params: {},
      message: "Annual Revenue must not be zero or a negative number",
      test: (value: string) => valueCleaner(value) > 0,
    } as any),
});

export const validationSchemaUploadCompanyDocuments = Yup.object().shape({
  certificateOfIncorporation: Yup.array().typeError(
    "Certificate of Incorporation is required"
  ),
  proofOfRegisteredAddress: Yup.array()
    .min(1, "Proof Of Registered Address is required")
    .typeError("Proof Of Registered Address is required"),
});
