import { Grid } from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import CheckboxField from "components/Common/FormFields/CheckboxField";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";
import { AgentProps } from "./constants";

const SecondaryContactDetails = () => {
  const formikProps = useFormikContext<AgentProps>();
  return (
    <MainCard title={"Secondary Contact Details"} content boxShadow>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Input
            name="secondaryContactName"
            label="Secondary Contact Name"
            fullWidth
            sx={{ width: "100%" }}
            value={formikProps.values.secondaryContactName}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            fullWidth
            sx={{ width: "100%" }}
            name="secondaryContactTitle"
            label="Secondary Contact Title"
            value={formikProps.values.secondaryContactTitle}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            fullWidth
            sx={{ width: "100%" }}
            name="secondaryContactEmail"
            label="Secondary Contact Email"
            value={formikProps.values.secondaryContactEmail}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={21}>
          <Input
            fullWidth
            sx={{ width: "100%" }}
            name="secondaryContactPhone"
            label="Secondary Contact Phone"
            value={formikProps.values.secondaryContactPhone}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
            fullWidth
            sx={{ width: "100%" }}
            label={"Secondary Marketplace User"}
            name="isSecondaryMarketplaceUser"
            value={formikProps.values.isSecondaryMarketplaceUser}
            {...formikProps}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};
export default SecondaryContactDetails;
