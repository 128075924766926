import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import {
  Director,
  Shareholder,
} from "components/Map/Sections/Compliance/Screening/types";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import { IChecklistData } from "../components/Map/Sections/Compliance/Scoring/Checklist/types";
import { accountrtk } from "./api-accounts";
import { maps } from "./api-maps";

interface ScoreObject {
  [key: string]: {
    label: string;
    score: number;
  };
}

export type MAP_TYPE =
  | "credit_report"
  | "screening"
  | "evaluation"
  | "check_list";

interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    data: string | number | string[];
  };
}

interface CreditReportResponse {
  [source: string]: {
    [field_name: string]: {
      name: string;
      id: string;
    };
  };
}

interface CreditReportDocument {
  id: string;
  filename: string;
  document_type_category: string;
  document_type: string;
  submitted: string;
  year: number | null;
}

interface AddCreditReportDocument {
  file: File;
  document_type: string;
  document_category: string;
}

interface ScreeningStatusItem {
  [key: string]: string[];
}

interface ScreeningStatus {
  [key: string]: ScreeningStatusItem;
}

export const compliance = createApi({
  reducerPath: "compliance",
  tagTypes: [
    "TEMPLATES",
    "SUMMARIES",
    "TABLE_VALUES",
    "SHAREHOLDERS",
    "DIRECTORS",
    "SCREENING",
    "CHECKLIST",
    "COMPLIANCE_SCORE",
    "PROVIDERS",
    "SCREENING_TOOLS",
    "CREDIT_REPORT_DOCUMENTS",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}`,
  }),
  endpoints: (builder) => ({
    getTemplates: builder.query<MapTemplate, MAP_TYPE>({
      query: (template_name) => ({
        url: `/maps/templates/compliance/${template_name}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TEMPLATES"],
    }),
    getCreditReport: builder.query<
      CreditReportResponse,
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/maps/${map_name}/${account_type}/credit-report`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TABLE_VALUES"],
    }),
    getCreditReportDocuments: builder.query<CreditReportDocument[], string>({
      query: (account_id) => ({
        url: `/compliance/${account_id}/credit-report-documents`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CREDIT_REPORT_DOCUMENTS"],
    }),
    addCreditReportDocument: builder.mutation<
      any,
      { account_id: string; data: AddCreditReportDocument }
    >({
      query: ({ account_id, data }) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("document_type", data.document_type);
        formData.append("document_category", data.document_category);
        return {
          url: `/accounts/${account_id}/documents`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["CREDIT_REPORT_DOCUMENTS", "TABLE_VALUES"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(maps.util.invalidateTags(["TABLE_VALUES"]));
          dispatch(accountrtk.util.invalidateTags(["ACCOUNT_DOCS"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error deleting director:", error);
        }
      },
    }),
    getChecklist: builder.query<
      IChecklistData,
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/maps/${map_name}/${account_type}/checklist`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CHECKLIST"],
    }),
    getComplianceScoring: builder.query<
      ScoreObject,
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/maps/${map_name}/${account_type}/checklist/compliance-risk-score`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["COMPLIANCE_SCORE"],
    }),
    getSummaries: builder.query<
      { deal: string; supplier: string; buyer: string },
      string
    >({
      query: (map_name) => ({
        url: `/maps/${map_name}/summaries`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SUMMARIES"],
    }),
    editSummaries: builder.mutation<
      any,
      {
        map_name: string;
        data: {
          summary: string;
          subject: string;
        };
      }
    >({
      query: ({ map_name, data }) => {
        return {
          url: `/maps/${map_name}/summaries`,
          method: "PUT",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SUMMARIES"],
      // async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      //   try {
      //     await queryFulfilled;
      //     dispatch(compliance.util.invalidateTags(["TABLE_VALUES"]));
      //     dispatch(maps.util.invalidateTags(["TABLE_VALUES"]));
      //   } catch (error) {
      //     //eslint-disable-next-line no-console
      //     console.error("Error editing credit report:", error);
      //   }
      // },
    }),
    generateFactSheet: builder.query<any, string>({
      query: (map_name) => ({
        url: `/maps/${map_name}/investment-criteria-card/export`,
        method: "get",
      }),
      // transformResponse: (response) => response?.data,
      providesTags: [],
    }),
    editCreditReport: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        data: {
          map_metadata_id: string;
          source: string;
          value: any;
        };
      }
    >({
      query: ({ map_name, account_type, data }) => {
        return {
          url: `/maps/${map_name}/${account_type}`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TABLE_VALUES", "CHECKLIST", "COMPLIANCE_SCORE"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(compliance.util.invalidateTags(["TABLE_VALUES"]));
          dispatch(maps.util.invalidateTags(["TABLE_VALUES"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error editing credit report:", error);
        }
      },
    }),
    getScreening: builder.query<
      Record<string, any>,
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/maps/${map_name}/${account_type}/screening`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TABLE_VALUES", "SCREENING"],
    }),
    getScreeningShareholders: builder.query<
      Shareholder[],
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/maps/${map_name}/${account_type}/screening/shareholders`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SHAREHOLDERS"],
    }),
    getScreeningDirectors: builder.query<
      Director[],
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/maps/${map_name}/${account_type}/screening/directors`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SHAREHOLDERS", "DIRECTORS"],
    }),
    editScreeningCompany: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        data: {
          map_metadata_id: string;
          source: string;
          value: any;
        };
      }
    >({
      query: ({ map_name, account_type, data }) => {
        return {
          url: `/maps/${map_name}/${account_type}`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SCREENING", "COMPLIANCE_SCORE", "CHECKLIST"],
    }),
    editCheckListCompany: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        data: {
          map_metadata_id: string;
          source: string;
          value: any;
        };
      }
    >({
      query: ({ map_name, account_type, data }) => {
        return {
          url: `/maps/${map_name}/${account_type}`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SCREENING", "CHECKLIST"],
    }),
    editScreeningCompanyBatch: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        data: {
          map_metadata_id: string;
          source: string;
          value: any;
        }[];
      }
    >({
      query: ({ map_name, account_type, data }) => {
        return {
          url: `/maps/${map_name}/${account_type}/batch`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SCREENING", "COMPLIANCE_SCORE", "CHECKLIST"],
    }),
    editScreeningShareholder: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        shareholder_id: string;
        data: {
          status: string;
          remarks: string;
          source: string;
        };
      }
    >({
      query: ({ map_name, account_type, shareholder_id, data }) => {
        return {
          url: `/maps/${map_name}/${account_type}/screening/shareholders/${shareholder_id}`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SHAREHOLDERS"],
    }),
    editScreeningShareholderBatch: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        data: {
          shareholder_id: string;
          status: string;
          remarks: string;
          source: string;
        }[];
      }
    >({
      query: ({ map_name, account_type, data }) => {
        return {
          url: `/maps/${map_name}/${account_type}/screening/shareholders/batch`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SHAREHOLDERS"],
    }),
    editScreeningDirector: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        director_id: string;
        data: {
          status: string;
          remarks: string;
          source: string;
        };
      }
    >({
      query: ({ map_name, account_type, director_id, data }) => {
        return {
          url: `/maps/${map_name}/${account_type}/screening/directors/${director_id}`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["DIRECTORS"],
    }),
    editScreeningDirectorBatch: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        data: {
          director_id: string;
          status: string;
          remarks: string;
          source: string;
        }[];
      }
    >({
      query: ({ map_name, account_type, data }) => {
        return {
          url: `/maps/${map_name}/${account_type}/screening/directors/batch`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["DIRECTORS"],
    }),
    postShareholderProof: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        shareholder_id: string;
        data: {
          file: File;
          source: string;
        };
      }
    >({
      query: ({ map_name, account_type, shareholder_id, data }) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("source", data.source);
        return {
          url: `/maps/${map_name}/${account_type}/screening/shareholders/${shareholder_id}/documents`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SHAREHOLDERS"],
    }),
    postDirectorProof: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        director_id: string;
        data: {
          file: File;
          source: string;
        };
      }
    >({
      query: ({ map_name, account_type, director_id, data }) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("source", data.source);
        return {
          url: `/maps/${map_name}/${account_type}/screening/directors/${director_id}/documents`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["DIRECTORS"],
    }),
    getScreeningDocumentUrl: builder.query<
      {
        id: string;
        name: string;
        url: string;
        document_type: string;
      },
      { map_name: string; account_type: string; document_id: string }
    >({
      query: ({
        map_name,
        account_type,
        document_id,
      }: {
        map_name: string;
        account_type: string;
        document_id: string;
      }) => ({
        url: `/maps/${map_name}/${account_type}/screening/documents/${document_id}`,
        method: "get",
      }),
      transformResponse: (response) => response.data,
    }),
    postCompanyProof: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        map_metadata_id: string;
        data: {
          file: File;
          source: string;
        };
      }
    >({
      query: ({ map_name, account_type, map_metadata_id, data }) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("source", data.source);
        formData.append("map_metadata_id", map_metadata_id);
        return {
          url: `/maps/${map_name}/${account_type}/screening/documents`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SCREENING"],
    }),
    getComplianceProviders: builder.query<string[], void>({
      query: () => ({
        url: `/compliance/providers`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["PROVIDERS"],
    }),
    getComplianceScreeningTools: builder.query<string[], void>({
      query: () => ({
        url: `/compliance/screening-tools`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SCREENING_TOOLS"],
    }),
    getScreeningStatus: builder.query<
      ScreeningStatus,
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/maps/${map_name}/${account_type}/screening-completeness`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SCREENING", "CHECKLIST"],
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useGetCreditReportQuery,
  useGetChecklistQuery,
  useGetComplianceScoringQuery,
  useEditCreditReportMutation,
  useGetScreeningQuery,
  useGetScreeningShareholdersQuery,
  useGetScreeningDirectorsQuery,
  useEditScreeningCompanyMutation,
  useEditCheckListCompanyMutation,
  useGetSummariesQuery,
  useEditSummariesMutation,
  useGenerateFactSheetQuery,
  useEditScreeningCompanyBatchMutation,
  useEditScreeningShareholderMutation,
  useEditScreeningShareholderBatchMutation,
  useEditScreeningDirectorMutation,
  useEditScreeningDirectorBatchMutation,
  usePostShareholderProofMutation,
  usePostDirectorProofMutation,
  usePostCompanyProofMutation,
  useGetScreeningDocumentUrlQuery,
  useGetComplianceProvidersQuery,
  useGetComplianceScreeningToolsQuery,
  useGetCreditReportDocumentsQuery,
  useAddCreditReportDocumentMutation,
  useGetScreeningStatusQuery,
} = compliance;
