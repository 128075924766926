import { Box, Paper, Tab, Tabs } from "@mui/material";
import { useGetComplianceScreeningToolsQuery } from "api/api-compliance";
import SkeletonLoad from "components/Common/SkeletonLoad";
import React, { useState } from "react";
import IDVerification from "./IDVerification";
import ScreenByProvider from "./ScreenByProvider";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ComplianceScreening = ({
  accountType,
  isLocked,
}: {
  accountType: string;
  isLocked: boolean;
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data: SCREENING_TOOLS = [], isLoading } =
    useGetComplianceScreeningToolsQuery();

  return (
    <>
      {isLoading && <SkeletonLoad bars={10} />}
      {!isLoading && (
        <Paper sx={{ width: "100%", padding: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="compliance screening tabs"
            centered
            variant="fullWidth"
          >
            {SCREENING_TOOLS?.map((provider, index: number) => (
              <Tab key={index} label={provider} {...a11yProps(index)} />
            ))}
            <Tab
              key={"id-verif"}
              label={"ID Verification"}
              {...a11yProps(SCREENING_TOOLS?.length)}
            />
          </Tabs>
          {SCREENING_TOOLS?.map((provider, index: number) => (
            <TabPanel key={index} value={value} index={index}>
              <ScreenByProvider
                provider={provider}
                accountType={accountType}
                isLocked={isLocked}
              />
            </TabPanel>
          ))}
          <TabPanel
            key={"id-verif"}
            value={value}
            index={SCREENING_TOOLS?.length}
          >
            <IDVerification accountType={accountType} isLocked={isLocked} />
          </TabPanel>
        </Paper>
      )}
    </>
  );
};

export default ComplianceScreening;
