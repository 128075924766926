import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface Countries {
  label: string;
  value: string;
}

export const aiForm = createApi({
  reducerPath: "aiform",
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_LCLITE_BE_URL}/api/v1/`,
  }),
  endpoints: (builder) => ({
    countries: builder.query({
      query: () => "country",
    }),
    saveRegistrationForm: builder.mutation({
      query: (payload) => ({
        url: `/saveregistrationdata`,
        method: "POST",
        body: payload,
        formData: true,
      }),
    }),
  }),
});

export const { useCountriesQuery, useSaveRegistrationFormMutation } = aiForm;
