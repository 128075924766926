import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";

interface IIncomlendReview {
  cl_id: string;
  cl_creator: string;
  is_rejected: boolean;
  send_message_to: {
    contact_description: string;
    email: string;
    name: string;
  };
  salesName: string;
}

const IncomlendReview = ({
  cl_id,
  cl_creator,
  is_rejected = false,
  send_message_to = { contact_description: "", email: "", name: "" },
  salesName,
}: IIncomlendReview) => {
  const formikProps = useFormikContext<{
    comments: string;
    action: "contact_creator" | "approve" | "reject" | "resubmit";
  }>();

  return (
    <Stack mt={1} px={1} spacing={1} width={"100%"}>
      <FormControl sx={{ mt: 1, mb: 0.5, p: 0.5 }}>
        <RadioGroup
          aria-labelledby="action"
          name="action"
          value={formikProps.values.action}
          onChange={(e) => {
            formikProps.setFieldValue("action", e.target.value);
          }}
        >
          <Stack mt={1} px={1} spacing={1} width={"100%"}>
            <FormControlLabel
              value={"contact_creator"}
              control={<Radio sx={{ p: 0, pr: 1 }} />}
              sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
              label={
                <Stack>
                  <Typography
                    fontWeight={"bold"}
                  >{`Request changes to the client: ${send_message_to?.name} (${send_message_to?.email})`}</Typography>
                  <Typography fontSize={"1em"}>
                    {`Send a mail to the client ${send_message_to?.name} requesting changes to the credit limit.`}
                  </Typography>
                  <Typography fontSize={"1em"}>
                    {`The RM ${salesName} will be copied in the mail.`}
                  </Typography>
                  {send_message_to?.contact_description !== "" && (
                    <Typography fontWeight={"bold"} color={"error"}>
                      {`(${send_message_to?.contact_description})`}
                    </Typography>
                  )}
                </Stack>
              }
            />
            <FormControlLabel
              value={"approve"}
              control={<Radio sx={{ p: 0, pr: 1 }} />}
              sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
              disabled={is_rejected}
              label={
                <Stack>
                  <Typography
                    fontWeight={"bold"}
                  >{`Approve Credit Limit`}</Typography>
                  <Typography variant={"caption"}>
                    {`Approve Credit Limit ${cl_id} with comments`}
                  </Typography>
                </Stack>
              }
            />
            <FormControlLabel
              value={"reject"}
              control={<Radio sx={{ p: 0, pr: 1 }} />}
              sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
              disabled={is_rejected}
              label={
                <Stack>
                  <Typography
                    fontWeight={"bold"}
                  >{`Reject Credit Limit`}</Typography>
                  <Typography variant={"caption"}>
                    {`Reject Credit Limit ${cl_id} with comments`}
                  </Typography>
                </Stack>
              }
            />
            <FormControlLabel
              value={"resubmit"}
              control={<Radio sx={{ p: 0, pr: 1 }} />}
              sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
              label={
                <Stack>
                  <Typography
                    fontWeight={"bold"}
                  >{`Resubmit Credit Limit`}</Typography>
                  <Typography variant={"caption"}>
                    {`Resubmit Credit Limit ${cl_id} with comments`}
                  </Typography>
                </Stack>
              }
            />
          </Stack>
        </RadioGroup>
      </FormControl>

      <Input
        sx={{
          ".MuiInputBase-root": {
            height: "22vh",
            backgroundClip: "border-box",
            borderRadius: "10px",
            textarea: {
              height: "20vh !important",
              overflow: "scroll !important",
            },
          },
        }}
        name={"comments"}
        label={""}
        placeholder={"Enter Comments"}
        {...formikProps}
        multiline
        style={{ width: "100%" }}
      />
    </Stack>
  );
};
export default IncomlendReview;
