import { Person } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useGetComplianceMapApprovalsQuery } from "api/api-maps";
import { BUYER, SUPPLIER } from "codes";
import { MapContext } from "pages/maps/map";
import { PERMISSIONS } from "permissions";
import * as React from "react";
import { useAppSelector } from "redux/hooks";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`compliance-basetabs-tabpanel-${index}`}
      aria-labelledby={`compliance-basetabs-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `compliance-basetabs-tab-${index}`,
    "aria-controls": `compliance-basetabs-tabpanel-${index}`,
  };
}

export default function ComplianceBaseTabs({
  Section,
}: {
  Section: React.ComponentType<{ accountType: string; isLocked: boolean }>;
}) {
  const [value, setValue] = React.useState(0);
  const mapId = React.useContext(MapContext);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { data: supplierApprovals } = useGetComplianceMapApprovalsQuery(
    { map_name: mapId ?? "", account_type: SUPPLIER.toLowerCase() },
    { skip: !mapId }
  );

  const { data: buyerApprovals } = useGetComplianceMapApprovalsQuery(
    { map_name: mapId ?? "", account_type: BUYER.toLowerCase() },
    { skip: !mapId }
  );
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const READ_ONLY = USER_PERMISSIONS.includes(PERMISSIONS.map_view_compliance);

  const isLocked = (approvalsData: any) => {
    return (
      approvalsData?.head_compliance_decision?.approval_status === "Approved" ||
      READ_ONLY
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="CreditReportTabs"
          variant="fullWidth"
        >
          <Tab
            label={SUPPLIER?.toUpperCase()}
            {...a11yProps(0)}
            icon={<Person />}
            iconPosition="start"
          />
          <Tab
            label={BUYER?.toUpperCase()}
            {...a11yProps(1)}
            icon={<Person />}
            iconPosition="start"
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Section
          accountType={SUPPLIER?.toLowerCase()}
          isLocked={isLocked(supplierApprovals)}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Section
          accountType={BUYER?.toLowerCase()}
          isLocked={isLocked(buyerApprovals)}
        />
      </CustomTabPanel>
    </Box>
  );
}
