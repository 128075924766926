import { Box, Slide, Stack, Typography } from "@mui/material";
import { useGetPlatformUsageSummaryQuery } from "api/api-marketing";
import DataTablev2 from "components/Common/DataTablev2";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useRef } from "react";

const CumulativeTable = () => {
  const isMobile = useIsMobile();
  const {
    data: platformUsageSummary,
    isFetching,
    isLoading,
    refetch,
  } = useGetPlatformUsageSummaryQuery(null);

  const rowsPerPage = 6;

  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref, 1000) : 1000;

  const headerLength = platformUsageSummary
    ? Object.keys(platformUsageSummary).length
    : 0;
  const defaultColumnWidth = width ? (width / headerLength) * 0.35 : 200;

  const marketingValues: number[] = platformUsageSummary?.["Marketing Leads"]
    ? Object.values(platformUsageSummary["Marketing Leads"])
    : [];
  const originationValues: number[] = platformUsageSummary?.[
    "Origination Leads"
  ]
    ? Object.values(platformUsageSummary["Origination Leads"])
    : [];

  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Stack
          spacing={1}
          id="stack-platform-usage-cumulative"
          sx={{ width: "100%" }}
        >
          <Typography variant="h3">
            Platform usage since July 2023 (Cumulative)
          </Typography>
          {(isFetching || isLoading) && <SkeletonLoad bars={10} />}
          {!isFetching &&
            platformUsageSummary !== undefined &&
            Object.keys(platformUsageSummary).length > 0 && (
              <DataTablev2
                data={[
                  ["Marketing Leads", ...marketingValues],
                  ["Origination Leads", ...originationValues],
                ]}
                defaultColumnWidth={defaultColumnWidth}
                rowsPerPage={rowsPerPage}
                customColumns={{
                  0: { 0: "Source", type: "string" },
                  1: { 1: "Signups", type: "number" },
                  2: { 2: "Submitted Companies", type: "number" },
                  3: { 3: "Tracked Shipments", type: "number" },
                  4: { 4: "Submitted Credit Limits", type: "number" },
                  5: { 5: "Approved Credit Limits", type: "number" },
                  6: { 6: "Rejected Credit Limits", type: "number" },
                  7: { 7: "Started Onboardings", type: "number" },
                  8: { 8: "Submitted Onboardings", type: "number" },
                }}
              />
            )}
        </Stack>
      </Box>
    </Slide>
  );
  return <></>;
};

export default CumulativeTable;
