import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import bangla from "./translations/bangla.json";
import chinese from "./translations/chinese.json";
import english from "./translations/english.json";
import french from "./translations/french.json";
import hindi from "./translations/hindi.json";

const env = import.meta.env.VITE_ENV;
const debug = Boolean(env === "local" || env === "dev");

const resources = {
  en: {
    translation: english,
  },
  hi: {
    translation: hindi,
  },
  fr: {
    translation: french,
  },
  ch: {
    translation: chinese,
  },
  bn: {
    translation: bangla,
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //  -- Prevent people with language set on browser, to access their native languages.
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug,
    fallbackLng: "en",
    resources,
  });

export default i18n;
