import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V1_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import { defaultValues } from "helpers/formFields";
import {
  addAllCountries,
  addCountries,
  addPartnerEligibleCountries,
} from "redux/reducers/countriesReducer";
import { setSteps } from "redux/reducers/stepsReducer";
import type { Country, Step } from "types";

export const misc = createApi({
  reducerPath: "misc",
  tagTypes: ["STEPS"],
  baseQuery: axiosBaseQuery({
    baseUrl: VITE_API_V1_URL,
  }),
  endpoints: (builder) => ({
    translations: builder.query<any, void>({
      query: () => ({ url: "/translations", method: "get" }),
    }),
    getCountries: builder.query<Country[], void>({
      query: () => ({
        url: `/countries`,
        method: "get",
      }),
      transformResponse: (response) => response.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(addAllCountries(data));
        } catch (err) {
          dispatch(addAllCountries([]));
        }
      },
    }),
    getCountriesByType: builder.query<Country[], string>({
      query: (type: string) => ({
        url: `/countries?type=${type}`,
        method: "get",
      }),
      transformResponse: (response) => response.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(addCountries(data));
        } catch (err) {
          dispatch(addCountries([]));
        }
      },
    }),
    getPartnerEligibleCounties: builder.query<Country[], string>({
      query: (type: string) => ({
        url: `/countries?type=${type}`,
        method: "get",
      }),
      transformResponse: (response) => response.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(addPartnerEligibleCountries(data));
        } catch (err) {
          dispatch(addPartnerEligibleCountries([]));
        }
      },
    }),
    getSteps: builder.query<Step, string>({
      query: (company_id: string) => ({
        url: `/steps?company_id=${company_id}`,
        method: "get",
      }),
      providesTags: ["STEPS"],
      transformResponse: (response) => response.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(setSteps(data));
        } catch (err) {
          dispatch(setSteps({ form: defaultValues, step: 0 }));
        }
      },
    }),
  }),
});

export const {
  useTranslationsQuery,
  useGetCountriesQuery,
  useGetCountriesByTypeQuery,
  useGetPartnerEligibleCountiesQuery,
  useGetStepsQuery,
} = misc;
