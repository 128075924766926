import { CL_DEFAULT } from "codes";
import moment from "moment";
import type { Partner, SearchCompany, UploadDocs } from "types";
import {
  validationSchemaRegistrationStep,
  validationSchemaUploadCompanyDocuments,
} from "./registrationStep";
import { validationSchemaTradingRelationStep } from "./tradingRelationship";

export type RegistrationFormFields = {
  companyType: string;
  country: string;
  name: string;
  companyNameInitial: string;
  crStage: number;
  incorporation_date?: Date | string;
  registrationNumber: string;
  registrationFileOcr: any;
  certificateOfIncorporation: File[] | null;
  proofOfRegisteredAddress: File[] | null;
  idFile?: UploadDocs;
  userIDocr?: Record<string, any>;
  registrationFile: any;
  requested_amount: number;
  currency: string;
  clStatus: string;
  list: Partner[];
  address_line1: string;
  address_line2: string;
  postcode: string;
  city?: string;
  industry: string;
  goods_category: string;
  kycAutomation: boolean;
  caseCommonId: string;
  companyId: string;
  toApprove: string[];
  credit_limit?: Record<string, any>;
  firstName: string;
  lastName: string;
  nationality: string;
  dateOfBirth: Date;
  expiryDate: Date;
  idNumber: string;
  website: string;
  status: string;
  annual_revenue: string;
  iso3: string;
  is_identified: boolean;
  accept1: boolean;
  accept2: boolean;
  accept3: boolean;
  foundCompanies: SearchCompany[];
  uploaded: { [documentName: string]: boolean };
  docs: Record<string, any>;
  isUploaded: boolean;
  call_error: any;
};
const minimumDate = moment().subtract(18, "years");
const minimumExpiry = moment().add(90, "days");

export const defaultValues: RegistrationFormFields = {
  companyType: "",
  country: "",
  name: "",
  crStage: 0,
  incorporation_date: undefined,
  registrationNumber: "",
  registrationFileOcr: {},
  registrationFile: undefined,
  certificateOfIncorporation: null,
  proofOfRegisteredAddress: null,
  foundCompanies: [],
  requested_amount: CL_DEFAULT,
  currency: "USD",
  clStatus: "",
  address_line1: "",
  address_line2: "",
  postcode: "",
  kycAutomation: false,
  caseCommonId: "",
  companyId: "",
  city: "",
  industry: "",
  goods_category: "",
  //OCR
  firstName: "",
  lastName: "",
  nationality: "",
  dateOfBirth: new Date(minimumDate.toDate()),
  expiryDate: new Date(minimumExpiry.toDate()),
  idNumber: "",
  website: "",
  status: "",
  annual_revenue: "0",
  //tr
  iso3: "",
  accept1: false,
  accept2: false,
  accept3: false,
  list: [],
  credit_limit: {},
  toApprove: [],
  idFile: undefined,
  userIDocr: undefined,
  uploaded: {},
  docs: {},
  is_identified: false,
  isUploaded: false,
  companyNameInitial: "",
  call_error: undefined,
};

export const validationSchemas: Record<number, any> = {
  0: {
    0: validationSchemaRegistrationStep,
    1: validationSchemaRegistrationStep,
    2: validationSchemaRegistrationStep,
    3: validationSchemaUploadCompanyDocuments,
  },
  1: { 0: validationSchemaTradingRelationStep },
  2: { 0: validationSchemaTradingRelationStep },
};
