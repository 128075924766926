import { Typography } from "@mui/material";

const RiskResult = ({
  finalScore,
  result,
}: {
  finalScore: number;
  result: string;
}) => {
  const getColor = (result: string) => {
    switch (result) {
      case "LOW":
        return "#29b050";
      case "MEDIUM LOW":
        return "#92cf51";
      case "MEDIUM":
        return "#fffd00";
      case "MEDIUM HIGH":
        return "#fcc000";
      case "HIGH":
        return "#f90200";
      default:
        return "red";
    }
  };
  return (
    <Typography
      sx={{
        bgcolor: finalScore <= 10 ? getColor(result) : "red",
        textAlign: "center",
        borderRadius: 0.5,
        py: 0.5,
      }}
    >
      {finalScore <= 10 ? result : "ERROR"}
    </Typography>
  );
};
export default RiskResult;
