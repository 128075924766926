import { Download, Print } from "@mui/icons-material";
import { useGetGeneratedTaxInvoiceQuery } from "api/api-operations";
import { INCOMLEND_INTERNAL_LINK, TAX_INVOICES_LINK } from "codes";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import ViewGeneratedTaxInvoice from "components/TaxInvoice/View";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useParams } from "react-router-dom";

interface TaxInvoiceFees {
  amount_exclude_gst: number;
  description: string;
  quantity: number;
  unit_price: number;
}

interface Payee {
  name: string;
  street: string;
  city: string;
  postal_code: string;
  country: string;
  contact_name: string;
  contact_email: string;
  contact_phone_number: string;
  alternative_email: string;
}

const TaxInvoice = () => {
  const internalName = useParams<{ id: string }>()?.id;
  const {
    data: taxInvoiceData,
    isLoading,
    refetch,
  } = useGetGeneratedTaxInvoiceQuery(internalName ?? "", {
    skip: !internalName,
  });
  const downloadDivAsPdf = async () => {
    const div = document.getElementById("tax-invoice");

    if (div) {
      try {
        const canvas = await html2canvas(div, {
          scale: 5, // Increase the scale to get better quality
        });
        const imgData = canvas.toDataURL("image/jpeg", 1);

        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        });
        const imgWidth = 595.28; // A4 width in pt
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
        pdf.addImage(
          imgData,
          "JPEG",
          0,
          0,
          imgWidth,
          imgHeight,
          "tax-invoice",
          "NONE"
        );
        pdf.save(`Tax Invoice - ${taxInvoiceData?.sf_tax_invoice_id}.pdf`);
      } catch (error) {
        console.error("Failed to generate PDF", error);
      }
    }
  };

  const print = () => {
    const printContents = document.getElementById("tax-invoice")?.innerHTML;
    if (!printContents) return;

    const iframe = document.createElement("iframe");
    iframe.style.position = "absolute";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "none";
    const printStyle = `
    <style>
      @media print {
        body {
          -webkit-print-color-adjust: exact;
          background-color: #ededed;
        }
      }
    </style>
  `;

    document.body.appendChild(iframe);

    const doc = iframe.contentWindow?.document;
    if (doc) {
      doc.open();
      // Copy current head and body content to the iframe
      doc.write(`
        <html>
          <head>
            <title>Tax Invoice - ${taxInvoiceData?.sf_tax_invoice_id}</title>
            ${document.head.innerHTML}
            ${printStyle}
          </head>
          <body>
            ${printContents}
          </body>
        </html>
      `);
      doc.close();

      // Ensure iframe's window has the styles applied
      iframe.contentWindow?.focus();
      iframe.contentWindow?.print();
    }

    // Remove the iframe after printing
    setTimeout(() => {
      document.body.removeChild(iframe);
    }, 1000);
  };

  return (
    <Layout
      title="Tax Invoice"
      backArrow
      backLink={`${INCOMLEND_INTERNAL_LINK}/${TAX_INVOICES_LINK}`}
      primary={{
        children: "Download",
        onClick: () => downloadDivAsPdf(),
        fullWidth: true,
        startIcon: <Download />,
      }}
      secondary={{
        children: "Print",
        onClick: () => print(),
        fullWidth: true,
        startIcon: <Print />,
      }}
      headerConfig={{
        syncAction: refetch,
        left: 0,
        mid: 0,
        right: 6,
      }}
    >
      {isLoading && <SkeletonLoad bars={10} />}
      {!isLoading && taxInvoiceData && (
        <ViewGeneratedTaxInvoice taxInvoice={taxInvoiceData} />
      )}
    </Layout>
  );
};

export default TaxInvoice;
