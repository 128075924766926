import { SxProps, TableCell, TableRow } from "@mui/material";
import { CustomColumn, ITEM_HEIGHT } from "../..";
import RenderCell from "./RenderCell";

interface ColumnProps<T> {
  index: number;
  style: React.CSSProperties;
  data: {
    columns: string[];
    items: T;
    classes: {};
    stripeBgColor: string;
    pointerOnHover: boolean;
    mainColor: string;
    customColumns: CustomColumn;
    defaultColumnWidth: string | number;
    handleRowClick: (field: T, header: string) => void;
    applyCellStyling: (column: string, item: T) => SxProps;
    cellSx?: SxProps;
  };
}

export default function Column<T extends Record<string, any> = {}>({
  index,
  style,
  data,
}: ColumnProps<T>) {
  const {
    columns,
    items,
    stripeBgColor,
    pointerOnHover,
    mainColor,
    customColumns,
    defaultColumnWidth,
    handleRowClick,
    cellSx,
    applyCellStyling = (column: string, item: T) => ({}),
  } = data;
  const item = items[index] as T[keyof T];

  return (
    <TableRow
      component="div"
      style={style}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        "&:nth-of-type(odd)": {
          backgroundColor: "white",
        },
        "&:nth-of-type(even)": {
          backgroundColor: stripeBgColor,
        },
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        boxSizing: "border-box",
        minWidth: "100%",
        width: "100%",
      }}
    >
      {columns.map((column, colIndex) => {
        const isDisabled = item?.disabled ?? false;

        return (
          <TableCell
            component="div"
            variant="body"
            key={`cellcolumn-${column}-${colIndex}-${index}`}
            id={`cellcolumn-${column}-${colIndex}-${index}`}
            sx={{
              cursor: pointerOnHover ? "pointer" : "none",
              color: isDisabled ? "grey" : mainColor,
              display: "flex",
              flexGrow: 1,
              flexShrink: 0,
              alignItems: "center",
              borderRight: "1px solid #e0e0e0",
              ...cellSx,
              ...applyCellStyling(column, item),
            }}
            style={{
              flexBasis: "auto",
              height: ITEM_HEIGHT,
              width: customColumns?.[column]?.minWidth ?? defaultColumnWidth,
            }}
            onClick={() => handleRowClick(item, column)}
          >
            <RenderCell
              params={{ value: item[column] }}
              disabled={isDisabled}
              column={column}
              item={item}
              customColumns={customColumns}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
}
