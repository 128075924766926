import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import styles from "./nodata.module.scss";

export interface NoDataProps extends BoxProps {
  /**Display Text */
  text: string;
}

const NoData = (props: NoDataProps) => {
  const { text, ...rest } = props;
  const theme = useTheme();
  return (
    <Box
      className={styles.noData}
      sx={{ bgcolor: theme.palette.background.paper, flexGrow: 1 }}
      {...rest}
    >
      <Typography variant={"h4"} textAlign={"center"}>
        {text}
      </Typography>
    </Box>
  );
};

export default NoData;
