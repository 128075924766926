import { Theme } from "@mui/material";

export const modalStyling = ({
  isMobile,
  theme,
}: {
  isMobile: boolean;
  theme: Theme;
}) => {
  const modalOverlaySx = {
    backgroundColor: `${theme.palette.background.paper} !important`,
    width: isMobile ? "60vw !important" : "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: isMobile ? "2ch" : "4ch",
    padding: isMobile ? "1ch" : "1ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
    ">div": {
      ">div": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };
  const modalContentSx = {
    height: isMobile ? "88vh!important" : "69vh!important",
    justifyContent: "flex-start !important",
  };
  const messageContainerProps = {
    spacing: isMobile ? 1 : 2,
    justifyContent: "center",
    alignItems: "flex-start",
    height: "auto",
  };
  return {
    modalOverlaySx,
    modalSx,
    modalContentSx,
    messageContainerProps,
  };
};
