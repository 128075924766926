import { Add } from "@mui/icons-material";
import { Box, Grid, Slide, Tab, Tabs, Typography } from "@mui/material";
import { useGetCreditLimitsOpportunitiesQuery } from "api/api-origination";

import { INCOMLEND_INTERNAL_LINK } from "codes";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CreateLead from "components/Company/Lead/CreateLead";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";
import React from "react";
import OpportunitiesList from "./product";
import styles from "./sales.module.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`origination-tabpanel-${index}`}
      aria-labelledby={`origination-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `origination-tab-${index}`,
    "aria-controls": `origination-tabpanel-${index}`,
  };
}

const CreditLimitOpportunities = () => {
  const isMobile = useIsMobile();
  const { pathname } = history.location;

  const {
    data: creditLimitsOpportunities,
    isLoading,
    isFetching,
    refetch,
    startedTimeStamp = 0,
    fulfilledTimeStamp = 0,
    //refetch clrs every 5mins
  } = useGetCreditLimitsOpportunitiesQuery(null, {
    pollingInterval: 300000,
    refetchOnReconnect: true,
  });

  const [value, setValue] = React.useState(0);
  const [createLead, setCreateLead] = React.useState<boolean>(false);
  const openCreateLeadPage = () => setCreateLead(true);
  const closeCreateLeadPage = () => setCreateLead(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={`Credit Limit Pipelines`}
          backArrow={false}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.5em" : "1.5em",
          }}
          headerConfig={{
            syncAction: refetch,
            left: 8,
            mid: 0,
            right: 2,
            xs: {
              left: 12,
              mid: 0,
              right: 0,
            },
            alignItems: "center",
          }}
          className={styles.layout}
          primary={{
            onClick: createLead ? closeCreateLeadPage : openCreateLeadPage,
            isCancel: createLead,
            startIcon: createLead ? undefined : <Add />,
            children: createLead ? "Cancel" : "Create Lead",
            variant: "contained",
            fullWidth: true,
          }}
          mode="default"
        >
          {createLead && (
            <CreateLead
              onEnd={(internal_name) => {
                closeCreateLeadPage();
                if (internal_name)
                  history.push(
                    `${INCOMLEND_INTERNAL_LINK}/leads/${internal_name}`
                  );
              }}
            />
          )}
          {(isFetching || isLoading) && <SkeletonLoad bars={10} />}
          {(import.meta.env.VITE_ENV === "local" ||
            import.meta.env.VITE_ENV === "dev") &&
            !createLead &&
            `Development mode only - Data fetched in: ${
              (fulfilledTimeStamp - startedTimeStamp) / 1000
            }s`}

          {!isFetching && creditLimitsOpportunities && !createLead && (
            <Grid>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="origination tabs"
                >
                  <Tab label="Factoring" {...a11yProps(0)} />
                  <Tab label="Reverse Factoring" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <OpportunitiesList
                  creditLimitsOpportunities={
                    creditLimitsOpportunities.factoring
                  }
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <OpportunitiesList
                  creditLimitsOpportunities={
                    creditLimitsOpportunities.reverse_factoring
                  }
                />
              </CustomTabPanel>
            </Grid>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};

export default CreditLimitOpportunities;
