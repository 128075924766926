import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { Country } from "types";
import type { RootState } from "../store";

// Define a type for the slice state
interface Countries {
  value: Country[];
  cities: any[];
  country: Country;
  countries: Country[];
  partnerEligibleCountries: Country[];
}

// Define the initial state using that type
export const initialState: Countries = {
  value: [],
  cities: [],
  country: {
    id: "",
    name: "",
    phone_prefix: "",
  },
  countries: [],
  partnerEligibleCountries: [],
};

export const countrySlice = createSlice({
  name: "countries",
  // `createSlice` will infer the state type from the `initialState` argument
  // Use the PayloadAction type to declare the contents of `action.payload`
  initialState,

  reducers: {
    addCountries: (state, action: PayloadAction<Country[]>) => {
      state.value = action.payload;
    },
    addPartnerEligibleCountries: (state, action: PayloadAction<Country[]>) => {
      state.partnerEligibleCountries = action.payload;
    },
    addAllCountries: (state, action: PayloadAction<any>) => {
      state.countries = action.payload;
    },
    clearAll: () => initialState,
  },
});
export const {
  addCountries,
  addPartnerEligibleCountries,
  addAllCountries,
  clearAll,
} = countrySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAllCountries = (state: RootState) => state.countries.value;
export const selectCountries = (state: RootState) => state.countries.value;
export const selectCities = (state: RootState) => state.countries.cities;
export default countrySlice.reducer;
