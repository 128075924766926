import { useGetAccountByNameQuery } from "api/api-accounts";
import { useGetMapSummaryQuery } from "api/api-maps";
import { AccountInputDetailed } from "components/Map/Sections/Compliance/CreditReport/types";
import type { Account } from "types";

interface UseAccountDataProps {
  accountType: string;
  mapId: string | null;
}

interface UseAccountDataResponse {
  accountInput: AccountInputDetailed | undefined;
  mapData: any | undefined;
  isLoading: boolean;
  supplierInternalData: Account | undefined;
  buyerInternalData: Account | undefined;
}

const useAccountData = ({
  accountType,
  mapId,
}: UseAccountDataProps): UseAccountDataResponse => {
  const { data: mapData, isLoading: mapLoading } = useGetMapSummaryQuery(
    mapId ?? "",
    { skip: mapId === null || mapId === "" }
  );

  const buyerInternalName = mapData?.buyer_internal_name ?? "";
  const sellerInternalName = mapData?.seller_internal_name ?? "";

  const { data: buyerInternalData } = useGetAccountByNameQuery(
    buyerInternalName,
    { skip: buyerInternalName === null || buyerInternalName === "" }
  );
  const { data: supplierInternalData } = useGetAccountByNameQuery(
    sellerInternalName,
    { skip: sellerInternalName === null || sellerInternalName === "" }
  );

  const mapAccountToAccountInputDetailed = (
    account: Account
  ): AccountInputDetailed => ({
    "Company Full Name": account.name,
    "Incorporation Date": account.incorporation_date
      ? new Date(account.incorporation_date)
      : undefined,
    "Country of incorporation": account.country,
    "Registration Number ": account.reg_no,
    "Registered Address": [
      account.address_line1,
      account.address_line2,
      account.city,
      account.postcode,
      account.country,
    ]
      .filter(Boolean)
      .join(" "),
    "Operating Address": account.shipping_address
      ? [
          account.shipping_address.address_line1,
          account.shipping_address.address_line2,
          account.shipping_address.city,
          account.shipping_address.postcode,
          account.shipping_address.country,
        ]
          .filter(Boolean)
          .join(" ")
      : undefined,
    Email: account.contact_email,
    Website: account.website,
    "Business Industry": account.industry,
    "Business type": account.goods_category,
  });

  let accountInput: AccountInputDetailed | undefined;
  if (accountType === "buyer" && buyerInternalData) {
    accountInput = mapAccountToAccountInputDetailed(buyerInternalData);
  } else if (accountType === "supplier" && supplierInternalData) {
    accountInput = mapAccountToAccountInputDetailed(supplierInternalData);
  }

  const isLoading = mapLoading;

  return {
    accountInput,
    mapData,
    isLoading,
    supplierInternalData,
    buyerInternalData,
  };
};

export default useAccountData;
