import { Box, Grid } from "@mui/material";
import { useGetBankAccountsQuery } from "api/api-banks";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";

const BankAccountsList = ({ companyId }: { companyId: string }) => {
  const { data: curr_user } = useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const {
    data: bankAccounts,
    refetch: refetchBanks,
    isFetching,
  } = useGetBankAccountsQuery(companyId, {
    skip: !companyId,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  return (
    <Box>
      <Grid item xs={12} mt={2} px={3}>
        {isFetching && <SkeletonLoad bars={10} />}
        {!isFetching && bankAccounts && Boolean(bankAccounts?.length) ? (
          <DataTablev2
            key={bankAccounts?.length}
            data={bankAccounts}
            customColumns={{
              id: { id: "Bank ID", type: "text" },
              bank_name: { bank_name: "Bank Name", type: "text" },
              bank_account_number: {
                bank_account_number: "Bank Account Number",
                type: "text",
              },
              beneficiary_name: {
                beneficiary_name: "Beneficiary Name",
                type: "text",
              },
              currency: { currency: "Currency", type: "text" },
              country: { country: "Country", type: "text" },
              swift_code: { swift_code: "Swift Code", type: "text" },
              iban: { iban: "IBAN", type: "text" },
            }}
            rowsPerPage={rowsPerPage}
            sx={{
              table: {
                minWidth: "100% !important",
              },
            }}
          />
        ) : (
          <NoData
            text="No banks found"
            style={{ margin: "1em auto", width: "100%" }}
          />
        )}
      </Grid>
    </Box>
  );
};
export default BankAccountsList;
