import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useGetMapSummaryQuery } from "api/api-maps";
import MainCard from "components/Common/Cards/MainCard";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import { MapContext } from "pages/maps/map";
import { useContext } from "react";
import { MapApprover, MapSummary } from "types";
import ApprovalHistoryTableField from "./TableField";

interface Approval {
  [department: string]: MapApprover;
}

const TableHeader = styled(TableCell)(() => ({
  fontWeight: "bold",
  fontSize: "1em",
  lineHeight: "1em",
  minWidth: "10em",
}));

const calculateComplianceStatus = (
  supplierStatus: string,
  buyerStatus: string
): string => {
  switch (true) {
    case supplierStatus === "Approved" && buyerStatus === "Approved":
      return "Approved";
    case supplierStatus === "Rejected" || buyerStatus === "Rejected":
      return "Rejected";
    default:
      return "Pending";
  }
};
const MapApproval = () => {
  const id = useContext(MapContext);
  const { data: mapSummary, isLoading } = useGetMapSummaryQuery(id ?? "", {
    skip: id === undefined,
  });
  const { approvers = {} } = mapSummary as MapSummary;

  const approvalHistoryGroup: Approval = {
    head_compliance: {
      ...approvers["head_compliance_supplier"],
      approval_status: calculateComplianceStatus(
        approvers["head_compliance_supplier"]?.approval_status,
        approvers["head_compliance_buyer"]?.approval_status
      ),
    },
    legal: {
      ...approvers["legal"],
    },
    risk: {
      ...approvers["risk"],
    },
  };

  return (
    <Formik
      enableReinitialize
      initialValues={approvalHistoryGroup}
      onSubmit={console.log}
    >
      {(formikProps) => (
        <Form>
          {isLoading && <SkeletonLoad bars={10} />}
          {!isLoading && (
            <MainCard
              title={`Approval History`}
              headerSx={{ py: 2, px: 3 }}
              content
              contentSX={{ py: 2, px: 3 }}
              children={
                <TableContainer component={Paper}>
                  <Table
                    sx={{
                      minWidth: 650,
                      border: "1px solid #e0e0e0",
                      bgcolor: "background.default",
                    }}
                    size="small"
                    aria-label="approval-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableHeader>Department</TableHeader>
                        <TableHeader>Approver</TableHeader>
                        <TableHeader>Approval Date</TableHeader>
                        <TableHeader>Comments</TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(formikProps.values)
                        .filter(
                          ([key]) =>
                            key !== "head_compliance_buyer" &&
                            key !== "head_compliance_supplier"
                        )
                        .map(([key, _]) => {
                          return (
                            <ApprovalHistoryTableField
                              key={key}
                              department={key}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default MapApproval;
