import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Grid, Typography, useTheme } from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import toProperCase from "helpers/propercase";

import { useGetSFOpportunityQuery } from "api/api-sales";
import { isMobile } from "react-device-detect";
import SalesforceForm from "./SalesForceForm";

const SFOpportunity = ({
  sfOpportunityId,
  sf_link,
}: {
  sfOpportunityId: string;
  sf_link?: string;
}) => {
  const theme = useTheme();
  const { data: sfOpportunity } = useGetSFOpportunityQuery(sfOpportunityId, {
    skip: !Boolean(sf_link),
  });
  return (
    <MainCard
      content={Boolean(sfOpportunity)}
      title={
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid item>
              <Typography
                fontWeight={"bold"}
                fontSize={isMobile ? "1.1em" : "1.3em"}
                variant={"h3"}
              >{`${toProperCase("salesforce")}`}</Typography>
            </Grid>
            <Grid item>
              {!sfOpportunity || "error" in sfOpportunity ? (
                <PrivacyTipIcon color={"error"} />
              ) : (
                <VerifiedUserIcon color={"success"} />
              )}
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color:
                    !sfOpportunity || "error" in sfOpportunity
                      ? theme.palette.error.main
                      : theme.palette.success.main,
                  fontWeight: "bold",
                }}
              >
                {!sfOpportunity || "error" in sfOpportunity
                  ? "Opportunity not created"
                  : "Opportunity created"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      }
    >
      {sfOpportunity && <SalesforceForm data={sfOpportunity} />}
    </MainCard>
  );
};
export default SFOpportunity;
