import { Box, Paper, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import ReportComparison from "./ReportComparison";
import Shareholders from "./Shareholders";

const REPORT_TABS = [
  { name: "Credit Reports", component: ReportComparison },
  { name: "Shareholders & Directors", component: Shareholders },
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreditReportDetails = ({
  accountType,
  isLocked,
}: {
  accountType: string;
  isLocked: boolean;
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ width: "100%", padding: 2 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="compliance screening tabs"
        centered
        variant="fullWidth"
      >
        {REPORT_TABS.map((tab, index) => (
          <Tab label={tab.name} {...a11yProps(index)} key={index} />
        ))}
      </Tabs>
      {REPORT_TABS.map((tab, index) => {
        const Component = tab.component;
        return (
          <TabPanel value={value} index={index} key={index}>
            <Component
              accountType={accountType as "supplier" | "buyer"}
              isLocked={isLocked}
            />
          </TabPanel>
        );
      })}
    </Paper>
  );
};

export default CreditReportDetails;
