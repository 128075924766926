import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export interface LoaderProps {
  /**Open Loader */
  open?: boolean;
  content?: JSX.Element;
}

const Loader = (props: LoaderProps) => {
  const { open = false, content } = props;

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        {content}
      </Backdrop>
    </div>
  );
};

export default Loader;
