const toCamelCase = (input: string): string => {
  const regex = /\s+(\w)?/gi;
  return (
    input
      ?.replace(/[&#+$~%.'":*?<>{}]/g, "")
      .toLowerCase()
      .trim()
      .replace(regex, (match, letter) => {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return letter ? letter.toUpperCase() : letter;
      })
      .replace(/[?\u0250-\ue007]|[&#+$~%.'":*?<>{}]/g, "")
      .replace(/undefined/g, "") ?? ""
  );
};
export default toCamelCase;
