import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TuneIcon from "@mui/icons-material/Tune";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import { Grid, Paper, SxProps, Typography, useTheme } from "@mui/material";

import Search, { SearchProps } from "components/Common/Search";
import exportToCsv from "helpers/exportToCsv";
import isObjectEmpty from "helpers/isObjectEmpty";
import useIsMobile from "hooks/useIsMobile";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomColumn } from "..";
import styles from "../datatablev2.module.scss";
import { dynamicSearch, getColumns } from "../processor";
import ColumnToggle from "./ColumnToggle";
import FilterChip from "./FilterChip";
import FilterModal from "./FilterModal";

interface ToolBarOptions {
  showColumns: boolean;
  showFilters: boolean;
  showExport: boolean;
  showSearch: boolean;
}

interface Toolbar<T> {
  data: T[];
  initialData: T[];
  columnsToHide: string[];
  setFilteredData: React.Dispatch<React.SetStateAction<T[]>>;
  headers: string[];
  searchBarProps?: Partial<SearchProps>;
  toolBarOptions?: Partial<ToolBarOptions>;
  customColumns?: CustomColumn;
  initialFilters: Record<string, any>;
  onFilterChange: (filters: Record<string, any>) => void;
  allHideableColumns: string[];
  setAllHideableColumns: React.Dispatch<React.SetStateAction<string[]>>;
  hideablesPreset: string[];
  getColumnVisibility: (columns: T) => void;
  sx?: SxProps;
  toolSx?: SxProps;
}

export function Toolbar<T extends Record<string, any> = {}>({
  data,
  initialData,
  setFilteredData,
  onFilterChange,
  headers,
  searchBarProps = {
    curved: true,
    processSearch: (val: any) => console.log(val),
  },
  toolBarOptions,
  columnsToHide = [],
  customColumns = {},
  allHideableColumns,
  setAllHideableColumns,
  getColumnVisibility,
  initialFilters = {},
  hideablesPreset = [],
  sx,
  toolSx = { cursor: "pointer" },
}: Toolbar<T>) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openCol, setOpenCol] = useState(false);
  const [filters, setFilters] = useState<Record<string, any>>(initialFilters);
  const { showColumns, showFilters, showExport, showSearch } =
    toolBarOptions as ToolBarOptions;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenCol = () => setOpenCol(true);
  const handleCloseCol = () => setOpenCol(false);
  const isMobile = useIsMobile();
  const theme = useTheme();
  const clearFilters = () => {
    setFilteredData(initialData);
    setFilters({});
  };

  return (
    <Grid container spacing={1} sx={sx} id="toolbar">
      <Grid item xs={12}>
        <Paper elevation={1} sx={{ padding: isMobile ? "1ch" : "1ch 2ch" }}>
          <Grid container alignItems={"center"}>
            <Grid item xs={6} lg={9}>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                {showColumns && (
                  <Grid item xs={4} lg={3}>
                    {isMobile ? (
                      <ViewWeekIcon
                        onClick={handleOpenCol}
                        sx={{ margin: "auto" }}
                      />
                    ) : (
                      <Grid
                        container
                        spacing={1}
                        onClick={handleOpenCol}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Grid
                          item
                          sx={{
                            ...toolSx,
                            cursor: "pointer",
                            margin: "auto 0",
                          }}
                          xs={2}
                        >
                          <ViewWeekIcon />
                        </Grid>

                        <Grid
                          item
                          sx={{
                            ...toolSx,
                            cursor: "pointer",
                          }}
                          xs={10}
                        >
                          <Typography className={styles.toolText}>
                            Show/Hide Columns
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
                {showFilters && (
                  <Grid item xs={4} lg={3}>
                    {isMobile ? (
                      <TuneIcon onClick={handleOpen} />
                    ) : (
                      <Grid
                        container
                        spacing={1}
                        onClick={handleOpen}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Grid
                          item
                          sx={{
                            ...toolSx,
                            cursor: "pointer",
                            margin: "auto 0",
                          }}
                        >
                          <TuneIcon />
                        </Grid>
                        <Grid item sx={{ ...toolSx, cursor: "pointer" }}>
                          <Typography className={styles.toolText}>
                            Filters
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
                {showExport && (
                  <Grid item xs={4} lg={3}>
                    {isMobile ? (
                      <FileDownloadIcon />
                    ) : (
                      <Grid
                        container
                        spacing={1}
                        sx={{ ...toolSx, cursor: "pointer" }}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Grid
                          item
                          sx={{
                            ...toolSx,
                            cursor: "pointer",
                            margin: "auto 0",
                          }}
                        >
                          <FileDownloadIcon
                            onClick={() =>
                              exportToCsv(data, columnsToHide, customColumns)
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{ ...toolSx, cursor: "pointer" }}
                          onClick={() =>
                            exportToCsv(data, columnsToHide, customColumns)
                          }
                        >
                          <Typography className={styles.toolText}>{`${t(
                            "export"
                          )}`}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {showSearch && (
              <Grid item lg={3} xs={6}>
                <Search
                  onChange={(val: any) =>
                    dynamicSearch({
                      data: initialData,
                      key: val,
                      headers,
                      setFilteredData,
                    })
                  }
                  sx={{
                    "& .MuiInputBase-input": {
                      [theme.breakpoints.down("sm")]: {
                        padding: "0.5rem 1rem",
                      },
                    },
                  }}
                  {...searchBarProps}
                />
              </Grid>
            )}
          </Grid>
          {showFilters && (
            <FilterModal
              data={data}
              initialData={initialData}
              open={open}
              columnsToHide={columnsToHide}
              handleClose={handleClose}
              customColumns={customColumns ?? {}}
              setFilteredData={setFilteredData}
              filters={filters}
              setFilters={setFilters}
              clearFilters={clearFilters}
              onFilterChange={onFilterChange}
            />
          )}
          <ColumnToggle
            open={openCol}
            handleClose={handleCloseCol}
            allHideableColumns={allHideableColumns}
            setAllHideableColumns={setAllHideableColumns}
            columnsToHide={columnsToHide}
            columns={getColumns(data)}
            customColumns={customColumns}
            hideablesPreset={hideablesPreset}
            getColumnVisibility={getColumnVisibility}
          />
        </Paper>
      </Grid>

      {/* Chips */}
      {!isObjectEmpty(filters) && !open && (
        <Grid item xs={12}>
          <FilterChip
            filters={filters}
            clearFilters={clearFilters}
            customColumns={customColumns}
          />
        </Grid>
      )}
    </Grid>
  );
}
