import { Box, Chip, Grid, Slide } from "@mui/material";
import { useGetAccountQuery } from "api/api-accounts";
import { useGetSingleAccountContactQuery } from "api/api-users-v2";
import { TR_LINK, USERS_LINK } from "codes";
import Breadcrumbs from "components/Common/Breadcrumbs";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import ViewEditUser from "components/Onboard/User/ViewEditUser";
import history from "helpers/history";
import isObjectEmpty from "helpers/isObjectEmpty";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { Account, Contact } from "types";

const UserProfile = () => {
  const { id } = useParams();
  const { location } = history;
  const state: Record<string, any> = location;
  const { company_id, contact_id, isPartner, callBackUrl } = state.state;

  const { data: company, isFetching: isFetchingCompany } = useGetAccountQuery(
    company_id,
    {
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  const {
    data: contact,
    isFetching,
    refetch,
  } = useGetSingleAccountContactQuery(
    {
      accountId: company_id,
      contactId: contact_id,
    },
    { refetchOnFocus: true, refetchOnReconnect: true }
  );

  if (!id) history.back();
  return (
    <Slide in direction={"left"}>
      <Box id="users-box" sx={{ width: "100%" }}>
        <Layout
          title={`Profile - ${contact?.first_name} ${contact?.last_name}`}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            syncAction: refetch,
            left: 10,
            mid: 0,
            right: 2,
            endComponent: (
              <Grid item>
                <Chip
                  label={contact?.email}
                  size="small"
                  sx={{ fontWeight: "bold" }}
                  variant="outlined"
                  color="error"
                />
              </Grid>
            ),
          }}
          mode="default"
          backArrow
          backAction={() => history.back()}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isFetching && <SkeletonLoad bars={1} />}
              {!isFetching && !isObjectEmpty(contact as Contact) && (
                <Breadcrumbs
                  data={[
                    {
                      name: "Users",
                      link: isPartner
                        ? `${TR_LINK}/profile/${company?.id}`
                        : USERS_LINK,
                    },
                    {
                      name: contact?.first_name
                        ? `${contact?.first_name} ${contact?.last_name}`
                        : "",
                      link: `${location.pathname}`,
                      active: true,
                    },
                  ]}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {isFetchingCompany && <SkeletonLoad bars={1} />}
              {!isFetchingCompany &&
                !isObjectEmpty(company as Account) &&
                contact && (
                  <ViewEditUser
                    isPartner={isPartner}
                    id={company_id ?? ""}
                    user={contact}
                    company_name={company?.name ?? ""}
                    callBackUrl={callBackUrl}
                  />
                )}
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};
export default UserProfile;
