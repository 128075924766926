import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { OverridableStringUnion } from "types";
import type { RootState } from "../store";

interface AppState {
  value: string;

  user_permission: string[];
  permission: OverridableStringUnion<
    | ""
    | "RISK_ADMIN"
    | "OPERATION_ADMIN"
    | "FINANCE_ADMIN"
    | "LEGAL_ADMIN"
    | "ORIGINATION_ADMIN"
    | "MARKETING_ADMIN"
    | "ROLE_TECH"
  >;
  role: {
    id: string;
    name: OverridableStringUnion<
      | ""
      | "RISK_ADMIN"
      | "OPERATION_ADMIN"
      | "FINANCE_ADMIN"
      | "LEGAL_ADMIN"
      | "ORIGINATION_ADMIN"
      | "MARKETING_ADMIN"
      | "ROLE_TECH"
    >;
    role: string;
    permission: string[];
  };
  permissions: string[];
  marketPlaceCurrency: OverridableStringUnion<
    "USD" | "SGD" | "HKD" | "GBP" | "EUR" | "AUD"
  >;
  profile: string;
}

// Define the initial state using that type
const initialState: AppState = {
  value: "",
  permission: "",
  user_permission: [],
  role: {
    id: "",
    name: "",
    role: "",
    permission: [],
  },
  permissions: [],
  marketPlaceCurrency: "USD",
  profile: "",
};

export const appStateSlice = createSlice({
  name: "appState",
  // `createSlice` will infer the state type from the `initialState` argument
  // Use the PayloadAction type to declare the contents of `action.payload`
  initialState,
  //CRUD
  reducers: {
    setAppState: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },

    setAppPermission: (
      state,
      action: PayloadAction<AppState["permission"]>
    ) => {
      state.permission = action.payload;
    },
    setAppPermissions: (
      state,
      action: PayloadAction<AppState["permissions"]>
    ) => {
      state.permissions = [...action.payload];
    },
    setUserPermission: (
      state,
      action: PayloadAction<AppState["user_permission"]>
    ) => {
      state.user_permission = [...action.payload];
    },
    setRole: (state, action: PayloadAction<AppState["role"]>) => {
      state.role = action.payload;
    },
    setProfile: (state, action: PayloadAction<AppState["profile"]>) => {
      state.profile = action.payload;
    },
    setMarketPlaceCurrency: (
      state,
      action: PayloadAction<AppState["marketPlaceCurrency"]>
    ) => {
      state.marketPlaceCurrency = action.payload;
    },
    clearAppState: () => initialState,
  },
});

export const {
  setAppState,

  clearAppState,
  setAppPermission,
  setAppPermissions,
  setUserPermission,
  setRole,
  setMarketPlaceCurrency,
  setProfile,
} = appStateSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectappState = (state: RootState) => state.appState.value;
export const selectappPermission = (state: RootState) =>
  state.appState.permission;

export default appStateSlice.reducer;
