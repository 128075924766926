import { Grid, Stack, Typography, useTheme } from "@mui/material";
import SkeletonLoad from "components/Common/SkeletonLoad";

import { CLContext } from "pages/credit-limits/cl-detail";
import { PERMISSIONS } from "permissions";
import { useContext } from "react";
import { useAppSelector } from "redux/hooks";
import CLDisplay from "../CLDisplay";

const CLBadges = () => {
  const theme = useTheme();
  const creditLimit = useContext(CLContext);
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  return (
    <Grid item xs={12}>
      <Stack direction={"row"} spacing={1}>
        <Grid item xs={6} lg={2}>
          {!creditLimit && <SkeletonLoad bars={1} />}
          {creditLimit && <CLDisplay status={creditLimit?.internalStatus} />}
        </Grid>
        {USER_PERMISSIONS.includes(PERMISSIONS.get_insurer) && (
          <Grid item xs={6} lg={2}>
            <Typography
              style={{
                background: "transparent",
                padding: "6px 16px",
                borderRadius: theme.shape.borderRadius * 2.5,
                borderColor: creditLimit?.coface_found
                  ? theme.palette.success.main
                  : theme.palette.error.main,
                color: creditLimit?.coface_found
                  ? theme.palette.success.main
                  : theme.palette.error.main,
                border: "1px solid",
                fontWeight: "600",
                textAlign: "center",
                width: "100%",
                cursor: "not-allowed",
                fontSize: "0.8em",
              }}
              sx={{
                [theme.breakpoints.down("sm")]: {
                  fontSize: "1em",
                },
              }}
            >
              {creditLimit?.coface_found ? "Coface Identified" : "Coface N/A"}
            </Typography>
          </Grid>
        )}
      </Stack>
    </Grid>
  );
};
export default CLBadges;
