import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import {
  useFetchUserPermissionsQuery,
  useGetCurrentUserQuery,
} from "api/api-users-v2";
import Companies from "assets/navigationIcons/companies.svg?react";
import Layout from "components/Common/Layout";
import Search from "components/Common/Search";
import { roleRedirect } from "helpers/redirect";
import useIsMobile from "hooks/useIsMobile";
import { Fragment, useEffect, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import {
  setAppPermission,
  setAppState,
  setProfile,
  setRole,
  setUserPermission,
} from "redux/reducers/appStatesReducer";
import { UserPermission } from "types";

const AccountsListingPage = () => {
  const { data: user, isFetching } = useGetCurrentUserQuery();

  const { data: userPermissions, isFetching: isFetchingUserPermissions } =
    useFetchUserPermissionsQuery(user?.id ?? "", {
      skip: !Boolean(user),
    });

  const [filteredList, setFilteredList] = useState<UserPermission[]>();
  useEffect(() => {
    if (!isFetchingUserPermissions && !filteredList)
      setFilteredList(userPermissions);
  }, [isFetchingUserPermissions]);

  const isMobile = useIsMobile();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const selectAccount = (perm: UserPermission) => {
    dispatch(setProfile(perm.account_profile));
    dispatch(setAppPermission(perm.director ? "Director" : "Non-Director"));
    dispatch(setUserPermission(perm.permission ?? []));
    dispatch(
      setRole({ ...perm, role: perm.director ? "Director" : "Non-Director" })
    );
    dispatch(setAppState(perm.account_state));
    roleRedirect(perm.id);
  };
  return (
    <Box>
      <Layout
        title={"Choose Your Account"}
        textHeadingOptions={{
          fontWeight: "bolder",
          level: 2,
          fontSize: isMobile ? "1.2em" : "1.5em",
        }}
        headerConfig={{
          left: 8,
          mid: 0,
          right: 2,
          xs: {
            left: 12,
            mid: 0,
            right: 0,
          },
          alignItems: "center",
        }}
        backArrow={false}
      >
        <Stack spacing={{ xs: 2, lg: 5 }}>
          <Search
            onChange={(searchString) =>
              setFilteredList(
                userPermissions?.filter((perm) =>
                  perm?.name?.toLowerCase().includes(searchString.toLowerCase())
                )
              )
            }
          />
          <List dense={true}>
            {filteredList?.map((perm) => (
              <Fragment key={perm.id}>
                <ListItem
                  sx={{ mb: 1, mt: 1 }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="select-account"
                      onClick={() => selectAccount(perm)}
                    >
                      <ArrowCircleRightIcon
                        color="error"
                        sx={{ fontSize: "1.5em" }}
                      />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                      <Companies fill={theme.palette.inactive.main} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${perm.name}`}
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => selectAccount(perm)}
                  />
                  {perm?.is_default && <Chip label="Default" />}
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
        </Stack>
      </Layout>
    </Box>
  );
};
export default AccountsListingPage;
