import {
  Director,
  ScreeningProps,
  ScreeningPropsShareholderDirector,
  Shareholder,
} from "components/Map/Sections/Compliance/Screening/types";
import toCamelCase from "helpers/camelCase";
import { useEffect, useMemo, useState } from "react";

interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    data: string | number | string[];
  };
}

const useScreenByProviderGenerator = (
  dataTemplate = {} as MapTemplate,
  data = {} as Record<string, Record<string, ScreeningProps>>,
  shareholders = [] as Shareholder[],
  directors = [] as Director[],
  provider = "" as string
) => {
  const companyVariations = useMemo(
    () => Object.keys(dataTemplate),
    [dataTemplate]
  );

  const shareholderVariation = useMemo(
    () =>
      Array.from(
        { length: shareholders.length },
        (_, i) => `Shareholder ${i + 1}`
      ),
    [shareholders.length]
  );

  const directorVariation = useMemo(
    () =>
      Array.from({ length: directors.length }, (_, i) => `Director ${i + 1}`),
    [directors.length]
  );

  const [initialValuesCompany, setInitialValues] = useState<
    Record<string, ScreeningProps>
  >({});
  const [initialValuesShareholders, setInitialValuesShareholders] = useState<
    Record<string, ScreeningPropsShareholderDirector>
  >({});
  const [initialValuesDirectors, setInitialValuesDirectors] = useState<
    Record<string, ScreeningPropsShareholderDirector>
  >({});

  useEffect(() => {
    const companyInitialValues = companyVariations.reduce(
      (acc, variation) => {
        acc[toCamelCase(variation)] = {
          id: data?.[provider]?.[variation]?.id ?? "",
          value_id: data?.[provider]?.[variation]?.id ?? "",
          map_metadata_id: data?.[provider]?.[variation]?.map_metadata_id ?? "",
          // name: data?.[provider]?.[variation]?.name ?? "",
          name:
            data?.[provider]?.[variation]?.values
              .map(
                (value) => `${value.value} (${value.reports?.join("\n") ?? ""})`
              )
              .join("\n") ?? "",
          values: data?.[provider]?.[variation]?.values ?? [],
          label: dataTemplate?.[variation]?.name ?? "",
          status: data?.[provider]?.[variation]?.status ?? "No Hits",
          remarks: data?.[provider]?.[variation]?.remarks ?? "None",
          documents: data?.[provider]?.[variation]?.documents ?? [],
        } as ScreeningProps;
        return acc;
      },
      {} as Record<string, ScreeningProps>
    );

    setInitialValues(companyInitialValues);

    const shareholdersInitialValues = shareholderVariation.reduce(
      (acc, variation, idx) => {
        acc[toCamelCase(variation)] = {
          id: shareholders?.[idx]?.shareholder_id ?? "",
          name: shareholders?.[idx]?.name ?? "",
          type: shareholders?.[idx]?.type ?? "",
          percentage: shareholders?.[idx]?.percentage ?? "",
          screening: shareholders?.[idx]?.screening ?? {},
          // ) as ScreeningPropsShareholderDirector["screening"],
          // documentProof: directors?.[idx]?.documents ?? [],
        } as ScreeningPropsShareholderDirector;
        return acc;
      },
      {} as Record<string, ScreeningPropsShareholderDirector>
    );

    setInitialValuesShareholders(shareholdersInitialValues);

    const directorsInitialValues = directorVariation.reduce(
      (acc, variation, idx) => {
        acc[toCamelCase(variation)] = {
          id: directors?.[idx]?.director_id ?? "",
          name: directors?.[idx]?.name ?? "",
          screening: directors?.[idx]?.screening ?? {},
          // documentProof: directors?.[idx]?.documents ?? [],
        } as ScreeningPropsShareholderDirector;
        return acc;
      },
      {} as Record<string, ScreeningPropsShareholderDirector>
    );

    setInitialValuesDirectors(directorsInitialValues);
  }, [data, shareholders, directors]);

  return {
    initialValuesCompany,
    initialValuesShareholders,
    initialValuesDirectors,
  };
};

export default useScreenByProviderGenerator;
