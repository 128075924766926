import * as Sentry from "@sentry/react";
import { GROUP_LIST } from "codes";
import Loader from "components/Common/Loader";
import { roleRedirect } from "helpers/redirect";
import Error from "pages/error";
import { Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

const IncomlendInternalLR = () => {
  const APP_PERMISSION = useAppSelector((state) => state.appState.permission);
  const APP_PERMISSIONS = useAppSelector((state) => state.appState.permissions);

  const location = useLocation();

  useEffect(() => {
    if (!GROUP_LIST.includes(APP_PERMISSION)) roleRedirect(APP_PERMISSION);
  }, [APP_PERMISSIONS, location.pathname]);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => {
        const sentence = (error as Error).stack;
        const regex = /(https?:\/\/[^\s)]+)/;
        const match = sentence?.match(regex);
        const link = match ? match[0] : "";
        return (
          <Error
          error={error as Error}
            componentStack={componentStack}
            resetError={resetError}
            scope={`Incomlend internal routes > ${link?.split("/src/")?.pop()}`}
          />
        );
      }}
      beforeCapture={(scope) => {
        scope.setTag("location", "Sales routes");
      }}
    >
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};

export default IncomlendInternalLR;
