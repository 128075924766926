import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useGetTemplatesQuery } from "api/api-compliance";
import {
  useEditEvaluationMutation,
  useGetEvaluationValuesQuery,
} from "api/api-maps";
import MainCard from "components/Common/Cards/MainCard";
import { MapContext } from "pages/maps/map";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

interface IEvaluation {
  id: string;
  name: string;
  value: string;
  type: string;
}

const ComplianceEvaluation = ({
  accountType,
  isLocked,
}: {
  accountType: string;
  isLocked: boolean;
}) => {
  const mapName = React.useContext(MapContext);
  const { data: evalutationTemplateValues } =
    useGetTemplatesQuery("evaluation");

  const labels = useMemo(
    () => Object.values(evalutationTemplateValues ?? {}),
    [evalutationTemplateValues]
  );

  const [patchValues] = useEditEvaluationMutation();
  const { data: evaluationValues, isLoading } = useGetEvaluationValuesQuery(
    { map_name: mapName ?? "", account_type: accountType },
    { skip: !Boolean(mapName) }
  );

  const [answers, setAnswers] = useState<string[]>([]);
  const [yesAnswers, setYesAnswers] = useState<number[]>([]);

  useEffect(() => {
    if (evaluationValues) {
      const initialAnswers = labels.map((label) => {
        const evaluation = evaluationValues.find((e) => e.id === label.id);
        return evaluation ? evaluation.value : "";
      });
      setAnswers(initialAnswers);

      const initialYesAnswers = initialAnswers
        .map((value, index) => (value === "yes" ? index + 1 : null))
        .filter((index) => index !== null) as number[];
      setYesAnswers(initialYesAnswers);
    }
  }, [evaluationValues, labels]);

  const handleRadioChange = useCallback(
    (index: number, value: string, id: string) => {
      if (mapName) {
        setAnswers((prevAnswers) => {
          const newAnswers = [...prevAnswers];
          newAnswers[index] = value;

          setYesAnswers((prevYesAnswers) => {
            let newYesAnswers;
            if (value === "yes") {
              newYesAnswers = [...prevYesAnswers, index + 1];
            } else {
              newYesAnswers = prevYesAnswers.filter((i) => i !== index + 1);
            }
            return newYesAnswers.sort((a, b) => a - b);
          });

          const payload = {
            map_metadata_id: id,
            value: value,
            source: "",
          };
          patchValues({
            map_name: mapName,
            account_type: accountType as "supplier" | "buyer",
            data: payload,
          });

          return newAnswers;
        });
      }
    },
    [mapName, accountType, patchValues]
  );

  if (isLoading || !evaluationValues) {
    return (
      <Box
        flexGrow={1}
        p={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box flexGrow={1} p={2}>
      <Stack spacing={2}>
        {yesAnswers.length > 0 && (
          <Alert severity="error">
            The following questions were answered with "Yes" :{" "}
            {yesAnswers.join(", ")}
          </Alert>
        )}
        <MainCard
          title={
            <Typography variant="h3" gutterBottom textAlign={"center"}>
              Risk Evaluation
            </Typography>
          }
          subheader={
            <Typography variant="subtitle1" gutterBottom textAlign={"center"}>
              If you have answered YES to any of the below, the client may be
              considered high risk. For Enhanced Due Diligence requirements,
              refer to note below.
            </Typography>
          }
        >
          <>
            {labels.map((item, index) => (
              <Fragment key={item.id}>
                <Stack
                  spacing={2}
                  direction={"row"}
                  alignItems="center"
                  sx={{ marginTop: 2 }}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={12} md={10}>
                    <Typography>
                      {index + 1}
                      {". "}
                      {item.name}
                      {answers[index] === "yes" && (
                        <Typography component="span" color="error">
                          {" "}
                          (Answered Yes)
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2} mr={"auto"}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend"></FormLabel>
                      <RadioGroup
                        row
                        value={answers[index] ?? ""}
                        onChange={(e) =>
                          handleRadioChange(index, e.target.value, item.id)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                          disabled={isLocked}
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                          disabled={isLocked}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Stack>
                {index !== labels.length - 1 && (
                  <Grid item xs={12} md={12}>
                    <Divider />
                  </Grid>
                )}
              </Fragment>
            ))}
          </>
          <Stack spacing={1} p={1}>
            <FormHelperText>
              ^ Where the Company does not assess a country with a corruption
              score of below 30 as High Risk, please note.
            </FormHelperText>
            <FormHelperText>
              + Sanctions mean countries that are the subject of the United
              Nations Security Council Resolutions or any countries or persons
              which are the subject of specific sanctions (such as those imposed
              by the governments of the USA or Singapore), The full list of US
              sanctioned countries is available at the OFAC website.
            </FormHelperText>
          </Stack>
        </MainCard>
        <MainCard
          title={
            <Typography variant="h3" gutterBottom textAlign={"center"}>
              Enhanced Due Diligence (“EDD”)
            </Typography>
          }
        >
          <>
            <Typography>
              Group senior management must sign off on any high risk individual.
              An EDD file note (please use template) must be submitted for
              approval with the following information, where applicable:
            </Typography>
            <ul>
              <li>Reason for Client to be classified as High Risk</li>
              <li>
                Rationale as to why client relationship is particularly
                important
              </li>
              <li>
                For PEPs, the EDD file note must also include the following
                information:
                <ul>
                  <li>Identity of the PEP linked to the client relationship</li>
                  <li>Reason for being classified as politically-exposed</li>
                </ul>
              </li>
              <li>
                Has source of wealth and source of funds been established?
              </li>
              <li>
                What are the relationships the Client has with other Financial
                Institutions?
              </li>
              <li>
                If Client is a Corporation, in addition to the questions above,
                the Firm also has to identify the relationships between the
                client and the ultimate beneficial owners.
              </li>
            </ul>
          </>
          <Stack spacing={1} p={1}>
            <FormHelperText>
              ^ Where the Company does not assess a country with a corruption
              score of below 30 as High Risk, please note.
            </FormHelperText>
            <FormHelperText>
              + Sanctions mean countries that are the subject of the United
              Nations Security Council Resolutions or any countries or persons
              which are the subject of specific sanctions (such as those imposed
              by the governments of the USA or Singapore), The full list of US
              sanctioned countries is available at the OFAC website.
            </FormHelperText>
          </Stack>
        </MainCard>
      </Stack>
    </Box>
  );
};

export default ComplianceEvaluation;
