import SyncIcon from "@mui/icons-material/Sync";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  ButtonProps,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useApproveCreditLimitMutation,
  useContactCreditLimitCreatorMutation,
  useGetCreditLimitQuery,
  useGetInsurerIdentificationQuery,
  useRejectCreditLimitMutation,
  useRequestCreditLimitMutation,
  useResubmitCreditLimitMutation,
} from "api/api-origination";
import Modal from "components/Common/Modal";

import {
  CL_APPROVED,
  CREDIT_LIMITS_LINK,
  INCOMLEND_INTERNAL_LINK,
  MARKETING_ADMIN,
  REJECTED_BY_SALES,
} from "codes";
import CLBadges from "components/CLRequest/CLDetailBody/CLBadges";
import CLSectionTabs from "components/CLRequest/CLDetailBody/CLSectionTabs";
import CreditLimitActivityLog from "components/CLRequest/CLDetailBody/CreditLimitActivityLog";
import { initialValues } from "components/CLRequest/CLDetailBody/formvalues";
import CofaceSelect from "components/CLRequest/CofaceSelect";
import IncomlendReview from "components/CLRequest/IncomlendReview";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";
import { PERMISSIONS } from "permissions";
import { createContext, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import secondaryButtonStyle from "styles/secondaryButtonStyle";
import { CLDetails } from "types";

export const CLContext = createContext<CLDetails | undefined>(undefined);
const CLDetail = () => {
  const { push } = history;
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { id } = useParams();
  const USER_PERMISSION = useAppSelector(
    (state) => state.appState.user_permission
  );
  const APP_PERMISSION = useAppSelector((state) => state.appState.permission);
  const initialIncomlendReviewModalValues: {
    comments: string;
    action: "reject" | "contact_creator" | "resubmit" | "approve";
  } = {
    comments: "",
    action: "contact_creator",
  };
  const {
    data: creditLimit,
    isFetching: isFetchingCLR,
    refetch,
  } = useGetCreditLimitQuery(id ?? "", {
    skip: !Boolean(id),
  });

  const [requestCreditLimit, { isLoading: isLoadingRequestCL }] =
    useRequestCreditLimitMutation();
  const [rejectCreditLimit, { isLoading: isLoadingRejectCL }] =
    useRejectCreditLimitMutation();
  const [contactCreditLimitCreator, { isLoading: isLoadingContactCreator }] =
    useContactCreditLimitCreatorMutation();
  const [resubmitCreditLimit, { isLoading: isLoadingResubmit }] =
    useResubmitCreditLimitMutation();
  const [approveCreditLimit, { isLoading: isLoadingApproval }] =
    useApproveCreditLimitMutation();

  const { data: insurers, isFetching } = useGetInsurerIdentificationQuery(
    creditLimit?.id ?? "",
    { skip: !Boolean(creditLimit?.id) }
  );
  const [showChangeStatusModal, setShowChangeStatusModal] = useState<{
    visible: boolean;
    status:
      | {
          type:
            | "request"
            | "reject"
            | "contact_creator"
            | "approve"
            | "resubmit";
          cl_id: string;

          buyerName: string;
          id: string;
        }
      | undefined;
  }>({
    visible: false,
    status: undefined,
  });
  const [showIncomlendReviewModal, setShowIncomlendReviewModal] = useState<{
    visible: boolean;
  }>({
    visible: false,
  });
  const getIncomlendReviewButtonText = (status: string) => {
    switch (status) {
      case "approve":
        return { color: "success", text: "Approve Credit Limit" };
      case "contact_creator":
        return { color: "primary", text: "Request changes" };
      case "reject":
        return { color: "error", text: "Reject Credit Limit" };
      case "resubmit":
        return { color: "primary", text: "Resubmit Credit Limit" };
      default:
        return { color: "primary", text: "Request changes" };
    }
  };
  const isApproved = (status: string) => status === CL_APPROVED;
  const isRejected = (status: string) => status === REJECTED_BY_SALES;
  const confirmClick = ({
    credit_limit_id,
    comments,
    action,
  }: {
    credit_limit_id: string;
    comments: string;
    action: "reject" | "contact_creator" | "resubmit" | "approve";
  }) => {
    if (action === "reject") {
      rejectCreditLimit({
        credit_limit_id: credit_limit_id,
        comments: {
          comments: comments,
        },
      })
        .unwrap()
        .catch((error) => {
          const errorFile = error?.message;
          toast(`Something went wrong : ${errorFile}`, {
            type: "error",
          });
        })
        .then(refetch);
    }
    if (action === "contact_creator") {
      contactCreditLimitCreator({
        credit_limit_id: credit_limit_id,
        comments: {
          comments: comments,
        },
      })
        .unwrap()
        .catch((error) => {
          const errorFile = error?.message;
          toast(`Something went wrong : ${errorFile}`, {
            type: "error",
          });
        })
        .then(refetch);
    }
    if (action === "resubmit") {
      resubmitCreditLimit({
        credit_limit_id: credit_limit_id,
        comments: {
          comments: comments,
        },
      })
        .unwrap()
        .catch((error) => {
          const errorFile = error?.message;
          toast(`Something went wrong : ${errorFile}`, {
            type: "error",
          });
        })
        .then(refetch);
    }
    if (action === "approve") {
      approveCreditLimit({
        credit_limit_id: credit_limit_id,
        comments: {
          comments: comments,
        },
      })
        .unwrap()
        .catch((error) => {
          const errorFile = error?.message;
          toast(`Something went wrong : ${errorFile}`, {
            type: "error",
          });
        })
        .then(refetch);
    }
    setShowIncomlendReviewModal({
      visible: false,
    });
  };
  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };

  const [coface_id, setCofaceId] = useState("");

  return (
    <CLContext.Provider value={creditLimit}>
      <Grid container spacing={2} padding={isMobile ? 0 : "3ch"}>
        <Grid item xs={12}>
          <Typography
            style={{
              textDecoration: "underline",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              push(`${INCOMLEND_INTERNAL_LINK}${CREDIT_LIMITS_LINK}`);
            }}
          >{`<- Back to Credit Limits`}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={{ xs: 1 }}
          >
            <Grid item lg={6} xs={12}>
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item>
                  <Typography
                    fontWeight={"bold"}
                    fontSize={isMobile ? "1em" : "1.5em"}
                    variant="h3"
                  >
                    {id}
                  </Typography>
                </Grid>

                <Grid item>
                  <Tooltip
                    children={<SyncIcon onClick={() => refetch()} />}
                    title={"Sync latest changes"}
                  />
                </Grid>
              </Grid>
            </Grid>
            {isMobile && <CLBadges />}
            <Grid item lg={6} xs={12}>
              <Grid container spacing={2} justifyContent={"end"}>
                <Grid item lg={6} xs={12}>
                  {isFetchingCLR && <SkeletonLoad bars={1} />}
                  {!isFetchingCLR &&
                    creditLimit &&
                    !creditLimit.coface_found &&
                    USER_PERMISSION.includes(PERMISSIONS.get_insurer) && (
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        disabled={
                          isRejected(creditLimit?.internalStatus) ||
                          APP_PERMISSION === MARKETING_ADMIN
                        }
                        loading={isLoadingRequestCL}
                        onClick={() =>
                          creditLimit?.id &&
                          setShowChangeStatusModal({
                            visible: true,
                            status: {
                              id: creditLimit?.id,
                              cl_id: id ?? "",
                              buyerName: creditLimit.buyerName,
                              type: "request",
                            },
                          })
                        }
                      >
                        Request Coface
                      </LoadingButton>
                    )}
                </Grid>

                <Grid item lg={6} xs={12}>
                  {isFetchingCLR && <SkeletonLoad bars={1} />}
                  {!isFetchingCLR && creditLimit && (
                    <LoadingButton
                      fullWidth
                      variant="outlined"
                      color="primary"
                      loading={isLoadingContactCreator}
                      disabled={
                        isApproved(creditLimit?.internalStatus) ||
                        APP_PERMISSION === MARKETING_ADMIN
                      }
                      onClick={() =>
                        creditLimit?.id &&
                        setShowIncomlendReviewModal({
                          visible: true,
                        })
                      }
                    >
                      Incomlend Review
                    </LoadingButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {!isMobile && <CLBadges />}
          </Grid>
        </Grid>
        {!creditLimit && isFetchingCLR && <SkeletonLoad bars={10} />}
        {creditLimit && !isFetchingCLR && (
          <Grid item xs={12}>
            <Formik
              initialValues={{ ...initialValues, ...creditLimit }}
              onSubmit={() => {}}
            >
              <Form>
                {isMobile ? (
                  <Stack spacing={4} direction="row">
                    <Grid container>
                      <Grid xs={12}>
                        <CLSectionTabs />
                      </Grid>
                      <Grid xs={12}>
                        <CreditLimitActivityLog
                          creditLimitId={creditLimit?.id}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                ) : (
                  <Stack spacing={4} direction="row">
                    <CLSectionTabs />
                    <CreditLimitActivityLog creditLimitId={creditLimit?.id} />
                  </Stack>
                )}
              </Form>
            </Formik>
          </Grid>
        )}
        <Modal
          message={
            showChangeStatusModal.status &&
            creditLimit && (
              <CofaceSelect
                statusType={showChangeStatusModal?.status?.type}
                cl_id={showChangeStatusModal?.status?.cl_id}
                buyerName={showChangeStatusModal?.status?.buyerName}
                insurers={insurers}
                buyerRegNo={creditLimit?.buyerRegNo}
                isFetching={isFetching}
                setCofaceId={setCofaceId}
                coface_id={coface_id}
              />
            )
          }
          sx={modalSx}
          width={isMobile ? "90%" : "40%"}
          height={"auto"}
          modalContentSx={{ height: "auto" }}
          open={showChangeStatusModal.visible}
          modalOverlaySx={modalOverlaySx}
          modalFooterSx={{ maxWidth: "100%" }}
          onClose={() =>
            setShowChangeStatusModal({
              visible: false,
              status: undefined,
            })
          }
          primary={{
            variant: "contained",
            loading:
              showChangeStatusModal?.status?.type === "request"
                ? isLoadingRequestCL
                : showChangeStatusModal?.status?.type === "reject"
                  ? isLoadingRejectCL
                  : false,
            onClick: () => {
              if (showChangeStatusModal?.status?.type === "request") {
                requestCreditLimit({
                  credit_limit_id: showChangeStatusModal?.status?.id,
                  coface_id,
                  comments: { comments: "" },
                })
                  .unwrap()
                  .catch((error) => {
                    const errorFile = error?.message;
                    toast(`Something went wrong : ${errorFile}`, {
                      type: "error",
                    });
                  });
              }
              if (showChangeStatusModal?.status?.type === "reject") {
                rejectCreditLimit({
                  credit_limit_id: showChangeStatusModal?.status?.id,
                  comments: { comments: "" },
                })
                  .unwrap()
                  .catch((error) => {
                    const errorFile = error?.message;
                    toast(`Something went wrong : ${errorFile}`, {
                      type: "error",
                    });
                  });
              }
              setShowChangeStatusModal({
                visible: false,
                status: undefined,
              });
            },
            children: "Confirm",
          }}
          closeButton
          secondary={{
            variant: "outlined",
            onClick: () =>
              setShowChangeStatusModal({
                visible: false,
                status: undefined,
              }),
            sx: {
              ...secondaryButtonStyle,
              bgcolor: theme.palette.error.main,
              color: theme.palette.background.default,
              border: `1px solid ${theme.palette.error.main}`,
              ":hover": {
                bgcolor: theme.palette.error.main,
                color: theme.palette.background.default,
                outline: "transparent",
                border: `1px solid ${theme.palette.error.main}`,
              },
            },
            children: `Cancel`,
          }}
        />
        <Formik
          onSubmit={console.log}
          initialValues={initialIncomlendReviewModalValues}
        >
          {(formikProps) => (
            <Form>
              <Modal
                Header={
                  <Typography fontWeight={"bold"}>
                    Submit Your Review
                  </Typography>
                }
                message={
                  id &&
                  creditLimit && (
                    <IncomlendReview
                      cl_id={id}
                      cl_creator={creditLimit?.creator}
                      is_rejected={
                        creditLimit?.internalStatus === REJECTED_BY_SALES
                      }
                      send_message_to={{
                        contact_description: creditLimit?.contact_description,
                        email: creditLimit?.email,
                        name: creditLimit?.client_name,
                      }}
                      salesName={creditLimit?.salesName}
                    />
                  )
                }
                sx={modalSx}
                width={isMobile ? "90%" : "40%"}
                height={"auto"}
                modalContentSx={{ height: "auto" }}
                open={showIncomlendReviewModal.visible}
                modalOverlaySx={modalOverlaySx}
                modalFooterSx={{
                  maxWidth: "100%",
                  justifyContent: "space-between",
                }}
                onClose={() =>
                  setShowIncomlendReviewModal({
                    visible: false,
                  })
                }
                closeButton
                primary={{
                  variant: "contained",
                  fullWidth: true,
                  onClick: () => {
                    confirmClick({
                      credit_limit_id: creditLimit?.id ?? "",
                      comments: formikProps.values.comments,
                      action: formikProps.values.action,
                    });
                  },
                  children: getIncomlendReviewButtonText(
                    formikProps.values.action
                  ).text,
                  sx: { minWidth: "12vw" },
                  color: getIncomlendReviewButtonText(formikProps.values.action)
                    .color as ButtonProps["color"],
                }}
                secondary={{
                  variant: "outlined",
                  fullWidth: true,
                  onClick: () =>
                    setShowIncomlendReviewModal({
                      visible: false,
                    }),
                  children: `Cancel`,
                }}
              />
            </Form>
          )}
        </Formik>
      </Grid>
    </CLContext.Provider>
  );
};
export default CLDetail;
