import { CreditReportDocument } from "./types";

export const getCreditReportDocument = (
  documents: CreditReportDocument[],
  provider: string
) => {
  return (
    documents?.filter(
      (document) =>
        document.document_type?.toLowerCase() === provider?.toLowerCase()
    )[0] ?? {
      id: "",
      filename: "",
      document_type_category: "",
      document_type: "",
      submitted: "",
      year: null,
    }
  );
};
