interface MapApprover {
  contact_id: string;
  approver_name: string;
  approval_date: string;
  approval_status: string;
  comments?: string;
}

interface CreditCommitteeApproval {
  ceo_ipl: MapApprover;
  ceo_icpl: MapApprover;
  funding: MapApprover;
  [department: string]: MapApprover;
}

export const initialValues: CreditCommitteeApproval = {
  ceo_ipl: {
    contact_id: "",
    approver_name: "",
    approval_date: "",
    approval_status: "",
    comments: undefined,
  },
  ceo_icpl: {
    contact_id: "",
    approver_name: "",
    approval_date: "",
    approval_status: "",
    comments: undefined,
  },
  funding: {
    contact_id: "",
    approver_name: "",
    approval_date: "",
    approval_status: "",
    comments: undefined,
  },
};

export const KEY_PHASE: Record<string, string> = {
  onboarding_officer_decision: "Prepared by Onboarding Officer",
  compliance_officer_decision: "Verified by Compliance Officer",
  head_compliance_decision: "Approved by Group Head of Compliance",
};

export const ROLE_KEY_VALUE: Record<string, string>[] = [
  {
    name: "Prepared by Onboarding Officer",
    value: "onboarding_officer_decision",
  },
  {
    name: "Verified by Compliance Officer",
    value: "compliance_officer_decision",
  },
  {
    name: "Approved by Group Head of Compliance",
    value: "head_compliance_decision",
  },
];
