import { Container, Stack, Typography } from "@mui/material";
import { currencyFormatter } from "helpers/currencyFormatter";
import styles from "./battery.module.scss";

const Battery = ({
  value,
  total,
  title,
}: {
  value: number;
  total: number;
  title: string;
}) => {
  const charge = value > 0 ? Math.round((value / total) * 100) : 0;

  function batteryCharge() {
    let newCol = ["#316d08", "#60b939", "#51aa31", "#64ce11", "#255405"];
    if (charge < 20) {
      newCol = ["#750900", "#c6462b", "#b74424", "#df0a00", "#590700"];
    } else if (charge < 40) {
      newCol = ["#754f00", "#f2bb00", "#dbb300", "#df8f00", "#593c00"];
    } else {
      newCol = ["#316d08", "#60b939", "#51aa31", "#64ce11", "#255405"]; //100%
    }
    return newCol;
  }
  const col = batteryCharge();
  return (
    <Stack justifyContent={"end"} spacing={1}>
      <Typography
        textAlign="center"
        fontWeight={"bold"}
        fontSize={"1.3em"}
        color="white"
      >
        {title}
      </Typography>
      <Container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div
          className={styles.battery}
          style={{
            backgroundImage: `linear-gradient(to right, transparent 5%, ${col[0]} 5%, ${col[0]} 7%, ${col[1]} 8%, ${col[1]} 10%, ${col[2]} 11%, ${col[2]} ${charge - 3}%, ${col[3]} ${charge - 2}%, ${col[3]} ${charge}%, ${col[4]} ${charge + 5}%, black 95%, transparent 95%), linear-gradient(to bottom, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.4) 4%, rgba(255,255,255,0.2) 7%, rgba(255,255,255,0.2) 14%, rgba(255,255,255,0.8) 14%, rgba(255,255,255,0.2) 40%, rgba(255,255,255,0) 41%, rgba(255,255,255,0) 80%, rgba(255,255,255,0.2) 80%, rgba(255,255,255,0.4) 86%, rgba(255,255,255,0.6) 90%, rgba(255,255,255,0.1) 92%, rgba(255,255,255,0.1) 95%, rgba(255,255,255,0.5) 98%)`,
          }}
        ></div>
        <Typography
          textAlign="right"
          fontWeight={600}
        >{`${charge}%`}</Typography>
      </Container>
      <Typography
        textAlign="center"
        color={"markdown.main"}
        fontWeight={"bold"}
        bgcolor={"rgba(222, 222, 222, 0.3)"}
        fontFamily={"monospace, monospace"}
      >{`${currencyFormatter({ amount: value * 0.000001 })}M of ${currencyFormatter({ amount: total * 0.000001 })}M`}</Typography>
    </Stack>
  );
};
export default Battery;
