import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";

interface AdminRole {
  get_assigned_credit_limits: boolean;
  get_all_leads: boolean;
  get_all_users: boolean;
  get_all_accounts: boolean;
  get_account: boolean;
  get_all_credit_limits: boolean;
  get_credit_limit: boolean;
  update_credit_limit: boolean;
  request_credit_limit: boolean;
  reject_credit_limit: boolean;
  access_it_module: boolean;
  access_supplier_factoring_module: boolean;
  access_supplier_reverse_factoring_module: boolean;
  access_marketplace_module: boolean;
  access_lead_module: boolean;
  access_credit_limit_module: boolean;
  get_all_groups: boolean;
  get_all_departments: boolean;
  add_permissions: boolean;
  add_department_contact: boolean;
  add_group_permission: boolean;
  update_department_contact: boolean;
  get_all_permissions: boolean;
  update_contact_group: boolean;
  update_contact_account_permissions: boolean;
  add_user: boolean;
  delete_user: boolean;
  add_contact_group: boolean;
  add_groups: boolean;
  update_group_permission: boolean;
  get_platform_usage: boolean;
  create_lead: boolean;
  show_platform_usage: boolean;
  approve_credit_limit: boolean;
  test_customer_io: boolean;
  show_maps: boolean;
  show_support_tickets: boolean;
  vaglette: boolean;
  show_credit_limits: boolean;
  show_onboardings: boolean;
  show_cargo_tracking: boolean;
  show_company_profile: boolean;
  show_dashboard: boolean;
  show_leaves: boolean;
  show_invoice_statuses: boolean;
  show_leads: boolean;
  show_ewallet: boolean;
  show_campaign_testing: boolean;
  show_balances: boolean;
  show_organization_chart: boolean;
  show_user_management: boolean;
  show_statuses: boolean;
  show_request_financing: boolean;
  get_insurer: boolean;
  show_countries: boolean;
  show_onboarding_statuses: boolean;
}

interface AdminRoles {
  ACCOUNT_ADMIN: AdminRole;
  MARKETING_ADMIN: AdminRole;
  OPERATION_ADMIN: AdminRole;
  ORIGINATION_ADMIN: AdminRole;
  RISK_ADMIN: AdminRole;
  ORIGINATION_MEMBER: AdminRole;
  MARKETING_MEMBER: AdminRole;
  IT_ADMIN: AdminRole;
  IT_MEMBER: AdminRole;
  RISK_MEMBER: AdminRole;
  COMPLIANCE_ADMIN: AdminRole;
  COMPLIANCE_MEMBER: AdminRole;
  FUNDING_ADMIN: AdminRole;
  FUNDING_MEMBER: AdminRole;
  FINANCE_ADMIN: AdminRole;
  FINANCE_MEMBER: AdminRole;
  OPERATION_MEMBER: AdminRole;
  ACCOUNT_MEMBER: AdminRole;
  LEGAL_ADMIN: AdminRole;
  LEGAL_MEMBER: AdminRole;
  GROUP_TEST: AdminRole;
}
interface IUserIT {
  departments: string[];
  first_name: string;
  groups: string[];
  is_active: string;
  last_login: Date;
  last_name: string;
  id: string;
  username: string;
  contact_id: string;
}
interface Account {
  id: string;
  name: string;
  permission: string;
  role: string;
}
interface UserDetails {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  id_no: null | any;
  is_active: boolean;
  title: string;
  last_login: string;
  sign_up: string;
  opted_marketing_updates: boolean;
  settings: null | any;
  accounts: Account[];
  departments: string[];
  groups: string[];
  role?: any;
}
const formatUsers = (
  data: {
    user_id: string;
    contact_id: string;
    first_name: string;
    last_name: string;
    username: string;
    last_login: Date;
    contact_created_at: string;
    is_active: boolean;
    departments: string[];
    groups: string[];
  }[]
): IUserIT[] => {
  return data?.map((item) => ({
    first_name: item.first_name,
    last_name: item.last_name,
    username: item.username,
    last_login: item.last_login,
    departments: item.departments,
    groups: item.groups,
    id: item.user_id,
    is_active: item.is_active ? "True" : "False",
    contact_id: item.contact_id,
  }));
};

export const it = createApi({
  reducerPath: "it",
  tagTypes: [
    "USERS",
    "USERS_INTERNAL",
    "USERS_EXTERNAL",
    "USERS_GROUPS",
    "USERS_GROUP_PERMISSIONS",
    "PERMISSIONS",
    "GROUP_PERMISSIONS",
    "GROUPS",
    "GROUP_MENUS",
    "GROUP_ACCESS",
    "ACCOUNTS",
    "DEPARTMENTS",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/it`,
  }),
  endpoints: (builder) => ({
    // Users Table
    getInternalUsers: builder.query<IUserIT[], null>({
      query: () => ({ url: "/users/internal", method: "get" }),
      transformResponse: (response) => formatUsers(response?.data),
      providesTags: ["USERS_INTERNAL"],
    }),
    getExternalUsers: builder.query<IUserIT[], null>({
      query: () => ({ url: "/users/external", method: "get" }),
      transformResponse: (response) => formatUsers(response?.data),
      providesTags: ["USERS_EXTERNAL"],
    }),
    getUserById: builder.query<UserDetails, string>({
      query: (id) => ({ url: `/users/${id}`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["USERS"],
    }),
    updateUserById: builder.mutation<any, Partial<UserDetails>>({
      query: ({ id, ...payload }) => {
        return {
          url: `/users/contact/${id}`,
          method: "PUT",
          data: payload,
        };
      },
      invalidatesTags: ["USERS_INTERNAL", "USERS"],
      transformResponse: (response) => response?.data,
    }),
    inviteUser: builder.mutation<any, any>({
      query: (data) => {
        const { account_id, ...payload } = data;
        return {
          url: `/invite-contact/${account_id}`,
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["USERS_EXTERNAL"],
      transformResponse: (response) => response?.data,
    }),
    inviteInternalUser: builder.mutation<any, any>({
      query: (payload) => {
        return {
          url: `/invite-contact/internal`,
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["USERS_INTERNAL"],
      transformResponse: (response) => response?.data,
    }),
    // Groups
    getAllGroups: builder.query<AdminRoles, null>({
      query: () => ({
        url: `/groups`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["GROUPS"],
    }),
    getAllGroupMenus: builder.query<Record<string, string>[], null>({
      query: () => ({
        url: `/groups/utils/menus`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["GROUP_MENUS"],
    }),
    getAllGroupAccesses: builder.query<Record<string, string>[], null>({
      query: () => ({
        url: `/groups/utils/access`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["GROUP_ACCESS"],
    }),
    getUserGroups: builder.query<{ id: string; name: string }[], string>({
      query: (user_id) => ({
        url: `/user/${user_id}/groups`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["USERS_GROUPS"],
    }),
    addRemoveUserGroups: builder.mutation<
      { id: string; name: string }[],
      { groups_to_add: string[]; groups_to_remove: string[]; user_id: string }
    >({
      query: (data) => {
        const { user_id, ...payload } = data;
        return {
          url: `/user/${user_id}/groups`,
          method: "PUT",
          data: payload,
        };
      },
      invalidatesTags: ["USERS_GROUPS", "USERS"],
      transformResponse: (response) => response?.data,
    }),
    addRemoveGroupPermissions: builder.mutation<
      any,
      {
        group_name: string;
        data: {
          permission_name: string;
          is_active: boolean;
        }[];
      }
    >({
      query: (payload) => {
        const { group_name, data } = payload;
        return {
          url: `/groups/${group_name}/permissions`,
          method: "PUT",
          data: [...data],
        };
      },
      invalidatesTags: [
        "USERS_GROUPS",
        "USERS_INTERNAL",
        "GROUP_PERMISSIONS",
        "USERS_GROUP_PERMISSIONS",
        "GROUPS",
      ],
      transformResponse: (response) => response?.data,
    }),
    addNewPermission: builder.mutation<any, string[]>({
      query: (permissions) => {
        return {
          url: `/permissions`,
          method: "POST",
          data: {
            permissions,
          },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["GROUP_PERMISSIONS", "GROUPS", "PERMISSIONS"],
    }),
    // Departments
    getAllDepartments: builder.query<{ id: string; name: string }[], null>({
      query: () => ({
        url: `/departments`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["DEPARTMENTS"],
    }),
    // Permissions
    getPermissionsPerGroup: builder.query<any[], string>({
      query: (group_name) => ({
        url: `/groups/${group_name}/permissions`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["GROUP_PERMISSIONS"],
    }),
    getAllUserPermissionsWithGroups: builder.query<AdminRoles, string>({
      query: (user_id) => ({
        url: `/user/${user_id}/groups-with-permissions`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["USERS_GROUP_PERMISSIONS", "USERS_GROUPS"],
    }),
    // Accounts
    getAllAccounts: builder.query<
      { id: string; name: string; reg_no: string }[],
      null
    >({
      query: () => ({
        url: `/accounts`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["ACCOUNTS"],
    }),
    addAccountToUser: builder.mutation<
      any,
      { user_id: string; account_id: string }
    >({
      query: (data) => {
        return {
          url: `/contact-permission`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["USERS"],
      transformResponse: (response) => response?.data,
    }),
    removeAccountFromUser: builder.mutation<
      any,
      { user_id: string; account_id: string }
    >({
      query: (data) => {
        return {
          url: `/remove-contact-permission`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["USERS"],
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useGetInternalUsersQuery,
  useGetExternalUsersQuery,
  useGetUserByIdQuery,
  useUpdateUserByIdMutation,
  useGetPermissionsPerGroupQuery,
  useGetAllUserPermissionsWithGroupsQuery,
  useGetUserGroupsQuery,
  useGetAllGroupsQuery,
  useGetAllGroupMenusQuery,
  useGetAllGroupAccessesQuery,
  useAddRemoveUserGroupsMutation,
  useGetAllAccountsQuery,
  useInviteUserMutation,
  useInviteInternalUserMutation,
  useGetAllDepartmentsQuery,
  useAddAccountToUserMutation,
  useRemoveAccountFromUserMutation,
  useAddRemoveGroupPermissionsMutation,
  useAddNewPermissionMutation,
} = it;
