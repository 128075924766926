export const uniqueObject = (arr: any[], keyName: string) => {
  const uniqueMap = new Map();
  arr.forEach((obj) => {
    const name = obj?.[keyName];
    if (typeof name === "string" && !uniqueMap.has(name)) {
      uniqueMap.set(name, obj);
    }
  });
  return Array.from(uniqueMap.values());
};
export default function unique(arr: any[]) {
  return [...new Set(arr)];
}
