import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { useAddInterVieweeMutation } from "api/api-risk";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";
import { MapContext } from "pages/maps/map";
import React, { useContext } from "react";
import { toast } from "react-toastify";

interface AddIntervieweeProps {
  open: boolean;
  onClose: () => void;
  accountType: string;
}

const AddInterviewee: React.FC<AddIntervieweeProps> = ({
  open,
  onClose,
  accountType,
}) => {
  const [addInterviewee] = useAddInterVieweeMutation();
  const mapId = useContext(MapContext);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle variant="h3">Add Interviewee</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Formik
        initialValues={{ interviewee_name: "", interviewee_title: "" }}
        onSubmit={(values) => {
          const { interviewee_name, interviewee_title } = values;
          mapId &&
            addInterviewee({
              map_id: mapId,
              account_type: accountType,
              data: {
                interviewee_name,
                interviewee_title,
              },
            })
              .then(() => {
                toast.success("Interviewee added");
                onClose();
              })
              .catch(() => toast.error("Error adding interviewee"));
        }}
      >
        <Form>
          <DialogContent dividers>
            <Stack spacing={2}>
              <Input
                label="Interviewee Name"
                fullWidth
                name={"interviewee_name"}
              />
              <Input
                label="Interviewee Title"
                fullWidth
                name={"interviewee_title"}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button variant="contained" color="primary" type="submit">
              Add
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddInterviewee;
