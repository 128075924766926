import SyncIcon from "@mui/icons-material/Sync";
import { Box, Slide, Stack } from "@mui/material";
import { useGetEligibilityByIsoQuery } from "api/api-legal";
import { INCOMLEND_INTERNAL_LINK, LEGAL_LINK } from "codes";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import EligibilityClause from "components/Legal/EligibilityClause";
import EligibilityCondition from "components/Legal/EligibilityCondition";
import LegalActivityLog from "components/Legal/LegalActivityLog";
import { Form, Formik } from "formik";
import useIsMobile from "hooks/useIsMobile";
import { useParams } from "react-router-dom";

const ViewEditEligibility = () => {
  const isMobile = useIsMobile();
  const { iso3 } = useParams();

  const {
    data: eligibility,
    isFetching: isFetchingEligibility,
    refetch,
  } = useGetEligibilityByIsoQuery(iso3 ?? "", {
    skip: !Boolean(iso3),
  });

  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={`Country Eligibility of ${eligibility?.name}`}
          textHeadingOptions={{
            fontWeight: "bolder",
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            left: 9,
            mid: 1,
            right: 2,
            xs: {
              left: 2,
              mid: 0,
              right: 10,
            },
            alignItems: "center",
          }}
          secondary={{
            onClick: refetch,
            startIcon: <SyncIcon />,
            children: "Sync",
            variant: "outlined",
            fullWidth: true,
          }}
          backArrow
          backLink={`${INCOMLEND_INTERNAL_LINK}${LEGAL_LINK}`}
          mode="default"
        >
          {isFetchingEligibility && !eligibility && <SkeletonLoad bars={10} />}
          {!isFetchingEligibility && eligibility && (
            <Formik
              initialValues={{
                ...eligibility,
                newCondition: {
                  party: "",
                  required_document_id: "",
                  document_name: "",
                  condition: "",
                },
              }}
              onSubmit={() => {}}
              enableReinitialize
            >
              {() => (
                <Form>
                  <Stack spacing={2} id="work-activity" direction="row">
                    <Stack spacing={2}>
                      {!iso3 && <SkeletonLoad bars={3} />}
                      {iso3 && (
                        <EligibilityClause iso3={iso3} refetch={refetch} />
                      )}
                      {!iso3 && <SkeletonLoad bars={3} />}
                      {iso3 && <EligibilityCondition iso3={iso3} />}
                    </Stack>
                    {!iso3 && <SkeletonLoad bars={3} />}
                    {iso3 && (
                      <LegalActivityLog
                        iso3={iso3}
                        version_id={eligibility?.draft}
                      />
                    )}
                  </Stack>
                </Form>
              )}
            </Formik>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};
export default ViewEditEligibility;
