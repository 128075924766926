import { Container, Link, Stack, Typography } from "@mui/material";
import MayDay from "assets/error-page.svg?react";
import { SUPPORT_EMAIL } from "codes";

export interface ErrorPageProps {
  /**Window goBack */
  goBack: () => void;
  /**Window push */
  push?: any;
  address: string;
  homePath?: string;
}

const ErrorPage = ({
  goBack,
  push,
  homePath = "/dashboard",
  address = SUPPORT_EMAIL,
}: ErrorPageProps) => {
  const handleTryAgainClick = () => {
    goBack();
  };

  const subject = "Error Encountered";
  const content = "I encountered an error, these are the details:";
  const mailto = `mailto:${address}?subject=${subject}&body=${content}`;

  return (
    <Stack justifyContent={"center"} alignItems={"center"} spacing={3}>
      <Container maxWidth={"sm"}>
        <MayDay />
      </Container>
      <Typography variant="h3" textAlign={"center"}>
        Oops! Something went wrong.
      </Typography>
      <Stack direction={"row"} spacing={2}>
        <Typography
          sx={{ textDecoration: "underline" }}
          onClick={handleTryAgainClick}
        >
          Try Again
        </Typography>
        <Link href={homePath}>Go Home</Link>
        <Link href={mailto}>Contact Us</Link>
      </Stack>
    </Stack>
  );
};

export default ErrorPage;
