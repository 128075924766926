import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS } from "codes";
import { useEffect, useState } from "react";

const useDefaultTableDimensions = (table: any[], width: number) => {
  const [defaultRows, setDefaultRows] = useState(DEFAULT_ROWS);
  const [defaultColumnWidth, setDefaultColumnWidth] = useState(200);
  const { data: curr_user } = useGetCurrentUserQuery();

  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  useEffect(() => {
    const headerLength = Object.keys(table?.[0] ?? {})?.length;
    setDefaultColumnWidth((width / headerLength) * 1.6);
    setDefaultRows(rowsPerPage);
  }, [table, width]);

  return { defaultColumnWidth, defaultRows };
};

export default useDefaultTableDimensions;
