import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useGetMapDocumentsQuery,
  useGetMapInsurersQuery,
  useGetMapSummaryQuery,
} from "api/api-maps";
import { VITE_API_V2_URL } from "codes";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import MapGeneralDetails from "components/Map/General";
import { mapSummaryDefaults } from "components/Risk/defaults";
import { Form, Formik } from "formik";
import { MapContext } from "pages/maps/map";
import React from "react";

const Operations = () => {
  const id = React.useContext(MapContext);
  const theme = useTheme();

  const {
    data: mapData,
    refetch: refetchMapSummary,
    isLoading,
  } = useGetMapSummaryQuery(id ?? "", { skip: !id });

  const {
    data: mapDocuments,
    refetch: refetchMapDocuments,
    isFetching: isFerchingMapDocuments,
  } = useGetMapDocumentsQuery(id ?? "", { skip: !id });

  const {
    data: mapInsurers,
    refetch: refetchMapInsurers,
    isFetching: isFetchingMapInsurers,
  } = useGetMapInsurersQuery(id ?? "", { skip: !id });

  const refetch = () => {
    refetchMapSummary();
    refetchMapDocuments();
    refetchMapInsurers();
  };

  const generatePdf = () => {
    const getToken = () => JSON.parse(localStorage.getItem("token") as string);
    const url = `${VITE_API_V2_URL}/maps/${id}/investment-criteria-card/export`;
    const link = document.createElement("a");
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()?.value}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error("Access Denied");
        }
      })
      .then((blob) => {
        const objectURL = URL.createObjectURL(blob);
        link.href = objectURL;
        link.download = `${id}_Risk_Score_Card.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  };

  return (
    <Layout
      title="Summary"
      LayoutHeader={
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={1}
          justifyContent="flex-end"
        >
          <Tooltip title="Map must be approved by the credit committee to generate the factsheet.">
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={generatePdf}
                fullWidth
                disabled={mapData?.map_approved ? false : true}
              >
                Generate FactSheet
              </Button>
            </Box>
          </Tooltip>
          <Typography
            style={{
              background: mapData?.map_approved
                ? theme.palette.success.main
                : theme.palette.error.main,
              padding: "1ch 1ch",
              borderRadius: "10px",
              color: "white",
              fontWeight: "600",
              lineHeight: "1.5em",
              width: "fit-content",
              fontSize: "1em",
              maxWidth: "100%",
            }}
            sx={{
              [theme.breakpoints.down("sm")]: {
                fontSize: "1em",
              },
            }}
          >
            {mapData?.map_approved ? "APPROVED" : "PENDING REVIEW"}
          </Typography>
        </Stack>
      }
      headerConfig={{
        syncAction: refetch,
        left: 3,
        mid: 6,
        right: 12,
        xs: {
          left: 12,
          mid: 12,
          right: 0,
        },
        alignItems: "start",
      }}
    >
      <Formik
        enableReinitialize
        initialValues={{
          ...mapSummaryDefaults,
          ...mapData,
          map_name: id,
          map_documents: mapDocuments,
          map_insurers: mapInsurers,
        }}
        onSubmit={() => {}}
      >
        <Form>
          {isLoading && <SkeletonLoad bars={10} />}
          {!isLoading && mapData && <MapGeneralDetails />}
        </Form>
      </Formik>
    </Layout>
  );
};

export default Operations;
