import { TableCell, TableRow, Typography } from "@mui/material";
import { useModifyMapFieldMutation } from "api/api-maps";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { IRiskScoring } from "components/Risk/defaults";
import { useFormikContext } from "formik";
import toCamelCase from "helpers/camelCase";
import { toast } from "react-toastify";
import { RiskCategory } from "types";

interface RiskCategoryDescription {
  map_metadata_id: string;
  value_type: string;
  name: string;
  value: number;
}

const Category = ({
  category,
  type,
  mapId,
  disabled,
}: {
  category: RiskCategory;
  type: string;
  mapId: string;
  disabled: boolean;
}) => {
  const formikProps = useFormikContext<IRiskScoring>();
  const metadataIddField = `${type}.${toCamelCase(category.item)}.id`;
  const descriptionField = `${type}.${toCamelCase(category.item)}.description`;
  const scoreField = `${type}.${toCamelCase(category.item)}.score`;
  const value =
    formikProps.values?.[type]?.[toCamelCase(category.item)]?.description;
  const [modifyMapField, { isLoading }] = useModifyMapFieldMutation();

  const setter = (value: string | undefined) => {
    const score = category.item_descriptions.find(
      (item) => item.name === value
    )?.value;
    const mapMetadataId = category.map_metadata_id;
    if (mapMetadataId) {
      formikProps.setFieldValue(metadataIddField, mapMetadataId);
      formikProps.setFieldValue(descriptionField, value);
      formikProps.setFieldValue(scoreField, score);
    }
  };
  const currentSelectionMeta = category?.current_risk_selection_metadata;
  const formikItem = formikProps.values?.[type]?.[toCamelCase(category.item)];

  return (
    <TableRow>
      <TableCell>
        <Typography fontWeight={"bold"}>{category.item} </Typography>
      </TableCell>
      <TableCell>
        {type !== "adjustment" ? (
          <VariableDropdown
            name={descriptionField}
            keyValue={"name"}
            value={value}
            data={category.item_descriptions}
            setValue={setter}
            handleSave={(value) =>
              //check whether the new value is different from the current value
              {
                const canUpdate = currentSelectionMeta
                  ? formikItem?.description !==
                    currentSelectionMeta?.current_risk_rating
                  : true;

                canUpdate &&
                  modifyMapField({
                    map_name: mapId,
                    data: {
                      map_metadata_id: formikItem?.id,
                      value: {
                        name: value,
                        value: formikItem?.score,
                      },
                    },
                  })
                    .unwrap()
                    .then(() => {
                      toast(`Set ${type}'s ${category.item} as ${value}`, {
                        type: "success",
                      });
                    })
                    .catch((e) =>
                      toast(`An error occured: ${e?.message}`, {
                        type: "error",
                      })
                    );
              }
            }
            disabled={disabled}
            noHover={disabled}
          />
        ) : (
          <VariableInput
            name={descriptionField}
            label={undefined}
            value={value}
            type={"text"}
            handleSave={(value) =>
              modifyMapField({
                map_name: mapId,
                data: {
                  map_metadata_id: category.map_metadata_id ?? "",
                  value: {
                    name: value,
                    value: formikItem?.score,
                  },
                },
              })
                .unwrap()
                .then(() => {
                  toast(`Set ${category.item} as ${value}`, {
                    type: "success",
                  });
                })
                .catch((e) =>
                  toast(`An error occured: ${e?.message}`, { type: "error" })
                )
            }
            disabled={disabled}
            noHover={disabled}
            {...formikProps}
          />
        )}
      </TableCell>
      <TableCell>
        {type !== "adjustment" ? (
          <Typography>{formikItem?.score}</Typography>
        ) : (
          <VariableInput
            name={scoreField}
            label={undefined}
            value={formikItem?.score}
            type={"number"}
            handleSave={(value) =>
              modifyMapField({
                map_name: mapId,
                data: {
                  map_metadata_id: category.map_metadata_id ?? "",
                  value: {
                    name: formikProps.values?.[type]?.[
                      toCamelCase(category.item)
                    ]?.description,
                    value,
                  },
                },
              })
                .unwrap()
                .then(() => {
                  toast("Updated succesfully", { type: "success" });
                })
                .catch((e) =>
                  toast(`An error occured: ${e?.message}`, { type: "error" })
                )
            }
            disabled={disabled}
            noHover={disabled}
            {...formikProps}
          />
        )}
      </TableCell>
    </TableRow>
  );
};
export default Category;
