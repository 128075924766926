import { useTheme } from "@mui/material";
import EmailIcon from "assets/email.svg?react";
import WeChatIcon from "assets/socialmedia/wechat.svg?react";
import WhatsAppIcon from "assets/socialmedia/whatsapp-2.svg?react";
import useIsMobile from "hooks/useIsMobile";
import FloatingBox from "./FloatingBox";

const Floater = ({ shouldShowFloater }: { shouldShowFloater?: boolean }) => {
  const theme = useTheme();

  const mobile = 971507772486;
  const text = "";

  const baseButtonConfig = {
    buttonColor: "#2dbb00",
    backgroundColor: theme.palette.primary.main,
    size: "small" as "small" | "medium" | "large" | undefined,
  };
  const isMobile = useIsMobile();

  const buttons = [
    {
      icon: WhatsAppIcon,
      name: "WhatsApp",
      onClick: () =>
        window.open(
          `https://api.whatsapp.com/send?phone=${mobile}&text=${text}`,
          "_blank"
        ),
      ...baseButtonConfig,
    },
    {
      icon: WeChatIcon,
      name: "WeChat",
      onClick: () =>
        window.open(`https://u.wechat.com/kDyq4o-M74ywG1XjRwskHqQ`, "_blank"),
      ...baseButtonConfig,
    },
    {
      icon: EmailIcon,
      name: "Email",
      onClick: () => window.open(`mailto:sales@incomlend.com`, "_blank"),
      ...baseButtonConfig,
    },
  ];

  return shouldShowFloater ? (
    <FloatingBox
      data={buttons}
      style={
        isMobile
          ? {
              bottom: "15vh",
              right: 5,
              margin: 10,
            }
          : { bottom: "15vh", right: 15, margin: 14 }
      }
    />
  ) : null;
};

export default Floater;
