import { VITE_API_V2_URL } from "codes";
import { browserVersion, deviceDetect, isSafari } from "react-device-detect";
import { subscribeUser } from "serviceWorkerRegistration";

export const getDeviceID = () => {
  const navigator_info = window.navigator;
  const device = deviceDetect(navigator_info.userAgent);
  const uid = `${device.osName}-${device.osVersion}-${device.browserName}-${device.browserVersion}-${device.deviceType}`;
  return uid.toString();
};
const env = import.meta.env.VITE_ENV;
const debug = Boolean(env === "local" || env === "dev");

export const triggerSubscription = async (contact_id: string) => {
  try {
    // Check if PushManager is supported and obtain permission if required
    if ("PushManager" in window && !(isSafari && browserVersion === "15.6")) {
      const permissionStatus = await Notification.requestPermission();
      if (permissionStatus === "granted") {
        await subscribeUser(contact_id);
        return true;
      }
      return false;
    }
    return false;
  } catch (error) {
    if (debug)
      // eslint-disable-next-line no-console
      console.error("Error requesting notification permission:", error);
    return false;
  }
};

// Check if user is already subscribed to push notifications
export async function checkSubscription() {
  if ("serviceWorker" in navigator === false) return false;
  if (isSafari && browserVersion === "15.6") return false;
  if ("serviceWorker" in navigator) {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();
    const token = JSON.parse(localStorage.getItem("token") as string);

    if (subscription) {
      const response = await fetch(
        `${VITE_API_V2_URL}/notifications/check-subscription`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token?.value}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            endpoint: subscription.endpoint,
          }),
        }
      );
      const data = await response.json();
      return data.exists;
    }
    return false;
  }
}

export const unsubscribeUser = async () => {
  if ("serviceWorker" in navigator === false) return false;
  if (isSafari && browserVersion === "15.6") return false;
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      if (subscription) {
        await subscription.unsubscribe();
        return true;
      }
      return false;
    } catch (error) {
      if (debug)
        // eslint-disable-next-line no-console
        console.error("Error unsubscribing user:", error);
      return false;
    }
  }
};
