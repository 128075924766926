import { useEffect, useRef, useState } from "react";

import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import PerfectScrollbar from "react-perfect-scrollbar";

import BellIcon from "assets/bell.svg?react";

import {
  useGetNotificationsQuery,
  useUpdateMarkAsReadAllMutation,
} from "api/api-notifications";
import MainCard from "components/Common/Cards/MainCard";
import Transitions from "components/Common/extended/Transitions";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { avatarStyles } from "styles/theme";
import NotificationList from "./NotificationList";
import NotificationTypeTabs from "./NotificationTypeTabs";

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };
  const { data: notifications, isLoading: loading } =
    useGetNotificationsQuery();

  const [markAll] = useUpdateMarkAsReadAllMutation();

  const prevOpen = useRef<boolean>(open);

  const unreadCount: number =
    notifications?.filter((item) => !item.notif_is_read)?.length || 0;

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const { last90Days = [], older = [] } = Object.create({
    last90Days: notifications
      ?.filter((notif) =>
        moment(notif.send_at).isAfter(moment().subtract(90, "days"))
      )
      ?.sort((a, b) => moment(b.send_at).diff(a.send_at)),
    older: notifications
      ?.filter((notif) =>
        moment(notif.send_at).isBefore(moment().subtract(90, "days"))
      )
      ?.sort((a, b) => moment(b.send_at).diff(a.send_at)),
  });

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down("md")]: {
            mr: 2,
          },
        }}
      >
        <Badge badgeContent={unreadCount} color="error">
          <ButtonBase sx={{ borderRadius: "12px" }}>
            <Avatar
              variant="rounded"
              sx={{
                ...avatarStyles.commonAvatar,
                ...avatarStyles.mediumAvatar,
                transition: "all .2s ease-in-out",
                background: theme.palette.background.default,
                color: theme.palette.primary.main,
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background: theme.palette.primary.main,
                  color: theme.palette.background.default,
                },
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              color="inherit"
            >
              <BellIcon />
            </Avatar>
          </ButtonBase>
        </Badge>
      </Box>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
          strategy: "fixed",
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? "top" : "top-right"}
            in={open}
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                  content
                  title={
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ pt: 2, px: 2 }}
                    >
                      <Grid item>
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems={"center"}
                        >
                          <Typography fontWeight={600} variant={"h4"}>
                            Notification Centre
                          </Typography>
                          {notifications && (
                            <Avatar
                              sx={{
                                width: "1.5em",
                                height: "1.5em",
                                border: `1px solid ${theme.palette.secondary.main}`,
                                color: theme.palette.secondary.main,
                                bgcolor: "transparent",
                              }}
                              variant="rounded"
                            >
                              {notifications?.length}
                            </Avatar>
                          )}
                        </Stack>
                      </Grid>
                      {Boolean(notifications?.length) && (
                        <Grid item>
                          <Button
                            variant="text"
                            onClick={() => markAll().then(() => setOpen(false))}
                            sx={{ textTransform: "inherit" }}
                          >
                            Mark as all read
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  }
                  headerSx={{ p: 1 }}
                  contentSX={{
                    background: theme.palette.background.paper,
                  }}
                >
                  {loading && <SkeletonLoad bars={10} />}
                  {!loading && (
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      id="notification-container"
                    >
                      <Grid item xs={12}>
                        <PerfectScrollbar
                          style={{
                            maxHeight: "60vh",
                            minHeight: "10vh",
                            overflowY: "scroll",
                          }}
                        >
                          <Stack spacing={1}>
                            <NotificationTypeTabs notifications={last90Days} />
                            <Divider />
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: theme.palette.inactive.main,
                                fontStyle: "italic",
                              }}
                            >
                              Older Notifications
                            </Typography>
                            <NotificationList notifications={older} />
                          </Stack>
                        </PerfectScrollbar>
                      </Grid>
                      {notifications && !Boolean(notifications.length) && (
                        <NoData text={"No new notifications"} sx={{ mt: 2 }} />
                      )}
                    </Grid>
                  )}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
