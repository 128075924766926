import useResizeObserver from "@react-hook/resize-observer";
import { useLayoutEffect, useState } from "react";

const useWidth = (target: any, defaultWidth = 1000): number => {
  const [width, setWidth] = useState(defaultWidth);

  useLayoutEffect(() => {
    setWidth(target?.current?.getBoundingClientRect()?.width ?? defaultWidth);
  }, [target]);

  useResizeObserver(target, (entry) =>
    setWidth(entry.contentRect.width as any)
  );
  return width;
};
export default useWidth;
