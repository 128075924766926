import { Box, FormHelperText, Grid, Slide, Typography } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  useDeleteBankAccountMutation,
  useRegisterBankAccountMutation,
  useUpdateBankAccountMutation,
} from "api/api-banks";
import { COMPANIES, CURRENCIES, TR_LINK } from "codes";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";
import history from "helpers/history";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";

import AutoComplete from "components/Common/AutoComplete";
import MainCard from "components/Common/Cards/MainCard";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import { BankAccount, Country } from "types";
import styles from "../onboard.module.scss";
import { bankAccountvalidationSchema, initialValues } from "./formValues";

const ViewEditBankAccount = ({
  bankAccount,
  account_id,
  partner_id,
}: {
  bankAccount: BankAccount;
  account_id: string;
  partner_id?: string;
}) => {
  const countries = useAppSelector((state) => state.countries.countries);

  const [deleteBankAccount, { isLoading: isLoadingDeletebankAccount }] =
    useDeleteBankAccountMutation();

  const [updateBankAccount, { isLoading: isLoadingUpdateResult }] =
    useUpdateBankAccountMutation();
  const [registerBankAccount, { isLoading: isLoadingRegistration }] =
    useRegisterBankAccountMutation();

  return (
    <Slide in direction={"left"}>
      <Box>
        <Formik
          initialValues={{ ...initialValues, ...bankAccount }}
          validationSchema={bankAccountvalidationSchema}
          enableReinitialize
          onSubmit={() => {}}
        >
          {(formikProps) => {
            const setter = (value: string) => {
              const selected = countries?.find(
                (item: any) => item.name === value
              );
              formikProps.setFieldValue(
                "bank_address.country",
                selected?.name,
                true
              );
            };
            const BankAccountDisplay = (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MainCard
                    title={
                      <Typography
                        variant="h3"
                        style={{ fontWeight: "bold", fontSize: "1.6em" }}
                      >
                        Bank Account Details
                      </Typography>
                    }
                    content
                    children={
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} lg={6}>
                              <Input
                                label={"Bank Name "}
                                name={"bank_name"}
                                labelClassName={styles.labelClass}
                                style={{ width: "100%" }}
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Input
                                label={"Swift / BIC code "}
                                name={"swift_code"}
                                placeholder="Swift/BIC is between 8 to 11 characters"
                                labelClassName={styles.labelClass}
                                style={{ width: "100%" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} lg={6}>
                              <Input
                                label={"Account Number / IBAN "}
                                name={"bank_account_number"}
                                labelClassName={styles.labelClass}
                                placeholder="Generally 7-15 digit numbers"
                                style={{ width: "100%" }}
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Input
                                label={"Beneficiary Name "}
                                name={"beneficiary_name"}
                                labelClassName={styles.labelClass}
                                placeholder="Name of the account holder"
                                style={{ width: "100%" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} lg={6}>
                              <Drop
                                name={"currency"}
                                label={"Account Currency "}
                                keyValue={"name"}
                                data={Object.values(CURRENCIES)}
                                fullWidth
                                placeholder={initialValues?.currency}
                                {...formikProps}
                              />
                              {Boolean(formikProps.touched.currency) && (
                                <FormHelperText
                                  error={Boolean(formikProps.errors.currency)}
                                >
                                  {typeof formikProps.errors.currency ===
                                  "string"
                                    ? formikProps.errors.currency
                                    : ""}
                                </FormHelperText>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <MainCard
                    title={
                      <Typography
                        variant="h3"
                        style={{ fontWeight: "bold", fontSize: "1.6em" }}
                      >
                        Bank Address
                      </Typography>
                    }
                    content
                    children={
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Input
                            label={"Street 1 "}
                            name={"bank_address.address_line1"}
                            labelClassName={styles.labelClass}
                            placeholder="Street address line 1"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            label={"Street 2 "}
                            name={"bank_address.address_line2"}
                            labelClassName={styles.labelClass}
                            placeholder="Street address line 2"
                            style={{ width: "100%" }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} lg={6}>
                              <Input
                                label={"City "}
                                name={"bank_address.city"}
                                labelClassName={styles.labelClass}
                                placeholder="City of the bank"
                                style={{ width: "100%" }}
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Input
                                label={"Postal Code "}
                                name={"bank_address.postcode"}
                                labelClassName={styles.labelClass}
                                placeholder="Postal code of the bank"
                                style={{ width: "100%" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={1}
                            justifyContent={"space-between"}
                          >
                            <Grid item xs={12} lg={6}>
                              <LabelViewOnly label={`Country`} />
                              <AutoComplete
                                data={countries as Country[]}
                                name={"bank_address.country"}
                                labelKey={"name" as any}
                                value={formikProps.values.bank_address.country}
                                placeholder={`Select Country`}
                                onChange={(
                                  _: any,
                                  newValue:
                                    | NonNullable<string | Country>
                                    | (string | Country)[]
                                    | null
                                ) => {
                                  setter((newValue as Country)?.name);
                                }}
                                {...formikProps}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} justifyContent={"space-between"}>
                    <Grid item xs={3}>
                      <LoadingButton
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                          history.push(
                            partner_id
                              ? `${TR_LINK}/profile/${partner_id}`
                              : COMPANIES,
                            {
                              tab: partner_id ? 4 : 2,
                            }
                          );
                        }}
                      >
                        Back
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={3}>
                      <LoadingButton
                        variant="contained"
                        fullWidth
                        disabled={!formikProps.touched}
                        loading={isLoadingRegistration || isLoadingUpdateResult}
                        type="submit"
                        onClick={() => {
                          const bankAccountData = {
                            name: formikProps.values.bank_name,
                            bank_name: formikProps.values.bank_name,
                            bank_account_number:
                              formikProps.values.bank_account_number,
                            beneficiary_name:
                              formikProps.values.beneficiary_name,
                            currency: formikProps.values.currency,
                            bank_address: {
                              address_line1:
                                formikProps.values.bank_address.address_line1,
                              address_line2:
                                formikProps.values.bank_address.address_line2,
                              city: formikProps.values.bank_address.city,
                              country: formikProps.values.bank_address.country,
                              postcode:
                                formikProps.values.bank_address.postcode,
                            },
                            swift_code: formikProps.values.swift_code,
                          };
                          const saveFunction =
                            bankAccount && bankAccount?.id
                              ? updateBankAccount({
                                  bankAccountId: bankAccount?.id,
                                  data: bankAccountData,
                                  account_id,
                                })
                                  .unwrap()
                                  .catch((error) => {
                                    const errorFile = error?.message;
                                    toast(
                                      `Something went wrong : ${errorFile}`,
                                      {
                                        type: "error",
                                      }
                                    );
                                  })
                              : registerBankAccount({
                                  data: bankAccountData,
                                  account_id,
                                }).catch((error) => {
                                  const errorFile = error?.message;
                                  toast(`Something went wrong : ${errorFile}`, {
                                    type: "error",
                                  });
                                });
                          saveFunction.then((res: any) => {
                            if ("error" in res) {
                              toast(res.message, { type: "error" });
                            } else {
                              toast(`Your bank information has been updated!`, {
                                type: "success",
                              });
                              history.push(
                                partner_id
                                  ? `${TR_LINK}/profile/${partner_id}`
                                  : COMPANIES,
                                {
                                  tab: partner_id ? 4 : 2,
                                }
                              );
                            }
                          });
                        }}
                      >
                        Save
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
            return <Form>{BankAccountDisplay}</Form>;
          }}
        </Formik>
      </Box>
    </Slide>
  );
};
export default ViewEditBankAccount;
