import { TFunction } from "i18next";

export function RegistrationDetailsKeyMappings(
  t: TFunction<"translation", undefined>
): Record<string, string> {
  const default_fields: Record<string, any> = {
    name: `${t("company-full-legal-name")}`,
    reg_no: `${t("company-registration-number")}`,
    address_line1: `${t("address")} 1`,
    incorporation_date: `${t("company-incorporation-date")}`,
    city: `${t("city")}`,
    country: `${t("country")}`,
    postcode: `${t("postcode")}`,
    shipping_address: "Shipping Address",
  };

  return default_fields;
}
export function AdditionalDetailsKeyMappings(
  isOtherAccountingSoftware: boolean
): Record<string, string> {
  const default_fields: Record<string, string> = {
    accounting_software: "Company Accounting Software",
    annual_revenue: "Annual Revenue",
    number_of_employees: "No. of Employees",
  };
  if (isOtherAccountingSoftware)
    default_fields.other_accounting_software =
      "Please specify your accounting software";
  return default_fields;
}
export function TradeDetailsKeyMappings(): Record<string, string> {
  return {
    average_invoice_amount: "Average Invoice Amount (in USD equivalent)​",
    average_payment_terms: "Average Payment Terms",
  };
}
