import { Box, Grid } from "@mui/material";
import { useGetMonthlyInvoiceStatsQuery } from "api/api-operations";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function MonthlyInvoiceChart() {
  const { data: monthlyInvoiceStats, isFetching } =
    useGetMonthlyInvoiceStatsQuery(null);

  // Calculate the total amount in millions
  const totalAmountMillions =
    (monthlyInvoiceStats?.financed_per_buyer.reduce(
      (total, invoice) => total + Number(invoice?.total_financed_amount || 0),
      0
    ) ?? 0) / 1000000;

  const options: Highcharts.Options = {
    chart: {
      type: "pie",
    },
    title: {
      text: `Invoice Financed this month - ${totalAmountMillions.toFixed(
        2
      )}M (USD $)`,
    },
    series: [
      {
        type: "pie",
        name: "Amount (USD $)",
        data:
          monthlyInvoiceStats?.financed_per_buyer.map((e) => ({
            name: e?.buyer,
            y: Number(e?.total_financed_amount),
          })) ?? [],
      },
    ],
  };

  return (
    <Box sx={{ display: "flex" }} flexGrow={1}>
      {isFetching && <SkeletonLoad bars={10} />}
      {!isFetching && !monthlyInvoiceStats && (
        <NoData text="No montlhy invoice data to be displayed" />
      )}
      {!isFetching && monthlyInvoiceStats && (
        <Grid container>
          <Grid item xs={12}>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              allowChartUpdate
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default MonthlyInvoiceChart;
