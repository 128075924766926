import {
  Grid,
  GridTypeMap,
  Slider,
  SliderProps,
  SxProps,
  TextFieldProps,
} from "@mui/material";
import React from "react";

import { currencyFormatter } from "helpers/currencyFormatter";
import styles from "./slider.module.scss";

export interface ISlider extends SliderProps {
  name: string;
  inputFieldSx?: SxProps;
  valueTag?: string;
  spacing?: GridTypeMap["props"]["spacing"];
  /**
   * Used in conditions like form-within-form, where the field value setter is external.
   */
  setFieldValue?: (field: string, value: any, shouldValidate?: any) => void;
  changeHandler?: (x?: any) => void;

  textFieldSize?: GridTypeMap["props"]["xs"];
  disabled?: boolean;
  currency?: boolean;
  onChange?: (x?: any) => void;
  textFieldProps?: TextFieldProps;
  defaultValue?: number;
}

const CLSlider = (props: ISlider) => {
  const {
    size = "medium",
    defaultValue = 20,
    name,
    step,
    spacing = 4,
    min = 10,
    max = 110,
    valueTag = "",
    inputFieldSx,
    setFieldValue,
    changeHandler,
    textFieldSize = 12,
    textFieldProps,
    disabled,
    onChange,
    ...sliderProps
  } = props;
  const [value, setValue] = React.useState<string>(
    currencyFormatter({ amount: defaultValue })
  );

  const valueCleaner = (val: string) =>
    Number(val?.split(".")[0]?.replaceAll(",", ""));

  const valuetext = (value: number) => {
    return `${value} ${valueTag}`;
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(currencyFormatter({ amount: newValue as number }));
    setFieldValue ? setFieldValue(name, newValue as number) : null;
    changeHandler ? changeHandler(newValue as number) : null;
  };

  const marks = [
    {
      value: min,
      label: (
        <Grid item className={styles.left}>{`${currencyFormatter({
          amount: min,
          currency: valueTag,
          decimalPlace: 0,
        })}`}</Grid>
      ),
    },
    {
      value: max,
      label: (
        <Grid item className={styles.right}>{`${currencyFormatter({
          amount: max,
          currency: valueTag,
          decimalPlace: 0,
        })}`}</Grid>
      ),
    },
  ];
  return (
    <Slider
      size={size}
      name={name}
      value={valueCleaner(value)}
      defaultValue={Number(defaultValue)}
      aria-label={name}
      valueLabelDisplay="auto"
      getAriaValueText={valuetext}
      step={step}
      min={min}
      max={max}
      onChange={handleChange}
      marks={marks}
      disabled={disabled}
      {...sliderProps}
    />
  );
};

export default CLSlider;
