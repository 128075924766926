import { SvgIconComponent } from "@mui/icons-material";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LegacyRef, forwardRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Item } from "..";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: { item: Item; level: number }) => {
  const theme = useTheme();
  const location = useLocation();
  const Icon = item.icon as SvgIconComponent;
  const isActive = (item: Item): boolean => {
    if (item?.match) return item.match(location.pathname);
    return location.pathname.includes(item.link);
  };

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link
        {...props}
        to={item.link}
        ref={ref as LegacyRef<HTMLAnchorElement> | undefined}
      />
    )),
  };

  return (
    <ListItemButton
      {...listItemProps}
      sx={{
        borderRadius: `${theme.shape.borderRadius}px`,
        mb: 0.5,
        alignItems: "center",
        py: level > 1 ? 1 : 1,
        pl: `${level * 20}px`,
        "&.Mui-selected": {
          bgcolor:
            level > 1
              ? "transparent !important"
              : `rgba(${theme.palette.primary.mainChannel} / ${theme.palette.action.selectedOpacity})`,
        },
      }}
      data-testid={`listitem-menu-${item.title}`}
      selected={isActive(item)}
    >
      <ListItemIcon
        sx={{
          my: "auto",
          minWidth: !item?.icon ? 18 : 36,
          justifyContent: "center",
          color: theme.palette.inactive.main,
          ...(isActive(item) && {
            color: level > 1 ? "error.main" : item.iconActiveColor,
          }),
        }}
      >
        <Icon />
      </ListItemIcon>
      <ListItemText
        primary={item.title}
        sx={{
          color: isActive(item) && level > 1 ? "error.main" : `primary.main`,
          ".MuiTypography-root": {
            fontWeight: isActive(item) ? "bold" : 400,
          },
        }}
      />
    </ListItemButton>
  );
};
export default NavItem;
