import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V1_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";

export const vendors = createApi({
  reducerPath: "vendors",
  tagTypes: ["IDENFY"],
  baseQuery: axiosBaseQuery({
    baseUrl: VITE_API_V1_URL,
  }),
  endpoints: (builder) => ({
    idenfySearch: builder.mutation<
      { companies: any; isVendorAvailable: boolean },
      { iso3: string; name: string; reg_no: string }
    >({
      query(payload) {
        const { iso3, name, reg_no } = payload;
        const data = {
          iso3,
          company_name: name?.trim(),
          company_reg_no: reg_no?.trim()?.replace(/\s/g, ""),
        };
        return {
          url: `/search-company`,
          method: "POST",
          timeout: 10000,
          data,
        };
      },
      transformResponse: (response) => {
        return {
          companies: response?.data?.companies ?? [],
          isVendorAvailable: response.status === 200,
        };
      },

      invalidatesTags: ["IDENFY"],
    }),
    vendorAddress: builder.mutation<
      void,
      {
        iso3: string;
        registrationNumber: string;
        city: string;
        postcode: string;
        address_line1: string;
        address_line2: string;
      }
    >({
      query: (data) => {
        return {
          url: `/vendor-address`,
          method: "POST",
          data,
        };
      },
    }),
    fetchOcrData: builder.mutation<
      any,
      {
        file: any;
        doctype: string;
      }
    >({
      query: ({ file, doctype }) => {
        const data = new FormData();
        data.append("file", file);
        data.append("doctype", doctype);
        return {
          url: `/ocr-v2`,
          data,
          method: "POST",
        };
      },
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useIdenfySearchMutation,
  useVendorAddressMutation,
  useFetchOcrDataMutation,
} = vendors;
