import { FormControlLabel, Switch } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";

interface ToggleSwitchProps<T> {
  i: number;
  label: string;
  name: string;
  state: { [x: string]: any };
  getColumnVisibility: (columns: T) => void;
  setAllHideableColumns: Dispatch<SetStateAction<string[]>>;
  setColumnStates: React.Dispatch<
    React.SetStateAction<{
      [key: string]: any;
    }>
  >;
}
const ToggleSwitch = <T extends Record<string, any> = {}>({
  i,
  label,
  name,
  state,
  getColumnVisibility,
  setAllHideableColumns,
  setColumnStates,
}: ToggleSwitchProps<T>) => {
  const [checked, setChecked] = useState(state[name]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newHideables: { [x: string]: any } = {
      ...state,
      [event.target.name]: event.target.checked,
    };
    setColumnStates(newHideables);
    const hideables = Object.entries(newHideables)
      .map((item) => {
        if (!item[1]) return item[0];
        return "";
      })
      .filter((x) => x);
    getColumnVisibility(newHideables as T);
    setAllHideableColumns(hideables);
    setChecked(event.target.checked);
  };

  return (
    <FormControlLabel
      key={i}
      control={<Switch checked={checked} onChange={handleChange} name={name} />}
      label={label}
    />
  );
};
export default ToggleSwitch;
