import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEditMapSummaryMutation } from "api/api-maps";
import Drop from "components/Common/Drop";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import VariableInput from "components/Common/VariableInput";
import { useFormikContext } from "formik";
import ordinal_suffix_of from "helpers/ordinalSuffix";
import { toast } from "react-toastify";
import { MapSummary } from "types";
import styles from "../maps.module.scss";
import { ADVANCE_RATIOS } from "../Scoring/constants";
import SeasonalBusiness from "./SeasonalBusiness";

const CCDecision = () => {
  const formikProps = useFormikContext<MapSummary>();
  const theme = useTheme();
  const currentMonth = new Date().getMonth();
  const { map_name } = formikProps.values;
  const [editMapSummary] = useEditMapSummaryMutation();
  const currentMonthAbbr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ][currentMonth];

  const editor = (field: string, value: any, friendlyLabel: string) => {
    editMapSummary({
      map_name,
      data: { [field]: value },
    })
      .unwrap()
      .then(() => {
        toast.success(`${friendlyLabel} Updated`);
      })
      .catch((err) => toast.error(`Error: ${err?.message}`));
  };
  const conditionTexts = [
    "Updated Buyer Financials",
    "Updated Supplier Financials",
    "Updated Track record",
    "Updated Repayment Slips",
  ];
  const riskRestrictionsTexts = [
    "No Withdrawal on account of overdue",
    "Set-off overdue invoices against other repayments",
  ];

  const handleChangeRR = (e: React.ChangeEvent<HTMLInputElement>) => {
    let riskRestrictionsSelection = formikProps.values.risk_restrictions;
    if ((e.target as any).checked) {
      riskRestrictionsSelection = [
        ...riskRestrictionsSelection,
        e.target.value,
      ];
    } else {
      riskRestrictionsSelection = formikProps.values.risk_restrictions.filter(
        (condition) => condition !== e.target.value
      );
    }
    formikProps.setFieldValue(
      "risk_restrictions",
      riskRestrictionsSelection,
      true
    );
    editor("risk_restrictions", riskRestrictionsSelection, "Risk Restrictions");
  };

  return (
    <Grid
      container
      spacing={1}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Grid item lg={3} xs={12}>
        <LabelViewOnly label="Approved" labelClassName={styles.labelClass} />
      </Grid>
      <Grid item lg={3} xs={12}>
        <FormGroup>
          <FormControl>
            <RadioGroup
              aria-labelledby="map_approved"
              name="map_approved"
              value={formikProps.values.map_approved}
              onChange={(e) => {
                formikProps.setFieldValue(
                  "map_approved",
                  e.target.value === "true",
                  true
                );
                editor(
                  "map_approved",
                  e.target.value === "true",
                  "Map Approved"
                );
              }}
              row
            >
              <Grid container spacing={1}>
                <Grid item>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    sx={{ alignItems: "center" }}
                    label={"Yes"}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    sx={{ alignItems: "center" }}
                    label={"No"}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </FormGroup>
      </Grid>
      <Grid item lg={3} xs={12}>
        <LabelViewOnly
          label="Approved Payment Terms"
          labelClassName={styles.labelClass}
        />
      </Grid>
      <Grid item lg={3} xs={12}>
        <FormGroup>
          <FormControl>
            <RadioGroup
              aria-labelledby="payment_terms_approved"
              name="payment_terms_approved"
              value={formikProps.values.payment_terms_approved}
              onChange={(e) => {
                formikProps.setFieldValue(
                  "payment_terms_approved",
                  e.target.value === "true",
                  true
                );
                editor(
                  "payment_terms_approved",
                  e.target.value === "true",
                  "Approved Payment Terms"
                );
              }}
              row
            >
              <Grid container spacing={1}>
                <Grid item>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    sx={{ alignItems: "center" }}
                    label={"Yes"}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    sx={{ alignItems: "center" }}
                    label={"No"}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </FormGroup>
      </Grid>
      <Grid item xs={12} lg={6}>
        <LabelViewOnly label={"No. of approved payment days"} required />
        <VariableInput
          name={"approved_payment_days"}
          label={""}
          type="number"
          style={{ width: "100%" }}
          value={formikProps.values.approved_payment_days}
          handleSave={(value) => {
            formikProps.setFieldValue("approved_payment_days", value);
            editor("approved_payment_days", value, "Approved Payment Days");
          }}
          {...formikProps}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <LabelViewOnly
          label="Incoterms Approved"
          labelClassName={styles.labelClassDropdown}
        />
        <Drop
          name={"incotermsApproved"}
          keyValue={"name"}
          data={[]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={4}>
        <LabelViewOnly
          label="Expected Payment Days"
          labelClassName={styles.labelClass}
        />
      </Grid>
      <Grid item xs={8}>
        <FormGroup>
          <FormControl>
            <RadioGroup
              aria-labelledby="expected_payment_days"
              name="expected_payment_days"
              value={formikProps.values.expected_payment_days}
              onChange={(e) => {
                formikProps.setFieldValue(
                  "expected_payment_days",
                  e.target.value,
                  true
                );
                editor(
                  "expected_payment_days",
                  e.target.value,
                  "Expected Payment Days"
                );
              }}
              row
            >
              <Grid container spacing={1}>
                <Grid item>
                  <FormControlLabel
                    value={"As Per Due Date"}
                    control={<Radio />}
                    sx={{ alignItems: "center" }}
                    label={"As Per Due Date"}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={"Weekly"}
                    control={<Radio />}
                    sx={{ alignItems: "center" }}
                    label={"Weekly"}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={"Fortnightly"}
                    control={<Radio />}
                    sx={{ alignItems: "center" }}
                    label={"Fortnightly"}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={"Monthly"}
                    control={<Radio />}
                    sx={{ alignItems: "center" }}
                    label={"Monthly"}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </FormGroup>
      </Grid>
      {formikProps.values.expected_payment_days === "Weekly" && (
        <>
          <Grid item lg={4} xs={12}>
            <LabelViewOnly
              label={"Select Day"}
              labelClassName={styles.labelClass}
            />
          </Grid>
          <Grid item lg={8} xs={12}>
            <Drop
              name={"day"}
              keyValue={"name"}
              data={[
                { name: "Sunday", value: "Sunday" },
                { name: "Monday", value: "Monday" },
                { name: "Tuesday", value: "Tuesday" },
                { name: "Wednesday", value: "Wednesday" },
                { name: "Thursday", value: "Thursday" },
                { name: "Friday", value: "Friday" },
                { name: "Saturday", value: "Saturday" },
              ]}
              fullWidth
              value={formikProps.values.day}
              setValue={(value) => {
                formikProps.setFieldValue("day", value);
                editor("day", value, "Day");
              }}
            />
          </Grid>
        </>
      )}
      {formikProps.values.expected_payment_days === "Monthly" && (
        <Grid
          item
          lg={12}
          xs={12}
          sx={{
            backgroundColor: "#a2a6c338",
            borderRadius: 1,
            border: `1px dashed ${theme.palette.primary.main}`,
            paddingBottom: "8px",
          }}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <LabelViewOnly
              label={"Day of the Month"}
              sx={{ minWidth: "10vw", height: "100%" }}
              required
            />
            <VariableInput
              name={"day"}
              label={""}
              type="number"
              value={formikProps.values.day}
              handleSave={(value) => {
                if (Number(value) < 1 || Number(value) > 31) {
                  toast.error("Please enter a valid day of the month");
                  return;
                } else {
                  formikProps.setFieldValue("day", `${value}`);
                  editor(
                    "day",
                    `${value}`,
                    `${ordinal_suffix_of(Number(value))} day of the month`
                  );
                }
              }}
            />
          </Stack>
        </Grid>
      )}
      <Grid item lg={4} xs={12}>
        <LabelViewOnly
          label={
            <Grid container spacing={1}>
              <Grid item>
                <Typography fontWeight={"bold"}>
                  Advance Ratio Approved :
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: theme.palette.error.main }}
                  fontWeight={"bold"}
                >{`${formikProps.values.advance_ratio_approved ?? 60}%`}</Typography>
              </Grid>
            </Grid>
          }
          labelClassName={styles.labelClass}
        />
      </Grid>

      <Grid item lg={8} xs={12} margin="auto">
        <Drop
          label=""
          name={"advance_ratio_approved"}
          keyValue={"name"}
          data={ADVANCE_RATIOS}
          fullWidth
          setValue={(value) => {
            formikProps.setFieldValue("advance_ratio_approved", value);
            editor("advance_ratio_approved", value, "Advance Ratio Approved");
          }}
          {...formikProps}
        />
      </Grid>

      <Grid item lg={4} xs={12}>
        <LabelViewOnly
          label="Seasonal Business"
          labelClassName={styles.labelClass}
        />
      </Grid>
      <SeasonalBusiness />

      <Grid item lg={6} xs={12}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <LabelViewOnly
            label="Enhanced Factoring approach"
            labelClassName={styles.labelClass}
          />

          <FormGroup>
            <FormControl>
              <RadioGroup
                aria-labelledby="enhanced_factoring"
                name="enhanced_factoring"
                value={formikProps.values.enhanced_factoring}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    "enhanced_factoring",
                    e.target.value === "true",
                    true
                  );
                  editor(
                    "enhanced_factoring",
                    e.target.value === "true",
                    "Enhanced Factoring Approach"
                  );
                }}
                row
              >
                <Grid container spacing={1}>
                  <Grid item>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      sx={{ alignItems: "center" }}
                      label={"Yes"}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      sx={{ alignItems: "center" }}
                      label={"No"}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </FormGroup>
        </Stack>
      </Grid>

      <Grid item lg={6} xs={12}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <LabelViewOnly
            label="Big Buyer Approach"
            labelClassName={styles.labelClass}
          />

          <FormGroup>
            <FormControl>
              <RadioGroup
                aria-labelledby="big_buyer"
                name="big_buyer"
                value={formikProps.values.big_buyer}
                onChange={(e) => {
                  formikProps.setFieldValue(
                    "big_buyer",
                    e.target.value === "true",
                    true
                  );
                  editor(
                    "big_buyer",
                    e.target.value === "true",
                    "Big Buyer Approach"
                  );
                }}
                row
              >
                <Grid container spacing={1}>
                  <Grid item>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      sx={{ alignItems: "center" }}
                      label={"Yes"}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      sx={{ alignItems: "center" }}
                      label={"No"}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </FormGroup>
        </Stack>
      </Grid>

      <Grid item xs={12} my={2}>
        <Divider />
      </Grid>
      <Grid item xs={12} lg={12} xl={12}>
        <LabelViewOnly
          label="Credit Committee Conditions"
          labelClassName={styles.labelClass}
        />
        {conditionTexts.map((condition) => {
          const checked = Boolean(
            formikProps.values.credit_committee_conditions?.find(
              (cc) => cc === condition
            )
          );

          return (
            <FormControlLabel
              key={condition}
              control={<Checkbox checked={checked} />}
              label={condition}
              onChange={(e) => {
                const value = (e.target as any).checked;
                let ccConditionsSelection =
                  formikProps.values.credit_committee_conditions ?? [];
                if (value) {
                  ccConditionsSelection = [...ccConditionsSelection, condition];
                } else {
                  ccConditionsSelection =
                    formikProps.values.credit_committee_conditions?.filter(
                      (existing_condition) => existing_condition !== condition
                    );
                }

                formikProps.setFieldValue(
                  "credit_committee_conditions",
                  ccConditionsSelection,
                  true
                );
                editor(
                  "credit_committee_conditions",
                  ccConditionsSelection,
                  "Credit Committee Conditions"
                );
              }}
            />
          );
        })}
      </Grid>
      <Grid item xs={12} lg={12} xl={12}>
        <LabelViewOnly
          label="Risk Restrictions"
          labelClassName={styles.labelClass}
        />
        {riskRestrictionsTexts.map((condition) => {
          const checked = Boolean(
            formikProps.values.risk_restrictions?.find((cc) => cc === condition)
          );

          return (
            <FormControlLabel
              key={condition}
              control={<Checkbox checked={checked} />}
              label={condition}
              onChange={(e) => {
                const value = (e.target as any).checked;
                let riskRestrictionsSelection =
                  formikProps.values.risk_restrictions ?? [];
                if (value) {
                  riskRestrictionsSelection = [
                    ...riskRestrictionsSelection,
                    condition,
                  ];
                } else {
                  riskRestrictionsSelection =
                    formikProps.values.risk_restrictions?.filter(
                      (existing_condition) => existing_condition !== condition
                    );
                }

                formikProps.setFieldValue(
                  "risk_restrictions",
                  riskRestrictionsSelection,
                  true
                );
                editor(
                  "risk_restrictions",
                  riskRestrictionsSelection,
                  "Risk Restrictions"
                );
              }}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};
export default CCDecision;
