export default function ordinal_suffix_of(number: number) {
  const moduli10 = number % 10;
  const moduli100 = number % 100;
  if (moduli10 === 1 && moduli100 !== 11) {
    return `${number}st`;
  }
  if (moduli10 === 2 && moduli100 !== 12) {
    return `${number}nd`;
  }
  if (moduli10 === 3 && moduli100 !== 13) {
    return `${number}rd`;
  }
  return `${number}th`;
}
