import { FormikProps } from "formik";
import { AddPartnerType } from "helpers/formFields/tradingRelationship";

export const nameHasSimilarRegNo = (
  data: any[],
  formikProps: FormikProps<AddPartnerType>
) =>
  data?.find(
    (item) =>
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      item?.reg_no
        ?.toLowerCase()
        .includes(
          formikProps.values.registration_number.toLowerCase().trim()
        ) ||
      item?.registration_number
        ?.toLowerCase()
        .includes(formikProps.values.registration_number.toLowerCase().trim())
  );

export const hasSimilarName = (
  data: any[],
  formikProps: FormikProps<AddPartnerType>
) =>
  data?.find(
    (item) =>
      item?.name
        ?.toLowerCase()
        .includes(formikProps.values.name.toLowerCase().trim())
    // eslint-disable-next-line function-paren-newline
  );

export const hasSimilarRegNo = (
  data: any[],
  formikProps: FormikProps<AddPartnerType>
) =>
  data?.find(
    (item) =>
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      item?.reg_no
        ?.toLowerCase()
        .includes(
          formikProps.values.registration_number.toLowerCase().trim()
        ) ||
      item?.registration_number
        ?.toLowerCase()
        .includes(formikProps.values.registration_number.toLowerCase().trim())
    // eslint-disable-next-line function-paren-newline
  );
export const hasSameRegNo = (
  data: any[],
  formikProps: FormikProps<AddPartnerType>
) =>
  data?.find(
    (item) =>
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      item?.reg_no
        ?.toLowerCase()
        .includes(
          formikProps.values.registration_number.toLowerCase().trim()
        ) ||
      item?.registration_number
        ?.toLowerCase()
        .includes(formikProps.values.registration_number.toLowerCase().trim())
  );
