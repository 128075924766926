import { useEffect, useState } from "react";
import type { PartnerData } from "types";

const usePartnerStatus = (partner: PartnerData) => {
  const [
    isPartnerAdditionalInformationFilledUp,
    setisPartnerAdditionalInformationFilledUp,
  ] = useState(false);
  const [
    isPartnerTradeDetailsInformationFilledUp,
    setisPartnerTradeDetailsInformationFilledUp,
  ] = useState(false);

  useEffect(() => {
    setisPartnerAdditionalInformationFilledUp(
      partner?.industry !== "" &&
        partner?.goods_category !== "" &&
        partner?.annual_revenue !== null &&
        partner?.annual_revenue !== undefined
    );
    setisPartnerTradeDetailsInformationFilledUp(
      partner?.average_invoice_amount !== null &&
        partner?.average_invoice_amount !== undefined &&
        partner?.average_payment_terms !== "" &&
        partner?.number_of_past_paid_transaction !== null &&
        partner?.number_of_past_paid_transaction !== undefined
    );
  }, [partner]);
  return {
    isPartnerAdditionalInformationFilledUp,
    isPartnerTradeDetailsInformationFilledUp,
  };
};

export default usePartnerStatus;
