import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V1_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { Company } from "types";

export const companiesrtk = createApi({
  reducerPath: "companiesrtk",
  tagTypes: ["Company", "Buyers", "USER_DOCS"],
  baseQuery: axiosBaseQuery({
    baseUrl: VITE_API_V1_URL,
  }),
  endpoints: (builder) => ({
    getBuyerCompanies: builder.query<Company[], string>({
      query: (id: string) => ({
        url: `/buyers?company_id=${id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["Buyers"],
    }),

    getIndustries: builder.query<string[], void>({
      query: () => ({
        url: `/industries`,
        method: "get",
      }),
      transformResponse: (response) =>
        response?.data?.map((industry: string) => {
          return { name: industry };
        }),
    }),
    getGoods: builder.query<string[], string>({
      query: (industry: string) => ({
        url: `/goods/${industry}`,
        method: "get",
      }),
      transformResponse: (response) =>
        response?.data?.map((good: string) => {
          return { name: good };
        }),
    }),
  }),
});

export const {
  useGetBuyerCompaniesQuery, //marketplace
  useGetIndustriesQuery,
  useGetGoodsQuery,
} = companiesrtk;
