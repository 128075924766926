import {
  ADD_COMPANY,
  DASHBOARD,
  INCOMLEND_INTERNAL,
  INCOMLEND_INTERNAL_LINK,
  NEW_USER,
  ONBOARDING,
  REGISTERED,
} from "codes";
import history from "./history";

// eslint-disable-next-line prefer-regex-literals
export const isUUID = new RegExp(
  /[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/
);

const ADMIN_APP_STATE_LINK_MAP: { [x: string]: string } = {
  [ONBOARDING]: DASHBOARD,
  [REGISTERED]: DASHBOARD,
  [NEW_USER]: ADD_COMPANY,
};

export const redirect = async (language = "en") =>
  history.push(`${DASHBOARD}?lang=${language}`);
export const roleRedirect = (ROLE: string) =>
  ROLE === INCOMLEND_INTERNAL
    ? history.replace(INCOMLEND_INTERNAL_LINK)
    : history.replace(ROLE === "" ? ADD_COMPANY : DASHBOARD);

export const userRedirect = (APP_STATE: string) => {
  history.replace(ADMIN_APP_STATE_LINK_MAP[APP_STATE]);
};

export default { redirect, isUUID, roleRedirect, userRedirect };
