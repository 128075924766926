import Modal from "components/Common/Modal";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTheme } from "@mui/material";
import packageInfo from "../../../package.json";

const NewVersionPrompt = () => {
  const theme = useTheme();
  const [updateModal, setUpdateModal] = useState(false);
  const { version } = packageInfo;
  const LOCAL_APP_VERSION = localStorage.getItem("appVer");
  const { t } = useTranslation();
  useEffect(() => {
    if (!LOCAL_APP_VERSION) localStorage.setItem("appVer", version);
    else if (LOCAL_APP_VERSION && LOCAL_APP_VERSION !== version) {
      setUpdateModal(true);
    }
  }, [LOCAL_APP_VERSION]);
  const isMobile = useIsMobile();

  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch 3ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,

    fontWeight: "inherit !important",
  };

  return (
    <Modal
      message={`${t("new-version-available")}`}
      modalContentSx={{ height: "10vh !important" }}
      modalOverlaySx={modalOverlaySx}
      open={updateModal}
      onClose={() => setUpdateModal(false)}
      closeButton
      width={isMobile ? "98vw" : "500px"}
      primary={{
        variant: "contained",
        onClick: () => {
          localStorage.setItem("appVer", version);
          location.reload();
        },
        children: `${t("refresh")}`,
      }}
    />
  );
};
export default NewVersionPrompt;
