import { Grid, useTheme } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";

import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import InputField, {
  InputFieldProps,
} from "components/Common/FormFields/InputField";
import useIsMobile from "hooks/useIsMobile";
import styles from "./mfacodeform.module.scss";

export interface MfaCodeFormProps {
  /**
   * Submit action for MFACode
   */
  onSubmit?: (
    values: { mfaCode: string },
    formikHelpers: FormikHelpers<{
      mfaCode: string;
    }>
  ) => any;
  /**Extra props for primary button */
  primaryButtonOptions?: LoadingButtonProps;
  /**Custom class for login form */
  className?: string;
  inputFieldProps?: Partial<InputFieldProps>;
  labelClassName?: string;
}

const MfaCodeForm = (props: MfaCodeFormProps) => {
  const { onSubmit, inputFieldProps, primaryButtonOptions, labelClassName } =
    props;
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const inputFieldSx = {
    ".MuiTextField-root": {
      borderRadius: theme.shape.borderRadius,
      height: "3.5em",
      mt: 0.1,
      ".MuiInputBase-root": {
        "&::before": {
          borderBottom: "1px solid transparent",
        },
        ".MuiNativeSelect-select": {
          pl: 2,
        },
        height: "100%",
        ">input": {
          height: "80%",
        },
      },
      ".MuiButtonBase-root": {
        mr: 1,
      },
    },
  };
  if (onSubmit !== undefined)
    return (
      <Formik initialValues={{ mfaCode: "" }} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={isMobile ? 0 : 2}>
              <Grid item xs={12}>
                <InputField
                  label=""
                  type="text"
                  name="mfaCode"
                  autoComplete="off"
                  placeholder={`${t("enter-otp-code")}`}
                  disabled={isSubmitting}
                  data-testid="verification-code-input"
                  style={{ width: "100%" }}
                  sx={inputFieldSx}
                  labelClassName={labelClassName}
                  fullWidth
                  {...inputFieldProps}
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  data-testid="verify-button"
                  className={styles.signButton}
                  type="submit"
                  size="medium"
                  variant="contained"
                  style={{ width: "100%" }}
                  loading={isSubmitting}
                  {...primaryButtonOptions}
                >
                  {`${t("verify")}`}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  return <></>;
};
export default MfaCodeForm;
