import { Box, Chip, Grid, Slide, useTheme } from "@mui/material";
import {
  useGetAccountByNameQuery,
  useGetPartnersQuery,
} from "api/api-accounts";
import {
  ACCOUNTS,
  BUYER,
  INCOMLEND_INTERNAL_LINK,
  SELLER,
  SUPPLIER,
} from "codes";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import AccountSectionTabs from "components/Company/Account/AccountSectionTabs";
import { Form, Formik } from "formik";
import { useParams } from "react-router-dom";

const Account = () => {
  const { id } = useParams();

  const theme = useTheme();
  const {
    data: account,
    isFetching: isFetchingLead,
    isLoading: isLoadingLead,
    refetch: refetchLead,
  } = useGetAccountByNameQuery(id ?? "", { skip: !id });

  const profile = account?.profile ?? "";

  const partnerType = profile === SELLER ? BUYER?.toLowerCase() : SELLER;
  const partnerName = partnerType === SELLER ? SUPPLIER : BUYER;

  const getLabel = (profile: string) =>
    profile !== ""
      ? profile?.toLowerCase() === SELLER
        ? SUPPLIER
        : BUYER
      : "No Profile Set";

  const {
    data: partners,
    isFetching,
    isUninitialized: isPartnersUninitialized,
    isLoading,
    refetch: refetchPartners,
  } = useGetPartnersQuery(account?.id ?? "", {
    skip: !Boolean(account?.id),
  });
  const syncAction = () => {
    refetchLead();
    refetchPartners();
  };
  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={account?.name ?? ""}
          backArrow
          backLink={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}`}
          subtitle=""
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            sx: {
              fontSize: "1.2em",
              [theme.breakpoints.down("md")]: {
                fontSize: "1.5em",
              },
            },
          }}
          headerConfig={{
            left: 5,
            mid: 0,
            right: 5,
            syncAction,
            endComponent: (
              <Grid item>
                <Chip
                  label={getLabel(profile)}
                  sx={{ height: "3em" }}
                  variant="outlined"
                />
              </Grid>
            ),
            xs: {
              left: 12,
              mid: 0,
              right: 12,
            },
            alignItems: "center",
          }}
          mode="default"
        >
          <Grid container spacing={{ xs: 3, lg: 1 }}>
            {isFetchingLead && <SkeletonLoad bars={5} />}
            {!isFetchingLead && account && (
              <Formik
                enableReinitialize
                initialValues={{
                  any_credit_note: false,
                  any_delays_of_payment_with_buyer: false,
                  ...account,
                  partners,
                  syncAction,
                }}
                onSubmit={console.log}
              >
                <Form>
                  <AccountSectionTabs />
                </Form>
              </Formik>
            )}
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};
export default Account;
