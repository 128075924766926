const SupplierIcon = ({
  active,
  activeColor,
  size = "52.34",
}: {
  active: boolean;
  activeColor: string;
  size: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 52.34 52.331"
    id="supplier-card"
  >
    <g
      id="Group_2409"
      data-name="Group 2409"
      transform="translate(2576.411 2797.314)"
    >
      <path
        id="Path_3054"
        data-name="Path 3054"
        d="M-2541.491-2661.541h16.2c.972,0,1.236.26,1.237,1.216s0,1.925,0,2.888c0,.822-.3,1.123-1.108,1.126-.776,0-1.552,0-2.379,0,0,.812,0,1.569,0,2.328,0,.863-.287,1.158-1.13,1.159q-2.37,0-4.741,0c-.807,0-1.1-.3-1.1-1.129,0-.761,0-1.521,0-2.319h-12.208c0,.782,0,1.557,0,2.332,0,.819-.3,1.116-1.117,1.118q-2.37.005-4.741,0c-.819,0-1.112-.3-1.116-1.117,0-.762,0-1.524,0-2.328h-12.208c0,.748,0,1.5,0,2.26,0,.9-.28,1.184-1.159,1.186q-2.316,0-4.632,0c-.915,0-1.182-.276-1.184-1.214,0-.74,0-1.48,0-2.273-.827,0-1.6,0-2.379,0-.811,0-1.105-.3-1.108-1.125,0-.963,0-1.925,0-2.888s.258-1.211,1.234-1.217h.51v-.654q0-12.97,0-25.939c0-1.077.231-1.309,1.306-1.309h30.517c1.077,0,1.311.232,1.311,1.306v26.6Zm-17.466-26.119h-3.461c0,1.381,0,2.723,0,4.065,0,.822-.3,1.124-1.108,1.126-1.526,0-3.051,0-4.577,0-1.046,0-1.29-.241-1.29-1.271,0-1.3,0-2.6,0-3.927h-3.448v12.148h13.885Zm5.259,0h-3.462v12.136h13.887v-12.13h-3.45c0,1.406,0,2.768,0,4.13,0,.742-.318,1.052-1.069,1.055q-2.4.007-4.795,0c-.813,0-1.108-.3-1.11-1.123C-2553.7-2684.934-2553.7-2686.276-2553.7-2687.66Zm-5.271,26.093v-12.155h-3.449c0,1.365,0,2.7,0,4.043,0,.882-.286,1.161-1.182,1.162q-2.016,0-4.032,0c-1.7,0-1.761-.059-1.761-1.781v-3.411h-3.461v12.141Zm15.688.013v-12.154h-3.442c0,1.382,0,2.725,0,4.067,0,.821-.3,1.122-1.11,1.124-1.526,0-3.052,0-4.577,0-1.043,0-1.287-.241-1.287-1.274,0-1.3,0-2.6,0-3.923h-3.448v12.16Zm17.458,1.793h-48.76v1.681h48.76Zm-41.782-27.927v3.44h3.4v-3.44Zm15.7,0v3.44h3.4v-3.44Zm-12.292,17.4v-3.42h-3.42v3.42Zm15.7,0v-3.424h-3.415v3.424Zm-19.177,14.008h-3.427v1.674h3.427Zm19.182,1.693v-1.692h-3.425v1.692Zm15.754-1.7v1.681h3.414v-1.681Z"
        transform="translate(-0.035 -92.164)"
        fill={active ? activeColor : "#001E61"}
      />
      <path
        id="Path_3055"
        data-name="Path 3055"
        d="M-2321.433-2786.837c-.9,0-1.693.007-2.491,0a.89.89,0,0,1-1.014-1c-.01-.781-.041-1.565.017-2.342a1.43,1.43,0,0,1,.406-.888c2.223-1.983,4.471-3.938,6.715-5.9a.889.889,0,0,1,1.4-.019c2.246,1.957,4.494,3.911,6.714,5.9a1.517,1.517,0,0,1,.426.934c.062.758.026,1.524.018,2.287-.008.708-.326,1.021-1.042,1.027-.8.008-1.59,0-2.425,0v26.989h-1.763v-.57q0-13.46,0-26.92c0-.993.254-1.241,1.265-1.242h2.165a1.824,1.824,0,0,0-.667-2.019c-1.825-1.492-3.567-3.086-5.376-4.668l-3.293,2.878a25.208,25.208,0,0,1-2.063,1.776,1.767,1.767,0,0,0-.7,2.033c.756,0,1.531,0,2.305,0,.823,0,1.127.3,1.128,1.105q0,13.6,0,27.193v.448h-1.725Z"
        transform="translate(-214.842 -0.002)"
        fill={active ? activeColor : "#c10230"}
      />
      <path
        id="Path_3056"
        data-name="Path 3056"
        d="M-2564.2-2786.837v3.457h-1.74v-.559c0-1.181,0-2.361,0-3.542,0-.79.3-1.094,1.079-1.1s1.557,0,2.361,0a1.833,1.833,0,0,0-.619-1.969c-1.842-1.5-3.6-3.112-5.433-4.719l-1.431,1.251q-1.908,1.667-3.816,3.334c-.915.8-.913.8-.836,2.1.747,0,1.5,0,2.256,0,.932,0,1.206.27,1.206,1.192q0,1.934-.007,3.868a.668.668,0,0,1-.038.135h-1.684v-3.453c-.811,0-1.584,0-2.357,0-.855,0-1.143-.289-1.146-1.142,0-.69.016-1.381-.007-2.07a1.282,1.282,0,0,1,.482-1.092c2.222-1.929,4.431-3.872,6.647-5.808a.9.9,0,0,1,1.437-.012c2.231,1.946,4.467,3.887,6.672,5.862a1.6,1.6,0,0,1,.443.98c.066.739.026,1.488.019,2.232s-.321,1.045-1.074,1.051C-2562.563-2786.833-2563.342-2786.837-2564.2-2786.837Z"
        transform="translate(0 -0.002)"
        fill={active ? activeColor : "#c10230"}
      />
      <path
        id="Path_3057"
        data-name="Path 3057"
        d="M-2445.473-2783.379h-1.733v-3.46c-.812,0-1.585,0-2.359,0-.851,0-1.136-.292-1.14-1.149,0-.708-.047-1.421.02-2.124a1.613,1.613,0,0,1,.437-.981c2.2-1.975,4.441-3.916,6.671-5.863a.9.9,0,0,1,1.437,0c2.231,1.946,4.467,3.887,6.672,5.862a1.613,1.613,0,0,1,.438.982c.066.721.023,1.451.019,2.178,0,.787-.305,1.093-1.081,1.1s-1.557,0-2.395,0v3.459h-1.755v-.9c0-1.09-.005-2.18,0-3.27,0-.715.318-1.03,1.03-1.037.8-.008,1.592,0,2.416,0a1.881,1.881,0,0,0-.632-1.973c-1.842-1.5-3.6-3.111-5.428-4.715l-1.436,1.255c-1.285,1.123-2.538,2.288-3.87,3.353a1.806,1.806,0,0,0-.736,2.079c.729,0,1.5,0,2.276,0,.854,0,1.142.29,1.144,1.144C-2445.471-2786.1-2445.473-2784.757-2445.473-2783.379Z"
        transform="translate(-107.388)"
        fill={active ? activeColor : "#c10230"}
      />
      <path
        id="Path_3058"
        data-name="Path 3058"
        d="M-2300.691-2527.581h1.673v1.683h-1.673Z"
        transform="translate(-235.573 -230.458)"
        fill={active ? activeColor : "#c10230"}
      />
      <path
        id="Path_3059"
        data-name="Path 3059"
        d="M-2251.078-2527.561v1.677h-1.687v-1.677Z"
        transform="translate(-276.52 -230.475)"
        fill={active ? activeColor : "#c10230"}
      />
      <path
        id="Path_3060"
        data-name="Path 3060"
        d="M-2480.291-2617.412h1.678v1.677h-1.678Z"
        transform="translate(-82.124 -153.707)"
        fill={active ? activeColor : "#c10230"}
      />
      <path
        id="Path_3061"
        data-name="Path 3061"
        d="M-2370.819-2615.636h-1.657v-1.7h1.657Z"
        transform="translate(-174.24 -153.775)"
        fill={active ? activeColor : "#c10230"}
      />
      <path
        id="Path_3062"
        data-name="Path 3062"
        d="M-2480.291-2521.6h1.678v1.677h-1.678Z"
        transform="translate(-82.124 -235.569)"
        fill={active ? activeColor : "#c10230"}
      />
      <path
        id="Path_3063"
        data-name="Path 3063"
        d="M-2370.834-2521.56v1.673h-1.683v-1.673Z"
        transform="translate(-174.205 -235.602)"
        fill={active ? activeColor : "#c10230"}
      />
    </g>
  </svg>
);

const BuyerIcon = ({
  active,
  activeColor,
  size = "52.34",
}: {
  active: boolean;
  activeColor: string;
  size?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 51.914 51.862"
      id="buyer-card"
    >
      <g
        id="Group_2408"
        data-name="Group 2408"
        transform="translate(2118.977 2797.061)"
      >
        <path
          id="Path_3044"
          data-name="Path 3044"
          d="M-2108.489-2656.544c0,.83.007,1.621,0,2.412a.881.881,0,0,1-.992,1q-2.459.012-4.918,0a.88.88,0,0,1-1-.992c-.01-.792,0-1.584,0-2.464-.763,0-1.51,0-2.258,0-.928,0-1.2-.267-1.2-1.177s0-1.8,0-2.7c0-1.078.227-1.309,1.285-1.309h16.01v-.656q0-12.862,0-25.724c0-1.063.23-1.291,1.3-1.291H-2070c1.062,0,1.292.232,1.292,1.3v26.37c.306,0,.571-.011.833,0a.846.846,0,0,1,.889.885q.026,1.7,0,3.4a.849.849,0,0,1-.937.891c-.81.013-1.62,0-2.515,0,0,.766,0,1.514,0,2.262,0,.924-.269,1.2-1.181,1.2q-2.324,0-4.648,0c-.781,0-1.082-.3-1.087-1.073s0-1.543,0-2.35h-12.106c0,.783,0,1.552,0,2.322,0,.806-.3,1.1-1.114,1.1q-2.351,0-4.7,0c-.805,0-1.1-.3-1.1-1.115,0-.755,0-1.51,0-2.3Zm22.444-19.089v-12.054h-3.42v.552c0,1.207.006,2.414,0,3.62a.87.87,0,0,1-.954.986q-2.513.015-5.025,0a.858.858,0,0,1-.932-.952c-.013-.738,0-1.477,0-2.215v-1.977h-3.432v12.04Zm1.794-.013h13.765v-12.035h-3.414c0,1.4,0,2.762,0,4.128,0,.684-.316,1.021-.971,1.025q-2.485.015-4.971,0a.867.867,0,0,1-.97-.969c-.011-1.225,0-2.449,0-3.674,0-.172-.015-.345-.023-.515h-3.408Zm-15.565,13.842h13.772v-12.03h-3.421c0,1.4,0,2.746,0,4.1,0,.736-.315,1.043-1.06,1.046-1.4.005-2.81,0-4.215,0-1.543,0-1.642-.1-1.642-1.656v-3.494h-3.433Zm29.364-12.038h-3.448c0,1.385,0,2.733,0,4.081,0,.748-.309,1.066-1.037,1.069q-2.432.009-4.863,0c-.7,0-1.01-.323-1.014-1.035-.007-1.009,0-2.017,0-3.026v-1.094h-3.422v12.033h13.788Zm-46.66,15.491h48.355v-1.664h-48.355Zm22.486-29.34v3.4h3.395v-3.4Zm18.974.017h-3.392v3.39h3.392Zm-15.569,17.232v-3.4h-3.395v3.4Zm12.158-.006h3.4v-3.391h-3.4Zm-31.186,15.568v-1.667h-3.386v1.667Zm15.62-.013h3.391v-1.661h-3.391Zm19.025,0h3.391v-1.664h-3.391Z"
          transform="translate(-0.095 -92.078)"
          fill={active ? activeColor : "#001E61"}
        />
        <path
          id="Path_3045"
          data-name="Path 3045"
          d="M-2111.176-2737.943c1.979-1.731,3.919-3.423,5.85-5.126a.661.661,0,0,0,.192-.407c.028-.338.009-.681.009-1.1-.809,0-1.594,0-2.378,0s-1.079-.305-1.079-1.082q0-13.511,0-27.021v-.4h1.7v26.774c.824,0,1.591,0,2.358,0,.844,0,1.126.289,1.13,1.14,0,.7.047,1.409-.019,2.106a1.6,1.6,0,0,1-.432.975c-2.187,1.959-4.4,3.883-6.616,5.813a.9.9,0,0,1-1.426,0c-2.212-1.931-4.429-3.855-6.616-5.814a1.592,1.592,0,0,1-.435-.972c-.065-.733-.025-1.475-.018-2.214.007-.712.314-1.025,1.018-1.032.79-.008,1.58,0,2.429,0v-26.778h1.74v.563q0,13.349,0,26.7c0,.994-.251,1.246-1.24,1.247h-2.173a1.835,1.835,0,0,0,.623,1.956C-2114.738-2741.127-2113-2739.534-2111.176-2737.943Z"
          transform="translate(0 -20.516)"
          fill={active ? activeColor : "#c10230"}
        />
        <path
          id="Path_3046"
          data-name="Path 3046"
          d="M-1985.4-2785.182c1.969-1.723,3.919-3.379,5.791-5.119.3-.277.237-.937.361-1.5-.921,0-1.689,0-2.458,0-.815,0-1.11-.294-1.112-1.1,0-1.333,0-2.665,0-4.042h1.715v3.415c.827,0,1.6,0,2.365,0,.813,0,1.1.295,1.108,1.107a20.279,20.279,0,0,1-.019,2.106,1.689,1.689,0,0,1-.449,1.021c-2.185,1.961-4.4,3.885-6.615,5.814a.881.881,0,0,1-1.384.008c-2.226-1.942-4.456-3.879-6.658-5.848a1.5,1.5,0,0,1-.419-.927c-.061-.752-.026-1.512-.018-2.268.008-.7.323-1.006,1.038-1.012.788-.007,1.576,0,2.4,0v-3.419h1.745v3.382c0,1.708-.058,1.766-1.748,1.766h-1.656a1.789,1.789,0,0,0,.63,1.969c1.731,1.41,3.378,2.921,5.06,4.391C-1985.632-2785.365-1985.534-2785.289-1985.4-2785.182Z"
          transform="translate(-107.602 -0.091)"
          fill={active ? activeColor : "#c10230"}
        />
        <path
          id="Path_3047"
          data-name="Path 3047"
          d="M-1863.984-2793.62v-3.418h1.728v3.7c0,1.262-.181,1.444-1.421,1.446-.63,0-1.261,0-1.891.01a.335.335,0,0,0-.1.043,1.768,1.768,0,0,0,.639,1.914c1.823,1.492,3.564,3.083,5.369,4.661.52-.453,1.021-.886,1.519-1.322q1.891-1.654,3.781-3.31c.845-.742.844-.744.707-2-.76,0-1.528,0-2.3,0-.817,0-1.113-.294-1.116-1.1,0-1.346,0-2.692,0-4.062h1.713v3.433c.827,0,1.6,0,2.364,0,.814,0,1.107.295,1.111,1.1,0,.72.042,1.444-.018,2.16a1.514,1.514,0,0,1-.406.934c-2.215,1.981-4.457,3.931-6.7,5.883a.869.869,0,0,1-1.343-.023c-2.226-1.942-4.456-3.879-6.658-5.848a1.5,1.5,0,0,1-.421-.926c-.061-.752-.026-1.512-.018-2.268.007-.7.321-1.009,1.034-1.015C-1865.62-2793.625-1864.832-2793.62-1863.984-2793.62Z"
          transform="translate(-215.197 -0.007)"
          fill={active ? activeColor : "#c10230"}
        />
        <path
          id="Path_3048"
          data-name="Path 3048"
          d="M-2094.649-2797.061h1.664v1.663h-1.664Z"
          transform="translate(-20.815)"
          fill={active ? activeColor : "#c10230"}
        />
        <path
          id="Path_3049"
          data-name="Path 3049"
          d="M-2045.145-2795.277h-1.664v-1.663h1.664Z"
          transform="translate(-61.747 -0.104)"
          fill={active ? activeColor : "#c10230"}
        />
        <path
          id="Path_3050"
          data-name="Path 3050"
          d="M-1913.313-2617.332v1.667h-1.663v-1.667Z"
          transform="translate(-174.543 -153.776)"
          fill={active ? activeColor : "#c10230"}
        />
        <path
          id="Path_3051"
          data-name="Path 3051"
          d="M-1807.2-2617.422h1.661v1.662h-1.661Z"
          transform="translate(-266.755 -153.699)"
          fill={active ? activeColor : "#c10230"}
        />
        <path
          id="Path_3052"
          data-name="Path 3052"
          d="M-1915.082-2521.625h1.668v1.678h-1.668Z"
          transform="translate(-174.452 -235.663)"
          fill={active ? activeColor : "#c10230"}
        />
        <path
          id="Path_3053"
          data-name="Path 3053"
          d="M-1805.591-2519.9h-1.656v-1.682h1.656Z"
          transform="translate(-266.716 -235.701)"
          fill={active ? activeColor : "#c10230"}
        />
      </g>
    </svg>
  );
};
export default { BuyerIcon, SupplierIcon };
