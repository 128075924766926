import { Divider, Grid, useTheme } from "@mui/material";
import * as React from "react";

import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import styles from "../layout.module.scss";
export interface LayoutSideBarProps {
  /**
   * Components to be enclosed in sidebar
   */
  children: JSX.Element | JSX.Element[];
  /**
   * Direction of the sidebar
   */
  sideBarDirection?: "left" | "right";
  sidebarClass?: string;
  /**
   * To show collapse button
   */
  collapsable?: boolean;
}
const LayoutSideBar = (props: LayoutSideBarProps) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const collapse = () => {
    setCollapsed(!collapsed);
  };
  const { collapsable = false } = props;
  const theme = useTheme();
  const iconSx = {
    fill: "#FFFFFF",
    borderRadius: "50%",
    transition:
      "background-color 100ms linear 0s, color 100ms linear 0s, opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s",
    boxShadow:
      "rgb(9 30 66 / 16%) 0px 0px 0px 1px, rgb(9 30 66 / 16%) 0px 2px 4px 1px",
    path: {
      fill: theme.palette.primary.main,
    },
    ":hover": {
      fill: theme.palette.primary.main,
      path: {
        fill: "#FFFFFF",
      },
    },
  };
  return (
    <>
      <Grid
        component={"aside"}
        className={`aside ${styles[`${props.sideBarDirection ?? "left"}-sidebar`]} ${
          props?.sidebarClass
        }`}
        style={{ order: collapsed ? -1 : 0 }}
      >
        <Grid item xs={12}>
          {!collapsed && props.children}
        </Grid>
      </Grid>
      {collapsable && (
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            ".MuiDivider-wrapper": {
              padding: `calc(2px * 1.2)`,
              display: "flex",
            },
            "&::before, &::after": {
              borderColor: theme.palette.primary.main,
            },
          }}
        >
          {collapsed ? (
            <ChevronLeft sx={iconSx} onClick={collapse} />
          ) : (
            <ChevronRight sx={iconSx} onClick={collapse} />
          )}
        </Divider>
      )}
    </>
  );
};
LayoutSideBar.displayName = "LayoutSideBar";
export default LayoutSideBar;
