import { Grid, InputAdornment } from "@mui/material";
import DocumentUploadController from "components/Documents/DocumentUploadController";
import { useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";

import DateInput from "components/Common/DateInput";
import Drop from "components/Common/Drop";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import { MapGeneral } from "../defaults";
import styles from "../maps.module.scss";

const Insurance = () => {
  const formikProps = useFormikContext<MapGeneral>();
  const currencies = [
    { label: "USD", value: "US$" },
    { label: "SGD", value: "SGD" },
    { label: "HKD", value: "HK$" },
    { label: "GBP", value: "£" },
    { label: "EUR", value: "€" },
    { label: "AUD", value: "A$" },
  ];
  return (
    <>
      <Grid item xs={12} lg={4}>
        <Drop
          label="Insurer"
          name={"transportDocumentType"}
          keyValue={"name"}
          data={[
            { name: "Atradius", value: "Atradius" },
            { name: "Coface", value: "Coface" },
            { name: "Credendo", value: "Credendo" },
            { name: "Oman Insurance", value: "Oman Insurance" },
            { name: "ICIEC", value: "ICIEC" },
            { name: "Mercury", value: "Mercury" },
            {
              name: "Funder's Insurance",
              value: "Funder's Insurance",
            },
            {
              name: "Client Insurance",
              value: "Client Insurance",
            },
            {
              name: "Partner Insurance",
              value: "Partner Insurance",
            },
          ]}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Input
          labelClassName={styles.labelClass}
          name={"insurerBuyerRating"}
          label={"Insurer Rating on Buyer (Atradius)"}
          required
          type="number"
          style={{ width: "100%" }}
          {...formikProps}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Input
          labelClassName={styles.labelClass}
          name={"insurerBuyerRating"}
          label={"Insurer Rating on Buyer (Coface)"}
          required
          type="number"
          style={{ width: "100%" }}
          {...formikProps}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Drop
          label="Insurance Currency"
          required
          data={currencies}
          name="currency"
          fullWidth
          disabled={false}
          keyValue={"label"}
          {...formikProps}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Input
          labelClassName={styles.labelClass}
          name={"creditLimitedApprovedByInsurer"}
          label={"Credit Limit Approved by Insurer"}
          placeholder={`Credit Limit Approved by Insurer`}
          textfieldProps={{
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  {formikProps.values.currency}
                </InputAdornment>
              ),
            },
          }}
          type="money"
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <LabelViewOnly
          label="Previous Credit Limit"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={currencyFormatter({
            amount: formikProps.values.previousCreditLimit,
            currency: formikProps.values.currency,
          })}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <LabelViewOnly
          label="Available Credit Limit"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={currencyFormatter({
            amount: formikProps.values.availableCreditLimit,
            currency: formikProps.values.currency,
          })}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <LabelViewOnly
          label="Credit Limit Change Date"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly label={`${formikProps.values.creditLimitChangeDate}`} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <LabelViewOnly
          label="Insured % of Invoice Face Value"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={formikProps.values.insuredPercentageOfInvoiceFaceValue}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <LabelViewOnly
          label="Uninsured % of Invoice Face Value"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={formikProps.values.uninsuredPercentageOfInvoiceFaceValue}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <DateInput
          labelClassName={styles.labelClass}
          wrapperClassName={styles.datePickerWrapper}
          name={"insuranceStartDate"}
          label={"Insurance Start Date (DD/MM/YYYY)"}
          value={formikProps.values.insuranceStartDate}
          setFieldValue={formikProps.setFieldValue}
          error={formikProps.errors.insuranceStartDate as string}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <DateInput
          labelClassName={styles.labelClass}
          wrapperClassName={styles.datePickerWrapper}
          name={"insuranceExpiryDate"}
          label={"Insurance Expiry Date (DD/MM/YYYY)"}
          value={formikProps.values.insuranceStartDate}
          setFieldValue={formikProps.setFieldValue}
          error={formikProps.errors.insuranceStartDate as string}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          labelClassName={styles.labelClass}
          sx={{
            ".MuiInputBase-root": {
              height: "15vh",
              backgroundClip: "border-box",
              borderRadius: "10px",
              textarea: {
                height: "13vh !important",
                overflow: "scroll !important",
              },
            },
          }}
          name={"riskComment"}
          label={"Risk comment (If any)"}
          className={styles.formInput}
          value={formikProps.values.riskComment ?? ""}
          multiline
          style={{ width: "100%" }}
          {...formikProps}
        />
      </Grid>
      <Grid item xs={6}>
        <DocumentUploadController
          data={[
            {
              name: "Attachment of Insurance",
              label: "Attachment of Insurance",
              value: "Attachment of Insurance",
              items: [],
              id: "",
              description: "",
              template: "",
              yearly_set_count: 1,
              count_unique_uploaded: 0,
            },
          ]}
          formikProps={formikProps}
          initialData={[]}
          onUpload={(fileName) => console.log(fileName)}
          onView={(fileName) => console.log(fileName)}
          onDelete={(fileName) => console.log(fileName)}
          enableCount={false}
          deleteDisabled
          viewDisabled
        />
      </Grid>
    </>
  );
};
export default Insurance;
