
import LogoDesktopDark from "assets/logo-dark.svg?react";
import LogoDesktop from "assets/logo.svg?react";
import { Link } from "react-router-dom";

import { ButtonBase } from "@mui/material";
import { DASHBOARD, NEW_USER } from "codes";
import history from "helpers/history";
import { useAppSelector } from "redux/hooks";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ light }: { light: boolean }) => {
  const APP_STATE = useAppSelector((state) => state.appState.value);
  const Logo = light ? LogoDesktop : LogoDesktopDark;
  return (
    <ButtonBase
      disableRipple
      onClick={() => {
        if (APP_STATE !== NEW_USER) history.push(DASHBOARD);
      }}
      component={Link}
      to={DASHBOARD}
    >
      <Logo style={{ width: "17ch" }} />
    </ButtonBase>
  );
};

export default LogoSection;
