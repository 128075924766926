import { Clear } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";

import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import * as React from "react";
import { useState } from "react";

const SingleTextAc = ({
  data,
  header,
  setFilters,
  filters,
  customFieldName,
}: {
  data: any[];
  header: string;
  filters: Record<string, any>;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  customFieldName?: string;
}) => {
  const [value, setValue] = useState<Record<string, any>[]>(
    filters[header]?.keywords ?? []
  );

  return (
    <Autocomplete
      id={`${header}-filter`}
      multiple
      sx={{
        width: "100%",
        ".MuiFormControl-root": {
          ".MuiInputBase-root": {
            paddingRight: "0 !important",
          },
        },
      }}
      value={value}
      clearIcon={
        <Clear
          onClick={() => {
            const revertFilters = { ...filters };
            delete revertFilters[header];
            setFilters(revertFilters);
          }}
        />
      }
      onChange={(event: any, newValue: any[]) => {
        setValue(newValue);
        if (newValue.length)
          setFilters((prev) => {
            return {
              ...prev,
              [header]: {
                comparator: "is",
                keywords: newValue,
              },
            };
          });
        else {
          const revertFilters = { ...filters };
          delete revertFilters[header];
          setFilters(revertFilters);
        }
      }}
      options={data}
      getOptionLabel={(option: any) => option ?? "Not defined"}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={customFieldName ?? header}
          margin="normal"
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        if (option) {
          const matches = match(option, inputValue);
          const parts = parse(option, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part: any, index: number) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }
        return <li {...props}>Not defined</li>;
      }}
    />
  );
};

export default SingleTextAc;
