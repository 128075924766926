import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { IShareholder } from "components/Map/Sections/Compliance/CreditReport/types";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { CreditLimit, MapSummary, RiskCategories } from "types";

interface MapRelationship {
  name: string;
  country: string;
  id: string;
}
type ScoreType = "Low" | "Medium Low" | "Medium" | "Medium High" | "High";
interface Map {
  map_id: string;
  relationship_id: string;
  buyer: MapRelationship;
  seller: MapRelationship;
  next_monitoring_date: Date;
  score: ScoreType;
}
interface MapTable {
  map_id: string;
  buyer: string;
  seller: string;
  relationship_id: string;
  score: ScoreType;
  next_monitoring_date: Date;
}

interface AccountComment {
  created_at: Date;
  financial_comments: string;
  background_comments: string;
  background_extra_info: string;
}

interface ApproveMapModule {
  approver: string;
  approval_status: string;
  comments: string;
}

interface Approval {
  approver_name: string;
  approval_date: Date | undefined;
  approval_status: string;
  comments: string;
}

interface ComplianceApproval extends Approval {
  contact_id: string;
}

interface ComplianceApprovals {
  onboarding_officer_decision: ComplianceApproval;
  compliance_officer_decision: ComplianceApproval;
  head_compliance_decision: ComplianceApproval;
  ceo_decision: ComplianceApproval;
  [key: string]: ComplianceApproval;
}

interface CreditCommitteeApprovals {
  ceo_ipl: ComplianceApproval;
  ceo_icpl: ComplianceApproval;
  funding: ComplianceApproval;
  [key: string]: ComplianceApproval;
}

interface IEvaluation {
  id: string;
  name: string;
  value: string;
  type: string;
}

const formatMapsForTable = (data: Map[]) =>
  data.map((item) => ({
    ...item,
    buyer: item.buyer.name,
    seller: item.seller.name,
  }));
export type MAP_TYPE = "background" | "financial" | "transactions";
interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    format: string;
    data: string | number | string[];
  };
}
interface MapRiskParamValues {
  [source: string]: {
    [field_name: string]: any;
  };
}
export const maps = createApi({
  reducerPath: "maps",
  tagTypes: [
    "TEMPLATES",
    "TABLE_VALUES",
    "TRANSACTION_TABLE_VALUES",
    "SUMMARY_TABLE_VALUES",
    "SCORE_CARD",
    "MAP_DOCUMENTS",
    "INSURERS",
    "ACCOUNT_COMMENTS",
    "RELATIONSHIP_COMMENTS",
    "SCORECARD_HEADER",
    "SCORE_CARD_PDF",
    "SHAREHOLDERS",
    "EVALUATION",
    "SCORE_CARD_PDF",
    "RISK_MAP_APPROVERS",
    "COMPLIANCE_MAP_APPROVERS",
    "LEGAL_MAP_APPROVALS",
    "CREDIT_COMMITEE_APPROVERS",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/maps`,
  }),
  endpoints: (builder) => ({
    getMaps: builder.query<MapTable[], null>({
      query: () => ({ url: "", method: "get" }),
      transformResponse: (response) => formatMapsForTable(response?.data),
    }),
    getMap: builder.query<MapTable, string>({
      query: (map_name) => ({
        url: `/${map_name}/relationship`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getTemplates: builder.query<MapTemplate, MAP_TYPE>({
      query: (template_name) => ({
        url: `/template/risk/${template_name}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TEMPLATES"],
    }),
    editMapRiskParameters: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        data: {
          map_metadata_id: string;
          source: string;
          value: any;
        };
      }
    >({
      query: ({ map_name, account_type, data }) => {
        return {
          url: `/${map_name}/${account_type}`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TABLE_VALUES"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(maps.util.invalidateTags(["TABLE_VALUES"]));
          dispatch(maps.util.invalidateTags(["EVALUATION"]));
        } catch (error) {
          //eslint-disable-next-line no-console
          console.error("Error deleting director:", error);
        }
      },
    }),
    getMapRiskParameters: builder.query<
      MapRiskParamValues,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        item_type: MAP_TYPE;
      }
    >({
      query: ({ map_name, account_type, item_type }) => ({
        url: `/${map_name}/${account_type}/${item_type}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TABLE_VALUES"],
    }),
    getMapTransactionValues: builder.query<
      MapRiskParamValues,
      {
        map_name: string;
      }
    >({
      query: ({ map_name }) => ({
        url: `/${map_name}/relationship`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TRANSACTION_TABLE_VALUES"],
    }),
    editMapTransactionDetails: builder.mutation<
      any,
      {
        map_name: string;
        data: {
          map_metadata_id: string;
          source: string;
          value: any;
        };
      }
    >({
      query: ({ map_name, data }) => {
        return {
          url: `/${map_name}/relationship`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TRANSACTION_TABLE_VALUES"],
    }),
    getCategories: builder.query<RiskCategories, string>({
      query: (map_name) => ({
        url: `/${map_name}/score-card`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SCORE_CARD"],
    }),
    modifyMapField: builder.mutation<
      any,
      {
        map_name: string;
        data: {
          map_metadata_id: string;
          value: any;
        };
      }
    >({
      query: ({ map_name, data }) => {
        return {
          url: `/${map_name}/score-card`,
          method: "patch",
          data,
        };
      },
      invalidatesTags: ["SCORE_CARD", "SCORE_CARD_PDF"],
    }),
    getMapSummary: builder.query<MapSummary, string>({
      query: (map_name) => ({
        url: `/${map_name}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SUMMARY_TABLE_VALUES"],
    }),
    getScorecardHeader: builder.query<MapSummary, string>({
      query: (map_name) => ({
        url: `/${map_name}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SCORECARD_HEADER"],
    }),
    editMapSummary: builder.mutation<
      any,
      { map_name: string; data: { [field_name: string]: any } }
    >({
      query: ({ map_name, data }) => {
        return {
          url: `/${map_name}`,
          method: "patch",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SUMMARY_TABLE_VALUES", "SCORE_CARD_PDF"],
    }),
    editScorecardHeader: builder.mutation<
      any,
      { map_name: string; data: { [field_name: string]: any } }
    >({
      query: ({ map_name, data }) => ({
        url: `/${map_name}`,
        method: "patch",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["SCORECARD_HEADER", "SCORE_CARD_PDF"],
    }),
    getMapDocuments: builder.query<MapSummary, string>({
      query: (map_name) => ({
        url: `/${map_name}/documents`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["MAP_DOCUMENTS"],
    }),
    getMapInsurers: builder.query<CreditLimit, string>({
      query: (map_name) => ({
        url: `/${map_name}/credit-limit`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["INSURERS"],
    }),
    getAccountComments: builder.query<
      AccountComment,
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/${map_name}/${account_type}/summary`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["ACCOUNT_COMMENTS"],
    }),
    editAccountComments: builder.mutation<
      any,
      {
        map_name: string;
        account_type: string;
        data: {
          [field_name: string]: string;
        };
      }
    >({
      query: ({ map_name, account_type, data }) => ({
        url: `/${map_name}/${account_type}/summary`,
        data,
        method: "put",
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["ACCOUNT_COMMENTS"],
    }),
    exportMap: builder.query<any, string>({
      query: (map_name) => ({
        url: `/${map_name}/score-card/export`,
        method: "get",
      }),
      transformResponse: (response) => response,
      providesTags: ["SCORE_CARD_PDF"],
    }),
    getShareholdersHierarchy: builder.query<
      IShareholder,
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/${map_name}/${account_type}/shareholders`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SHAREHOLDERS"],
    }),
    getEvaluationValues: builder.query<
      IEvaluation[],
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/${map_name}/${account_type}/evaluation`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["EVALUATION"],
    }),
    editEvaluation: builder.mutation<
      any,
      {
        map_name: string;
        account_type: "supplier" | "buyer";
        data: {
          map_metadata_id: string;
          source: string;
          value: any;
        };
      }
    >({
      query: ({ map_name, account_type, data }) => {
        return {
          url: `/${map_name}/${account_type}`,
          method: "PATCH",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["EVALUATION"],
    }),
    editTemplate: builder.mutation<
      any,
      {
        data: string[];
      }
    >({
      query: ({ data }) => ({
        url: `/template/field-order`,
        data,
        method: "put",
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["TEMPLATES", "SCORE_CARD"],
    }),
    getRiskMapApprovals: builder.query<Approval, string>({
      query: (map_name) => ({
        url: `/${map_name}/approval/risk`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["RISK_MAP_APPROVERS"],
    }),
    approveRiskMap: builder.mutation<
      any,
      {
        map_name: string;
        data: ApproveMapModule;
      }
    >({
      query: ({ map_name, data }) => ({
        url: `/${map_name}/approval/risk`,
        method: "put",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["RISK_MAP_APPROVERS", "SUMMARY_TABLE_VALUES"],
    }),
    editApproverRiskMap: builder.mutation<
      any,
      {
        map_name: string;
        data: Partial<ApproveMapModule>;
      }
    >({
      query: ({ map_name, data }) => ({
        url: `/${map_name}/approval/risk`,
        method: "patch",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["RISK_MAP_APPROVERS"],
    }),
    getLegalMapApprovals: builder.query<Approval, string>({
      query: (map_name) => ({
        url: `/${map_name}/approval/legal`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["LEGAL_MAP_APPROVALS"],
    }),
    approveLegalMap: builder.mutation<
      any,
      {
        map_name: string;
        data: ApproveMapModule;
      }
    >({
      query: ({ map_name, data }) => ({
        url: `/${map_name}/approval/legal`,
        method: "put",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["LEGAL_MAP_APPROVALS", "SUMMARY_TABLE_VALUES"],
    }),
    getComplianceMapApprovals: builder.query<
      ComplianceApprovals,
      { map_name: string; account_type: string }
    >({
      query: ({ map_name, account_type }) => ({
        url: `/${map_name}/${account_type}/compliance-approvals`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["COMPLIANCE_MAP_APPROVERS"],
    }),
    editApprover: builder.mutation<
      any,
      {
        map_name: string;
        account_type: string;
        role: string;
        data: Partial<ApproveMapModule>;
      }
    >({
      query: ({ map_name, account_type, role, data }) => ({
        url: `/${map_name}/${account_type}/compliance-approvals/${role}`,
        method: "patch",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["COMPLIANCE_MAP_APPROVERS", "SUMMARY_TABLE_VALUES"],
    }),
    approveComplianceMap: builder.mutation<
      any,
      {
        map_name: string;
        account_type: string;
        role: string;
        data: ApproveMapModule;
      }
    >({
      query: ({ map_name, account_type, role, data }) => ({
        url: `/${map_name}/${account_type}/compliance-approvals/${role}`,
        method: "put",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["COMPLIANCE_MAP_APPROVERS", "SUMMARY_TABLE_VALUES"],
    }),

    getCreditCommitteeMapApprovals: builder.query<
      CreditCommitteeApprovals,
      string
    >({
      query: (map_name) => ({
        url: `/${map_name}/approval/credit-committee`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CREDIT_COMMITEE_APPROVERS"],
    }),

    approveCreditCommittee: builder.mutation<
      any,
      {
        map_name: string;
        role: string;
        data: ApproveMapModule;
      }
    >({
      query: ({ map_name, role, data }) => ({
        url: `/${map_name}/approval/credit-committee/${role}`,
        method: "put",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["CREDIT_COMMITEE_APPROVERS", "SUMMARY_TABLE_VALUES"],
    }),

    getScreeningDocumentsShareholder: builder.query<
      any,
      { map_name: string; account_type: string; shareholder_id: string }
    >({
      query: ({ map_name, account_type, shareholder_id }) => ({
        url: `/${map_name}/${account_type}/screening/shareholders/${shareholder_id}/documents`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["MAP_DOCUMENTS"],
    }),
    addScreeningDocumentShareholder: builder.mutation<
      any,
      {
        map_name: string;
        account_type: string;
        shareholder_id: string;
        data: FormData;
      }
    >({
      query: ({ map_name, account_type, shareholder_id, data }) => ({
        url: `/${map_name}/${account_type}/screening/shareholders/${shareholder_id}/documents`,
        method: "POST",
        data,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["MAP_DOCUMENTS"],
    }),
  }),
});

export const {
  useGetMapsQuery,
  useGetMapQuery,
  useGetTemplatesQuery,
  useEditMapRiskParametersMutation,
  useGetMapRiskParametersQuery,
  useGetMapTransactionValuesQuery,
  useEditMapTransactionDetailsMutation,
  useGetCategoriesQuery,
  useModifyMapFieldMutation,
  useGetMapSummaryQuery,
  useEditMapSummaryMutation,
  useGetMapDocumentsQuery,
  useGetMapInsurersQuery,
  useGetAccountCommentsQuery,
  useEditAccountCommentsMutation,
  useGetScorecardHeaderQuery,
  useEditScorecardHeaderMutation,
  useExportMapQuery,
  useGetShareholdersHierarchyQuery,
  useGetEvaluationValuesQuery,
  useEditTemplateMutation,
  useGetRiskMapApprovalsQuery,
  useApproveRiskMapMutation,
  useEditApproverRiskMapMutation,
  useEditEvaluationMutation,
  useGetComplianceMapApprovalsQuery,
  useEditApproverMutation,
  useApproveComplianceMapMutation,
  useGetCreditCommitteeMapApprovalsQuery,
  useApproveCreditCommitteeMutation,
  useAddScreeningDocumentShareholderMutation,
  useGetScreeningDocumentsShareholderQuery,
  useGetLegalMapApprovalsQuery,
  useApproveLegalMapMutation,
} = maps;
