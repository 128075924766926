import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { resetStateAction } from "../actions/resetState";

export const unauthenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (
      isRejectedWithValue(action) &&
      (action.payload as any)?.status === 401
    ) {
      dispatch(resetStateAction());
    }

    return next(action);
  };
