// import { useEffect, useState } from "react";
// import { AccountInputDetailed } from "./types";

// interface CreditReportField {
//   name: string;
//   id: string;
// }

// interface CreditReportResponse {
//   [source: string]: {
//     [field_name: string]: CreditReportField;
//   };
// }

// const useGetDiscrepancies = (
//   creditReportValues?: CreditReportResponse,
//   accountInput?: AccountInputDetailed
// ) => {
//   const [discrepancies, setDiscrepancies] = useState<string[]>([]);

//   const formatValue = (value: any): string => {
//     if (value instanceof Date) return value.toLocaleDateString();
//     // eslint-disable-next-line no-else-return
//     else if (typeof value === "object" && value !== null)
//       return JSON.stringify(value);
//     return value;
//   };

//   const checkDiscrepancies = (field: {
//     [field_name: string]: {
//       name: string;
//       id: string;
//     };
//   }): string[] => {
//     const finalVersion = creditReportValues?.finalversion ?? {};
//     const result: string[] = [];
//     if (accountInput !== undefined && creditReportValues !== undefined)
//       Object.entries(accountInput).forEach(([key, value]) => {
//         const valueFinalversion = formatValue(finalVersion?.[key]?.name);
//         if (
//           field[key]?.name !== undefined &&
//           (key.includes("Date")
//             ? value
//             : value?.toLowerCase().replace(/\s+/g, "")) !==
//             (key.includes("Date")
//               ? formatValue(field[key]?.name)
//               : formatValue(field[key]?.name)
//                   ?.toLowerCase()
//                   .replace(/\s+/g, "")) &&
//           (valueFinalversion === undefined || valueFinalversion === null)
//         )
//           result.push(key);
//       });
//     return result;
//   };

//   const cycleThroughValues = () => {
//     const finalResult: string[] = [];
//     if (creditReportValues !== undefined)
//       // eslint-disable-next-line no-unused-vars
//       Object.entries(creditReportValues).forEach(([_, value]) => {
//         finalResult.push(
//           ...checkDiscrepancies(
//             value as { [field_name: string]: CreditReportField }
//           )
//         );
//       });
//     return new Set(finalResult);
//   };

//   useEffect(() => {
//     setDiscrepancies(Array.from(cycleThroughValues()));
//   }, [creditReportValues]);
//   return discrepancies;
// };

// export default useGetDiscrepancies;

import moment from "moment";
import { useEffect, useState } from "react";
import { AccountInputDetailed } from "./types";

interface CreditReportField {
  name: string;
  id: string;
}

interface CreditReportResponse {
  [source: string]: {
    [field_name: string]: CreditReportField;
  };
}

const useGetDiscrepancies = (
  creditReportValues?: CreditReportResponse,
  accountInput?: AccountInputDetailed
) => {
  const [discrepanciesFinal, setDiscrepancies] = useState<string[]>([]);

  const formatValue = (value: any): string => {
    if (value instanceof Date) return moment(value).format("yyyy-MM-DD");
    if (typeof value === "object" && value !== null)
      return JSON.stringify(value);
    return value;
  };

  const normalizeValue = (value: any, isDate: boolean = false): string => {
    const formattedValue = formatValue(value);
    if (isDate) {
      return formattedValue;
    }
    return formattedValue !== undefined &&
      formattedValue !== null &&
      formattedValue !== ""
      ? formattedValue.toLowerCase().replace(/\s+/g, "")
      : "";
  };

  const checkDiscrepanciesWithAccountInput = (field: {
    [field_name: string]: { name: string; id: string };
  }): string[] => {
    const result: string[] = [];

    if (accountInput !== undefined && creditReportValues !== undefined) {
      Object.entries(accountInput).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          const isDate = key.includes("Date");
          const normalizedAccountValue = normalizeValue(value, isDate);
          const normalizedFieldValue = normalizeValue(field[key]?.name, isDate);
          if (
            field[key]?.name !== undefined &&
            field[key]?.name !== null &&
            normalizedAccountValue !== normalizedFieldValue
          ) {
            result.push(key);
          }
        }
      });
    }

    return result;
  };

  const checkDiscrepanciesBetweenReports = (): string[] => {
    const discrepanciesSet = new Set<string>();

    if (creditReportValues !== undefined) {
      const allFields = new Set<string>();

      Object.entries(creditReportValues).forEach(([source, fields]) => {
        if (source !== "finalversion") {
          Object.keys(fields).forEach((field) => allFields.add(field));
        }
      });

      allFields.forEach((field) => {
        const values = new Set<string>();

        Object.entries(creditReportValues).forEach(([source, fields]) => {
          if (source !== "finalversion" && fields[field]?.name !== undefined) {
            values.add(
              normalizeValue(fields[field]?.name, field.includes("Date"))
            );
          }
        });

        if (values.size > 1) {
          discrepanciesSet.add(field);
        }
      });
    }

    return Array.from(discrepanciesSet);
  };

  const cycleThroughValues = () => {
    const discrepancies = new Set<string>();

    if (creditReportValues !== undefined) {
      // eslint-disable-next-line no-unused-vars
      Object.entries(creditReportValues).forEach(([_, value]) => {
        checkDiscrepanciesWithAccountInput(
          value as { [field_name: string]: CreditReportField }
        ).forEach((discrepancy) => discrepancies.add(discrepancy));
      });

      checkDiscrepanciesBetweenReports().forEach((discrepancy) =>
        discrepancies.add(discrepancy)
      );

      // Remove discrepancies if finalversion has a value
      discrepancies.forEach((field) => {
        const nameValue = creditReportValues?.finalversion?.[field]?.name;

        if (nameValue !== undefined && nameValue !== null && nameValue !== "") {
          discrepancies.delete(field);
        }
      });
    }

    return Array.from(discrepancies);
  };

  useEffect(() => {
    setDiscrepancies(cycleThroughValues());
  }, [creditReportValues]);

  return discrepanciesFinal;
};

export default useGetDiscrepancies;
