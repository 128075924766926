import { Divider, Stack } from "@mui/material";

import { useFormikContext } from "formik";
import { CLDetails } from "types";

import { useGetPartnersQuery } from "api/api-accounts";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Fragment } from "react/jsx-runtime";
import TransactionWithSupplier from "./TransactionWithSupplier";

const CreditLimitPartners = () => {
  const formikProps = useFormikContext<CLDetails>();
  const { buyerId } = formikProps.values;

  const {
    data: partners,
    isFetching,
    isUninitialized: isPartnersUninitialized,
    isLoading,
    refetch: refetchPartners,
  } = useGetPartnersQuery(buyerId ?? "", {
    skip: !Boolean(buyerId),
  });

  return (
    <Stack spacing={1}>
      {isFetching && <SkeletonLoad bars={10} />}
      {!isFetching &&
        partners &&
        partners?.map((partner) => (
          <Fragment key={partner.id}>
            {buyerId && (
              <Stack spacing={1}>
                <TransactionWithSupplier
                  partner={partner}
                  companyId={buyerId}
                />
              </Stack>
            )}
            <Divider />
          </Fragment>
        ))}
    </Stack>
  );
};
export default CreditLimitPartners;
