import { Chip, ChipProps, Grid, Stack, Typography } from "@mui/material";
import { useGetAccountByNameQuery } from "api/api-accounts";
import { useGetComplianceScoringQuery } from "api/api-compliance";
import { useGetMapSummaryQuery } from "api/api-maps";
import { SUPPLIER } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import VariableDate from "components/Common/VariableDate";
import { Form, Formik } from "formik";
import toCamelCase from "helpers/camelCase";
import toProperCase from "helpers/propercase";
import { MapContext } from "pages/maps/map";
import React from "react";
import { MapSummary } from "types";

const ComplianceScoringRisk = ({ accountType }: { accountType: string }) => {
  const mapName = React.useContext(MapContext);
  const { data: mapData } = useGetMapSummaryQuery(mapName ?? "", {
    skip: !mapName,
  });
  const getAccountId = (mapData: MapSummary) => {
    if (accountType?.toLowerCase() === SUPPLIER?.toLowerCase()) {
      return mapData?.seller_internal_name;
    } else {
      return mapData?.buyer_internal_name;
    }
  };
  const accountId = mapData && getAccountId(mapData);
  const {
    data: account,
    isFetching: isFetchingLead,
    isLoading: isLoadingLead,
    refetch: refetchLead,
  } = useGetAccountByNameQuery(accountId ?? "", { skip: !accountId });
  // Define the items with their labels and chip colors
  const getColor = (risk: string): ChipProps["color"] => {
    switch (risk) {
      case "Low":
        return "success";
      case "Medium":
        return "warning";
      case "High":
        return "error";
      default:
        return "info";
    }
  };

  const { data: scoring } = useGetComplianceScoringQuery({
    map_name: mapName ?? "",
    account_type: accountType as "supplier" | "buyer",
  });

  const items: {
    label: (type?: string) => string;
    chipLabel: (type?: string, extraKey?: string) => ChipProps["label"];
  }[] = [
    {
      label: (type) => `Client Risk : ${type}`,
      chipLabel: (type) => scoring?.["client_risk"]?.["label"] ?? "N/A",
    },
    {
      label: (type) => `Country Risk : ${type}`,
      chipLabel: (type) => scoring?.["country_risk"]?.["label"] ?? "N/A",
    },
    {
      label: (type) => `Product Risk : ${type}`,
      chipLabel: (type) => scoring?.["product_risk"]?.["label"] ?? "N/A",
    },
    {
      label: (type) => `Industry Risk : ${type}`,
      chipLabel: (type) => scoring?.["industry_risk"]?.["label"] ?? "N/A",
    },
    {
      label: (type) => `Triggers Events: ${type ?? "N/A"}`,
      chipLabel: (type) => scoring?.["trigger_risk"]?.["label"] ?? "N/A",
    },
  ];

  const scoringKeys = [
    { type: `${accountType}`, extraKey: undefined },
    { type: `${account?.country}`, extraKey: undefined },
    { type: `${mapData?.product}`, extraKey: undefined },
    { type: `${account?.industry}`, extraKey: `${account?.goods_category}` },
    { type: `None`, extraKey: undefined },
  ];
  return (
    <Formik
      enableReinitialize
      onSubmit={console.log}
      initialValues={{
        riskScoringRemarks:
          "No adverse findings. There is no reputational/financial/material risk to Incomlend.",
        ...items.reduce((acc: { [key: string]: string }, curr) => {
          acc[toCamelCase(curr.label(accountType))] = "";
          return acc;
        }, {}),
        nextReviewDate: "01/01/2025",
      }}
    >
      {(formikProps) => (
        <Form>
          <MainCard
            title={
              <Stack direction="row" justifyContent={"space-between"}>
                <Typography variant="h4">Compliance Risk Scoring</Typography>
                <Chip
                  label={scoring?.["aggregate_risk"]?.["label"]}
                  color={getColor(scoring?.["aggregate_risk"]?.["label"] ?? "")}
                  variant="outlined"
                  sx={{
                    fontSize: "0.875em",
                    fontWeight: "bold",
                  }}
                />
              </Stack>
            }
            headerSx={{ p: 2 }}
            children={
              <Stack spacing={2}>
                {items.map((item, index) => {
                  const rating = item.chipLabel(
                    toProperCase(scoringKeys[index]?.type ?? ""),
                    toProperCase(scoringKeys[index]?.extraKey ?? "")
                  ) as string;
                  return (
                    <Stack direction="row" justifyContent={"space-between"}>
                      <LabelViewOnly
                        label={item.label(
                          toProperCase(scoringKeys[index]?.type ?? "")
                        )}
                      />
                      <Chip
                        label={rating}
                        color={getColor(rating)}
                        variant="outlined"
                        sx={{
                          fontSize: "0.875em",
                          height: "2.5em",
                          fontWeight: "bold",
                        }}
                      />
                    </Stack>
                  );
                })}
                {/* <VariableInput
                  multiline
                  name="riskScoringRemarks"
                  label={"Remarks"}
                  value={formikProps.values.riskScoringRemarks}
                /> */}
              </Stack>
            }
            footer={
              <Stack
                direction={"row"}
                spacing={2}
                alignItems="center"
                justifyContent={"space-between"}
                sx={{ width: "100%" }}
              >
                <Grid item xs={12} md={12} sx={{ marginLeft: "2% !important" }}>
                  <LabelViewOnly
                    label={"Next Review Date"}
                    helpText={
                      <>
                        Frequency of review:
                        <ol>
                          <li>High Risk - annually</li>
                          <li>Medium Risk - once every 2 years</li>
                          <li>Low Risk - Once every 3 years</li>
                        </ol>
                      </>
                    }
                  />
                </Grid>

                <VariableDate
                  name={`nextReviewDate`}
                  containerSx={{ justifyContent: "flex-end" }}
                  disabled
                  label={""}
                  value={
                    formikProps.values?.nextReviewDate !== undefined
                      ? new Date(formikProps.values?.nextReviewDate)
                      : undefined
                  }
                  error={formikProps.errors?.nextReviewDate}
                />
              </Stack>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default ComplianceScoringRisk;
