import {
  ColorSystemOptions,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";
import componentStyleOverrides from "./componentStyleOverrides";

declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
    tertiary: Palette["primary"];
    inactive: Palette["primary"];
    field: Palette["primary"];
    markdown: Palette["primary"];
  }
  interface PaletteOptions {
    white?: PaletteOptions["primary"];
    tertiary?: PaletteOptions["primary"];
    inactive?: PaletteOptions["primary"];
    field?: PaletteOptions["primary"];
    markdown?: PaletteOptions["primary"];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true;
    tertiary: true;
    inactive: true;
    field: true;
    markdown: true;
  }
}
const lightPalette: ColorSystemOptions = {
  palette: {
    primary: {
      main: "#001E61",
      light: "#c0c0d1",
      dark: "#001E61",
      contrastText: "#fff",
      mainChannel: "0 30 97",
    },
    background: {
      default: "#fff",
      paper: "rgba(246, 246, 246)",
    },
    text: {
      primary: "#001E61",
      secondary: "#001E61",
      disabled: "#6b707f",
    },
    secondary: {
      main: "#504F6C",
      light: "#504F6C",
      dark: "#262634",
      contrastText: "#fff",
    },
    info: {
      main: "#808285",
      light: "#808285",
      dark: "#3d3e40",
      contrastText: "#fff",
    },
    tertiary: {
      main: "#C10230",
      light: "#C10230",
      dark: "#4d0113",
      contrastText: "#fff",
    },
    error: {
      main: "#C10230",
      light: "#C10230",
      dark: "#4d0113",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#2e7d32",
      dark: "#123214",
      contrastText: "#fff",
    },
    white: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#001E61",
      contrastText: "#000",
    },
    inactive: {
      main: "#a2a6c3",
      light: "#a2a6c3",
      dark: "#383c57",
      contrastText: "#383c57",
    },
    field: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#001E61",
      contrastText: "#000",
    },
    markdown: {
      main: "rgb(224,30,90)",
    },
  },
};

const defaultTheme = {
  shape: {
    borderRadius: 10,
  },
  mixins: {
    toolbar: {
      minHeight: "48px",
      padding: "16px",
      "@media (min-width: 600px)": {
        minHeight: "48px",
      },
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Inter", sans-serif',
    fontSize: 11,
    h2: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    h3: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "1.25rem",
      fontWeight: 600,
    },
    h4: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "1rem",
      fontWeight: 600,
    },
    h5: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "0.875rem",
      fontWeight: 600,
    },
    h6: {
      fontFamily: '"Inter", sans-serif',
      fontSize: "0.5rem",
      fontWeight: 500,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
};

const darkPalette: ColorSystemOptions = {
  palette: {
    primary: {
      main: "#fff",
      light: "rgba(243, 246, 249, 0.6)",
      dark: "#ffffff",
      contrastText: "#001E61",
      mainChannel: "255 255 255",
    },
    background: {
      default: "#121212",
      paper: "#282828",
    },
    divider: "#fff",
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    secondary: {
      main: "#504F6C",
      light: "#504F6C",
      dark: "#262634",
      contrastText: "#fff",
    },
    info: {
      main: "#808285",
      light: "#808285",
      dark: "#3d3e40",
      contrastText: "#fff",
    },
    tertiary: {
      main: "#C10230",
      light: "#C10230",
      dark: "#4d0113",
      contrastText: "#fff",
    },
    error: {
      main: "#C10230",
      light: "#C10230",
      dark: "#4d0113",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#2e7d32",
      dark: "#123214",
      contrastText: "#fff",
    },
    white: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#001E61",
      contrastText: "#000",
    },
    inactive: {
      main: "#a2a6c3",
      light: "#a2a6c3",
      dark: "#383c57",
      contrastText: "#383c57",
    },
    field: {
      light: "rgb(40, 40, 40)",
      main: "rgb(40, 40, 40)",
      dark: "rgb(40, 40, 40)",
      contrastText: "#fff",
    },
    markdown: {
      main: "#e8912d",
    },
  },
};

export const theme = extendTheme({
  ...defaultTheme,
  colorSchemes: {
    light: lightPalette,
    dark: darkPalette,
  },
  ...componentStyleOverrides(),
});

export const avatarStyles = {
  commonAvatar: {
    cursor: "pointer",
    borderRadius: "8px",
  },
  smallAvatar: {
    width: "22px",
    height: "22px",
    fontSize: "1rem",
  },
  mediumAvatar: {
    width: "34px",
    height: "34px",
    fontSize: "1.2rem",
  },
  largeAvatar: {
    width: "44px",
    height: "44px",
    fontSize: "1.5rem",
  },
};
export const menuStyling = {
  activeColor: theme.vars.palette.error.main,
  iconActiveColor: `${theme.vars.palette.primary.main} `,
  iconInactiveColor: `${theme.vars.palette.inactive.main}`,
  circularBackground: true,
};
