import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { SxProps, alpha, useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface SearchProps {
  /**Write JSDoc */
  value?: string;
  curved?: boolean;
  sx?: SxProps;
  className?: string;
  processSearch?: (searchkey: string) => void;
  textFieldProps?: TextFieldProps;
  onChange: (e: string) => void;
}

const Search = (props: SearchProps) => {
  const {
    curved,
    processSearch,
    className,
    sx,
    value = "",
    textFieldProps,
    onChange,
  } = props;
  const [search, setSearch] = useState(value);
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <FormControl
      id="search-form"
      sx={{
        position: "relative",
        "& .MuiFormControl-root": {
          width: "100%",
          border: "none",
        },
        borderRadius: curved ? "4ch" : theme.shape.borderRadius,
        backgroundClip: "content-box",
        "&:hover": {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        },
        marginLeft: 0,
        [theme.breakpoints.up("sm")]: {
          marginLeft: theme.spacing(1),
          width: "100%",
        },
      }}
    >
      <TextField
        {...textFieldProps}
        placeholder={`${t("search")}`}
        inputProps={{ "aria-label": "search" }}
        className={className}
        value={search}
        sx={{
          color: "inherit",
          "& .MuiInputBase-root": {
            borderRadius: "4ch",
            backgroundColor: theme.palette.field.main,
            "& .MuiInputBase-input": {
              padding: `${theme.spacing(1, 1, 1, 0)} `,
              // vertical padding + font size from searchIcon
              paddingLeft: `calc(1em + ${theme.spacing(1)})`,
              transition: theme.transitions.create("width"),
              backgroundColor: theme.palette.field.main,
              width: "100%",
              [theme.breakpoints.up("sm")]: {
                width: "90%",
                "&:focus": {
                  width: "100%",
                },
              },
            },
          },

          ...sx,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="search icon"
                edge="end"
                onClick={() => processSearch && processSearch(search)}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          setSearch(e.target.value);
          onChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") processSearch && processSearch(search);
        }}
      />
    </FormControl>
  );
};

export default Search;
