import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { Invoice } from "types";

// Define a type for the slice state
interface InvoiceState {
  invoices: Invoice[];
  // Other values that should be calculated once and then re-calculated if a new invoice is added
  averagesValues: {
    averageDelay: number;
    averageDiscountRate: number;
  };
  totalValues: {
    // Should be in several currencies ?
    totalAdvancedAmount: number;
    totalOverdueCost: number;
  };
}

// Define the initial state using that type
const initialState: InvoiceState = {
  // General filters data
  invoices: [],
  averagesValues: {
    averageDelay: 0,
    averageDiscountRate: 0,
  },
  totalValues: {
    // Should be in several currencies ?
    totalAdvancedAmount: 0,
    totalOverdueCost: 0,
  },
};

export const invoicesSlice = createSlice({
  name: "invoices",
  // `createSlice` will infer the state type from the `initialState` argument
  // Use the PayloadAction type to declare the contents of `action.payload`
  initialState,
  //CRUD
  reducers: {
    addInvoice: (state, action: PayloadAction<Invoice>) => {
      state.invoices = [...state.invoices, action.payload];
    },
    deleteInvoice: (state, action: PayloadAction<Invoice>) => {
      state.invoices.filter(
        (invoice) =>
          invoice.invoiceRefNumber !== action.payload.invoiceRefNumber
      );
    },
    updateInvoice: (state, action: PayloadAction<Invoice>) => {
      const position = state.invoices
        .map((invoice: Invoice) => invoice.invoiceRefNumber)
        .indexOf(action.payload.invoiceRefNumber);
      state.invoices.splice(position, 1, action.payload);
    },
    setInvoiceList: (state, action: PayloadAction<Invoice[]>) => {
      state.invoices = action.payload;
    },

    // Calculate current values for total TODO
    clearAll: () => initialState,
  },
});

export const { addInvoice, deleteInvoice, updateInvoice, clearAll } =
  invoicesSlice.actions;

// // Other code such as selectors can use the imported `RootState` type
// export const selectInvoices = (state: RootState) => state.invoices;

export default invoicesSlice.reducer;
