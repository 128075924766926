import moment from "moment";
import type { RegisterLead, RegisterLeadPartner } from "types";
import * as Yup from "yup";

export const defaultValues: RegisterLead & { iso3: string } = {
  company_type: "",
  country: "",
  name: "",
  iso3: "",
  incorporation_date: undefined,
  reg_no: "",
  address_line1: "",
  address_line2: "",
  city: "",
  postcode: "",
  shipping_address: {
    address_line1: "",
    address_line2: "",
    city: "",
    country: "",
    postcode: "",
    iso3: "",
  },
  same_shipping_operation_address: true,
  industry: "",
  goods_category: "",
  accounting_software: "Others",
  number_of_employees: "11-50 employees",
  annual_revenue: 0,
};
export const defaultValuesLeadPartner: RegisterLeadPartner & { iso3: string } =
  {
    name: "",
    country: "",
    lead_id: "",
    iso3: "",
  };
//eslint-disable-next-line prefer-regex-literals
const dateRegex = new RegExp(/\d{1,2}\/\d{1,2}\/\d{2,4}$/);

export const validationSchema = Yup.object().shape({
  company_type: Yup.string().required("Choose a Lead type"),
  name: Yup.string().required("Company Name is required"),
  country: Yup.string().required("Country is required"),
  reg_no: Yup.string()
    .required("Registration Number is Required")
    .matches(
      /^[0-9A-Za-z- (./_\\)]{3,100}$/,
      "Please ensure your Registration Number is of a valid format"
    ),
  address_line1: Yup.string().required("Please enter your Address"),
  address_line2: Yup.string().nullable(),
  incorporation_date: Yup.date()
    .required("Company Incorporation Date is required")
    .test({
      name: "incorporation_date",
      exclusive: false,
      params: {},
      message:
        "Please ensure your Company Incorporation Date is of a valid format",
      test: (value: string) =>
        dateRegex.test(moment(value).format("DD/MM/YYYY")),
    } as any)
    .nullable()
    .typeError("Please enter Company Incorporation Date")
    .max(
      moment().add(100, "years").toDate(),
      () => `Please enter a valid Company Incorporation Date`
    ),
  city: Yup.string().nullable(),
});
export const validationSchemaLeadPartner = Yup.object().shape({
  name: Yup.string().required("Company Name is required"),
  country: Yup.string().required("Country is required"),
});

export function AdditionalDetailsKeyMappings(): Record<string, string> {
  const default_fields: Record<string, string> = {
    annual_revenue: "Annual Revenue",

    industry: "Industry",
    goods_category: "Goods Category",
  };

  return default_fields;
}
