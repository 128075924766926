import { Box, Grid } from "@mui/material";
import { PlatformUsage, useGetPlatformUsageQuery } from "api/api-marketing";
import { PLATFORM_USAGE_CATEGORIES } from "codes";
import Drop from "components/Common/Drop";
import SkeletonLoad from "components/Common/SkeletonLoad";
import groupBy from "helpers/groupBy";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useIsMobile from "hooks/useIsMobile";
import React from "react";

const MonthlyChart = () => {
  const isMobile = useIsMobile();
  const {
    data: platformUsage,
    isFetching,
    isLoading,
    refetch,
  } = useGetPlatformUsageQuery(null);

  // Function to extract values from the selected object
  const extractValues = (selectedObject: PlatformUsage):number[] =>{
        return Object.values(selectedObject).slice(2) as number[];
  };

  // Function to find the selected object from an array based on the label
  const findSelectedObject = (dataArray: PlatformUsage[], selectedValue: string) => {
    return dataArray.find((obj: PlatformUsage) => obj.label === selectedValue);
  };

  const data = Array.isArray(platformUsage) ? platformUsage : [];

  const grouped = groupBy(data, "source");

  const date_select = grouped["Marketing Leads"]?.map((item) => ({
    name: item.label,
  }));

  const [date, setDate] = React.useState(date_select?.[0]?.name);
  const [marketingValues, setMarketingValues] = React.useState<number[]>(extractValues(grouped["Marketing Leads"]?.[0] ?? []));
  const [originationValues, setOriginationValues] = React.useState<number[]>(extractValues(grouped["Origination Leads"]?.[0] ?? []));

  const setter = (value: string) => {
    const selectedValue = value;
    const selectedObjectMarketing = findSelectedObject(
      grouped["Marketing Leads"],
      selectedValue
    );
    const selectedObjectOrigination = findSelectedObject(
      grouped["Origination Leads"],
      selectedValue
    );

    if (selectedObjectMarketing && selectedObjectOrigination) {
      const marketingValuesArray = extractValues(
        selectedObjectMarketing
      ) as number[];
      const originationValuesArray = extractValues(
        selectedObjectOrigination
      ) as number[];
      setMarketingValues(marketingValuesArray);
      setOriginationValues(originationValuesArray);
    }
    setDate(selectedValue);
  };

  const options: Highcharts.Options = {
    chart: {
      type: "column",
    },
    title: {
      text: `Platform Usage Statistics - ${date ?? ""}`,
    },
    xAxis: {
      categories: PLATFORM_USAGE_CATEGORIES,
    },
    series: [
      {
        type: "column",
        name: "Marketing Leads",
        data: marketingValues,
      },
      {
        type: "column",
        name: "Origination Leads",
        data: originationValues,
      },
    ],
  };

  return (
    <Box sx={{ display: "flex" }} flexGrow={1}>
      {isFetching && !platformUsage && (
        <SkeletonLoad bars={10} />
      )}
      {!isFetching &&
            platformUsage !== undefined &&
            Boolean(platformUsage?.length) &&<Grid container>
        <Grid item xs={12}>
          <Drop
            multilevel={false}
            data={date_select}
            name={"platFormUsageDate"}
            value={date}
            keyValue={"name"}
            setValue={setter}
            placeholder={`Select Date`}
            direction={"row"}
          />
        </Grid>
        <Grid item xs={12}>
         
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                allowChartUpdate
              />
        </Grid>
      </Grid>}
    </Box>
  );
};

export default MonthlyChart;
