export default function setWithExpiry(
  key: string,
  value: any,
  expiration: any
) {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + expiration * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
}
