import axios from "axios";
import { VITE_API_V2_URL } from "codes";
import { isLocalhost } from "helpers/isLocalhost";
import { getDeviceID } from "lib/webPush";
import { version } from "../package.json";

const isPrivateBrowsing = window.matchMedia("(private-browsing: true)").matches;

const env = import.meta.env.VITE_ENV;
const VAPID_PUBLIC_KEY = import.meta.env.VITE_VAPID_PUBLIC_KEY;
const debug = Boolean(env === "local" || env === "dev");

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

function registerValidSW(swUrl: string, config?: Config) {
  if ("serviceWorker" in navigator) {
    navigator?.serviceWorker
      .getRegistration(swUrl)
      .then((registration) => {
        if (registration) {
          // eslint-disable-next-line no-unused-expressions
          debug &&
            // eslint-disable-next-line no-console
            console.log("ServiceWorker is already registered");
          // Execute callback
          if (config && config.onSuccess) {
            config.onSuccess(registration);
          }
        } else {
          navigator?.serviceWorker
            // eslint-disable-next-line no-shadow
            .register(swUrl)
            // eslint-disable-next-line no-shadow
            .then((registration) => {
              registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                  return;
                }
                installingWorker.onstatechange = () => {
                  if (installingWorker.state === "installed") {
                    if (navigator?.serviceWorker.controller) {
                      // At this point, the updated precached content has been fetched,
                      // but the previous service worker will still serve the older
                      // content until all client tabs are closed.
                      // eslint-disable-next-line no-unused-expressions
                      debug &&
                        // eslint-disable-next-line no-console
                        console.log(
                          "New content is available and will be used when all tabs for this page are closed."
                        );

                      // Execute callback
                      if (config && config.onUpdate) {
                        config.onUpdate(registration);
                      }
                    } else {
                      // At this point, everything has been precached.
                      // It's the perfect time to display a
                      // "Content is cached for offline use." message.
                      // eslint-disable-next-line no-unused-expressions
                      debug &&
                        // eslint-disable-next-line no-console
                        console.log("Content is cached for offline use.");

                      // Execute callback
                      if (config && config.onSuccess) {
                        config.onSuccess(registration);
                      }
                    }
                  }
                };
              };
              // eslint-disable-next-line no-console
              console.log("ServiceWorker registered:", registration);
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error("Error during service worker registration:", error);
            });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Error checking service worker registration:", error);
      });
  }
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  if (!isPrivateBrowsing)
    axios
      .get(swUrl, {
        headers: { "Service-Worker": "script" },
      })
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers["content-type"];
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf("javascript") === -1)
        ) {
          // No service worker found. Probably a different app. Reload the page.
          navigator?.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed as normal.
          registerValidSW(swUrl, config);
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-unused-expressions
        debug &&
          // eslint-disable-next-line no-console
          console.log(
            "No internet connection found. App is running in offline mode."
          );
      });
}

export function register(config?: Config) {
  if (!isPrivateBrowsing)
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        const swUrl = "./sw.js";
        const LOCALHOST = "https://localhost:5173";
        if (isLocalhost) {
          // This is running on localhost. Let's check if a service worker still exists or not.
          checkValidServiceWorker(`${LOCALHOST}/${swUrl}`, config);
          navigator?.serviceWorker.ready.then(
            (registration: ServiceWorkerRegistration) => {
              // eslint-disable-next-line no-unused-expressions
              debug &&
                // eslint-disable-next-line no-console
                console.log(
                  "Service Worker registration successful with scope: ",
                  registration.scope
                );
            }
          );
        } else {
          // Is not localhost. Just register service worker
          registerValidSW(swUrl, config);
        }
      });
    }
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator?.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error.message);
      });
  }
}

export const registerWorker = () => {
  if (!isPrivateBrowsing)
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register(`/sw.js?v=${version}`)
        .then((registration) => {
          if (debug === true)
            //eslint-disable-next-line no-console
            console.log(
              "Service Worker registered with scope:",
              registration.scope
            );
        })
        .catch((error) => {
          if (debug === true)
            //eslint-disable-next-line no-console
            console.error("Service Worker registration failed:", error);
        });
    } else {
      // eslint-disable-next-line no-unused-expressions
      if (debug === true)
        //eslint-disable-next-line no-console
        console.log("No service worker in navigator");
      return undefined;
    }
};

export async function subscribeUser(contact_id: string) {
  if ("serviceWorker" in navigator) {
    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: VAPID_PUBLIC_KEY,
    });
    const JSONSubscription = subscription.toJSON();
    const body = {
      endpoint: JSONSubscription.endpoint,
      keys: JSONSubscription.keys,
      device_id: getDeviceID(),
      contact_id,
    };

    const response = await fetch(`${VITE_API_V2_URL}/subscribe`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      if (debug === true)
        //eslint-disable-next-line no-console
        console.error("Failed to subscribe the user.");
    }
  } else {
    //eslint-disable-next-line no-console
    console.error("Service workers are not supported.");
  }
}
