import { Typography, useTheme } from "@mui/material";
import {
  CL_APPROVED,
  CL_REJECTED,
  CL_REQUESTED,
  ICL_APPROVAL,
  MANUAL,
  PENDING_SALES,
  REJECTED_BY_INSURER,
  REJECTED_BY_SALES,
} from "codes";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

const CLDisplay = ({
  status,
  isSalesModule = true,
  style,
}: {
  status: string;
  isSalesModule?: boolean;
  style?: CSSProperties;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const FINAL_STATUS: Record<string, any> = {
    [CL_REJECTED]: {
      value: `${t("credit-limit-rejected")}`,
      background: "red",
    },
    [REJECTED_BY_SALES]: {
      value: "Rejected by sales",
      background: "red",
    },
    [REJECTED_BY_INSURER]: {
      value: "Rejected by insurer",
      background: "red",
    },
    [CL_REQUESTED]: isSalesModule
      ? {
          value: `Pending Insurer`,
          background: "orange",
        }
      : {
          value: `${t("pending-credit-limit-approval")}`,
          background: "orange",
        },
    [PENDING_SALES]: isSalesModule
      ? {
          value: `Pending Sales`,
          background: "orange",
        }
      : {
          value: `${t("pending-credit-limit-approval")}`,
          background: "orange",
        },
    [CL_APPROVED]: {
      value: `${t("credit-limit-approved")}`,
      background: "#9FCE63",
    },
    [ICL_APPROVAL]: {
      value: `${t("credit-limit-approved")}`,
      background: "#9FCE63",
    },
    KYC: {
      value: "KYC",
      background: "#9FCE63",
    },
    [MANUAL]: {
      value: "manual",
      background: "red",
    },
  };

  return (
    <Typography
      style={{
        background: FINAL_STATUS[status]?.background ?? "orange",
        padding: "6px 16px",
        borderRadius: theme.shape.borderRadius * 2.5,
        color: "white",
        fontWeight: "600",
        textAlign: "center",
        width: "100%",
        cursor: "not-allowed",
        fontSize: "0.8em",
        ...style,
      }}
      sx={{
        [theme.breakpoints.down("sm")]: {
          fontSize: "1em",
        },
      }}
    >
      {FINAL_STATUS[status]?.value ?? "Pending"}
    </Typography>
  );
};
export default CLDisplay;
