import EmailIcon from "@mui/icons-material/Email";
import { Grid, Link, Typography } from "@mui/material";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import styles from "../cl.module.scss";

const LabelOnly = ({
  label,
  value,
  isEmail,
  isLink,
}: {
  label: string;
  value: any;
  isEmail?: boolean;
  isLink?: boolean;
}) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <LabelViewOnly label={label} labelClassName={styles.labelClass} />
    </Grid>
    {!isLink && (
      <Grid item xs={!isEmail && !isLink && 12}>
        <Typography>{value}</Typography>
      </Grid>
    )}
    {(isEmail || isLink) && (
      <Grid item>
        {isEmail && value !== "N/A" && (
          <EmailIcon
            onClick={() => window.open(`mailto:${value}`, "_blank")}
            sx={{ cursor: "pointer" }}
          />
        )}
        {isLink && (
          <Link
            underline="always"
            onClick={() => window.open(`${value}`, "_blank")}
            sx={{ cursor: "pointer", wordBreak: "break-word" }}
          >
            {value}
          </Link>
        )}
      </Grid>
    )}
  </Grid>
);
export default LabelOnly;
