import debounce from "helpers/debounce";
import isObjectEmpty from "helpers/isObjectEmpty";
import moment from "moment";
import {
  FILTER_TYPE_IS,
  FILTER_TYPE_IS_BETWEEN,
  FILTER_TYPE_IS_NOT,
  FILTER_TYPE_ONLY,
  FILTER_TYPE_TILL,
} from "./Components/filterTypes";

export const hideColumns = ["id", "url", "ID", "URL", "disabled"];
export const currencyColumns = ["amount", "total", "price"];
export const getColumns = (data: any[]) => {
  return [...new Set(data.map((item) => Object.keys(item)).flat())];
};
interface ProcessorProps {
  data: any[];
  key: string;
  headers: string[];
  setFilteredData: React.Dispatch<React.SetStateAction<any[]>>;
}
// prettier-ignore
export const dynamicSearch = <T, >(props: ProcessorProps) => {
  const { data, key, setFilteredData } = props;
  const lowercaseKey = key.toLowerCase();
  const updatedHeaders = Object.keys(data[0]);

  const preserveOrder = (sortedArray: T[]) =>
    sortedArray.map((item) => {
      const result = updatedHeaders?.reduce(
        (acc: Record<string, any>, header: string) => {
          acc[header] = item[header as keyof typeof item];
          return acc;
        },
        {} as Record<string, any>
      );

      return result;
    });

  const search = () => {
    const searchResult = data.filter((item) =>
      Object.values(item as object).some((sub) =>
        `${sub}`.toLowerCase().includes(lowercaseKey)
      )
    );
    setFilteredData(key === "" ? data : (preserveOrder(searchResult) as T[]));
  };

  // Debounce the search function
  const debouncedSearch = debounce(search, 300);

  // Call the debounced search function on input change
  debouncedSearch();
};

const standardizeDate = (val: string) => moment(val).format("YYYY-MM-DD");
type Filters = Record<string, { comparator: string; keywords: any }>;
// prettier-ignore
export const filterAction = <T, >(filters: Filters, data: T[]) => {
  if (isObjectEmpty(filters)) return data;

  return data.filter((item) =>
    Object.entries(filters).every(([filter, { comparator, keywords }]) => {
      const value = item[filter as keyof typeof item];

      switch (comparator) {
        case FILTER_TYPE_IS:
          return keywords.includes(value);

        case FILTER_TYPE_IS_NOT:
          //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          return !keywords.includes(value);

        case FILTER_TYPE_ONLY:
          return value === keywords;

        case FILTER_TYPE_IS_BETWEEN:
          return value >= keywords[0] && value <= keywords[1];

        case FILTER_TYPE_TILL: {
          const target = standardizeDate(value as string);
          const startDate = standardizeDate(keywords[0]);
          const endDate = standardizeDate(keywords[1]);

          return (
            moment(target).isSameOrAfter(moment(startDate)) &&
            moment(target).isSameOrBefore(moment(endDate))
          );
        }

        default:
          //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          return !!value;
      }
    })
  );
};
// prettier-ignore
export const sort = <T, >(
  filteredData: T[],
  setFilteredData: React.Dispatch<React.SetStateAction<T[]>>,
  type: string,
  fieldName: string,
  headers: string[],
  order: "ASC" | "DSC"
) => {
  const retain = (sortedArray: any[]): Record<string, any> =>
    sortedArray.map((item) =>
      Object.fromEntries(
        headers.map((header) => [
          header,
          item[header] === "Not defined" ? "" : item[header],
        ])
      )
    );

  const comparator = (a: any, b: any) => {
    const compareValue = (field: string) => {
      if (type === "number") return Number(a[field]) - Number(b[field]);
      if (type === "date") return moment(a[field]).diff(moment(b[field]));

      return String(a?.[field])?.localeCompare(String(b?.[field]), undefined, {
        sensitivity: "base",
      });
    };

    return order === "ASC" ? compareValue(fieldName) : -compareValue(fieldName);
  };

  const sortedData = [...filteredData].sort(comparator);
  const retainedOrder = retain(sortedData);
  setFilteredData(retainedOrder as T[]);
};
