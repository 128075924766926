import { Breadcrumbs, Link } from "@mui/material";
import {} from "react-router-dom";

interface BreadCrumb {
  name: string;
  link: string;
  active?: boolean;
}
export interface BreadcrumbsProps {
  /**Array of Breadcrumb objects */
  data: BreadCrumb[];
}

const BreadcrumbsComponent = (props: BreadcrumbsProps) => {
  const { data } = props;

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {data &&
        data.map((breadcrumb, i) => (
          <Link
            key={i}
            underline="hover"
            color={breadcrumb.active ? "inherit" : "text.primary"}
            href={breadcrumb.link}
          >
            {breadcrumb.name}
          </Link>
        ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
