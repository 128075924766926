import * as Sentry from "@sentry/react";
import Loader from "components/Common/Loader";
import Floater from "components/Floater";
import i18n from "helpers/i18n";
import Error from "pages/error";
import { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { Outlet } from "react-router-dom";

const Auth = () => {
  
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Error
          error={error as Error}
          componentStack={componentStack}
          resetError={resetError}
          scope={"Auth"}
        />
      )}
      beforeCapture={(scope) => {
        scope.setTag("location", "Auth");
      }}
    >
      <Suspense fallback={<Loader open />}>
        <I18nextProvider i18n={i18n}>
          <Outlet />
          <Floater shouldShowFloater={true} />
        </I18nextProvider>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};

export default Auth;
