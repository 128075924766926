import { Skeleton, SkeletonProps, Stack } from "@mui/material";

interface SkeletonLoad extends SkeletonProps {
  bars?: number;
}
const SkeletonLoad = ({ bars = 5, ...rest }: SkeletonLoad) => {
  const iteration = new Array(bars).fill(true);

  return (
    <Stack
      width={"100%"}
      useFlexGap
      spacing={1}
      flexWrap="wrap"
      data-testid="skeleton-load"
    >
      {iteration.map((skeleton, i) => (
        <Skeleton
          key={`${i}-${Date.now()}`}
          variant="rectangular"
          sx={{ minWidth: 100, minHeight: "5ch" }}
          {...rest}
        />
      ))}
    </Stack>
  );
};
export default SkeletonLoad;
