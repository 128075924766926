import { Grid } from "@mui/material";
import { useGetScreeningStatusQuery } from "api/api-compliance";
import { MapContext } from "pages/maps/map";
import React, { useContext } from "react";
import ComplianceScoringApproval from "./Approval";
import ComplianceScoringChecklist from "./Checklist";
import ComplianceScoringRisk from "./ScoringMatrix";
interface ComplianceScoringProps {
  accountType: string;
  isLocked: boolean;
}

const ComplianceScoring: React.FC<ComplianceScoringProps> = ({
  accountType,
  isLocked,
}) => {
  const mapName = useContext(MapContext);

  const { data: screeningStatusData, isLoading: screeningStatusLoading } =
    useGetScreeningStatusQuery({
      map_name: mapName ?? "",
      account_type: accountType,
    });

  return (
    <Grid container spacing={2}>
      {/* Checklist section */}
      <Grid item xs={12}>
        <ComplianceScoringChecklist
          accountType={accountType}
          isLocked={isLocked}
          screeningStatusData={screeningStatusData ?? {}}
          screeningStatusLoading={screeningStatusLoading}
        />
      </Grid>

      {/* Risk Scoring section */}
      <Grid item xs={12} md={6}>
        <ComplianceScoringRisk accountType={accountType} />
      </Grid>
      <Grid item xs={12} md={6}>
        <ComplianceScoringApproval
          accountType={accountType}
          screeningStatusData={screeningStatusData ?? {}}
        />
      </Grid>
    </Grid>
  );
};

export default ComplianceScoring;
