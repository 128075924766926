import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useEditMapSummaryMutation,
  useEditMapTransactionDetailsMutation,
  useGetMapSummaryQuery,
  useGetMapTransactionValuesQuery,
  useGetTemplatesQuery,
} from "api/api-maps";
import AutoComplete from "components/Common/AutoComplete";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik, FormikProps } from "formik";
import toCamelCase from "helpers/camelCase";
import useTradeTableValueGenerator from "hooks/useTradeTableValueGenerator";
import { MapContext } from "pages/maps/map";
import { PERMISSIONS } from "permissions";
import React from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import styles from "../maps.module.scss";

interface MapTemplate {
  [field_name: string]: {
    id: string;
    name: string;
    type: string;
    log_id: string;
    data: string | number | string[];
  };
}

const TRANSACTION_DETAIL_ENUM = [
  "As per Supplier Call",
  "As per Buyer Call",
  "As per Trade Documents/Track Record",
  "As per Client’s input on Platform",
];

interface TRANSACTION_DETAIL_CRITERIA extends Record<string, any> {
  "As per Supplier Call": MapTemplate;
  "As per Buyer Call": MapTemplate;
  "As per Trade Documents/Track Record": MapTemplate;
  "As per Client’s input on Platform": MapTemplate;
}

const Transactions = () => {
  const theme = useTheme();
  const item_type = "transactions";
  const id = React.useContext(MapContext);
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const READ_ONLY = USER_PERMISSIONS?.includes(PERMISSIONS.map_view_risk);

  const {
    data: transactionsTemplate,
    isLoading: isLoadingTemplate,
    refetch,
  } = useGetTemplatesQuery(item_type);

  const {
    data: mapRiskParams,
    isLoading: isLoadingMapRiskParams,
    refetch: refetchValues,
  } = useGetMapTransactionValuesQuery(
    {
      map_name: id ?? "",
    },
    { skip: !id }
  );
  const [editMapTransaction] = useEditMapTransactionDetailsMutation();
  const { data: mapData, isLoading } = useGetMapSummaryQuery(id ?? "", {
    skip: !id,
  });

  const [editMapSummary] = useEditMapSummaryMutation();
  const editor = (field: string, value: any, friendlyLabel: string) => {
    id &&
      editMapSummary({
        map_name: id ?? "",
        data: { [field]: value },
      })
        .unwrap()
        .then(() => {
          toast.success(`${friendlyLabel} Updated`);
        })
        .catch((err) => toast.error(`Error: ${err?.message}`));
  };

  const transactionCriteria = useTradeTableValueGenerator(
    TRANSACTION_DETAIL_ENUM,
    mapRiskParams,
    transactionsTemplate,
    isLoadingMapRiskParams
  );

  const InputComponent = <T extends object>(props: {
    type: string;
    name: string;
    value: any;
    data?: string[];
    formikProps: FormikProps<T>;
    disabled?: boolean;
    noHover?: boolean;
    handleSave: (value: any) => void;
  }) => {
    switch (props?.type) {
      case "multi":
        const isStringOrArray = (value: string | string[]) => {
          if (!value) return [];
          if (typeof value === "string") return [value];
          return value;
        };
        return (
          <AutoComplete
            id={`${props?.name}-filter-combo-box`}
            name={props?.name}
            data={props?.data?.map((item) => ({ value: item })) ?? []}
            handleSave={(value) => {
              const finalValue = value?.map((item) => {
                if (typeof item !== "string") return item.value;
                return item;
              });
              props?.formikProps?.setFieldValue(props?.name, finalValue);
              props?.handleSave(finalValue);
            }}
            labelKey="value"
            value={isStringOrArray(props?.value)}
            limitTags={1}
            multiple
            disabled={props?.disabled}
          />
        );
      case "date":
        return (
          <VariableInput
            name={props?.name}
            label={undefined}
            labelClassName={styles.labelClass}
            type={"date"}
            value={props?.value ? new Date(props?.value) : undefined}
            handleSave={props?.handleSave}
            disabled={props?.disabled}
            noHover={props?.noHover}
            {...props?.formikProps}
          />
        );
      case "text":
        return (
          <VariableInput
            name={props?.name}
            label={undefined}
            labelClassName={styles.labelClass}
            type={"text"}
            value={props?.value}
            handleSave={props?.handleSave}
            disabled={props?.disabled}
            noHover={props?.noHover}
            {...props?.formikProps}
          />
        );
      case "drop":
        const data = (props?.data ?? []).map((item) => ({ value: item }));
        return (
          <VariableDropdown
            name={props?.name}
            keyValue={"value"}
            data={data}
            value={props?.value}
            handleSave={props?.handleSave}
            setValue={(value) =>
              props?.formikProps?.setFieldValue(props?.name, value)
            }
            disabled={props?.disabled}
            noHover={props?.noHover}
          />
        );
      default:
        return (
          <VariableInput
            name={props?.name}
            label={undefined}
            labelClassName={styles.labelClass}
            type={"text"}
            value={props?.value}
            handleSave={props?.handleSave}
            disabled={props?.disabled}
            noHover={props?.noHover}
            {...props?.formikProps}
          />
        );
    }
  };

  return (
    <Layout
      title={"Transaction Details"}
      headerConfig={{
        syncAction: () => {
          refetchValues();
          refetch();
        },
        left: 12,
        mid: 0,
        right: 0,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isLoading && <SkeletonLoad bars={2} />}
          {!isLoading && mapData && (
            <Formik
              initialValues={{
                transaction_extra_info: mapData?.transaction_extra_info,
              }}
              onSubmit={() => {}}
            >
              {(formikProps) => (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "1em",
                          p: 2,
                          bgcolor: theme.palette.primary.main,
                          borderRadius: 1,
                          color: theme.palette.background.default,
                        }}
                      >
                        Relation between Buyer & Supplier
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <VariableInput
                        labelClassName={styles.labelClassComment}
                        sx={{
                          ".MuiInputBase-root": {
                            height: "15vh",
                            backgroundClip: "border-box",
                            borderRadius: "10px",
                            textarea: {
                              height: "13vh !important",
                              overflow: "scroll !important",
                            },
                          },
                        }}
                        name={"transaction_extra_info"}
                        placeholder={"Enter your observations here..."}
                        label={""}
                        className={styles.formInput}
                        value={formikProps.values.transaction_extra_info}
                        multiline
                        style={{ width: "100%" }}
                        handleSave={(value) => {
                          formikProps.setFieldValue(
                            "transaction_extra_info",
                            value
                          );
                          editor("transaction_extra_info", value, "Comments");
                        }}
                        disabled={READ_ONLY}
                        noHover={READ_ONLY}
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
        </Grid>

        <Grid item xs={12}>
          {isLoadingTemplate && <SkeletonLoad bars={10} />}
          {!isLoadingTemplate && transactionsTemplate && (
            <Formik
              enableReinitialize
              initialValues={transactionCriteria}
              onSubmit={() => {}}
            >
              {(formikProps) => {
                return (
                  <Form>
                    <Table component={Paper}>
                      <TableHead
                        sx={{
                          borderBottom: `3px solid ${theme.palette.error.main}`,
                        }}
                      >
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography fontSize={"1em"} fontWeight={"bold"}>
                              As per Supplier Call
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={"1em"} fontWeight={"bold"}>
                              As per Buyer Call
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={"1em"} fontWeight={"bold"}>
                              As per Trade Documents/Track Record
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={"1em"} fontWeight={"bold"}>
                              As per Client’s input on Platform
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transactionsTemplate &&
                          Object.entries(transactionsTemplate).map(
                            ([key, keyParams]) => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      fontSize={"1em"}
                                      fontWeight={"bold"}
                                    >
                                      {key}
                                    </Typography>
                                  </TableCell>
                                  {Object.entries(formikProps.values).map(
                                    ([asPerCategory, observation], index) => {
                                      const value = observation[
                                        toCamelCase(key)
                                      ] as any;
                                      const fieldName = `[${asPerCategory}].${toCamelCase(key)}`;
                                      return (
                                        <TableCell>
                                          <InputComponent<
                                            typeof transactionCriteria
                                          >
                                            type={keyParams?.type}
                                            name={fieldName}
                                            value={value}
                                            data={keyParams?.data as string[]}
                                            formikProps={formikProps}
                                            disabled={READ_ONLY}
                                            noHover={READ_ONLY}
                                            handleSave={(value) => {
                                              id &&
                                                editMapTransaction({
                                                  map_name: id,
                                                  data: {
                                                    map_metadata_id:
                                                      keyParams?.id,
                                                    source: asPerCategory,
                                                    value,
                                                  },
                                                });
                                            }}
                                          />
                                        </TableCell>
                                      );
                                    }
                                  )}
                                </TableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading && <SkeletonLoad bars={2} />}
          {!isLoading && mapData && (
            <Formik
              initialValues={{
                transaction_comments: mapData?.transaction_comments,
              }}
              onSubmit={() => {}}
            >
              {(formikProps) => (
                <Form>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "1em",
                          p: 2,
                          bgcolor: theme.palette.primary.main,
                          borderRadius: 1,
                          color: theme.palette.background.default,
                        }}
                      >
                        Additional Comments
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <VariableInput
                        labelClassName={styles.labelClass}
                        sx={{
                          ".MuiInputBase-root": {
                            height: "15vh",
                            backgroundClip: "border-box",
                            borderRadius: "10px",
                            textarea: {
                              height: "13vh !important",
                              overflow: "scroll !important",
                            },
                          },
                        }}
                        disabled={READ_ONLY}
                        noHover={READ_ONLY}
                        name={"transaction_comments"}
                        placeholder={"Enter your observations here..."}
                        label={""}
                        className={styles.formInput}
                        value={formikProps.values.transaction_comments}
                        multiline
                        style={{ width: "100%" }}
                        handleSave={(value) => {
                          formikProps.setFieldValue(
                            "transaction_comments",
                            value
                          );
                          editor("transaction_comments", value, "Comments");
                        }}
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};
export default Transactions;
