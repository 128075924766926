import { Theme } from "@mui/material/styles";
// @ts-ignore
import InterRegular from "../assets/fonts/Inter-Regular.otf";
// @ts-ignore
import InterBold from "../assets/fonts/Inter-SemiBold.otf";
// @ts-ignore
import InterBolder from "../assets/fonts/Inter-ExtraBold.otf";

export default function componentStyleOverrides() {
  const borderRadius = 10;
  return {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius,
          },
        },
      },

      MuiCardHeader: {
        styleOverrides: {
          // eslint-disable-next-line no-shadow
          root: ({ theme }: { theme: Theme }) => ({
            color: theme.vars.palette.text.primary,
            padding: "24px",
          }),
          title: {
            fontSize: "1.125rem",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "24px",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          // eslint-disable-next-line no-shadow
          root: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.vars.palette.field.main,
          }),
          // eslint-disable-next-line no-shadow
          input: ({ theme }: { theme: Theme }) => ({
            borderRadius,
            backgroundColor: theme.vars.palette.field.main,
            "&::placeholder": {
              color: theme.vars.palette.field.contrastText,
            },
          }),
        },
      },
      MuiSlider: {
        styleOverrides: {
          // eslint-disable-next-line no-shadow
          root: ({ theme }: { theme: Theme }) => ({
            ".MuiSlider-thumb": {
              top: "45%",
              [theme.breakpoints.down("sm")]: {
                top: "28%",
              },
            },
          }),
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            paddingLeft: 15,
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            borderRadius,
            alignItems: "flex-start",
            ".MuiInputLabel-root": {
              transform: "translate(14px, 10px) scale(1)",
            },
            ".MuiInputLabel-shrink": {
              transform: "translate(14px, -9px) scale(0.75)",
            },
            ".MuiAutocomplete-tag": {
              height: "2.3em",
              transform: "translateY(-4px)",
            },
          },
          inputRoot: {
            height: "3.7em",
            borderRadius,
            alignItems: "flex-end",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            "&.MuiTextField-root": {
              borderRadius,
              marginTop: 0,
            },
            "&.Mui-disabled": {
              height: "auto",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          // eslint-disable-next-line no-shadow
          root: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.vars.palette.field.main,
            borderRadius,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.vars.palette.primary.main, //this is the actual outline border
              borderRadius,
            },
            "&:hover $notchedOutline": {
              borderColor: theme.vars.palette.primary.dark,
              borderRadius,
            },
            "&.MuiInputBase-multiline": {
              padding: 1,
            },
            "&.::placeholder": {
              fontWeight: "bold",
              opacity: 0.5,
              color: "red",
            },
          }),
          // eslint-disable-next-line no-shadow
          input: ({ theme }: { theme: Theme }) => ({
            color: theme.vars.palette.field.contrastText,
            fontWeight: 500,
            backgroundColor: theme.vars.palette.field.main,
            padding: "4.5px 15px",
            borderRadius,
            "&.MuiInputBase-inputSizeSmall": {
              padding: "4.5px 15px",
              "&.MuiInputBase-inputAdornedStart": {
                paddingLeft: 0,
              },
            },
          }),
          inputAdornedStart: {
            paddingLeft: 4,
          },
          notchedOutline: {
            borderRadius,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          // eslint-disable-next-line no-shadow
          root: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.vars.palette.divider,
            opacity: 1,
          }),
        },
      },

      MuiChip: {
        styleOverrides: {
          root: {
            "&.MuiChip-deletable .MuiChip-deleteIcon": {
              color: "inherit",
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          // eslint-disable-next-line no-shadow
          tooltip: ({ theme }: { theme: Theme }) => ({
            color: theme.vars.palette.background.paper,
            background: theme.vars.palette.grey[700],
          }),
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            height: "fit-content",
            overflow: "auto !important",
          },
          // eslint-disable-next-line no-shadow
          "::MozSelection": ({ theme }: { theme: Theme }) => ({
            color: theme.vars.palette.background.default,
            background: theme.vars.palette.primary.main,
          }),
          // eslint-disable-next-line no-shadow
          "::selection": ({ theme }: { theme: Theme }) => ({
            color: theme.vars.palette.background.default,
            background: theme.vars.palette.primary.main,
          }),
          "@supports (padding: max(0px))": {
            body: {
              paddingLeft: "min(0vmin, env(safe-area-inset-left))",
              paddingRight: "min(0vmin, env(safe-area-inset-right))",
            },
            header: {
              paddingLeft: "min(0vmin, env(safe-area-inset-left))",
              paddingRight: "min(0vmin, env(safe-area-inset-right))",
            },
            footer: {
              paddingLeft: "min(0vmin, env(safe-area-inset-left))",
              paddingRight: "min(0vmin, env(safe-area-inset-right))",
            },
          },
          "@font-face": [
            {
              fontFamily: "'Inter'",
              fontStyle: "normal",
              fontDisplay: "swap",
              fontWeight: 400,
              src: `local('Inter'), local('Inter-Regular'), url(${InterRegular}) format('opentype')`,
              unicodeRange:
                "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
            },
            {
              fontFamily: "'Inter'",
              fontStyle: "normal",
              fontDisplay: "swap",
              fontWeight: "bold",
              src: `local('Inter'), local('Inter-Bold'), url(${InterBold}) format('opentype')`,
              unicodeRange:
                "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
            },
            {
              fontFamily: "'Inter'",
              fontStyle: "normal",
              fontDisplay: "swap",
              fontWeight: "bolder",
              src: `local('Inter'), local('Inter-Bold'), url(${InterBolder}) format('opentype')`,
              unicodeRange:
                "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
            },
          ],
        },
      },
    },
  };
}
