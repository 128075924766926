import toCamelCase from "./camelCase";

export default function objectCamelcaser(
  object: Record<string, any>
): Record<string, any> {
  return Object.keys(object).reduce(
    (acc, key) => {
      const newKey = toCamelCase(key);
      acc[newKey] = object[key];
      return acc;
    },
    {} as Record<string, any>
  );
}
