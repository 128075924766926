import { Box, Container, Stack } from "@mui/material";
import { useGetAllDepartmentsQuery } from "api/api-it";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { useFormikContext } from "formik";

interface Ticket {
  title: string;
  content: string;
  priority_code_id?: string;
  assignee_department_id: string;
  estimate_min?: string;
}

const CreateTicket = () => {
  const formikProps = useFormikContext<Ticket>();

  const { data: departments, isFetching: isFetchingDepartments } =
    useGetAllDepartmentsQuery(null);

  const setter = (value: string) => {
    const department_id = departments?.find((dep) => dep.name === value)?.id;
    formikProps.setFieldValue("assignee_department_id", department_id, true);
  };
  return (
    <Box width={"100%"}>
      <Container>
        <Stack>
          <Input name={"title"} label={"Title"} {...formikProps} />

          <Input
            name={"content"}
            label={"Content"}
            {...formikProps}
            multiline
          />

          {isFetchingDepartments && <SkeletonLoad bars={1} />}
          {!isFetchingDepartments && departments && (
            <Drop
              name={"department_id"}
              label={"Department"}
              keyValue="name"
              data={departments}
              setValue={setter}
              {...formikProps}
            />
          )}
        </Stack>
      </Container>
    </Box>
  );
};
export default CreateTicket;
