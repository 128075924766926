import { styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { PERMISSIONS } from "permissions";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { useAppSelector } from "redux/hooks";
import Compliance from "./Compliance";
import Legal from "./Legal";
import Operations from "./Operations";
import Origination from "./Origination";
import Risk from "./Risk";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `map-sections-tab-${index}`,
    "aria-controls": `map-sections-tabpanel-${index}`,
  };
}
const TabPanelDiv = styled("div")`
  width: -webkit-fill-available;
  width: fill-available;
  width: -moz-available;
`;

const TAB_ORDER: Record<string, number> = {
  summary: 0,
  risk: 1,
  legal: 2,
  compliance: 3,
  origination: 4,
};
const MapSections = () => {
  const tab = location.search?.split("=")[1];
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );

  const disabled = !USER_PERMISSIONS.includes(PERMISSIONS.map_admin);

  const [value, setValue] = React.useState(tab ? TAB_ORDER[tab] : 0);
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <TabPanelDiv
        role="tabpanel"
        hidden={value !== index}
        id={`map-sections-tabpanel-${index}`}
        aria-labelledby={`map-sections-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              p: 3,
              maxWidth: { xs: 200, lg: "lg" },
              background: theme.palette.background.default,
              border: "1px solid",
              borderColor: theme.palette.divider,
              borderRadius: 1,
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            {children}
          </Box>
        )}
      </TabPanelDiv>
    );
  }

  const DEPARTMENTS = [
    {
      name: "Summary",
      component: Operations,
      disabled: false,
    },
    {
      name: "Risk",
      component: Risk,
      disabled:
        !USER_PERMISSIONS.includes(PERMISSIONS.map_access_risk) &&
        !USER_PERMISSIONS.includes(PERMISSIONS.map_view_risk),
    },
    {
      name: "Legal",
      component: Legal,
      disabled:
        !USER_PERMISSIONS.includes(PERMISSIONS.map_access_legal) &&
        !USER_PERMISSIONS.includes(PERMISSIONS.map_view_legal),
    },
    {
      name: "Compliance",
      component: Compliance,
      disabled:
        !USER_PERMISSIONS.includes(PERMISSIONS.map_access_compliance) &&
        !USER_PERMISSIONS.includes(PERMISSIONS.map_view_compliance),
    },
    {
      name: "Origination",
      component: Origination,

      disabled,
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        borderRadius: 1,
        height: { xs: "100%", lg: "auto" },
      }}
    >
      <Tabs
        orientation={isMobile ? "horizontal" : "vertical"}
        variant="scrollable"
        scrollButtons
        value={value}
        onChange={handleChange}
        aria-label="map tabs"
        sx={{
          borderRight: 1,
          borderColor: "divider",
          width: { xs: 100, lg: 200 },
        }}
      >
        {DEPARTMENTS.map((department, index) => (
          <Tab
            label={department.name}
            {...a11yProps(index)}
            key={index}
            data-testid={department.name}
            disabled={department.disabled}
          />
        ))}
      </Tabs>
      {DEPARTMENTS.map((department, index) => {
        const Component = department.component;
        return (
          <TabPanel value={value} index={index} key={index}>
            <Component />
          </TabPanel>
        );
      })}
    </Box>
  );
};
export default MapSections;
