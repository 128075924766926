import {
  ButtonProps,
  Grid,
  GridProps,
  GridTypeMap,
  StepLabel,
  StepLabelProps,
  SxProps,
} from "@mui/material";
import Step, { StepTypeMap } from "@mui/material/Step";
import Stepper, { StepperProps } from "@mui/material/Stepper";
import Typography, { TypographyProps } from "@mui/material/Typography";
import React from "react";

export interface StepperCompProps extends StepperProps {
  /**Array of Step Data */
  step: number;
  label: string;
  maxSteps: number;
  children: React.ReactNode;
  labels: string[];
  /**Define back button props, a secondary-button component */
  backButton?: ButtonProps;
  /**Define next button props, a primary-button component */
  nextButton?: ButtonProps;

  /**Define whether its optional */
  optionalStep?: boolean;
  /**Define activeStep is completable */
  stepProps?: StepTypeMap;
  canComplete?: boolean;

  /**Define reset button props, a secondary-button component */
  resetButton?: ButtonProps;
  /**if `false`, the action panel will be hidden */

  containerSx?: SxProps;
  contentSx?: SxProps;
  contentSpacing?: GridTypeMap["props"]["spacing"];
  buttonTraySx?: SxProps;
  labelProps?: StepLabelProps;
  mobileHeaderProps?: TypographyProps;
  showMobileHeader?: boolean;
  postComplete?: () => void;
  CustomFooter?: JSX.Element;
  useMobileStepper?: boolean;
  postCompleteButton?: ButtonProps;
  CompletionScreen?: JSX.Element;
  parentContainerProps?: GridProps;
  defaultNextButton?: ButtonProps;
  defaultBackButton?: ButtonProps;
}

const StepperComponent = (props: StepperCompProps) => {
  const {
    step,
    resetButton,
    maxSteps,
    label,
    children,
    labels,
    nonLinear,
    optionalStep,
    containerSx,
    contentSx,
    contentSpacing = 4,
    buttonTraySx,
    labelProps,
    stepProps,
    canComplete,
    mobileHeaderProps,
    showMobileHeader = true,
    postComplete,
    postCompleteButton,
    CustomFooter = <></>,

    CompletionScreen = <Typography>All Steps Completed!</Typography>,
    parentContainerProps,
    defaultNextButton,
    defaultBackButton,
    ...stepperProps
  } = props;

  return (
    <Grid container sx={containerSx} {...parentContainerProps}>
      <Grid item xs={12} id="activeStep-label">
        <Stepper
          alternativeLabel
          nonLinear={nonLinear}
          activeStep={step}
          {...stepperProps}
        >
          {labels.map((activeStep, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{activeStep}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      {step === maxSteps ? (
        <Grid item xs={12}>
          <Grid container sx={contentSx} spacing={contentSpacing}>
            <Grid item xs={12}>
              {CompletionScreen}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} id="activeStep-body">
          <Grid container sx={contentSx} spacing={contentSpacing}>
            <Grid item xs={12} id="activeStep-content">
              {children}
            </Grid>
            {CustomFooter}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default StepperComponent;
