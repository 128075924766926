interface MapApprover {
  contact_id: string;
  approver_name: string;
  approval_date: string;
  approval_status: string;
  comments: string;
}
interface ComplianceApprovals {
  onboarding_officer_decision: MapApprover;
  compliance_officer_decision: MapApprover;
  head_compliance_decision: MapApprover;
  [key: string]: MapApprover;
}

export const initialValues: ComplianceApprovals = {
  onboarding_officer_decision: {
    contact_id: "",
    approver_name: "",
    approval_date: "",
    approval_status: "",
    comments: "",
  },
  compliance_officer_decision: {
    contact_id: "",
    approver_name: "",
    approval_date: "",
    approval_status: "",
    comments: "",
  },
  head_compliance_decision: {
    contact_id: "",
    approver_name: "",
    approval_date: "",
    approval_status: "",
    comments: "",
  },
};

export const KEY_PHASE: Record<string, string> = {
  onboarding_officer_decision: "Prepared by Onboarding Officer",
  compliance_officer_decision: "Verified by Compliance Officer",
  head_compliance_decision: "Approved by Group Head of Compliance",
};

export const ROLE_KEY_VALUE: Record<string, string>[] = [
  {
    name: "Prepared by Onboarding Officer",
    value: "onboarding_officer_decision",
  },
  {
    name: "Verified by Compliance Officer",
    value: "compliance_officer_decision",
  },
  {
    name: "Approved by Group Head of Compliance",
    value: "head_compliance_decision",
  },
];
