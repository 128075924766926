import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { BankAccount } from "types";
import { accountrtk } from "./api-accounts";

const formatBankAccount = (banks: BankAccount[]): any[] => {
  return banks?.map((bank) => {
    return {
      id: bank.id,
      bank_name: bank.bank_name,
      bank_account_number: bank.bank_account_number,
      beneficiary_name: bank.beneficiary_name,
      currency: bank.currency,
      country: bank.bank_address?.country,
      swift_code: bank.swift_code,
      iban: bank.iban,
      sf_id: bank.sf_id,
      bank_address: bank.bank_address,
    };
  });
};
const ACCOUNTS_V2 = "/accounts";
export const banks = createApi({
  reducerPath: "banks",
  tagTypes: ["BANK"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}${ACCOUNTS_V2}/`,
  }),
  endpoints: (builder) => ({
    getBankAccount: builder.query<
      BankAccount,
      { account_id: string; bankaccount?: string }
    >({
      query: ({
        account_id,
        bankaccount,
      }: {
        account_id: string;
        bankaccount?: string;
      }) => ({
        url: `${account_id}/bank-accounts/${bankaccount}`,
        method: "get",
      }),
      transformResponse: (response) => response.data,
      providesTags: (result) => [{ type: "BANK", id: result?.id }],
    }),
    getBankAccounts: builder.query<BankAccount[], string>({
      query: (account_id) => ({
        url: `${account_id}/bank-accounts`,
        method: "get",
      }),
      transformResponse: (response) => formatBankAccount(response.data),
      providesTags: ["BANK"],
    }),
    updateBankAccount: builder.mutation<
      BankAccount,
      { bankAccountId: string; data: BankAccount; account_id: string }
    >({
      query(payload) {
        const { bankAccountId, data, account_id } = payload;
        return {
          url: `${account_id}/bank-accounts/${bankAccountId}`,
          method: "PUT",
          data,
        };
      },
      invalidatesTags: ["BANK"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            accountrtk.util.invalidateTags(["ACCOUNT_ONBOARDING_STATUS"])
          );
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    registerBankAccount: builder.mutation<
      BankAccount,
      { account_id: string; data: BankAccount }
    >({
      query: ({ account_id, data }) => {
        return {
          url: `${account_id}/bank-accounts`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["BANK"],
    }),
    deleteBankAccount: builder.mutation<
      any,
      { account_id: string; bank_account_id: string }
    >({
      query: ({ account_id, bank_account_id }) => {
        return {
          url: `${account_id}/bank-accounts/${bank_account_id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["BANK"],
    }),
  }),
});

export const {
  useGetBankAccountsQuery,
  useGetBankAccountQuery,
  useUpdateBankAccountMutation,
  useRegisterBankAccountMutation,
  useDeleteBankAccountMutation,
} = banks;
