import { Container } from "@mui/material";
import * as Sentry from "@sentry/react";
import Loader from "components/Common/Loader";
import { Form, Formik } from "formik";
import { defaultValues } from "helpers/formFields";
import { validationSchemaRegistrationStep } from "helpers/formFields/registrationStep";
import Error from "pages/error";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

const RegisterLR = (): React.ReactElement => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Error
        error={error as Error}
          componentStack={componentStack}
          resetError={resetError}
          scope={"Registration routes"}
        />
      )}
      beforeCapture={(scope) => {
        scope.setTag("location", "Registration routes");
      }}
    >
      <Suspense fallback={<Loader />}>
        <Formik
          enableReinitialize
          initialValues={defaultValues}
          validationSchema={validationSchemaRegistrationStep}
          onSubmit={() => {}}
        >
          <Form>
            <Container>
              <Outlet />
            </Container>
          </Form>
        </Formik>
      </Suspense>
    </Sentry.ErrorBoundary>
  );
};
export default RegisterLR;
