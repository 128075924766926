const mockData = {
  offer: [
    { name: "Offer 1", value: "offer1" },
    { name: "Offer 2", value: "offer2" },
  ],
  proposal: [
    { name: "Proposal 1", value: "proposal1" },
    { name: "Proposal 2", value: "proposal2" },
  ],
  product: [
    { name: "Product 1", value: "product1" },
    { name: "Product 2", value: "product2" },
  ],
  subProduct: [
    { name: "Sub Product 1", value: "subProduct1" },
    { name: "Sub Product 2", value: "subProduct2" },
  ],
  specialCase: [
    { name: "Special Case 1", value: "specialCase1" },
    { name: "Special Case 2", value: "specialCase2" },
  ],
  preQualifiedBuyers: [
    { name: "Buyer 1", value: "buyer1" },
    { name: "Buyer 2", value: "buyer2" },
  ],
  expectedTradeAmount: [
    { name: "Amount 1", value: "amount1" },
    { name: "Amount 2", value: "amount2" },
  ],
  currency: [
    { name: "USD", value: "usd" },
    { name: "EUR", value: "eur" },
  ],
  annualisedDiscountRate: [
    { name: "Rate 1", value: "rate1" },
    { name: "Rate 2", value: "rate2" },
  ],
  expectedTenor: [
    { name: "Tenor 1", value: "tenor1" },
    { name: "Tenor 2", value: "tenor2" },
  ],
  facilityFee: [
    { name: "Fee 1", value: "fee1" },
    { name: "Fee 2", value: "fee2" },
  ],
  processingFee: [
    { name: "Fee 1", value: "fee1" },
    { name: "Fee 2", value: "fee2" },
  ],
  countryOfIncorporation: [
    { name: "Country 1", value: "country1" },
    { name: "Country 2", value: "country2" },
  ],
};

const termsheetsMockData = [
  {
    id: "530eca28-212c-4de3-8df5-8ca4de4ae721",
    date: "2022-01-01",
    product: "Product 1",
    subProduct: "Sub Product 1",
    specialCase: "Special Case 1",
    features: "Features 1",
    advancedAmount: 10000,
    companyName: "Company 1",
    countryOfIncorporation: "Country 1",
    tradedGoodsDescription: "Goods Description 1",
    preQualifiedBuyers: "Buyers 1",
    expectedTradeAmount: 50000,
    currency: "USD",
    annualisedDiscountRate: 0.05, // converted from '5%'
    expectedTenor: "6 months",
    facilityFee: 1000,
    processingFee: 500,
    facilitySetupAndPlatformMaintenanceFee: 200,
    benchmarkRate: 0.03, // converted from '3%'
  },
  {
    id: "828b809d-7f73-4fc0-8591-dfccbb5c3110",
    date: "2022-02-01",
    product: "Product 2",
    subProduct: "Sub Product 2",
    specialCase: "Special Case 2",
    features: "Features 2",
    advancedAmount: 20000,
    companyName: "Company 2",
    countryOfIncorporation: "Country 2",
    tradedGoodsDescription: "Goods Description 2",
    preQualifiedBuyers: "Buyers 2",
    expectedTradeAmount: 100000,
    currency: "EUR",
    annualisedDiscountRate: 0.06, // converted from '6%'
    expectedTenor: "12 months",
    facilityFee: 1500,
    processingFee: 750,
    facilitySetupAndPlatformMaintenanceFee: 300,
    benchmarkRate: 0.04, // converted from '4%'
  },
  {
    id: "3360fe5a-8703-4364-a968-dc23b290bc9d",
    date: "2022-03-01",
    product: "Product 3",
    subProduct: "Sub Product 3",
    specialCase: "Special Case 3",
    features: "Features 3",
    advancedAmount: 30000,
    companyName: "Company 3",
    countryOfIncorporation: "Country 3",
    tradedGoodsDescription: "Goods Description 3",
    preQualifiedBuyers: "Buyers 3",
    expectedTradeAmount: 150000,
    currency: "GBP",
    annualisedDiscountRate: 0.07, // converted from '7%'
    expectedTenor: "24 months",
    facilityFee: 2000,
    processingFee: 1000,
    facilitySetupAndPlatformMaintenanceFee: 400,
    benchmarkRate: 0.05, // converted from '5%'
  },
];

export default { termsheetsMockData, mockData };
