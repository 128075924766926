import history from "./history";
import isObjectEmpty from "./isObjectEmpty";

export default function auth() {
  const token: Record<string, any> = JSON.parse(
    localStorage.getItem("token") ?? "{}"
  );

  if (isObjectEmpty(token)) history.push("/login");
}
