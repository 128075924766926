import { theme } from "./theme";

export default {
  fontSize: "1em",
  fontWeight: "bold",
  padding: "0.7ch 1.2ch",
  textTransform: "inherit",
  alignItems: "center",
  justifyContent: "center",
  margin: "auto",
  height: "auto",
  [theme.breakpoints.down("md")]: {
    height: "2.3em",
    ">div": {
      height: "2.3em",
      ">input": {
        height: "0.8em",
        fontSize: "0.8em",
      },
    },
  },
};
