import { Grid, Typography } from "@mui/material";

const ZoomInfoHiddenText = () => {
  return (
    <Grid
      item
      id="about"
      sx={{ visibility: "hidden", height: 0, padding: "0 !important" }}
    >
      <Typography variant={"h2"}>For Importer</Typography>
      <Typography variant={"h3"}>Stabilise Your Supply Chain</Typography>
      <Typography>
        As an importer, you may have difficulty improving your payment credit
        terms on invoices and securing the supply chain. We provide a solution
        to improve payment terms for both suppliers and you through the
        Incomlend invoice financing marketplace.
      </Typography>

      <Typography variant={"h2"}>For Exporter</Typography>
      <Typography variant={"h3"}>Unlock Your Working Capital</Typography>
      <Typography>
        Cash in your export receivable financing to maintain sufficient working
        capital to grow your business. You can receive the amount your buyers
        owe you ahead of time through invoice factoring, where we pay you
        upfront, up to 90% of the invoice amount. Your buyers pay us later,
        while we will refund you any surplus amount after deducting the funded
        amount. The funding you receive is non-recourse, even if the buyer fails
        to pay for financial reasons.
      </Typography>

      <Typography variant={"h2"}>For Investors</Typography>
      <Typography variant={"h3"}>
        Earn a Steady and Stable, Risk-adjusted Opportunity
      </Typography>
      <Typography>
        As an investor, you plan to diversify strategies to get steady and
        stable returns while minimising risks from a synthetic fixed-income
        asset. Our solutions provide stability, diversification benefits, and
        reliability for your investments in cross-border trade receivables.
      </Typography>
    </Grid>
  );
};
export default ZoomInfoHiddenText;
