import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Grid, Slide, TextField, Typography } from "@mui/material";
import { useGetBalancesQuery } from "api/api-finance";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { currencyFormatter } from "helpers/currencyFormatter";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useRef, useState } from "react";

const BalancesPage = () => {
  const isMobile = useIsMobile();

  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;

  const [ewalletName, setEWalletName] = useState<string>();
  const [searchWallet, setSearchWallet] = useState<boolean>(false);
  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();

  const {
    data: balances,
    isFetching,
    isSuccess,
    refetch,
  } = useGetBalancesQuery(ewalletName ?? "", {
    skip: !Boolean(ewalletName) || !searchWallet,
    refetchOnMountOrArgChange: true,
  });

  const headerLength = Object.keys(balances?.[0] ?? {})?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.6 : 200;
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  useEffect(() => {}, [isSuccess]);
  return (
    <Slide in direction={"left"}>
      <Box flexGrow={1}>
        <Layout
          title={`Balances`}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            syncAction: refetch,
            left: 12,
            mid: 0,
            right: 0,
          }}
          mode="default"
          alignItems="center"
        >
          {/**transaction list */}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  justifyContent={"start"}
                  alignItems={"end"}
                >
                  <Grid item xs={12} lg={3}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setEWalletName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      loading={isFetching}
                      onClick={() => [
                        setSearchWallet(true),
                        setTimeout(() => {
                          //to accept for next input and limit calls
                          setSearchWallet(false);
                        }, 1000),
                      ]}
                    >
                      Fetch
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>

              {/**transaction list */}

              <Grid item xs={12} ref={ref}>
                <Grid container spacing={1}>
                  {isFetching && <SkeletonLoad bars={10} />}
                  {!isFetching && balances?.length ? (
                    <DataTablev2
                      rowsPerPage={rowsPerPage}
                      defaultColumnWidth={defaultColumnWidth}
                      customColumns={{
                        effective_date: {
                          effective_date: "Effective Date",
                          type: "date",
                          minWidth: 150,
                        },
                        created_date: {
                          created_date: "Created Date",
                          type: "date",
                          minWidth: 150,
                        },
                        transaction_type: {
                          transaction_type: "Type",
                          type: "string",
                          minWidth: 150,
                        },
                        ccy: {
                          ccy: "Currency",
                          type: "text",
                        },

                        fx_usd_rate_daily_amount: {
                          fx_usd_rate_daily_amount: "FX USD Rate Daily Amount",
                          type: "number",
                          renderCell: ({ value }: { value: number }) => {
                            return (
                              <Typography>
                                {currencyFormatter({
                                  amount: Number(value),
                                })}
                              </Typography>
                            );
                          },
                        },

                        invoice_finance_deal_name: {
                          invoice_finance_deal_name:
                            "Invoice Finance Deal Name",
                          type: "text",
                          minWidth: 300,
                        },
                        invoice_finance_deal_url: {
                          invoice_finance_deal_url: "Invoice Finance Deal Url",
                          type: "text",
                          minWidth: 300,
                        },
                        transaction_url: {
                          transaction_url: "Transaction Url",
                          type: "text",
                          minWidth: 300,
                        },
                        trustee: {
                          trustee: "Trustee",
                          type: "text",
                        },
                        status: {
                          status: "Status",
                          type: "text",
                          minWidth: 200,
                        },

                        posting_id: {
                          posting_id: "Posting ID",
                          type: "text",
                        },

                        amount: {
                          amount: "Amount",
                          type: "number",
                          renderCell: ({ value }: { value: number }) => {
                            return (
                              <Typography>
                                {currencyFormatter({
                                  amount: Number(value),
                                })}
                              </Typography>
                            );
                          },
                        },
                        running_balance: {
                          running_balance: "Running Balance",
                          type: "number",
                          minWidth: 200,
                          renderCell: ({ value }: { value: number }) => {
                            return (
                              <Typography>
                                {currencyFormatter({
                                  amount: Number(value),
                                })}
                              </Typography>
                            );
                          },
                        },
                        running_balance_usd: {
                          running_balance_usd: "Running Balance USD",
                          type: "number",
                          minWidth: 200,
                          renderCell: ({ value }: { value: number }) => {
                            return (
                              <Typography>
                                {currencyFormatter({
                                  amount: Number(value),
                                })}
                              </Typography>
                            );
                          },
                        },
                      }}
                      toBeHidden={["invoice_id", "transaction_id"]}
                      data={balances}
                      sx={{
                        height: "30vh",
                        overflow: "scroll",
                      }}
                    />
                  ) : (
                    <NoData
                      text="No transactions found"
                      sx={{ margin: "auto" }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};
export default BalancesPage;
