import * as React from "react";
import styles from "../layout.module.scss";

export interface LayoutBodyProps {
  /**
   * Content of body component
   */
  children: React.ReactNode;
  style?: React.CSSProperties;
}
const LayoutBody = (props: LayoutBodyProps) => {
  return (
    <main style={props?.style} className={styles.main}>
      {props.children}
    </main>
  );
};
LayoutBody.displayName = "LayoutBody";
export default LayoutBody;
