import {
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { MapGeneral } from "components/Risk/defaults";
import { useFormikContext } from "formik";

import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Insurance from "..";
import styles from "../../maps.module.scss";
import InsuranceDetails from "../Details";

const InsuranceSummary = () => {
  const formikProps = useFormikContext<MapGeneral>();
  const theme = useTheme();
  return (
    <Grid item xs={12} margin="auto">
      <Grid container spacing={4} justifyContent={"space-between"}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InsuranceDetails />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1em",
                  p: 2,
                  bgcolor: theme.palette.primary.main,
                  borderRadius: 1,
                  color: "#ffffff",
                }}
              >
                Primary Insurance
              </Typography>
            </Grid>
            <Insurance />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={2}>
              <LabelViewOnly
                label="Top-Up Insurance"
                labelClassName={styles.labelClass}
              />
            </Grid>
            <Grid item xs={10}>
              <FormGroup>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="hasTopUpInsurance"
                    name="hasTopUpInsurance"
                    value={formikProps.values.hasTopUpInsurance}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        "hasTopUpInsurance",
                        e.target.value === "true",
                        true
                      );
                    }}
                    row
                  >
                    <Grid container spacing={1}>
                      <Grid item>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          sx={{ alignItems: "center" }}
                          label={"Yes"}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          sx={{ alignItems: "center" }}
                          label={"No"}
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </Grid>
            {Boolean(formikProps.values.hasTopUpInsurance) ? (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "1em",
                      p: 2,
                      bgcolor: theme.palette.primary.main,
                      borderRadius: 1,
                      color: "#ffffff",
                    }}
                  >
                    Top-Up Insurance
                  </Typography>
                </Grid>
                <Insurance />
              </>
            ) : (
              <></>
            )}
            <Grid item xs={2}>
              <LabelViewOnly
                label="Secondary Insurance"
                labelClassName={styles.labelClass}
              />
            </Grid>
            <Grid item xs={10}>
              <FormGroup>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="hasSecondaryInsurance"
                    name="hasSecondaryInsurance"
                    value={formikProps.values.hasSecondaryInsurance}
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        "hasSecondaryInsurance",
                        e.target.value === "true",
                        true
                      );
                    }}
                    row
                  >
                    <Grid container spacing={1}>
                      <Grid item>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          sx={{ alignItems: "center" }}
                          label={"Yes"}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          sx={{ alignItems: "center" }}
                          label={"No"}
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </FormGroup>
            </Grid>
            {Boolean(formikProps.values.hasSecondaryInsurance) ? (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "1em",
                      p: 2,
                      bgcolor: theme.palette.primary.main,
                      borderRadius: 1,
                      color: "#ffffff",
                    }}
                  >
                    Secondary Insurance
                  </Typography>
                </Grid>
                <Insurance />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default InsuranceSummary;
