import { Add, Delete, Link } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  AutocompleteChangeReason,
  Divider,
  Grid,
  IconButton,
  Link as LinkComponent,
  Stack,
  useTheme,
} from "@mui/material";
import {
  useAddCallAttendeeMutation,
  useDeleteParticipantMutation,
  useEditCallScheduleMutation,
  useEditIntervieweeMutation,
} from "api/api-risk";
import { useGetCallAttendeesQuery } from "api/api-users-v2";
import AutoComplete from "components/Common/AutoComplete";
import MainCard from "components/Common/Cards/MainCard";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableDate from "components/Common/VariableDate";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { useFormikContext } from "formik";
import moment from "moment";
import { MapContext } from "pages/maps/map";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Fragment } from "react/jsx-runtime";
import { MeetingDetails } from "..";
import styles from "../../maps.module.scss";
import AddInterviewee from "./AddInterviewee";
import AddLink from "./AddLink";

interface IPLAttendee {
  partcipant_id: string;
  contact_id: string;
  name: string;
  title: string;
  email: string;
}

const CallDetails = ({
  accountType,
  disabled,
}: {
  accountType: string;
  disabled: boolean;
}) => {
  const formikProps = useFormikContext<MeetingDetails>();
  const map_id = React.useContext(MapContext);
  const [editCallSchedule] = useEditCallScheduleMutation();
  const [addCallAttendee] = useAddCallAttendeeMutation();

  const [deleteParticipant] = useDeleteParticipantMutation();
  const [editInterviewee] = useEditIntervieweeMutation();
  const theme = useTheme();
  const { data: callAttendees, isLoading } = useGetCallAttendeesQuery(null);
  const [add, setAdd] = useState(false);
  const [addRecording, setAddRecording] = useState(false);

  const attendeeAction = (
    data: { contact_id: string }[],
    reason: AutocompleteChangeReason
  ) => {
    switch (reason) {
      case "removeOption":
        map_id &&
          addCallAttendee({
            map_id,
            account_type: accountType,
            data,
          })
            .then(() => toast.success("Attendees edited"))
            .catch(() => toast.error("Failed to edit attendees"));
        break;
      case "selectOption":
        map_id &&
          addCallAttendee({
            map_id,
            account_type: accountType,
            data,
          })
            .then(() => toast.success("Attendees edited"))
            .catch(() => toast.error("Failed to edit attendees"));
        break;
      default:
        return;
    }
  };
  return (
    <Fragment>
      <Grid item xs={12} lg={12}>
        <Stack spacing={1} direction={"row"}>
          <LabelViewOnly
            label={"Product : "}
            labelClassName={styles.labelClass}
          />
          <LabelViewOnly
            label={formikProps.values.productType}
            sx={{ fontWeight: 500 }}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} lg={6}>
        <MainCard
          title={"Incomlend Team"}
          border
          content
          boxShadow
          shadow={theme.shadows[16]}
          children={
            <Stack spacing={1} sx={{ height: "22vh", overflow: "scroll" }}>
              <LabelViewOnly
                label={`Call attendees`}
                labelClassName={styles.labelClass}
              />
              {isLoading && <SkeletonLoad bars={1} />}
              {callAttendees && !isLoading && (
                <AutoComplete
                  limitTags={1}
                  labelKey="name"
                  value={formikProps.values.ipl_attendees}
                  data={callAttendees.map((attendee) => ({
                    ...attendee,
                    name: `${attendee.first_name} ${attendee.last_name}`,
                    disabled: formikProps.values.ipl_attendees.some(
                      (item) => item.contact_id === attendee.contact_id
                    ),
                  }))}
                  name={"ipl_attendees"}
                  placeholder="Select attendees"
                  multiple
                  handleSave={(value, reason) => {
                    const data = value.map((item) => ({
                      contact_id: item.contact_id,
                    }));

                    attendeeAction(data, reason as AutocompleteChangeReason);
                  }}
                  disabled={disabled}
                />
              )}
              <Grid item xs={12} lg={12}>
                <VariableDate
                  labelClassName={styles.labelClass}
                  wrapperClassName={styles.datePickerWrapper}
                  name={"call_implementation_date"}
                  label={"Date of Call (DD/MM/YYYY)"}
                  value={
                    formikProps.values.call_implementation_date
                      ? new Date(
                          moment(
                            formikProps.values.call_implementation_date
                          ).format("YYYY-MM-DD")
                        )
                      : undefined
                  }
                  setFieldValue={(field, value) =>
                    formikProps.setFieldValue(field, value)
                  }
                  handleSave={(value) => {
                    map_id &&
                      !disabled &&
                      editCallSchedule({
                        map_id,
                        account_type: accountType,
                        data: {
                          call_implementation_date:
                            moment(value).format("YYYY-MM-DD"),
                        },
                      })
                        .unwrap()
                        .then(() => toast.success("Date of call updated"))
                        .catch(() =>
                          toast.error("Failed to update date of call")
                        );
                    return;
                  }}
                  error={formikProps.errors.call_implementation_date as string}
                  style={{ width: "96%" }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <VariableDropdown
                  name={"mode_of_call"}
                  keyValue={"name"}
                  value={formikProps.values.mode_of_call}
                  data={[
                    { name: "Teams Call", value: "Teams Call" },
                    { name: "Zoom Call", value: "Zoom Call", disabled: true },
                  ]}
                  label={"Mode of Call"}
                  disabled={disabled}
                  noHover={disabled}
                />
              </Grid>
            </Stack>
          }
          footer={
            <Grid item xs={12} lg={12}>
              <Grid
                container
                justifyContent={
                  formikProps.values.reference ? "space-between" : "end"
                }
                alignItems={"center"}
                spacing={0.5}
              >
                {formikProps.values.reference && (
                  <>
                    <Grid item>
                      <AttachFileIcon
                        color="primary"
                        sx={{ marginTop: "3px" }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <LinkComponent
                        href={formikProps.values.reference}
                        target="_blank"
                      >
                        {`Recording- ${formikProps.values.mode_of_call}`}
                      </LinkComponent>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} lg={6}>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    loading={isLoading}
                    disabled={disabled}
                    onClick={() => setAddRecording(true)}
                    startIcon={<Add />}
                    endIcon={<Link />}
                  >
                    Recording
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <MainCard
          title={"Interviewees"}
          border
          content
          boxShadow
          shadow={theme.shadows[16]}
          footer={
            <Grid
              item
              xs={12}
              lg={2}
              ml={"auto"}
              my={1}
              justifyContent={"flex-end"}
            >
              <LoadingButton
                variant="contained"
                fullWidth
                loading={isLoading}
                onClick={() => setAdd(true)}
                startIcon={<Add />}
                disabled={disabled}
              >
                Add
              </LoadingButton>
            </Grid>
          }
        >
          <Grid
            container
            spacing={1}
            sx={{ height: "20.75vh", overflow: "scroll" }}
          >
            {formikProps.values.interviewees.length ? (
              formikProps.values.interviewees?.map((interviewee, index) => {
                return (
                  <Grid item xs={12} lg={12} key={index}>
                    <Stack direction={"row"}>
                      <Grid item xs={12} lg={5}>
                        <VariableInput
                          labelClassName={styles.labelClass}
                          name={`interviewees[${index}].interviewee_name`}
                          label={"Interviewee"}
                          style={{ width: "100%" }}
                          value={interviewee?.interviewee_name}
                          fullWidth
                          disabled={disabled}
                          noHover={disabled}
                          handleSave={(value) => {
                            editInterviewee({
                              participant_id: interviewee?.participant_id,
                              data: {
                                interviewee_name: value as string,
                              },
                            })
                              .unwrap()
                              .then(() => toast.success("Interviewee updated"))
                              .catch(() =>
                                toast.error("Failed to update interviewee")
                              );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={5}>
                        <VariableInput
                          labelClassName={styles.labelClass}
                          name={`interviewees[${index}].interviewee_title`}
                          label={"Job Title"}
                          style={{ width: "100%" }}
                          value={interviewee?.interviewee_title}
                          fullWidth
                          disabled={disabled}
                          noHover={disabled}
                          handleSave={(value) => {
                            editInterviewee({
                              participant_id: interviewee?.participant_id,
                              data: {
                                interviewee_title: value as string,
                              },
                            })
                              .unwrap()
                              .then(() => toast.success("Interviewee updated"))
                              .catch(() =>
                                toast.error("Failed to update interviewee")
                              );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            deleteParticipant(interviewee?.participant_id)
                              .unwrap()
                              .then(() => toast.success("Interviewee deleted"))
                              .catch(() =>
                                toast.error("Failed to delete interviewee")
                              );
                          }}
                        >
                          <Delete color={disabled ? "disabled" : "error"} />
                        </IconButton>
                      </Grid>
                    </Stack>
                    <Grid item xs={12} lg={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <NoData text={"No Interviewee registered"} />
            )}
            <AddInterviewee
              open={add}
              onClose={() => setAdd(false)}
              accountType={accountType}
            />
            <AddLink
              open={addRecording}
              onClose={() => setAddRecording(false)}
              accountType={accountType}
            />
          </Grid>
        </MainCard>
      </Grid>
    </Fragment>
  );
};
export default CallDetails;
