import { Box, useTheme } from "@mui/material";
import { useDeleteShareholderMutation } from "api/api-accounts";
import { useGetShareholdersHierarchyQuery } from "api/api-maps";
import AddShareholder from "components/Map/Sections/Compliance/CreditReport/Shareholder/AddShareholder";
import ShareholderDisplay from "components/Map/Sections/Compliance/CreditReport/Shareholder/ShareholderDisplay";
import { MapContext } from "pages/maps/map";
import React, { useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { toast } from "react-toastify";
import { AccountShareholderType, IShareholder, ShareholderType } from "./types";

interface ShareholderProps {
  accountType: string;
  isLocked?: boolean;
}

const Shareholders: React.FC<ShareholderProps> = ({
  accountType,
  isLocked,
}) => {
  const id = React.useContext(MapContext);
  const { data: shareholdersData, isFetching } =
    useGetShareholdersHierarchyQuery(
      { map_name: id ?? "", account_type: accountType as "supplier" | "buyer" },
      { skip: !id }
    );
  const [deleteShareholder] = useDeleteShareholderMutation();
  const theme = useTheme();
  const [showAddShareholder, setShowAddShareholder] = useState(false);
  const [shareholderType, setShareholderType] =
    useState<ShareholderType>("individual");
  const [entityId, setEntityId] = useState<string>("");
  const [entityType, setEntityType] = useState<string>("");

  function calculateIndividualShares(
    shareholdersStata: IShareholder[],
    corporateMultiplier: number = 1
  ): number {
    let totalShares = 0;
    shareholdersStata.forEach((shareholder) => {
      if (shareholder.shareholder_type === "individual") {
        totalShares += (shareholder.percentage ?? 0) * corporateMultiplier;
      } else if (
        shareholder.shareholder_type === "corporate" &&
        shareholder.shareholders
      ) {
        totalShares += calculateIndividualShares(
          shareholder.shareholders,
          corporateMultiplier * (shareholder.percentage ?? 0) * 0.01
        );
      }
    });
    return parseFloat(totalShares.toFixed(2));
  }

  const handleAddShareholder = (
    shareholderTypeSet: ShareholderType | null,
    entityId: string,
    entityType: AccountShareholderType
  ) => {
    setShareholderType(shareholderTypeSet);
    setEntityId(entityId);
    setEntityType(entityType);
    setShowAddShareholder(true);
  };

  const handleDeleteShareholder = (
    account_id: string,
    shareholder_id: string
  ) => {
    deleteShareholder({ account_id, shareholder_id })
      .unwrap()
      .then(() => {
        toast.success("Shareholder deleted successfully");
      })
      .catch((error) => {
        toast.error(`Error occurred: ${error?.message}`);
      });
  };

  return (
    <Box>
      {showAddShareholder ? (
        <AddShareholder
          entityId={entityId}
          entityType={entityType}
          shareholderType={shareholderType}
          callback={() => setShowAddShareholder(false)}
          onClose={() => setShowAddShareholder(false)}
          isLocked={isLocked} // Pass isLocked to AddShareholder
        />
      ) : (
        <Tree
          lineWidth={"5px"}
          lineColor={`${theme.palette.error.main}`}
          lineStyle={"solid"}
          lineBorderRadius={"10px"}
          nodePadding={"0px"}
          label={
            <ShareholderDisplay
              account_id={shareholdersData?.id ?? ""}
              entity_id={shareholdersData?.id ?? ""}
              name={shareholdersData?.name ?? ""}
              percentage={calculateIndividualShares(
                shareholdersData?.shareholders ?? []
              )}
              address={shareholdersData?.address ?? ""}
              type={"account"}
              shareholderType={null}
              directors={shareholdersData?.directors}
              onClick={(shareholderType) => {
                if (!isLocked) {
                  handleAddShareholder(
                    shareholderType,
                    shareholdersData!.id,
                    "account"
                  );
                }
              }}
              onDelete={undefined} // No delete option for root
              hasShareholders={Boolean(shareholdersData?.shareholders?.length)}
              isLocked={isLocked} // Pass isLocked to ShareholderDisplay
            />
          }
        >
          {shareholdersData?.shareholders?.map((shareholder: IShareholder) => {
            const hasShareholders = Boolean(shareholder.shareholders?.length);
            return (
              <TreeNode
                key={shareholder.id}
                label={
                  <ShareholderDisplay
                    account_id={shareholdersData?.id ?? ""}
                    entity_id={shareholder.id}
                    name={shareholder.name}
                    percentage={shareholder.percentage ?? 0}
                    address={shareholder.address ?? ""}
                    type={shareholder.type}
                    shareholderType={shareholder.shareholder_type}
                    directors={shareholder.directors}
                    onClick={(shareholderType) => {
                      if (!isLocked) {
                        handleAddShareholder(
                          shareholderType,
                          shareholder.id,
                          "shareholder"
                        );
                      }
                    }}
                    onDelete={() => {
                      if (!isLocked) {
                        handleDeleteShareholder(
                          shareholdersData?.id ?? "",
                          shareholder.id
                        );
                      }
                    }}
                    hasShareholders={hasShareholders}
                    isLocked={isLocked} // Pass isLocked to ShareholderDisplay
                  />
                }
              >
                {hasShareholders &&
                  shareholder.shareholders?.map(
                    (subShareholder: IShareholder) => {
                      const hasSubShareholders = Boolean(
                        subShareholder.shareholders?.length
                      );
                      return (
                        <TreeNode
                          key={subShareholder.id}
                          label={
                            <ShareholderDisplay
                              account_id={shareholdersData?.id ?? ""}
                              entity_id={subShareholder.id}
                              name={subShareholder?.name}
                              percentage={subShareholder.percentage ?? 0}
                              address={subShareholder.address ?? ""}
                              type={subShareholder.type}
                              shareholderType={subShareholder.shareholder_type}
                              directors={subShareholder.directors}
                              onClick={(shareholderType) => {
                                if (!isLocked) {
                                  handleAddShareholder(
                                    shareholderType,
                                    subShareholder.id,
                                    "shareholder"
                                  );
                                }
                              }}
                              onDelete={() => {
                                if (!isLocked) {
                                  handleDeleteShareholder(
                                    shareholdersData?.id ?? "",
                                    subShareholder.id
                                  );
                                }
                              }}
                              hasShareholders={hasSubShareholders}
                              isLocked={isLocked} // Pass isLocked to ShareholderDisplay
                            />
                          }
                        >
                          {hasSubShareholders &&
                            subShareholder.shareholders?.map(
                              (nestedShareholder: IShareholder) => {
                                const hasNestedShareholders = Boolean(
                                  nestedShareholder.shareholders?.length
                                );
                                return (
                                  <TreeNode
                                    key={nestedShareholder.id}
                                    label={
                                      <ShareholderDisplay
                                        account_id={shareholdersData?.id ?? ""}
                                        entity_id={nestedShareholder.id}
                                        name={nestedShareholder.name}
                                        percentage={
                                          nestedShareholder.percentage ?? 0
                                        }
                                        address={
                                          nestedShareholder.address ?? ""
                                        }
                                        type={nestedShareholder.type}
                                        shareholderType={
                                          nestedShareholder.shareholder_type
                                        }
                                        directors={nestedShareholder.directors}
                                        onClick={(shareholderType) => {
                                          if (!isLocked) {
                                            handleAddShareholder(
                                              shareholderType,
                                              nestedShareholder.id,
                                              "shareholder"
                                            );
                                          }
                                        }}
                                        onDelete={() => {
                                          if (!isLocked) {
                                            handleDeleteShareholder(
                                              shareholdersData?.id ?? "",
                                              nestedShareholder.id
                                            );
                                          }
                                        }}
                                        hasShareholders={hasNestedShareholders}
                                        isLocked={isLocked} // Pass isLocked to ShareholderDisplay
                                      />
                                    }
                                  >
                                    {hasNestedShareholders &&
                                      nestedShareholder.shareholders?.map(
                                        (finalShareholder: IShareholder) => {
                                          return (
                                            <TreeNode
                                              key={finalShareholder.id}
                                              label={
                                                <ShareholderDisplay
                                                  account_id={
                                                    shareholdersData?.id ?? ""
                                                  }
                                                  entity_id={
                                                    finalShareholder.id
                                                  }
                                                  name={finalShareholder.name}
                                                  percentage={
                                                    finalShareholder.percentage ??
                                                    0
                                                  }
                                                  address={
                                                    finalShareholder.address ??
                                                    ""
                                                  }
                                                  type={finalShareholder.type}
                                                  shareholderType={
                                                    finalShareholder.shareholder_type
                                                  }
                                                  directors={
                                                    finalShareholder.directors
                                                  }
                                                  onClick={(
                                                    shareholderType
                                                  ) => {
                                                    if (!isLocked) {
                                                      handleAddShareholder(
                                                        shareholderType,
                                                        finalShareholder.id,
                                                        "shareholder"
                                                      );
                                                    }
                                                  }}
                                                  onDelete={() => {
                                                    if (!isLocked) {
                                                      handleDeleteShareholder(
                                                        shareholdersData?.id ??
                                                          "",
                                                        finalShareholder.id
                                                      );
                                                    }
                                                  }}
                                                  isLocked={isLocked} // Pass isLocked to ShareholderDisplay
                                                />
                                              }
                                            />
                                          );
                                        }
                                      )}
                                  </TreeNode>
                                );
                              }
                            )}
                        </TreeNode>
                      );
                    }
                  )}
              </TreeNode>
            );
          })}
        </Tree>
      )}
    </Box>
  );
};

export default Shareholders;
