import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useGetAccountRelationshipQuery,
  useModifyAccountRelationshipMutation,
} from "api/api-accounts";
import MainCard from "components/Common/Cards/MainCard";
import Drop from "components/Common/Drop";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Formik, useFormikContext } from "formik";
import valueCleaner from "helpers/valueCleaner";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TradeDetailsKeyMappings } from "../CompanyDetailsKeyMappings";
import styles from "../company.module.scss";
import { CompanyDetails } from "../validationSchema";

interface ITradeDetails {
  companyId: string;
  partnerId: string;
}

const TradeDetails = ({ companyId, partnerId }: ITradeDetails) => {
  const theme = useTheme();
  const companyDetailsFormikProps = useFormikContext<CompanyDetails>();
  const { t } = useTranslation();
  const [updateTradeDetails, { isLoading }] =
    useModifyAccountRelationshipMutation();

  const { data: relationship, isFetching } = useGetAccountRelationshipQuery({
    companyId: companyId,
    partnerId: partnerId ?? "",
  });

  const setterAveragePayement = (value: string) => {
    companyDetailsFormikProps.setTouched({ average_payment_terms: true }, true);
    companyDetailsFormikProps.setFieldValue(
      "average_payment_terms",
      value,
      true
    );
  };
  const { values } = companyDetailsFormikProps;
  const DAYS = [
    { name: "<30 (days)" },
    { name: "30 (days)" },
    { name: "45 (days)" },
    { name: "60 (days)" },
    { name: "90 (days)" },
    { name: "120 (days)" },
    { name: ">120 (days)" },
  ];
  const hasMissingFields = Boolean(
    Object.keys(TradeDetailsKeyMappings()).filter(
      (item) => !((relationship as Record<string, any>) ?? {})[item]
    ).length
  );

  return (
    <>
      {isFetching || relationship == undefined ? (
        <SkeletonLoad bars={10} />
      ) : (
        <Formik initialValues={relationship} onSubmit={() => {}}>
          {(formikProps) => {
            return (
              <MainCard
                border
                content
                boxShadow
                shadow={theme.shadows[16]}
                title={
                  <Typography fontWeight={"bold"} variant={"h3"}>
                    Trade Details
                  </Typography>
                }
                footer={
                  <Grid
                    xs={12}
                    lg={2}
                    ml={"auto"}
                    my={1}
                    justifyContent={"flex-end"}
                  >
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      disabled={!formikProps.dirty}
                      loading={isLoading}
                      data-testid="save-trade-details"
                      onClick={() => {
                        const {
                          average_invoice_amount,
                          number_of_past_paid_transaction,
                          average_payment_terms,
                          reason_of_any_delays_of_payment_with_buyer,
                          reason_for_credit_note,
                          any_credit_note,
                          any_delays_of_payment_with_buyer,
                        } = formikProps.values;

                        updateTradeDetails({
                          companyId,
                          partnerId,
                          data: {
                            average_invoice_amount: valueCleaner(
                              `${average_invoice_amount}`
                            ),
                            number_of_past_paid_transaction,
                            average_payment_terms,
                            reason_of_any_delays_of_payment_with_buyer,
                            reason_for_credit_note,
                            any_credit_note,
                            any_delays_of_payment_with_buyer,
                          },
                        })
                          .unwrap()
                          .then(() => {
                            toast(
                              `Successfully saved Trade Details for ${companyDetailsFormikProps?.values?.name}`,
                              {
                                type: "success",
                              }
                            );
                            companyDetailsFormikProps.resetForm({ values });
                          })
                          .catch((error) => {
                            const errorFile = error?.message;
                            toast(`Something went wrong : ${errorFile}`, {
                              type: "error",
                            });
                          });
                      }}
                    >
                      {t("save")}
                    </LoadingButton>
                  </Grid>
                }
              >
                <Stack spacing={1}>
                  {hasMissingFields && (
                    <Alert severity="error">
                      <AlertTitle>Missing Fields</AlertTitle>
                      <Stack
                        spacing={1}
                        direction={"row"}
                        flexWrap={"wrap"}
                        display={"flex"}
                        rowGap={"1ch"}
                      >
                        {Object.keys(TradeDetailsKeyMappings())
                          .filter(
                            (item) =>
                              !(formikProps.values as Record<string, any>)[item]
                          )
                          .map((error) => (
                            <Chip
                              label={`${TradeDetailsKeyMappings()[error]}`}
                            />
                          ))}
                      </Stack>
                    </Alert>
                  )}
                  <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName={styles.labelClass}
                        name={"average_invoice_amount"}
                        label={"Average Invoice Amount (in USD equivalent)"}
                        required
                        type="money"
                        textfieldProps={{
                          InputProps: {
                            startAdornment: (
                              <InputAdornment position="start">
                                USD
                              </InputAdornment>
                            ),
                          },
                        }}
                        style={{ width: "100%" }}
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Input
                        labelClassName={styles.labelClass}
                        name={"number_of_past_paid_transaction"}
                        label={"Number of Past Paid Transactions"}
                        required
                        style={{ width: "100%" }}
                        type="number"
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Drop
                        name={"average_payment_terms"}
                        label={"Average Payment Terms"}
                        required
                        keyValue={"name"}
                        data={DAYS}
                        fullWidth
                        setValue={setterAveragePayement}
                        {...formikProps}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Stack>
                        <LabelViewOnly
                          labelClassName={styles.labelClass}
                          label={"Any credit note"}
                          sx={{ fontWeight: "bolder" }}
                          required
                          {...formikProps}
                        />
                        <FormControl sx={{ mt: 1, mb: 0.5, p: 0.5 }}>
                          <RadioGroup
                            aria-labelledby="any_credit_note"
                            name="any_credit_note"
                            value={formikProps.values.any_credit_note}
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                "any_credit_note",
                                e.target.value === "true" ? true : false
                              );
                            }}
                            sx={{ flexWrap: "nowrap" }}
                            row
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                        {Boolean(formikProps.errors.any_credit_note) && (
                          <FormHelperText
                            error={Boolean(formikProps.errors.any_credit_note)}
                          >
                            {formikProps.errors.any_credit_note}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    {formikProps.values.any_credit_note && (
                      <Grid item xs={12} lg={12}>
                        <Input
                          labelClassName={styles.labelClass}
                          sx={{
                            ".MuiInputBase-root": {
                              height: "11vh",
                              textarea: {
                                height: "10vh !important",
                                overflow: "scroll !important",
                              },
                            },
                          }}
                          name={"reason_for_credit_note"}
                          label={"Reason for Credit Note"}
                          className={styles.formInput}
                          multiline
                          {...formikProps}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} lg={6}>
                      <Stack>
                        <LabelViewOnly
                          labelClassName={styles.labelClass}
                          label={"Any delays in payment with Buyer"}
                          required
                          {...formikProps}
                        />
                        <FormControl sx={{ mt: 1, mb: 0.5, p: 0.5 }}>
                          <RadioGroup
                            aria-labelledby="any_delays_of_payment_with_buyer"
                            name="any_delays_of_payment_with_buyer"
                            value={
                              formikProps.values
                                .any_delays_of_payment_with_buyer
                            }
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                "any_delays_of_payment_with_buyer",
                                e.target.value === "true" ? true : false
                              );
                            }}
                            sx={{ flexWrap: "nowrap" }}
                            row
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>

                        {Boolean(
                          formikProps.errors.any_delays_of_payment_with_buyer
                        ) && (
                          <FormHelperText
                            error={Boolean(
                              formikProps.errors
                                .any_delays_of_payment_with_buyer
                            )}
                          >
                            {
                              formikProps.errors
                                .any_delays_of_payment_with_buyer
                            }
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    {formikProps.values.any_delays_of_payment_with_buyer && (
                      <Grid item xs={12} lg={12}>
                        <Input
                          labelClassName={styles.labelClass}
                          sx={{
                            ".MuiInputBase-root": {
                              height: "11vh",
                              textarea: {
                                height: "10vh !important",
                                overflow: "scroll !important",
                              },
                            },
                          }}
                          name={"reason_of_any_delays_of_payment_with_buyer"}
                          label={"Reason for Delays in Payment with Buyer"}
                          className={styles.formInput}
                          multiline
                          {...formikProps}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Stack>
              </MainCard>
            );
          }}
        </Formik>
      )}
    </>
  );
};
export default TradeDetails;
