import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Grid, Slide, Typography, useTheme } from "@mui/material";

import { useGetExternalUsersQuery, useGetInternalUsersQuery } from "api/api-it";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK, IT_LINK } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Drop from "components/Common/Drop";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import InviteUser from "components/Users/InviteUser";
import ShowUserGroups from "components/Users/ShowUserGroups";
import history from "helpers/history";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";

import { useEffect, useRef, useState } from "react";
import { OverridableStringUnion } from "types";

interface IUserIT {
  departments: string[];
  first_name: string;
  groups: string[];
  is_active: string;
  last_login: Date;
  last_name: string;
  id: string;
  username: string;
  contact_id: string;
}

const ITUserManagement = () => {
  const isMobile = useIsMobile();
  const [userType, setUserType] =
    useState<OverridableStringUnion<"Internal" | "Clients" | "All">>(
      "Internal"
    );

  const {
    data: internalUsers,
    refetch: refetchInternalUsers,
    isFetching: isFetchingInternalUsers,
  } = useGetInternalUsersQuery(null);
  const {
    data: externalUsers,
    refetch: refetchExternalUsers,
    isFetching: isFetchingExternalUsers,
  } = useGetExternalUsersQuery(null);

  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();
  const [inviteuserMode, setInviteUserMode] = useState<boolean>(false);
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const USERS_TABLE_DATA: Record<
    string,
    { data: IUserIT[]; refetch: () => void; isFetching: boolean }
  > = {
    Internal: {
      data: internalUsers ?? [],
      refetch: refetchInternalUsers,
      isFetching: isFetchingInternalUsers,
    },
    Clients: {
      data: externalUsers ?? [],
      refetch: refetchExternalUsers,
      isFetching: isFetchingExternalUsers,
    },
    All: {
      data: [...(internalUsers ?? []), ...(externalUsers ?? [])],
      refetch: () => [refetchInternalUsers(), refetchExternalUsers()],
      isFetching: isFetchingInternalUsers || isFetchingExternalUsers,
    },
  };
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const headerLength = Object.keys(
    (internalUsers || externalUsers)?.[0] ?? {}
  )?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.6 : 200;

  const setter = (value: string) => setUserType(value);
  const theme = useTheme();

  useEffect(() => {
    USERS_TABLE_DATA[userType]?.refetch();
  }, [userType]);
  const initialFilters = {
    is_active: {
      comparator: "is",
      keywords: ["True"],
    },
  };
  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={inviteuserMode ? "Invite User" : "Users Table"}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            left: 6,
            mid: 0,
            right: 3,
            syncAction: USERS_TABLE_DATA[userType]?.refetch,
            justifyContent: "space-between",
            alignItems: "center",
            xs: {
              left: 12,
              mid: 0,
              right: 12,
            },
          }}
          primary={{
            onClick: () => setInviteUserMode(!inviteuserMode),
            isCancel: inviteuserMode,
            startIcon: inviteuserMode ? null : <EmailIcon />,
            children: inviteuserMode ? "Cancel" : "Invite User",
            variant: "contained",
            fullWidth: true,
          }}
          mode="default"
        >
          {inviteuserMode && (
            <InviteUser setInviteUserMode={setInviteUserMode} />
          )}
          {!inviteuserMode && (
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Drop
                  label={`User Type`}
                  multilevel={false}
                  data={[
                    {
                      value: "internal",
                      name: "Internal",
                    },
                    {
                      value: "external",
                      name: "Clients",
                    },
                    {
                      value: "all",
                      name: "All",
                    },
                  ]}
                  name={"userType"}
                  value={userType}
                  keyValue={"name"}
                  setValue={setter}
                />
              </Grid>

              <Grid item xs={12} ref={ref}>
                {USERS_TABLE_DATA?.[userType]?.isFetching && (
                  <SkeletonLoad bars={10} />
                )}
                {!USERS_TABLE_DATA?.[userType]?.isFetching &&
                  USERS_TABLE_DATA[userType]?.data &&
                  Boolean(USERS_TABLE_DATA[userType]?.data?.length) && (
                    <DataTablev2
                      data={USERS_TABLE_DATA[userType]?.data}
                      initialFilters={initialFilters}
                      defaultColumnWidth={defaultColumnWidth}
                      rowsPerPage={rowsPerPage}
                      customColumns={{
                        first_name: {
                          first_name: "First name",
                          minWidth: 160,
                          type: "text",
                        },
                        last_name: { last_name: "Last name", type: "text" },
                        username: {
                          username: "Username",
                          minWidth: 330,
                          type: "text",
                        },
                        groups: {
                          groups: "User Groups",
                          minWidth: 220,
                          type: "text",
                          renderCell: ({ value }: { value: string[] }) => {
                            if (value?.length === 1)
                              return (
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: "1em",
                                    p: 1,
                                    borderRadius: 1,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                  }}
                                  key={`${0}-u-group`}
                                >
                                  {value[0]}
                                </Typography>
                              );
                            return (
                              <ShowUserGroups
                                items={value}
                                title={"Multiple Groups"}
                                aria="group"
                              />
                            );
                          },
                        },
                        departments: {
                          departments: "Departments",
                          minWidth: 250,
                          type: "text",
                          renderCell: ({ value }: { value: string[] }) => {
                            if (value?.length === 1)
                              return (
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: "1em",
                                    p: 1,
                                    borderRadius: 1,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                  }}
                                  key={`${0}-u-department`}
                                >
                                  {value[0]}
                                </Typography>
                              );
                            return (
                              <ShowUserGroups
                                items={value}
                                title={"Multiple Departments"}
                                aria="department"
                              />
                            );
                          },
                        },
                        mobile: { mobile: "Phone", type: "text" },
                        title: {
                          title: "Job position",
                          type: "text",
                        },

                        opted_marketing_updates: {
                          opted_marketing_updates: "Marketing consent",
                          type: "boolean",
                          renderCell: (params: any) => {
                            const Icon = params.value
                              ? CheckCircleIcon
                              : CancelIcon;
                            const iconColor = params.value ? "green" : "red";

                            return (
                              <Grid container justifyContent={"center"}>
                                <Grid item>
                                  <Icon
                                    style={{
                                      textAlign: "center",
                                      color: iconColor,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            );
                          },
                        },
                        sign_up: {
                          sign_up: "Sign up",
                          type: "date",
                          renderCell: ({ value }: { value: string }) => (
                            <Typography
                              sx={{
                                fontSize: "1em!important",
                                width: "10vw",
                              }}
                            >
                              {value}
                            </Typography>
                          ),
                        },
                        last_login: {
                          last_login: "Last login",
                          type: "date",
                          minWidth: 300,
                        },
                        company_id: {
                          company_id: "Company name",
                          type: "text",
                        },
                        reg_no: { reg_no: "Registration Number", type: "text" },
                        is_active: { is_active: "Active" },
                      }}
                      toBeHidden={["contact_id"]}
                      onRowClicked={(field, columnName) => {
                        if (
                          columnName !== "groups" &&
                          columnName !== "departments"
                        )
                          history.push(
                            `${INCOMLEND_INTERNAL_LINK}${IT_LINK}/user-management/${field?.id}/manage`
                          );
                      }}
                      sx={{
                        table: {
                          minWidth: "100% !important",
                        },
                      }}
                    />
                  )}
              </Grid>
            </Grid>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};

export default ITUserManagement;
