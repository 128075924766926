import { useGetCategoriesQuery } from "api/api-maps";
import {
  getFinalScore,
  getScoringtableInitialValues,
} from "components/Risk/defaults";

interface RiskScoringProps {
  id?: string;
}
const useRiskScoring = (props: RiskScoringProps) => {
  const { id } = props;
  const SCORE_SHEET: string[] = [
    "LOW",
    "LOW",
    "LOW",
    "MEDIUM LOW",
    "MEDIUM LOW",
    "MEDIUM",
    "MEDIUM HIGH",
    "MEDIUM HIGH",
    "HIGH",
    "HIGH",
    "HIGH",
  ];

  const { data: categories } = useGetCategoriesQuery(id ?? "", {
    skip: id === undefined,
  });
  const {
    buyer = {},
    supplier = {},
    tradingRelationship = {},
    adjustment = {},
    buyers_assessment_weight,
    suppliers_assessment_weight,
    trading_relationship_weight,
  } = getScoringtableInitialValues(categories);
  const weightages = {
    buyers_assessment_weight,
    suppliers_assessment_weight,
    trading_relationship_weight,
  };
  const score = getFinalScore({
    buyer,
    supplier,
    tradingRelationship,
    adjustment,
    weightages,
  });
  const FINAL_SCORE = 10 - Math.trunc(score);
  const RESULT = SCORE_SHEET[FINAL_SCORE];
  return { score, RESULT, FINAL_SCORE };
};
export default useRiskScoring;
