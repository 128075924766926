import {
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import useIsMobile from "hooks/useIsMobile";
import React, { useEffect } from "react";

interface TablePagination<T> {
  data: T[];
  curr: number;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  setCurr: React.Dispatch<React.SetStateAction<number>>;
  total: number;
  setPaginatedData: React.Dispatch<React.SetStateAction<T[]>>;
}

const TablePagination = <T extends Record<string, any> = {}>({
  data,
  curr,
  count,
  total,
  setCurr,
  setPaginatedData,
  setCount,
}: TablePagination<T>) => {
  const isMobile = useIsMobile();

  useEffect(() => {
    setPaginatedData([...data].splice(count * (curr - 1), count * curr));
  }, [data]);

  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurr(value);
    const spliceBetween = [count * (value - 1), count];
    const paginated = [...data].splice(spliceBetween[0], spliceBetween[1]);
    setPaginatedData(paginated);
  };
  const pages =
    total / count > Math.round(total / count)
      ? Math.round(total / count) + 1
      : Math.round(total / count);
  const handleChangeOnCount = (event: SelectChangeEvent) => {
    const newCount = Number(event.target.value);
    if (newCount < 10) {
      setCount(total);
    } else {
      setCount(newCount);
    }
    const spliceBetween = [newCount * (curr - 1), newCount];
    const paginated = [...data].splice(spliceBetween[0], spliceBetween[1]);
    setPaginatedData(paginated);
  };
  return (
    <Paper
      elevation={1}
      id="pagination-tray"
      sx={{ padding: isMobile ? "1ch" : "1ch 2ch", width: "100%" }}
    >
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={1}
      >
        <Grid item xs={3} lg={1} margin="auto">
          <FormControl fullWidth>
            <Select
              value={count < 10 ? `${10}` : `${count}`}
              onChange={handleChangeOnCount}
              sx={{
                bgcolor: theme.palette.field.main,
                ">div": {
                  bgcolor: theme.palette.field.main,
                },
                [theme.breakpoints.down("sm")]: {
                  ".MuiSelect-select": {
                    padding: 1,
                  },
                },
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={9} lg={7} margin="auto">
          <Typography
            textAlign="center"
            fontSize={"1em"}
          >{`Showing ${curr} to ${
            total < count ? total : count
          } of ${total} entries`}</Typography>
        </Grid>
        <Grid item xs={12} lg={4} margin="auto">
          <Pagination
            count={pages}
            variant="outlined"
            shape="rounded"
            siblingCount={0}
            boundaryCount={1}
            sx={{
              ".MuiPagination-ul": {
                margin: "auto",
                justifyContent: "flex-end",
                [theme.breakpoints.down("md")]: {
                  justifyContent: "center",
                },
              },
            }}
            page={curr}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
export default TablePagination;
