import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Grid, useTheme } from "@mui/material";
import { useUpdateStepsMutation } from "api/api-accounts";

import SubmitCL from "components/CLRequest/SubmitCL";
import { FormikProps, useFormikContext } from "formik";
import { RegistrationFormFields } from "helpers/formFields";
import isObjectEmpty from "helpers/isObjectEmpty";

import useIsMobile from "hooks/useIsMobile";
import { t } from "i18next";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/hooks";
import { setSteps } from "redux/reducers/stepsReducer";
import secondaryButtonStyle from "styles/secondaryButtonStyle";

import { ProcessStep } from "types";

const CreditLimitPage = () => {
  const theme = useTheme();
  const formikProps: FormikProps<RegistrationFormFields> = useFormikContext();
  const isMobile = useIsMobile();
  const { list } = formikProps.values;
  const [updateSteps, { isLoading }] = useUpdateStepsMutation();
  const dispatch = useAppDispatch();

  const stepBack = (value: ProcessStep) => {
    updateSteps({
      company_id: value.data.companyId,
      step: value.step - 1,
      form: value.data,
    })
      .then(() => {
        dispatch(
          setSteps({
            step: value.step - 1,
            form: value.data,
          })
        );
      })
      .catch((error) => {
        const errorFile = error?.message;
        toast(`Something went wrong : ${errorFile}`, {
          type: "error",
        });
      });
  };

  return (
    <Grid container justifyContent={"space-between"} id="cl-step">
      <Grid item xs={12} height={isMobile ? "70vh" : "63vh"} overflow="scroll">
        <SubmitCL />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent={"space-between"}
          padding="3ch 0"
          id="control-panel-cl"
          spacing={1}
          sx={{
            [theme.breakpoints.down("sm")]: {
              maxWidth: "83vw",
            },
          }}
        >
          <Grid item xs={12} lg={3}>
            <LoadingButton
              sx={secondaryButtonStyle}
              onClick={() => {
                stepBack({
                  status: "incomplete",
                  step: 2,
                  data: formikProps.values,
                });
              }}
              loading={isLoading}
              variant="outlined"
              fullWidth
            >
              {`${t("back")}`}
            </LoadingButton>
          </Grid>

          <Grid item xs={12} lg={3}>
            <Button
              variant="contained"
              fullWidth
              disabled={!isObjectEmpty(formikProps.errors) || !list.length}
              type="submit"
            >
              {`${t("submit")}`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CreditLimitPage;
