import { Chip, Grid, Slider, useTheme } from "@mui/material";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import useIsMobile from "hooks/useIsMobile";
import React, { SyntheticEvent, useState } from "react";
import { CustomColumn } from "../..";

const SingleRangeFilter = ({
  range,
  label,
  filters,
  setFilters,
  customColumns,
}: {
  range: number[];
  label: string;
  filters: Record<string, any>;
  customColumns: CustomColumn;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}) => {
  const [value, setValue] = useState<number[]>(
    filters[label]?.keywords ?? range
  );

  const handleChange = (event: Event, newValue: any) => {
    setValue(newValue);
  };

  const clear = () => {
    setValue(range);
    const revertFilters = { ...filters };
    delete revertFilters[label];
    setFilters(revertFilters);
  };

  const marks = [
    {
      value: 0,
      label: <Grid item>0</Grid>,
    },
    {
      value: range[1],
      label: <Grid item>{range[1]}</Grid>,
    },
  ];
  const theme = useTheme();
  const isMobile = useIsMobile();
  return (
    <Grid container spacing={isMobile ? 1 : 5} alignItems="center">
      <Grid item lg={10} xs={10}>
        <LabelViewOnly
          label={customColumns?.[label]?.[label] ?? label}
          sx={{
            fontSize: "1em",
            color: theme.palette.primary.main,
            textTransform: "capitalize",
            letterSpacing: "0.01em",
            lineHeight: "1.5em",
          }}
        />
      </Grid>

      {filters[label]?.keywords && (
        <Grid item lg={2} xs={1.5}>
          <Chip label="Clear" onDelete={clear} onClick={clear} />
        </Grid>
      )}
      <Grid item lg={12} xs={10} margin="auto">
        <Slider
          getAriaLabel={() => `${label} range`}
          value={value}
          min={0}
          onChangeCommitted={(
            e: Event | SyntheticEvent<Element, Event>,
            newValue: number | number[]
          ) => {
            setFilters((prev) => {
              return {
                ...prev,
                [label]: {
                  comparator: "is-between",
                  keywords: newValue,
                },
              };
            });
          }}
          max={range[1]}
          onChange={handleChange}
          valueLabelDisplay="on"
          marks={marks}
          getAriaValueText={(value: number, index: number) => `${value}`}
        />
      </Grid>
    </Grid>
  );
};
export default SingleRangeFilter;
