import { Box } from "@mui/material";
import {
  useGetAccountQuery,
  useGetAccountRelationshipQuery,
  useGetDocumentsQuery,
  useGetPartnerContactsQuery,
} from "api/api-accounts";
import { useGetBankAccountsQuery } from "api/api-banks";

import { ONBOARDING, SELLER, TR_LINK } from "codes";
import Breadcrumbs from "components/Common/Breadcrumbs";
import Layout from "components/Common/Layout";
import CompanyDetails from "components/Company/Details";
import TradingPartnertabs from "components/TradingRelation/View";
import useIsMobile from "hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

const PartnerProfile = () => {
  const { t } = useTranslation();

  const USER_ROLE = useAppSelector((state) => state.appState.role);

  const location = useLocation();
  const tab = location.state?.tab;

  const APP_STATE = useAppSelector((state) => state.appState.value);

  const profile = useAppSelector((state) => state.appState.profile);

  const { id: partnerAccountId = "" } = useParams();

  const accountId = USER_ROLE.id;
  const { data: relationship } = useGetAccountRelationshipQuery({
    companyId: accountId,
    partnerId: partnerAccountId,
  });

  const { data: users } = useGetPartnerContactsQuery(
    { companyId: accountId, partnerId: partnerAccountId },
    {
      skip: !Boolean(partnerAccountId),
    }
  );

  const { data: pendingDocs, isFetching } = useGetDocumentsQuery(
    {
      account_id: partnerAccountId,
    },
    {
      skip: !Boolean(partnerAccountId) || !Boolean(USER_ROLE?.id),
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  const isMobile = useIsMobile();

  const { data: currentPartner } = useGetAccountQuery(partnerAccountId ?? "", {
    skip: !Boolean(partnerAccountId),
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  const { data: banks } = useGetBankAccountsQuery(
    profile === SELLER ? USER_ROLE?.id : partnerAccountId,
    {
      skip: !Boolean(profile === SELLER ? USER_ROLE?.id : partnerAccountId),
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  if (!currentPartner) return <></>;

  return (
    <Box sx={{ display: "flex" }} flexGrow={1}>
      <Layout
        title={currentPartner?.name}
        textHeadingOptions={{
          fontWeight: "bolder",
          level: 2,
          fontSize: isMobile ? "1.2em" : "1.5em",
        }}
        headerConfig={{
          left: 8,
          mid: 0,
          right: 2,
          xs: {
            left: 12,
            mid: 0,
            right: 0,
          },
          alignItems: "center",
        }}
        mode="default"
      >
        <Breadcrumbs
          data={[
            { name: `${t("trade-partners")}`, link: TR_LINK },
            {
              name: `${currentPartner?.name}`,
              link: `${location.pathname}`,
              active: true,
            },
          ]}
        />
        {APP_STATE === ONBOARDING && users && relationship ? (
          <TradingPartnertabs
            currentPartner={currentPartner}
            relationship={relationship}
            profile={profile}
            documentsUploaded={pendingDocs?.complete ?? false}
            users={users}
            partnerAccountId={partnerAccountId}
            banks={banks}
            tab={tab}
          />
        ) : (
          <CompanyDetails partnerCompany={currentPartner} isPartner />
        )}
      </Layout>
    </Box>
  );
};
export default PartnerProfile;
