import { Typography, useTheme } from "@mui/material";
import { CSSProperties } from "react";

const SourceDisplay = ({
  status,
  isSalesModule = true,
  style,
}: {
  status: string;
  isSalesModule?: boolean;
  style?: CSSProperties;
}) => {
  const theme = useTheme();
  const KYC = Boolean(status === "KYC");
  const background = KYC ? "#9FCE63" : "red";

  return (
    <Typography
      style={{
        background,
        padding: "6px 16px",
        borderRadius: "10px",
        color: "white",
        fontWeight: "600",
        fontSize: "0.8em",
        textAlign: "center",
        maxWidth: "100%",
        ...style,
      }}
      sx={{
        [theme.breakpoints.down("sm")]: {
          fontSize: "1em",
        },
      }}
    >
      {status}
    </Typography>
  );
};
export default SourceDisplay;
