import { useEffect, useState } from "react";
import type { Account, AccountRelationship } from "types";

//eslint-disable-next-line no-shadow
enum KeyMappings {
  shipping_address = "Shipping Address",
  accounting_software = "Company Accounting Software",
  annual_revenue = "Annual Revenue",
  number_of_employees = "No. of Employees",
  average_invoice_amount = "Average Invoice Amount (in USD equivalent)​",
  average_payment_terms = "Average Payment Terms",
  name = "Company Full Legal Name",
  reg_no = "Company Registration Number",
  incorporation_date = "Company Incorporation Date",
  address_line1 = "Address 1",
  city = "City",
  country = "Country",
  postcode = "Postcode",
  other_accounting_software = "Please specify your accounting software",
  address_line2 = "Address Line 2",
}
//eslint-disable-next-line no-shadow
enum ShippingAddressKeyMappings {
  address_line1 = "Address 1",
  postcode = "Postcode",
  city = "City",
  country = "Country",
}

export default function useIsCompanyFilledUp(
  company?: Account,
  relationship?: AccountRelationship,
  deps?: any[],
  isPartner = false
) {
  const [isCompanyFilledUp, setFillStatus] = useState(false);
  useEffect(() => {
    if (company) {
      let tradeDetailsCompleted = false;
      let companyDetailsCompleted = false;

      companyDetailsCompleted =
        Boolean(company?.industry) &&
        Boolean(company?.goods_category) &&
        Boolean(company?.annual_revenue) &&
        Boolean(company?.number_of_employees) &&
        Boolean(company?.incorporation_date) &&
        Boolean(company?.address_line1) &&
        Boolean(company?.country) &&
        Boolean(company?.postcode) &&
        Boolean(company?.city) &&
        Boolean(company?.incorporation_date) &&
        Boolean(company?.industry) &&
        Boolean(company?.shipping_address?.address_line1) &&
        Boolean(company?.shipping_address?.postcode) &&
        Boolean(company?.shipping_address?.city) &&
        Boolean(company?.shipping_address?.country);
      if (isPartner) {
        tradeDetailsCompleted =
          Boolean(relationship?.average_invoice_amount) &&
          Boolean(relationship?.average_payment_terms);
        setFillStatus(tradeDetailsCompleted && companyDetailsCompleted);
      } else setFillStatus(companyDetailsCompleted);
    }
  }, [...(deps ?? [])]);

  const keysToCheck = [
    "shipping_address",
    "accounting_software",
    "annual_revenue",
    "number_of_employees",
    "name",
    "reg_no",
    "incorporation_date",
    "address_line1",
    "city",
    "country",
    "postcode",
  ];
  const relationship_keys = ["average_invoice_amount", "average_payment_terms"];
  const shipping_address_keys = [
    "address_line1",
    "postcode",
    "city",
    "country",
  ];

  const info = keysToCheck.reduce((acc, key) => {
    if (key === "shipping_address") {
      //eslint-disable-next-line no-restricted-syntax
      for (const addressKey of shipping_address_keys) {
        if (
          company?.shipping_address?.[
            addressKey as keyof typeof company.shipping_address
          ] === null ||
          company?.shipping_address?.[
            addressKey as keyof typeof company.shipping_address
          ] === undefined ||
          company?.shipping_address?.[
            addressKey as keyof typeof company.shipping_address
          ] === ""
        ) {
          return `${acc}${ShippingAddressKeyMappings[addressKey as keyof typeof ShippingAddressKeyMappings]} is not filled up`;
        }
      }
    } else if (
      company?.[key as keyof Account] === null ||
      company?.[key as keyof Account] === undefined ||
      company?.[key as keyof Account] === ""
    ) {
      return `${acc}${key in KeyMappings ? KeyMappings[key as keyof typeof KeyMappings] : key} ${acc !== "" ? " , " : ""} `;
    }
    if (key === "average_invoice_amount" && relationship_keys.includes(key)) {
      return `${acc}${KeyMappings.average_invoice_amount} is not filled up`;
    }
    if (key === "average_payment_terms" && relationship_keys.includes(key)) {
      return `${acc}${KeyMappings.average_payment_terms} is not filled up`;
    }
    return acc;
  }, "");
  return {
    status: isCompanyFilledUp,
    info: info !== "" ? `${info} are not filled up` : "",
  };
}
