import { Grid } from "@mui/material";
import { useGetMonthlyInvoiceStatsQuery } from "api/api-operations";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import AvailableInvoiceChart from "components/DashboardInternal/AvailableInvoiceChart";
import DailyInvoiceChart from "components/DashboardInternal/DailyInvoiceChart";
import MonthlyInvoiceChart from "components/DashboardInternal/MonthlyInvoiceChart";

import useIsMobile from "hooks/useIsMobile";

function CommonInternalDashboard() {
  const isMobile = useIsMobile();
  const {
    data: monthlyInvoiceStats,
    isFetching,
    refetch,
  } = useGetMonthlyInvoiceStatsQuery(null);

  if (isFetching) return <SkeletonLoad bars={10} />;
  if (!monthlyInvoiceStats) return <NoData text="No Data to be displayed" />;
  return (
    <Layout
      title="Dashboard"
      id="platform-usage-statistics"
      textHeadingOptions={{
        fontWeight: "bolder",
        level: 2,
        fontSize: isMobile ? "1.2em" : "1.5em",
      }}
      headerConfig={{
        syncAction: refetch,
        left: 8,
        mid: 0,
        right: 2,
        xs: {
          left: 12,
          mid: 0,
          right: 0,
        },
        alignItems: "center",
      }}
      mode="default"
    >
      <Grid container spacing={3} sx={{ width: "100%", flexGrow: 1 }}>
        <Grid item xs={12} id="monthly-chart-container">
          <MonthlyInvoiceChart />
        </Grid>
        <Grid item xs={12} id="daily-chart-container">
          <DailyInvoiceChart />
        </Grid>
        <Grid item xs={12} id="available-chart-container">
          <AvailableInvoiceChart />
        </Grid>
      </Grid>
    </Layout>
  );
}

export default CommonInternalDashboard;
