import { Grid } from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import CheckboxField from "components/Common/FormFields/CheckboxField";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";
import { AgentProps } from "./constants";

const PrimaryContactDetails = () => {
  const formikProps = useFormikContext<AgentProps>();
  return (
    <MainCard title={"Primary Contact Details"} content boxShadow>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Input
            fullWidth
            sx={{ width: "100%" }}
            name="primaryContactName"
            label="Primary Contact Name"
            value={formikProps.values.primaryContactName}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            fullWidth
            sx={{ width: "100%" }}
            name="primaryContactTitle"
            label="Primary Contact Title"
            value={formikProps.values.primaryContactTitle}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            fullWidth
            sx={{ width: "100%" }}
            name="primaryContactEmail"
            label="Primary Contact Email"
            value={formikProps.values.primaryContactEmail}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            fullWidth
            sx={{ width: "100%" }}
            name="primaryContactPhone"
            label="Primary Contact Phone"
            value={formikProps.values.primaryContactPhone}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
            fullWidth
            sx={{ width: "100%" }}
            label={"Marketplace User"}
            name="isMarketplaceUser"
            value={formikProps.values.isMarketplaceUser}
            {...formikProps}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};
export default PrimaryContactDetails;
