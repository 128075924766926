import {
  Box,
  Button,
  Paper,
  Slide,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEditShareholderMutation } from "api/api-accounts";
import React, { useState } from "react";
import { toast } from "react-toastify";

interface EditShareholderProps {
  account_id: string;
  entity_id: string;
  name: string;
  percentage: number;
  address: string;
  onClose: () => void;
}

const EditShareholder: React.FC<EditShareholderProps> = ({
  account_id,
  entity_id,
  name,
  percentage,
  address,
  onClose,
}) => {
  const theme = useTheme();

  const [editedName, setEditedName] = useState(name);
  const [editedPercentage, setEditedPercentage] = useState<string>(
    percentage.toString()
  );
  const [editedAddress, setEditedAddress] = useState(address);
  const [editShareholder, { isLoading }] = useEditShareholderMutation();

  const handleSave = async () => {
    const parsedPercentage = parseFloat(editedPercentage);

    if (isNaN(parsedPercentage)) {
      toast.error("Percentage must be a valid number");
      return;
    }

    editShareholder({
      account_id,
      shareholder_id: entity_id,
      data: {
        shareholder_name: editedName,
        percentage: parsedPercentage,
        address: editedAddress,
      },
    })
      .unwrap()
      .then(() => {
        toast.success("Shareholder edited successfully");
        onClose();
      })
      .catch((error) => {
        toast.error(`Error occurred: ${error?.message}`);
      });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setEditedPercentage(isNaN(value) ? "" : value.toString());
  };

  return (
    <Slide in direction="left">
      <Paper
        elevation={3}
        sx={{ padding: theme.spacing(4), maxWidth: 500, margin: "auto" }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          {"Edit Shareholder " + name}
        </Typography>
        <form onSubmit={(e) => e.preventDefault()}>
          <Box mb={2}>
            <Typography>Name</Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
            />
          </Box>
          <Box mb={2}>
            <Typography>Percentage</Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              type="number"
              inputProps={{ min: 0, max: 100 }}
              value={editedPercentage}
              onChange={(e) => setEditedPercentage(e.target.value)}
              onBlur={handleBlur}
            />
          </Box>
          <Box mb={2}>
            <Typography>Address</Typography>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              value={editedAddress}
              onChange={(e) => setEditedAddress(e.target.value)}
            />
          </Box>
          <Box mt={4} display="flex" justifyContent="flex-end">
            <Button onClick={onClose} color="error" disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              disabled={isLoading}
              sx={{ marginLeft: theme.spacing(2) }}
            >
              Save
            </Button>
          </Box>
        </form>
      </Paper>
    </Slide>
  );
};

export default EditShareholder;
