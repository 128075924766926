import { Box, Slide } from "@mui/material";
import { useGetPlatformUsageSummaryQuery } from "api/api-marketing";
import { PLATFORM_USAGE_CATEGORIES } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useIsMobile from "hooks/useIsMobile";

const CumulativeChart = () => {
  const isMobile = useIsMobile();
  const {
    data: platformUsageSummary,
    isFetching,
    isLoading,
    refetch,
  } = useGetPlatformUsageSummaryQuery(null);

  const marketingValues: number[] = platformUsageSummary?.["Marketing Leads"]
    ? Object.values(platformUsageSummary["Marketing Leads"])
    : [];
  const originationValues: number[] = platformUsageSummary?.[
    "Origination Leads"
  ]
    ? Object.values(platformUsageSummary["Origination Leads"])
    : [];

  const options: Highcharts.Options = {
    chart: {
      type: "line",
    },
    title: {
      text: "Platform usage since July 2023",
    },
    xAxis: {
      categories: PLATFORM_USAGE_CATEGORIES,
    },
    series: [
      {
        type: "line",
        name: "Marketing Leads",
        data: marketingValues,
      },
      {
        type: "line",
        name: "Origination Leads",
        data: originationValues,
      },
    ],
  };

  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        {(isFetching || isLoading) && <SkeletonLoad bars={10} />}
        {!isFetching && (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </Box>
    </Slide>
  );
};

export default CumulativeChart;
