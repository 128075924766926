import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import {
  useDeleteDirectorMutation,
  useEditDirectorMutation,
} from "api/api-accounts";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";
import { MapContext } from "pages/maps/map";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import AddDirector from "./AddDirector";

interface ListOfDirectorsProps {
  entity_id: string;
  open: boolean;
  onClose: () => void;
  directors: Record<string, string>[];
  type: string;
  isLocked?: boolean;
}

const ListOfDirectors: React.FC<ListOfDirectorsProps> = ({
  entity_id,
  open,
  onClose,
  directors,
  type,
  isLocked = false,
}) => {
  const theme = useTheme();
  const mapId = useContext(MapContext);
  const [showAddDirector, setShowAddDirector] = useState(false);
  const [deleteDirector] = useDeleteDirectorMutation();
  const [editDirector] = useEditDirectorMutation();

  const handleAddDirector = () => {
    setShowAddDirector(true);
  };

  const handleAddDirectorSuccess = () => {
    setShowAddDirector(false);
  };

  const onDelete = (director: Record<string, string>) => {
    deleteDirector({
      account_id: entity_id,
      director_id: director.director_id,
    })
      .unwrap()
      .then(() => {
        toast.success("Director deleted successfully");
      })
      .catch((error) => {
        toast.error(`Error occurred: ${error?.message}`);
      });
  };

  const onUpdate = (values: any) => {
    const updatePromises = values.directors.map((director: any) => {
      return editDirector({
        account_id: entity_id,
        director_id: director.director_id,
        data: {
          fullname: director.full_name,
          function: director.function,
          address: director.address,
        },
      }).unwrap();
    });

    Promise.all(updatePromises)
      .then(() => {
        toast.success("Directors updated successfully");
      })
      .catch((error) => {
        toast.error(`Error occurred: ${error?.message}`);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          padding: theme.spacing(4),
        },
      }}
    >
      <DialogTitle variant="h3">List of Directors</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      {showAddDirector ? (
        <AddDirector
          entityId={entity_id}
          entityType={type}
          callback={handleAddDirectorSuccess}
          onClose={() => setShowAddDirector(false)}
          isLocked={isLocked}
        />
      ) : (
        <Formik
          initialValues={{ directors }}
          onSubmit={onUpdate}
          enableReinitialize
        >
          {({ values, handleChange }) => (
            <Form>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Function</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.directors.map((director, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Input
                            name={`directors.${index}.full_name`}
                            label=""
                            value={director.full_name}
                            onChange={handleChange}
                            disabled={isLocked}
                          />
                        </TableCell>
                        <TableCell>
                          <Input
                            name={`directors.${index}.function`}
                            label=""
                            value={director.function}
                            onChange={handleChange}
                            disabled={isLocked}
                          />
                        </TableCell>
                        <TableCell>
                          <Input
                            name={`directors.${index}.address`}
                            label=""
                            value={director.address}
                            onChange={handleChange}
                            disabled={isLocked}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => onDelete(director)}
                            disabled={isLocked}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <DialogActions sx={{ padding: theme.spacing(2) }}>
                <Button
                  onClick={onClose}
                  sx={{ ml: 2 }}
                  color="error"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddDirector}
                  disabled={isLocked}
                >
                  Add Director
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  type="submit"
                  sx={{ ml: 2 }}
                  disabled={isLocked}
                >
                  Save Changes
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default ListOfDirectors;
