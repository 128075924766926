import { Box, Grid, useTheme } from "@mui/material";
import {
  useCaptchaMutation,
  useRegisterInvitedUserMutation,
} from "api/api-auth";
import { IS_LOGIN, TNC_URL, URL } from "codes";
import Modal from "components/Common/Modal";
import Footer from "components/Footer";
import SignUp from "components/SignUp";
import InfoPanel from "components/SignUp/InfoPanel";
import SignupTNC from "components/SignUp/SignupTNC";
import ZoomInfoHiddenText from "components/SignUp/ZoomInfoHiddenText";
import { modalStyling } from "components/SignUp/modalStyling";
import { FormikProps } from "formik";
import { clearStorage } from "helpers/clearStorage";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import styles from ".././signup.module.scss";
import { ISignUp, adminAddedValidationSchema } from "../formFields";

const AdminAddedSignupComponent = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const firstName = queryParams.get("firstName");
  const lastName = queryParams.get("lastName");

  const captchaRef = useRef(null);
  const [error, setError] = useState("");

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [signUpFormik, setSignupFormik] = useState<FormikProps<ISignUp>>();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { messageContainerProps, modalContentSx, modalOverlaySx, modalSx } =
    modalStyling({ isMobile, theme });
  const dispatch = useAppDispatch();
  const [siteVerify] = useCaptchaMutation();
  const [registerInvitedUser] = useRegisterInvitedUserMutation();

  const onCaptchaClick = (token: string, ekey: string): Promise<boolean> => {
    let response: Promise<boolean>;

    response = siteVerify({
      key: ekey,
      secret: import.meta.env.VITE_HCAPTCHA_SECRET_KEY,
      token,
    })
      .unwrap()
      .then((res) => {
        if (!res) {
          setError("Failed to Verify reCaptcha");
          return res;
        } else return res;
      })
      .catch((error) => {
        return false;
      });

    return response;
  };
  const signUpUser = async (data: ISignUp) => {
    clearStorage(dispatch);
    registerInvitedUser({
      first_name: data.firstName,
      last_name: data.lastName,
      title: data.title ?? "",
      email: data.email,
      password: data.password,
      mobile: data.phoneNumber,
      accepted_terms: data.agreementCheck,
      opted_marketing_updates: data.marketingCheck ?? false,
    })
      .unwrap()
      .then(() => history.push("/login", IS_LOGIN))
      .catch((e: any) => setError(e?.message));
  };

  const MT = "Incomlend Platform Signup - Connecting Your Cash";
  const MD =
    "Simplify trade finance with Incomlend. Sign up now for efficient funding solutions and hassle-free transactions.";
  return (
    <>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <meta name="title" content={MT} />
        <meta name="description" content={MD} />

        {/* <!-- Open Graph / Facebook --/> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={URL} />
        <meta property="og:title" content={MT} />
        <meta property="og:description" content={MD} />
        <meta property="og:image" content="" />

        {/* <!-- Twitter --/> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={URL} />
        <meta property="twitter:title" content={MT} />
        <meta property="twitter:description" content={MD} />
        <meta property="twitter:image" content="" />
        {import.meta.env.VITE_ENV === "main" && (
          <script>
            {`window[(function(_Qt2,_Xb){var _4z='';for(var _0n=0;_0n<_Qt2.length;_0n++){var _l5=_Qt2[_0n].charCodeAt();_4z==_4z;_l5-=_Xb;_l5+=61;_l5%=94;_l5!=_0n;_l5+=33;_Xb>4;_4z+=String.fromCharCode(_l5)}return _4z})(atob('a1phJSJ7dnQnXHYs'), 17)] = 'ad8fe8f5c51680604707';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_CqM,_94){var _ac='';for(var _fz=0;_fz<_CqM.length;_fz++){var _WP=_CqM[_fz].charCodeAt();_WP-=_94;_WP+=61;_94>6;_WP!=_fz;_ac==_ac;_WP%=94;_WP+=33;_ac+=String.fromCharCode(_WP)}return _ac})(atob('OEREQENoXV06Q1xKOVtDM0I5QERDXDM/PV1KOVtEMTdcOkM='), 46)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     })`}
          </script>
        )}
      </Helmet>
      <Box>
        <Grid
          id="signup-data"
          container
          sx={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* Till translations are updated, this is disabled
          {isMobile ? (
            <>
              <Grid item margin="2vh auto -10px auto">
                <Logo onClick={() => push(HOME)} width="100%" height={"auto"} />
                <Grid item>
                  <Grid container alignItems={"center"} justifyContent={"end"}>
                    <Grid item>
                      <Switcher unAuthorized />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )} */}
          <ZoomInfoHiddenText />
          <Grid item xs={12}>
            <SignUp
              className={styles.signupContainer}
              sidePanel={isMobile ? <></> : <InfoPanel />}
              validationSchema={adminAddedValidationSchema}
              // Till translations are updated, this is disabled
              // CustomHeader={
              //   isMobile ? (
              //     <></>
              //   ) : (
              //     <Grid item xs={12} paddingTop={"0 !important"}>
              //       <Grid
              //         container
              //         alignItems={"center"}
              //         justifyContent={"end"}
              //       >
              //         <Grid item>
              //           <Switcher unAuthorized />
              //         </Grid>
              //       </Grid>
              //     </Grid>
              //   )
              // }
              recaptchaKey={import.meta.env.VITE_HCAPTCHA_SITE_KEY}
              captchaRef={captchaRef}
              onSubmit={signUpUser}
              layoutProps={{ bodyClassName: styles.signupBody }}
              error={error}
              onCaptchaClick={onCaptchaClick}
              tnc={{
                checkboxLabel: `${t("agree-to-incomlend-pte-ltd")}`,
                linkText: `${t("terms-and-conditions")}*`,
                tncUrl: TNC_URL,
                action(e, props) {
                  if (e.target.checked) {
                    setOpen(true);
                    setSignupFormik(props);
                  }
                },
              }}
              login={() => history.push("/login")}
              additionalField={
                <>
                  <input type="hidden" id="zoomIn-cpyRev" />
                  <input type="hidden" id="zoomIn-cpyCtry" />
                  <input type="hidden" id="zoomIn-cpyInd" />
                  <input type="hidden" id="zoomIn-cpyWb" />
                </>
              }
              layoutFooter={
                <Grid
                  container
                  className={styles.footer}
                  alignItems="center"
                  spacing={1}
                  marginLeft={"56%"}
                  marginRight="auto"
                  marginBottom="1.5%"
                  maxWidth="44%"
                >
                  <Footer textAlign="start" horizontal params="?auth=0" />
                </Grid>
              }
              ignoredFields={{
                firstName: true,
                lastName: true,
              }}
              disabledFields={{
                email: true,
              }}
              headingText={"Welcome, " + firstName + " " + lastName}
              initialFormValues={{
                firstName: firstName,
                lastName: lastName,
                email: email,
              }}
            />
            <Modal
              width={isMobile ? "95vw" : "80vw"}
              height={isMobile ? "100vh" : "80vh"}
              modalContentSx={modalContentSx}
              message={<SignupTNC />}
              open={open}
              onClose={handleClose}
              messageContainerProps={messageContainerProps}
              closeButton
              modalOverlaySx={modalOverlaySx}
              sx={modalSx}
              primary={{
                children: "Accept",
                onClick: () => {
                  signUpFormik?.setFieldValue("agreementCheck", true);
                  handleClose();
                },
              }}
              secondary={{
                children: t("cancel"),
                onClick: () => {
                  signUpFormik?.setFieldValue("agreementCheck", false);
                  handleClose();
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default AdminAddedSignupComponent;
