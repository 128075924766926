import InfoIcon from "@mui/icons-material/Info";
import {
  Divider,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useGetPartnerEligibleCountiesQuery } from "api/api-misc";
import { CL_DEFAULT, CL_STEP, MAX_CL, MIN_CL, SUPPLIER } from "codes";
import { FormikProps, useField, useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import { RegistrationFormFields } from "helpers/formFields";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";

import CLInputs from "../TradingRelation/CLInputs";
import styles from "./cl.module.scss";
import CLSlider from "./Slider";

const SubmitCL = () => {
  const formikProps: FormikProps<RegistrationFormFields> = useFormikContext();
  const { list, companyType } = formikProps.values;
  const {
    data: buyerEligibleCountries,
    isFetching: isFetchingBuyerEligibleCountries,
  } = useGetPartnerEligibleCountiesQuery("buyer");

  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(false);
  const errors: any = formikProps.errors;
  const CURRENCY = "USD";
  const theme = useTheme();

  useEffect(() => {
    if (Boolean(list.length) && isLoading) setIsLoading(false);
    else setIsLoading(true);
  }, [list]);
  const isIneligibleCountry = (country: string) =>
    !Boolean(
      buyerEligibleCountries?.find(
        (countries) => countries.name.toLowerCase() === country.toLowerCase()
      )
    );
  const valueCleaner = (val: string): number => {
    return Number(val?.replaceAll(",", ""));
  };
  const [field, meta, helpers] =
    useField<RegistrationFormFields["requested_amount"]>(`requested_amount`);

  const isError: boolean = Boolean(
    (field?.value && field?.value < MIN_CL) ||
      (field?.value && field?.value > MAX_CL)
  );

  return (
    <Grid container spacing={isMobile ? 0 : 2}>
      {/* //Factoring */}
      {companyType?.toLowerCase() === SUPPLIER?.toLowerCase() ? (
        <>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              textAlign="center"
              fontWeight={"bolder"}
              fontSize={isMobile ? "1.5em" : "2em"}
            >
              {`${t("credit-limit-request")}`}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} paddingTop={"0 !important"} margin="auto">
            <Typography
              textAlign="center"
              sx={{
                color: theme.palette.tertiary.main,
                fontWeight: "bolder",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "0.8em",
                },
              }}
            >
              {companyType?.toLowerCase() === SUPPLIER?.toLowerCase()
                ? `${t("about-to-submit-clr")}`
                : "You are about to submit a Credit limit request, click “Next” to continue."}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12} margin="auto">
            <Grid container spacing={isMobile ? 0 : 3}>
              <Grid item xs={12} mt={2}>
                <Typography
                  variant="h3"
                  fontWeight={"bold"}
                  fontSize={isMobile ? "1.2em" : "1.5em"}
                  textAlign={isMobile ? "center" : "start"}
                >
                  {t("submit-your-buyer-info")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {!isMobile && (
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={11} lg={3} textAlign="start">
                              <Typography
                                variant="h4"
                                fontWeight="bolder"
                                fontSize={"1em"}
                              >
                                {t("company-full-legal-name")}
                              </Typography>
                            </Grid>
                            <Grid item xs={11} lg={3} textAlign="start">
                              <Typography
                                variant="h4"
                                fontWeight="bolder"
                                fontSize={"1em"}
                              >
                                {`${t("registration-number")}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={11} lg={3} textAlign="start">
                              <Grid container spacing={2}>
                                <Grid item>
                                  <Typography
                                    variant="h4"
                                    fontWeight="bolder"
                                    fontSize={"1em"}
                                  >
                                    {`${t("country")}`}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Tooltip
                                    title={
                                      <Grid
                                        container
                                        maxHeight={"40vh"}
                                        overflow="scroll"
                                      >
                                        <Grid item>
                                          <Typography
                                            variant="h3"
                                            fontSize="1.2em"
                                            fontWeight={"bolder"}
                                          >
                                            {t("authorised-buyer-countries")}
                                          </Typography>
                                        </Grid>
                                        {buyerEligibleCountries &&
                                          buyerEligibleCountries?.map(
                                            (country) => (
                                              <Grid
                                                item
                                                xs={12}
                                                key={country.id}
                                              >
                                                {country.name}
                                              </Grid>
                                            )
                                          )}
                                      </Grid>
                                    }
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={11} lg={3} textAlign="start">
                              <Grid container spacing={2}>
                                <Grid item>
                                  <Typography
                                    fontWeight="bolder"
                                    variant="h4"
                                    fontSize={"1em"}
                                    textAlign="start"
                                  >
                                    {t("select-credit-limit")}
                                  </Typography>
                                </Grid>

                                <Grid item>
                                  <Tooltip
                                    title={
                                      <Grid container maxHeight={"40vh"}>
                                        {`Please request your credit limit within ${CURRENCY}${currencyFormatter(
                                          { amount: MIN_CL, decimalPlace: 0 }
                                        )} - ${CURRENCY}${currencyFormatter({
                                          amount: MAX_CL,
                                          decimalPlace: 0,
                                        })}`}
                                      </Grid>
                                    }
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    )}

                    {Boolean(list?.length) &&
                      list?.map((buyer: any, index: any) => {
                        const isInEligible = isIneligibleCountry(
                          list[index]["country"]
                        );

                        return (
                          <CLInputs
                            key={index}
                            index={index}
                            isInEligible={isInEligible}
                          />
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        // Reverse Factoring
        <>
          <Grid item xs={12}>
            <Typography variant="h3" fontWeight="bold" textAlign="center">
              Select the desired Credit Limit amount
            </Typography>
            <Typography fontSize={"0.8em"} textAlign="center">
              Once submitted, the amount cannot be changed
            </Typography>
          </Grid>
          <Grid item xs={12} lg={10}>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"start"}
              spacing={1}
            >
              <Grid item lg={5} xs={5} ml={5}>
                <NumericFormat
                  displayType={"input"}
                  thousandSeparator={true}
                  variant="standard"
                  customInput={TextField}
                  fullWidth
                  sx={{
                    backgroundColor: "white",

                    margin: 0,
                    "&:focus": {
                      border: "#ced4da",
                      backgroundColor: "white",
                    },
                    "&:hover": {
                      backgroundColor: "white",
                    },
                    "&$focused": {
                      backgroundColor: "white",
                    },
                    ".Mui-disabled": {
                      backgroundColor: "#ced4da",
                      color: "#393939",
                      WebkitTextFillColor: "#393939 !important",
                    },
                    ".Mui-focusVisible": {
                      outlineColor: "#393939",
                    },
                  }}
                  InputProps={{
                    inputProps: { min: MIN_CL, max: MAX_CL },
                    startAdornment: isMobile ? (
                      <InputAdornment position="start">
                        {CURRENCY}
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                    endAdornment: isMobile ? (
                      <></>
                    ) : (
                      <InputAdornment position="start">
                        {CURRENCY}
                      </InputAdornment>
                    ),
                    className: isError ? styles.invalidAmount : undefined,
                  }}
                  {...field}
                  onBlur={(e: any) => {
                    helpers.setValue(valueCleaner(e.target.value), true);
                  }}
                  error={isError}
                  onChange={(e: any) => {
                    helpers.setValue(valueCleaner(e.target.value), true);
                  }}
                />
                {isError && (
                  <FormHelperText error={isError}>
                    {errors?.[field.name]}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item lg={11} xs={8} margin="auto">
                <CLSlider
                  name={"requested_amount"}
                  min={MIN_CL}
                  step={CL_STEP}
                  max={MAX_CL}
                  spacing={2}
                  defaultValue={CL_DEFAULT}
                  valueTag={"USD"}
                  value={formikProps.values.requested_amount}
                  sx={{
                    width: "100%",
                    color: theme.palette.tertiary.main,
                  }}
                  {...formikProps}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default SubmitCL;
