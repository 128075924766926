import {
  Avatar,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useAddNodeMutation,
  useGetCountriesQuery,
  useGetDepartmentMembersQuery,
  useGetDepartmentsQuery,
  useGetEntitiesQuery,
  useGetOrgChartPaletteQuery,
} from "api/api-human-resources";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import Modal from "components/Common/Modal";
import VariableDropdown from "components/Common/VariableDropdown";
import { Form, Formik, useFormikContext } from "formik";
import React from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";

interface Node {
  id: string;
  name: string;
  title: string;
  level: number;
  color: string;
  member_layout: string[];
  node_id: string;
  tag?: string;
}

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  orgChart: Node[];
  update: () => void;
}

interface AddNodeInitialValues {
  tag: string;
  name: string;
  title: string;
  level: number;
  color: string;
  member_layout: string[];
  node_id: string;
  type: string;
  department_tag: string;
  country_tag: string;
  entity_tag: string;
  reporting_to: string;
}

const modalOverlaySx = {
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "2ch",
  padding: "7ch",
  height: "inherit !important",
  fontWeight: "inherit !important",
  backgroundColor: "#ffffff !important",
};
const modalSx = {
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  "&:focus-visible": {
    outline: "none",
  },
};

const AddOrgchartNodeModal: React.FC<Props> = (props) => {
  //level 0 consists of departments
  //level 1 consists of incorporated countries
  //level 2 consists of entities
  const { open, setOpen, orgChart, update } = props;

  const { data: departments = [] } = useGetDepartmentsQuery();
  const { data: countries = [] } = useGetCountriesQuery();
  const { data: entities = [] } = useGetEntitiesQuery();

  const [addNode, { isLoading: isAddingNode }] = useAddNodeMutation();

  const last_node = Math.max(...(orgChart?.map((item) => item.level) ?? []));

  const DEPARTMENT_TAGS = departments?.map((item) => ({
    tag: item.tag,
    name: item.name,
  }));

  const COUNTRY_TAGS = countries?.map((item) => ({
    tag: item.tag,
    name: item.name,
  }));

  const ENTITY_TAGS = entities?.map((item) => ({
    tag: item.tag,
    name: item.name,
  }));

  const theme = useTheme();
  type AddNodeInitialValuesExtended = AddNodeInitialValues & {
    department: string;
    reporting: string;
    entity: string;
    country: string;
  };
  const initialValues: AddNodeInitialValuesExtended = {
    name: "",
    tag: "",
    title: "",
    level: last_node + 1,
    color: "#007ad0",
    member_layout: [],
    node_id: "",
    type: "member",
    department_tag: "",
    country_tag: "",
    entity_tag: "",
    reporting_to: "",
    department: "",
    reporting: "",
    entity: "",
    country: "",
  };

  const { data: orgchartPalette = [] } = useGetOrgChartPaletteQuery();

  const AddNode = ({ type }: { type: string }) => {
    const formikProps = useFormikContext<AddNodeInitialValuesExtended>();
    const { values, setFieldValue } = formikProps;
    const { data: DEPARTMENT_MEMBERS = [] } = useGetDepartmentMembersQuery(
      values?.department_tag ?? "",
      {
        skip: !values.department_tag,
      }
    );
    const NODE_TAGS = DEPARTMENT_MEMBERS?.map((item) => ({
      baseTag: item?.tag?.split(/(\d+)/),
      level: item?.level,
    }))
      ?.map(({ baseTag, level }) => ({
        tag: baseTag?.[0],
        number: Number(baseTag?.[1] ?? 0),
        level,
      }))
      ?.sort((a, b) => a.number - b.number)
      ?.pop();

    switch (type) {
      case "department":
        return <></>;
      case "country":
        return <></>;
      case "entity":
        return <></>;
      case "member":
        return (
          <Stack
            spacing={1}
            id="node-particulars"
            sx={{ flexGrow: 1, width: "100%" }}
          >
            <Input
              name="name"
              label="Name"
              placeholder="Full Name"
              fullWidth
              required
              {...formikProps}
            />
            <Input
              name="title"
              label="Title"
              placeholder="Title"
              fullWidth
              required
              {...formikProps}
            />

            <VariableDropdown
              data={DEPARTMENT_TAGS}
              name="department"
              keyValue={"name"}
              value={values.department}
              setValue={(value) => {
                const tag = DEPARTMENT_TAGS.find(
                  (item) => item.name === value
                )?.tag;
                formikProps.setFieldValue("department_tag", tag);
              }}
              label="Select Department"
              placeholder="Select Department"
              fullWidth
              required
              {...formikProps}
            />

            <VariableDropdown
              data={DEPARTMENT_MEMBERS}
              name="reporting"
              keyValue={"name"}
              value={values.reporting}
              setValue={(value) => {
                const tag = DEPARTMENT_MEMBERS.find(
                  (item) => item.name === value
                )?.tag;
                const BASE_TAG = NODE_TAGS?.tag;
                const BASE_NUMBER = NODE_TAGS?.number ?? 0;
                const BASE_LEVEL = NODE_TAGS?.level ?? values.level;
                formikProps.setFieldValue(
                  "tag",
                  `${BASE_TAG}${BASE_NUMBER + 1}`
                );
                formikProps.setFieldValue("level", BASE_LEVEL);
                formikProps.setFieldValue("reporting_to", tag);
              }}
              label="Reporting to"
              placeholder="Select Member"
              fullWidth
              required
              disabled={!values.department_tag}
              noHover={!values.department_tag}
              {...formikProps}
            />

            <VariableDropdown
              data={COUNTRY_TAGS}
              name="country"
              keyValue={"name"}
              value={values.country}
              setValue={(value) => {
                const tag = COUNTRY_TAGS.find(
                  (item) => item.name === value
                )?.tag;
                formikProps.setFieldValue("country_tag", tag);
              }}
              label="Select Country"
              placeholder="Select Country"
              fullWidth
              required
              {...formikProps}
            />
            <VariableDropdown
              data={ENTITY_TAGS}
              name="entity"
              keyValue={"name"}
              value={values.entity}
              setValue={(value) => {
                const tag = ENTITY_TAGS.find(
                  (item) => item.name === value
                )?.tag;
                formikProps.setFieldValue("entity_tag", tag);
              }}
              label="Select Entity"
              placeholder="Select Entity"
              fullWidth
              required
              {...formikProps}
            />

            <Stack spacing={0.5} justifyContent={"start"} alignItems={"start"}>
              <Typography fontWeight={"bold"}>Select Color</Typography>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"start"}
                alignItems={"center"}
              >
                {orgchartPalette?.map((color) => (
                  <Avatar
                    key={color}
                    sx={{
                      backgroundColor: color,
                      width: "2em",
                      height: "2em",
                      outline:
                        color === values?.color
                          ? `2px solid ${theme.palette.primary.main}`
                          : "none",
                      border:
                        color === values?.color
                          ? `2px solid ${theme.palette.white.main}`
                          : "none",
                      my: "0.5em !important",
                    }}
                    variant="rounded"
                    onClick={() => {
                      color !== values?.color && setFieldValue("color", color);
                    }}
                  >
                    {" "}
                  </Avatar>
                ))}
              </Stack>
            </Stack>
          </Stack>
        );
      default:
        return <></>;
    }
  };
  const NODE_TYPES = [
    { value: "department", label: "Department" },
    { value: "country", label: "Country" },
    { value: "entity", label: "Entity" },
    { value: "member", label: "Member" },
  ];
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({ values, setFieldValue, dirty }) => (
        <Form>
          <Modal
            message={
              <Grid item xs={12}>
                <Stack
                  justifyContent={"start"}
                  alignItems={"start"}
                  spacing={2}
                >
                  <LabelViewOnly label={"Select Type"} />
                  <Select
                    labelId="select-node-type"
                    id="node-type-select"
                    value={values.type}
                    label={""}
                    fullWidth
                    onChange={(event: SelectChangeEvent) =>
                      setFieldValue("type", event.target.value)
                    }
                  >
                    {NODE_TYPES.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>

                  <AddNode type={values.type} />
                </Stack>
              </Grid>
            }
            sx={modalSx}
            Header={
              <Typography fontSize={"1.5em"} fontWeight={"bold"}>
                {`Add a New ${
                  NODE_TYPES.find((item) => item.value === values.type)?.label
                }`}
              </Typography>
            }
            width={isMobile ? "90%" : "40%"}
            height={"auto"}
            open={open}
            modalOverlaySx={modalOverlaySx}
            modalFooterSx={{ maxWidth: "100%" }}
            onClose={() => setOpen(false)}
            closeButton
            modalHeaderSx={{ backgroundColor: "transparent" }}
            secondary={{
              children: "Close",
              onClick: () => setOpen(false),
              color: "error",
            }}
            primary={{
              children: "Add",
              disabled: !dirty,
              loading: isAddingNode,
              onClick: () => {
                addNode({
                  tag: values.tag,
                  name: values.name,
                  title: values.title,
                  level: values.level,
                  color: values.color,
                  member_layout: values.member_layout,
                  department_tag: values.department_tag,
                  country_tag: values.country_tag,
                  entity_tag: values.entity_tag,
                  reporting_to: values.reporting_to,
                })
                  .unwrap()
                  .then(() => {
                    toast.success(`Node added ${values.name} successfully`);
                    update();
                    setOpen(false);
                  })
                  .catch((err) => {
                    toast.error(
                      `Error adding node: ${err?.message ?? "Unknown error"}`
                    );
                  });
              },
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddOrgchartNodeModal;
