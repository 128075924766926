import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useGetCountriesByTypeQuery } from "api/api-misc";
import AutoComplete from "components/Common/AutoComplete";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Country } from "types";
import styles from "./company.module.scss";
import { CompanyDetails } from "./validationSchema";

const ShippingAddress = ({ isDisabled }: { isDisabled: boolean }) => {
  const { t } = useTranslation();
  const formikProps = useFormikContext<CompanyDetails>();
  const { address_line1, address_line2, country, postcode, city } =
    formikProps.values;

  const { data: countries } = useGetCountriesByTypeQuery("");
  const setter = (value: string) => {
    const selected = countries?.find((item: any) => item.name === value);
    formikProps.setFieldValue("shipping_address.country", selected?.name, true);
    formikProps.setFieldValue("shipping_address.iso3", selected?.id, true);
  };

  return (
    <Grid item xs={12}>
      <Stack justifyContent={"start"} alignItems={"start"} spacing={1}>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"start"}
        >
          <Typography variant="h4" fontWeight={"bolder"}>
            Operation or Shipping Address
          </Typography>
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={() => {
              const shipping_address = {
                address_line1,
                address_line2,
                city,
                country,
                postcode,
                iso3: countries?.find((item: any) => item.name === country)?.id,
              };

              formikProps.setFieldValue("shipping_address", shipping_address);
            }}
          >
            Copy from Registered Address
          </Button>
        </Stack>
        <Grid container>
          <Grid item xs={12}>
            <Input
              labelClassName={styles.labelClass}
              name={"shipping_address.address_line1"}
              label={`${t("address")} 1`}
              required
              placeholder={`${t("street")} 1`}
              style={{ width: "100%" }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelClassName={styles.labelClass}
              name={"shipping_address.address_line2"}
              label={`${t("address")} 2`}
              placeholder={`${t("street")} 2`}
              style={{ width: "100%" }}
              disabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent={"space-between"}>
              <Grid item lg={6} xs={12}>
                <Input
                  labelClassName={styles.labelClass}
                  name={"shipping_address.city"}
                  label={`${t("city")}`}
                  required
                  placeholder={`${t("city")}`}
                  style={{ width: "100%" }}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <LabelViewOnly
                  label={`${t("country")}`}
                  labelClassName={styles.labelClass}
                />
                <AutoComplete
                  data={countries as Country[]}
                  name={"shipping_address.country"}
                  labelKey={"name" as any}
                  value={formikProps.values.shipping_address?.country ?? ""}
                  disabled={isDisabled}
                  placeholder={`${t("select-country")}`}
                  onChange={(
                    event: any,
                    newValue:
                      | NonNullable<string | Country>
                      | (string | Country)[]
                      | null
                  ) => {
                    setter((newValue as Country)?.name);
                  }}
                  fullWidth
                  {...formikProps}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              labelClassName={styles.labelClass}
              name={"shipping_address.postcode"}
              label={`${t("postcode")}`}
              required
              placeholder={`${t("postcode")}`}
              style={{ width: "100%" }}
              disabled={isDisabled}
            />
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
};
export default ShippingAddress;
