import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Alert, Grid, Stack, SvgIconTypeMap } from "@mui/material";
import {
  useEditScreeningCompanyMutation,
  usePostCompanyProofMutation,
} from "api/api-compliance";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { useFormikContext } from "formik";
import toCamelCase from "helpers/camelCase";
import useAccountData from "hooks/useAccountData";
import { MapContext } from "pages/maps/map";
import React, { useContext } from "react";
import DocumentProof from "./DocumentProof";
import { ScreeningProps } from "./types";

const COLOR_BY_HIT: Record<
  ScreeningProps["status"],
  SvgIconTypeMap["props"]["color"]
> = {
  "No Hits": "success",
  "True Hits": "error",
  "False Hits": "warning",
  "Hits Cleared": "primary",
};

interface BaseScreeningProps {
  variation: string;
  accountType: "supplier" | "buyer";
  source: string;
  isLocked: boolean;
}

const BaseScreening: React.FC<BaseScreeningProps> = ({
  variation,
  accountType,
  source,
  isLocked,
}) => {
  const mapName = useContext(MapContext);
  const { accountInput } = useAccountData({
    accountType,
    mapId: mapName ?? "",
  });

  const formikProps = useFormikContext<{
    initialValuesCompany: Record<string, ScreeningProps>;
  }>();
  const [patchValues] = useEditScreeningCompanyMutation();

  const saveToServer = async (field: string, value: any) => {
    await formikProps.setFieldValue(field, value);

    patchValues({
      map_name: mapName ?? "",
      account_type: accountType as "supplier" | "buyer",
      data: {
        map_metadata_id:
          formikProps.values?.initialValuesCompany?.[toCamelCase(variation)]
            ?.map_metadata_id,
        source: source,
        value: {
          status:
            formikProps.values?.initialValuesCompany?.[toCamelCase(variation)]
              ?.status,
          remarks:
            formikProps.values?.initialValuesCompany?.[toCamelCase(variation)]
              ?.remarks,
        },
      },
    });
  };

  const [postProofDocument] = usePostCompanyProofMutation();

  const handleProofSubmit = (file: File) => {
    postProofDocument({
      map_name: mapName ?? "",
      account_type: accountType as "supplier" | "buyer",
      map_metadata_id:
        formikProps.values?.initialValuesCompany[toCamelCase(variation)]
          ?.map_metadata_id,
      data: {
        file: file,
        source: source,
      },
    });
  };

  const data =
    formikProps.values?.initialValuesCompany?.[toCamelCase(variation)];

  const hasValidValues =
    data?.values?.length > 0 && data?.values.some((v) => v.value !== null);

  // Check for missing fields and determine if an alert is needed
  const missingFields = [];
  if (hasValidValues) {
    if (!data?.status) {
      missingFields.push("Status");
    }
    if (!data?.remarks) {
      missingFields.push("Remarks");
    }
    if (!data?.documents || data?.documents.length === 0) {
      missingFields.push("Proof");
    }
  }

  const isRequired = !!data?.values?.some((value) => value?.value);

  return (
    <>
      {hasValidValues && missingFields?.length > 0 && (
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Alert severity="warning">
            Missing fields for {variation}: {missingFields?.join(", ")}
          </Alert>
        </Stack>
      )}
      <Stack direction={{ xs: "column", sm: "row" }} spacing={0} mt={1}>
        <Grid item xs={12} lg={3}>
          <Stack direction={"column"}>
            <LabelViewOnly label={variation} />
            {variation === "Company Full Name" && (
              <LabelViewOnly
                sx={{ fontWeight: 400 }}
                label={
                  accountInput
                    ? accountInput?.["Company Full Name"] + "(Account Input)"
                    : "N/A"
                }
              />
            )}
            {data?.values?.map((value, index) =>
              value ? (
                <LabelViewOnly
                  key={index}
                  sx={{ fontWeight: 400 }}
                  label={
                    value.value
                      ? value.value +
                        " (" +
                        value.reports?.join(", ").toUpperCase() +
                        ")"
                      : null
                  }
                />
              ) : null
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={3}>
          <VariableDropdown
            required={isRequired}
            disabled={isLocked}
            noHover={isLocked}
            name={`initialValuesCompany.${[toCamelCase(variation)]}.status`}
            keyValue={"name"}
            value={data?.status}
            handleSave={(value) => {
              saveToServer(
                `initialValuesCompany.${[toCamelCase(variation)]}.status`,
                value
              );
            }}
            setValue={(value) =>
              formikProps.setFieldValue(
                `initialValuesCompany.${[toCamelCase(variation)]}.status`,
                value
              )
            }
            data={[
              { name: "No Hits" },
              { name: "True Hits" },
              { name: "False Hits" },
              { name: "Hits Cleared" },
            ]}
            direction={"column"}
            label={"Status"}
            icon={
              <FiberManualRecordIcon
                color={COLOR_BY_HIT[data?.status as ScreeningProps["status"]]}
              />
            }
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <VariableInput
            required={isRequired}
            disabled={isLocked}
            noHover={isLocked}
            name={`initialValuesCompany.${[toCamelCase(variation)]}.remarks`}
            label={"Remarks"}
            type="text"
            value={data?.remarks}
            handleSave={(value) => {
              saveToServer(
                `initialValuesCompany.${[toCamelCase(variation)]}.remarks`,
                value
              );
            }}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <DocumentProof
            id={data?.id ?? ""}
            label={data?.label ?? ""}
            description={data?.name ?? ""}
            documents={data?.documents}
            submit={handleProofSubmit}
            accountType={accountType}
            isLocked={isLocked}
          />
        </Grid>
      </Stack>
    </>
  );
};

export default BaseScreening;
