import { clearAllListeners, ThunkDispatch } from "@reduxjs/toolkit";
import { resetStateAction } from "redux/actions/resetState";
import { appStateSlice } from "redux/reducers/appStatesReducer";
import { companySlice } from "redux/reducers/companyReducer";
import { countrySlice } from "redux/reducers/countriesReducer";
import { clSlice } from "redux/reducers/creditLimitsReducer";
import { invoicesSlice } from "redux/reducers/invoicesReducer";
import { notifSlice } from "redux/reducers/notifReducer";
import { stepsSlice } from "redux/reducers/stepsReducer";
import { store } from "redux/store";
import { clearLocalStorage } from "./clearLocalStorage";

import { accountrtk } from "api/api-accounts";
import { aiForm } from "api/api-aiform";
import { auth } from "api/api-auth";
import { banks } from "api/api-banks";
import { companiesrtk } from "api/api-company";
import { compliance } from "api/api-compliance";
import { creditCommittee } from "api/api-credit-committee";
import { finance } from "api/api-finance";
import { human_resources } from "api/api-human-resources";
import { it } from "api/api-it";
import { legal } from "api/api-legal";
import { maps } from "api/api-maps";
import { marketing } from "api/api-marketing";
import { marketplace } from "api/api-marketplace";
import { misc } from "api/api-misc";

import { notifications } from "api/api-notifications";
import { operations } from "api/api-operations";
import { origination } from "api/api-origination";
import { risk } from "api/api-risk";
import { sales } from "api/api-sales";
import { support } from "api/api-support";
import { users } from "api/api-users";
import { users_v2 } from "api/api-users-v2";
import { vendors } from "api/api-vendors";
import { vendorsv2 } from "api/api-vendorsv2";
import { cargoSlice } from "redux/reducers/cargoReducer";
import { experimentalFeaturesSlice } from "redux/reducers/experimentalFeaturesReducer";

export function clearStorage(dispatch: ThunkDispatch<any, any, any>) {
  //RTK
  dispatch(companySlice.actions.clearAll());
  dispatch(countrySlice.actions.clearAll());
  dispatch(stepsSlice.actions.clearSteps());
  dispatch(appStateSlice.actions.clearAppState());
  dispatch(invoicesSlice.actions.clearAll());
  dispatch(clSlice.actions.clearAll());
  dispatch(notifSlice.actions.clearNotification());
  dispatch(cargoSlice.actions.clearShipments());
  dispatch(experimentalFeaturesSlice.actions.clearEFState());
  dispatch(resetStateAction());
  //Redux
  store.dispatch(clearAllListeners());
  //RTKQ
  dispatch(aiForm.util.resetApiState());
  dispatch(banks.util.resetApiState());
  dispatch(companiesrtk.util.resetApiState());
  dispatch(notifications.util.resetApiState());
  dispatch(misc.util.resetApiState());
  dispatch(sales.util.resetApiState());
  dispatch(origination.util.resetApiState());
  dispatch(users.util.resetApiState());
  dispatch(vendors.util.resetApiState());
  dispatch(vendorsv2.util.resetApiState());
  dispatch(users_v2.util.resetApiState());
  dispatch(accountrtk.util.resetApiState());
  dispatch(marketplace.util.resetApiState());
  dispatch(creditCommittee.util.resetApiState());
  dispatch(it.util.resetApiState());
  dispatch(finance.util.resetApiState());
  dispatch(marketing.util.resetApiState());
  dispatch(legal.util.resetApiState());
  dispatch(risk.util.resetApiState());
  dispatch(operations.util.resetApiState());
  dispatch(support.util.resetApiState());
  dispatch(auth.util.resetApiState());
  dispatch(maps.util.resetApiState());
  dispatch(compliance.util.resetApiState());
  dispatch(human_resources.util.resetApiState());
  //Browser
  clearLocalStorage();
}
