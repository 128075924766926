import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useEditMapSummaryMutation } from "api/api-maps";
import MainCard from "components/Common/Cards/MainCard";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import { useFormikContext } from "formik";
import { PERMISSIONS } from "permissions";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import { MapSummary } from "types";
import styles from "../maps.module.scss";

const SecurityDocuments = () => {
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );

  const disable = !USER_PERMISSIONS.includes(PERMISSIONS.map_admin);
  const formikProps = useFormikContext<MapSummary>();
  const { map_name } = formikProps.values;
  const [editMapSummary] = useEditMapSummaryMutation();
  const editor = (field: string, value: any, friendlyLabel: string) => {
    editMapSummary({
      map_name,
      data: { [field]: value },
    })
      .unwrap()
      .then(() => {
        toast.success(`${friendlyLabel} Updated`);
      })
      .catch((err) => toast.error(`Error: ${err?.message}`));
  };
  return (
    <MainCard
      sx={{ width: "100%" }}
      title="Security Documents"
      headerSx={{ py: 2, px: 3 }}
      content
      contentSX={{ py: 2, px: 3 }}
      children={
        <Grid
          container
          spacing={1}
          alignItems={"top"}
          justifyContent={"space-between"}
        >
          <Grid item lg={3} xs={12}>
            <LabelViewOnly
              label="Required"
              labelClassName={styles.labelClass}
            />
          </Grid>
          <Grid item lg={9} xs={12}>
            <FormGroup>
              <FormControl>
                <RadioGroup
                  aria-labelledby="security_required"
                  name="security_required"
                  value={formikProps.values.security_required}
                  onChange={(e) => {
                    formikProps.setFieldValue(
                      "security_required",
                      e.target.value === "true",
                      true
                    );
                    editor(
                      "security_required",
                      e.target.value === "true",
                      "Security Required"
                    );
                  }}
                  row
                >
                  <Grid container spacing={1}>
                    <Grid item>
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        sx={{ alignItems: "center" }}
                        label={"Yes"}
                        disabled={disable}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        sx={{ alignItems: "center" }}
                        label={"No"}
                        disabled={disable}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </FormGroup>
          </Grid>

          {formikProps.values.security_required && (
            <>
              <Grid item lg={12} xs={12}>
                <LabelViewOnly
                  label="Security Type"
                  labelClassName={styles.labelClass}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <FormGroup>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="security_type"
                      name="security_type"
                      value={formikProps.values.security_type}
                      onChange={(e) => {
                        formikProps.setFieldValue(
                          "security_type",
                          e.target.value,
                          true
                        );
                        editor(
                          "security_type",
                          e.target.value,
                          "Security Type"
                        );
                      }}
                      row
                    >
                      <Grid container>
                        <Grid item>
                          <FormControlLabel
                            value={"Post Dated Cheque"}
                            control={<Radio />}
                            sx={{ alignItems: "center" }}
                            label={"Post Dated Cheque"}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value={"Promissory Note"}
                            control={<Radio />}
                            sx={{ alignItems: "center" }}
                            label={"Promissory Note"}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value={"Personal Guarantee"}
                            control={<Radio />}
                            sx={{ alignItems: "center" }}
                            label={"Personal Guarantee"}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value={"Corporate Guarantee"}
                            control={<Radio />}
                            sx={{ alignItems: "center" }}
                            label={"Corporate Guarantee"}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value={"Floating Charge / Deed of Debenture"}
                            control={<Radio />}
                            sx={{ alignItems: "center" }}
                            label={"Floating Charge / Deed of Debenture"}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </FormGroup>
              </Grid>
            </>
          )}
        </Grid>
      }
    />
  );
};
export default SecurityDocuments;
