import { useTheme } from "@mui/material/styles";
import { createSvgIcon } from "@mui/material/utils";
import React from "react";
import { Action, Fab } from "react-tiny-fab";

interface IFloatingBox {
  icon: any;
  name: string;
  onClick: () => void;
  size?: "small" | "medium" | "large";
  backgroundColor?: React.CSSProperties["backgroundColor"];
}

export interface FloatingBoxProps {
  /**Floating Button Data */
  data: IFloatingBox[];
  event?: "hover" | "click";
  style?: React.CSSProperties;
  alwaysShowTitle?: boolean;
  icon?: React.ReactNode;
  mainButtonStyles?: React.CSSProperties;
  onClick?: (e: React.FormEvent) => void;
  text?: string;
  children?: React.ReactNode;
}

const FloatingBox = (props: FloatingBoxProps) => {
  const { data, ...rest } = props;
  const theme = useTheme();
  const Support = createSvgIcon(
    <>
      <circle className="cls-1" cx="10" cy="10" r="7.45" fill="currentColor" />
      <g>
        <path
          d="M12.72,11.81c1,0,1.81-.81,1.81-1.81s-.81-1.81-1.81-1.81"
          stroke={theme.palette.background.default}
          fill="currentColor"
        />
        <path
          d="M12.72,11.81h0c1,0,1.81-.81,1.81-1.81s-.81-1.81-1.81-1.81"
          stroke={theme.palette.background.default}
          fill="currentColor"
        />
        <path
          d="M7.28,8.19c-1,0-1.81,.81-1.81,1.81s.81,1.81,1.81,1.81"
          stroke={theme.palette.background.default}
          fill="currentColor"
        />
        <path
          d="M7.28,8.19c-1,0-1.81,.81-1.81,1.81s.81,1.81,1.81,1.81h0"
          stroke={theme.palette.background.default}
          fill="currentColor"
        />
        <path
          d="M7.28,11.81v-3.62c0-1.5,1.22-2.72,2.72-2.72s2.72,1.22,2.72,2.72v3.62c0,1.5-1.22,2.72-2.72,2.72"
          stroke={theme.palette.background.default}
          fill="currentColor"
        />
      </g>
    </>,
    "SupportIcon"
  );

  return (
    <Fab
      mainButtonStyles={{
        backgroundColor: theme.palette.error.main,
      }}
      icon={
        <Support
          color={"primary"}
          viewBox="0 0 20 20"
          sx={{ width: 60, height: 43.954 }}
        />
      }
      alwaysShowTitle={true}
      {...rest}
    >
      {data &&
        data.map((button, i) => {
          const { icon: Icon, backgroundColor, onClick, name } = button;
          return (
            <Action
              text={name}
              onClick={onClick}
              style={{ backgroundColor }}
              key={i}
            >
              <Icon />
            </Action>
          );
        })}
    </Fab>
  );
};

export default FloatingBox;
