import { Grid } from "@mui/material";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";
import React from "react";

const FeesDetails: React.FC = () => {
  return (
    <Formik
      initialValues={{
        membershipDirectCustomerFrequency: "",
        membershipIndirectCustomerFrequency: "",
        financingFeesInstalmentBasis: "",
        financingFeesInvoiceBasis: "",
        financingFeesFrequency: "",
        financingFeesCost: "",
        overdueAdditionalDiscountRate: "",
        sdrRateType: "",
        supplierDiscountRate: "",
        floatingRateMarginLessThan90: "",
        floatingRateMarginGreaterThan90: "",
        sdrChargedBasis: "",
        salesOwner: "",
        salesManager: "",
        headOfSales: "",
      }}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        return (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Input
                  name="membershipDirectCustomerFrequency"
                  label="Membership Direct Customer Frequency"
                  value={formikProps.values.membershipDirectCustomerFrequency}
                  onChange={(e) => {
                    formikProps.setFieldValue(
                      "membershipDirectCustomerFrequency",
                      e.target.value
                    );
                  }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  name="membershipIndirectCustomerFrequency"
                  label="Membership Indirect Customer Frequency"
                  value={formikProps.values.membershipIndirectCustomerFrequency}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  name="financingFeesInstalmentBasis"
                  label="Financing Fees Instalment Basis"
                  value={formikProps.values.financingFeesInstalmentBasis}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  fullWidth
                  name="financingFeesInvoiceBasis"
                  label="Financing Fees Invoice Basis"
                  value={formikProps.values.financingFeesInvoiceBasis}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  fullWidth
                  name="financingFeesFrequency"
                  label="Financing Fees Frequency"
                  value={formikProps.values.financingFeesFrequency}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  name="financingFeesCost"
                  label="Financing Fees Cost"
                  value={formikProps.values.financingFeesCost}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  name="overdueAdditionalDiscountRate"
                  label="Overdue Additional Discount rate / Month"
                  value={formikProps.values.overdueAdditionalDiscountRate}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  name="sdrRateType"
                  label="SDR Rate Type"
                  value={formikProps.values.sdrRateType}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  name="supplierDiscountRate"
                  label="Supplier Discount Rate / Month"
                  value={formikProps.values.supplierDiscountRate}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  name="floatingRateMarginLessThan90"
                  label="Floating Rate Margin = or <90% (Manual)"
                  value={formikProps.values.floatingRateMarginLessThan90}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  name="floatingRateMarginGreaterThan90"
                  label="Floating rate margin >90% (Manual)"
                  value={formikProps.values.floatingRateMarginGreaterThan90}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  name="sdrChargedBasis"
                  label="SDR charged basis"
                  value={formikProps.values.sdrChargedBasis}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Drop
                  name="salesOwner"
                  label="Sales Owner"
                  value={formikProps.values.salesOwner}
                  keyValue={""}
                  data={[]}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Drop
                  name="salesManager"
                  label="Sales Manager"
                  value={formikProps.values.salesManager}
                  keyValue={""}
                  data={[]}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Drop
                  name="headOfSales"
                  label="Head of Sales"
                  value={formikProps.values.headOfSales}
                  keyValue={""}
                  data={[]}
                  {...formikProps}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FeesDetails;
