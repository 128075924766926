import { Grid, InputAdornment, Paper, useTheme } from "@mui/material";
import {
  useEditMapSummaryMutation,
  useGetMapInsurersQuery,
  useGetMapSummaryQuery,
} from "api/api-maps";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import { MapContext } from "pages/maps/map";
import { PERMISSIONS } from "permissions";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import { mapSummaryDefaults } from "../defaults";
import styles from "../maps.module.scss";
import { ADVANCE_RATIOS, PAYMENT_TERMS } from "./constants";

const ScoreCardFooter: React.FC = () => {
  const theme = useTheme();
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );

  const READ_ONLY = USER_PERMISSIONS.includes(PERMISSIONS.map_view_risk);

  const id = useContext(MapContext);
  const { data: mapData } = useGetMapSummaryQuery(id ?? "", { skip: !id });
  const { data: insurers } = useGetMapInsurersQuery(id ?? "", { skip: !id });
  const [editMapSummary] = useEditMapSummaryMutation();
  const editor = (field: string, value: any, friendlyLabel: string) => {
    id &&
      editMapSummary({
        map_name: id,
        data: { [field]: value },
      })
        .unwrap()
        .then(() => {
          toast.success(`${friendlyLabel} Updated`);
        })
        .catch((err) => toast.error(`Error: ${err?.message}`));
  };

  return (
    <Formik
      initialValues={{
        ...mapSummaryDefaults,
        ...mapData,
        limitRequested: currencyFormatter({
          amount: insurers?.requested_amount ?? 0,
        }),
        limitApproved: currencyFormatter({
          amount: insurers?.approved_amount ?? 0,
        }),
      }}
      onSubmit={console.log}
    >
      {(formikProps) => (
        <Form>
          <Paper
            sx={{
              width: { xs: "100%", lg: "100%" },
              margin: "auto",
              padding: { xs: 2, lg: 4 },
              borderRadius: 2,
              border: `2px solid ${theme.palette.secondary.main}80`,
            }}
          >
            <Grid
              container
              justifyContent={"start"}
              alignItems={"center"}
              spacing={1}
            >
              <Grid item xs={12} lg={6}>
                <Input
                  name={"limitRequested"}
                  label={"Limit Requested"}
                  labelClassName={styles.labelClass}
                  textfieldProps={{
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">USD</InputAdornment>
                      ),
                    },
                  }}
                  type="money"
                  disabled
                  fullWidth
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <VariableInput
                  name={"score_card_comments"}
                  label={"Risk Comments"}
                  labelClassName={styles.labelClass}
                  value={formikProps.values.score_card_comments}
                  placeholder={
                    "Highlights / Mitigations / Proposals / Guarantees"
                  }
                  handleSave={(value) => {
                    editor("score_card_comments", value, "Risk Comments");
                  }}
                  fullWidth
                  style={{ width: "100%" }}
                  disabled={READ_ONLY}
                  noHover={READ_ONLY}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Input
                  name={"limitApproved"}
                  label={"Limit Approved"}
                  type="money"
                  textfieldProps={{
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">USD</InputAdornment>
                      ),
                    },
                  }}
                  labelClassName={styles.labelClass}
                  disabled
                  fullWidth
                  style={{ width: "100%" }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Drop
                  label="Advance Ratio Approved"
                  name={"advance_ratio_approved"}
                  keyValue={"name"}
                  data={ADVANCE_RATIOS}
                  value={`${formikProps.values.advance_ratio_approved}`}
                  fullWidth
                  disabled={READ_ONLY}
                  setValue={(value) => {
                    formikProps.setFieldValue(
                      "advance_ratio_approved",
                      value as string
                    );
                    editor(
                      "advance_ratio_approved",
                      value,
                      "Advance Ratio Approved"
                    );
                  }}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Drop
                  label="Payment Terms Approved"
                  name={"payment_terms_approved"}
                  keyValue={"name"}
                  value={formikProps.values.payment_terms_approved}
                  data={PAYMENT_TERMS}
                  disabled={READ_ONLY}
                  setValue={(value) => {
                    formikProps.setFieldValue(
                      "payment_terms_approved",
                      value as string
                    );
                    editor(
                      "payment_terms_approved",
                      value,
                      "Payment Terms Approved"
                    );
                  }}
                  fullWidth
                  {...formikProps}
                />
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ScoreCardFooter;
