import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";

export const finance = createApi({
  reducerPath: "finance",
  tagTypes: ["BALANCES"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/finance`,
  }),
  endpoints: (builder) => ({
    getBalances: builder.query<
      {
        amount: number;
        ccy: string;
        created_date: Date;
        effective_date: string;
        fx_usd_rate_daily_amount: number;
        invoice_finance_deal_name: string;
        invoice_finance_deal_url: string;
        posting_id: string;
        running_balance: number;
        running_balance_usd: number;
        status: string;
        transaction_id: string;
        transaction_url: string;
        trustee: string;
        type: string;
      }[],
      string
    >({
      query: (ewallet_name) => ({
        url: `/get-running-balances-from-ewallet?ewallet_name=${ewallet_name}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["BALANCES"],
    }),
  }),
});

export const { useGetBalancesQuery } = finance;
