import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { Account } from "types";
import type { RootState } from "../store";

// Define a type for the slice state

interface StepperAccount extends Partial<Account> {
  type?: string;
}

interface CompanyState {
  value: StepperAccount[];
  currentCompany: StepperAccount;
}

// Define the initial state using that type
export const initialState: CompanyState = {
  value: [],
  currentCompany: {
    id: "",
    accounting_software: "",
    address_line1: "",
    address_line2: "",
    addresses: [],
    agree_to_privacy_policy: false,
    agree_to_terms: false,
    annual_revenue: undefined,
    city: "",
    contact_email: "",
    country: "",
    goods_category: "",
    incorporation_date: undefined,
    industry: "",
    internal_name: "",
    is_identified: false,
    is_submitted: false,
    name: "",
    number_of_employees: "",
    postcode: "",
    profile: "",
    reg_no: "",
    same_shipping_operation_address: false,
    sf_account_id: "",
    shipping_address: {
      address_line1: "",
      address_line2: "",
      city: "",
      country: "",
      postcode: "",
    },
    status: "",
    website: "",
    source: "",
    type: "",
  },
};

export const companySlice = createSlice({
  name: "companies",
  // `createSlice` will infer the state type from the `initialState` argument
  // Use the PayloadAction type to declare the contents of `action.payload`
  initialState,
  //CRUD
  reducers: {
    addCompany: (state, action: PayloadAction<StepperAccount>) => {
      state.value = [action.payload]; //for Ver 0 state.value.push(action.payload);
    },
    deleteCompany: (state, action: PayloadAction<string | number>) => {
      state.value = state.value.filter(
        (company) => company.reg_no !== action.payload
      );
    },
    setCurrentCompany: (state, action: PayloadAction<StepperAccount>) => {
      state.currentCompany = Object.assign({}, action.payload);
    },
    updateCompany: (state, action: PayloadAction<StepperAccount>) => {
      const position = state.value
        .map((company) => company.reg_no)
        .indexOf(action.payload.reg_no);
      if (position !== -1) state.value.splice(position, 1, action.payload);
    },
    clearAll: () => initialState,
  },
});

export const {
  addCompany,
  deleteCompany,
  setCurrentCompany,
  updateCompany,
  clearAll,
} = companySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCompanies = (state: RootState) => state.companies.value;

export default companySlice.reducer;
