export function clearLocalStorage() {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  sessionStorage.clear();
  localStorage.clear();
  // eslint-disable-next-line no-console
  console.log("Session cleared");
}
