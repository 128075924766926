import TimerIcon from "@mui/icons-material/Timer";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useGetAccountQuery } from "api/api-accounts";

import { useTrackCargosQuery } from "api/api-vendorsv2";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Fragment } from "react";
import { useAppSelector } from "redux/hooks";

const TrackingList = ({
  setSelectedShipmentId,
}: {
  setSelectedShipmentId: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const USER_ROLE = useAppSelector((state) => state.appState.role);
  const {
    data: company,
    isLoading: isLoadingCompany,
    isFetching: isFetchingCompany,
    refetch: refetchCompany,
  } = useGetAccountQuery(USER_ROLE?.id, {
    skip: !Boolean(USER_ROLE?.id),
  });
  const { data: shipments, isFetching } = useTrackCargosQuery(
    company?.id ?? ""
  );

  return (
    <Fragment>
      {isFetching && <SkeletonLoad bars={2} />}
      {!isFetching &&
        shipments &&
        shipments.map((item) => (
          <Card elevation={10} sx={{ padding: 3, borderRadius: 3 }}>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography fontWeight={"bold"}>
                    Shipment ID - {item.shipment_id}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontStyle={"italic"}>
                    Please wait while we gather the latest updates on your
                    shipment. The process would take about 10-15 minutes.
                  </Typography>
                </Grid>
                <Grid item>
                  <TimerIcon color="warning" />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setSelectedShipmentId(item.shipment_id)}
              >
                Live tracking
              </Button>
            </CardActions>
          </Card>
        ))}
    </Fragment>
  );
};
export default TrackingList;
