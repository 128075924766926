import { Box, Grid, Slide, Stack, Typography, useTheme } from "@mui/material";
import {
  useGetInvoiceStatusesQuery,
  useGetRollsQuery,
} from "api/api-operations";
import Board from "components/Common/Board";
import { Id } from "components/Common/Board/types";
import Layout from "components/Common/Layout";
import Modal from "components/Common/Modal";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Switch from "components/Common/Switch";
import InvoiceStatusDetail from "components/Invoices/InvoiceStatusDetail";
import useIsMobile from "hooks/useIsMobile";
import { useState } from "react";
import { InvoiceStatus } from "types";
import styles from "./funder.module.scss";

interface StatusData extends Record<string, any> {
  [statusName: string]: InvoiceStatus[];
}
const Funder = () => {
  const isMobile = useIsMobile();

  const {
    data: invoiceStatuses,
    isFetching,
    refetch,
  } = useGetInvoiceStatusesQuery(null, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    pollingInterval: 300000,
  });

  const {
    data: invoiceRolls,
    isFetching: isFetchingRolls,
    refetch: refetchRolls,
  } = useGetRollsQuery(null, {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    pollingInterval: 300000,
  });

  const [showDetails, setShowDetails] = useState<{
    data: InvoiceStatus | undefined;
    open: boolean;
  }>({ data: undefined, open: false });
  const theme = useTheme();

  const modalOverlaySx = {
    backgroundColor: `${theme.palette.background.paper} !important`,
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "2ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
    ">div": {
      ">div": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const formatInvoiceStatuses = (data: StatusData): any[] => {
    let result: any[] = [];
    for (let value in data) {
      if (Boolean(data[value]?.length))
        result.push(
          ...data[value].map((item: InvoiceStatus) => ({
            status: value,
            id: item.name,
            name: item.buyer_name,
            data: item,
            value: item.requested_amount_usd,
          }))
        );
      else
        result.push({
          id: "",
          link: "",
          status: value,
          name: "",
          data: {},
          value: 0,
        });
    }
    return result;
  };
  const [rolls, setRolls] = useState(false);
  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={`Invoice Statuses`}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          className={styles.layout}
          LayoutHeader={
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: "11px",
                border: `1px dashed ${theme.palette.primary.main}`,
                py: 1,
              }}
            >
              <Typography fontWeight={"bold"}>Current</Typography>
              <Switch
                onChange={(e, checked) => setRolls(checked)}
                value={rolls}
              />
              <Typography fontWeight={"bold"}>Rolls</Typography>
            </Stack>
          }
          headerConfig={{
            left: 3,
            mid: 2.5,
            right: 6.5,
            syncAction: rolls ? refetchRolls : refetch,
            xs: {
              left: 12,
              mid: 12,
              right: 12,
            },
            alignItems: "center",
            justifyContent: "start",
          }}
          mode="default"
        >
          {isFetching && !invoiceStatuses?.length && <SkeletonLoad bars={10} />}
          {!isFetching && invoiceStatuses && !rolls && (
            <>
              <Board
                data={formatInvoiceStatuses(invoiceStatuses)}
                type="InvoiceStatus"
                action={(taskId: Id) => {
                  const targetInvoice = formatInvoiceStatuses(
                    invoiceStatuses
                  )?.find((invoice) => invoice.id === taskId);
                  setShowDetails({
                    data: {
                      ...targetInvoice?.data,
                      status: targetInvoice?.status,
                    },
                    open: true,
                  });
                }}
                groupByKey={"supplier_buyer_map"}
                isDragDisabled
                defaultConsolidate
              />
              <Modal
                message={
                  <Grid item xs={12}>
                    <InvoiceStatusDetail
                      {...(showDetails.data as InvoiceStatus)}
                    />
                  </Grid>
                }
                Header={
                  <Typography
                    fontWeight={"bold"}
                    fontSize={"1.3rem"}
                    sx={{ pl: "10px !important" }}
                  >
                    Invoice Finance Deal #{showDetails.data?.name}
                  </Typography>
                }
                sx={modalSx}
                width={isMobile ? "90%" : "50%"}
                height={"auto"}
                modalContentSx={{ height: "auto" }}
                open={showDetails.open}
                modalOverlaySx={modalOverlaySx}
                modalFooterSx={{ maxWidth: "100%" }}
                onClose={() =>
                  setShowDetails({
                    open: false,
                    data: undefined,
                  })
                }
                closeButton
              />
            </>
          )}
          {isFetchingRolls && !invoiceRolls?.length && (
            <SkeletonLoad bars={10} />
          )}
          {!isFetchingRolls && invoiceRolls && rolls && (
            <>
              <Board
                data={formatInvoiceStatuses(invoiceRolls)}
                type="InvoiceStatus"
                action={(taskId: Id) => {
                  const targetInvoice = formatInvoiceStatuses(
                    invoiceRolls
                  )?.find((invoice) => invoice.id === taskId);
                  setShowDetails({
                    data: {
                      ...targetInvoice?.data,
                      status: targetInvoice?.status,
                    },
                    open: true,
                  });
                }}
                groupByKey={"supplier_buyer_map"}
                isDragDisabled
                defaultConsolidate
              />
              <Modal
                message={
                  <Grid item xs={12}>
                    <InvoiceStatusDetail
                      {...(showDetails.data as InvoiceStatus)}
                    />
                  </Grid>
                }
                Header={
                  <Typography
                    fontWeight={"bold"}
                    fontSize={"1.3rem"}
                    sx={{ pl: "10px !important" }}
                  >
                    Invoice Finance Deal #{showDetails.data?.name}
                  </Typography>
                }
                sx={modalSx}
                width={isMobile ? "90%" : "50%"}
                height={"auto"}
                modalContentSx={{ height: "auto" }}
                open={showDetails.open}
                modalOverlaySx={modalOverlaySx}
                modalFooterSx={{ maxWidth: "100%" }}
                onClose={() =>
                  setShowDetails({
                    open: false,
                    data: undefined,
                  })
                }
                closeButton
              />
            </>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};
export default Funder;
