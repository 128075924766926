export default function groupBy<T>(
  arr: T[],
  key: keyof T
): Record<string, T[]> {
  return arr.reduce((acc: Record<string, T[]>, obj: T) => {
    const propertyValue = obj[key] as string;
    //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    acc[propertyValue] = acc[propertyValue] || [];
    acc[propertyValue].push(obj);
    return acc;
  }, {});
}
