export interface AgentProps {
  accountAgent: string;
  agentName: string;
  address: string;
  city: string;
  postCode: string;
  country: string;
  companyRegistrationNumber: string;
  website: string;
  primaryContactName: string;
  primaryContactTitle: string;
  primaryContactEmail: string;
  primaryContactPhone: string;
  isMarketplaceUser: boolean;
  secondaryContactName: string;
  secondaryContactTitle: string;
  secondaryContactEmail: string;
  secondaryContactPhone: string;
  isSecondaryMarketplaceUser: boolean;
  agentType: string;
  companyLegalType: string;
  companyIncorporationDate: string;
  onboardingStage: string;
  nextReviewDate: string;
  accountStatus: string;
  accountAgentFeesType: string;
  agentFeeValue: string;
  ageOfMap: string;
}
export const initialValues: AgentProps = {
  accountAgent: "",
  agentName: "",
  address: "",
  city: "",
  postCode: "",
  country: "",
  companyRegistrationNumber: "",
  website: "",
  primaryContactName: "",
  primaryContactTitle: "",
  primaryContactEmail: "",
  primaryContactPhone: "",
  isMarketplaceUser: false,
  secondaryContactName: "",
  secondaryContactTitle: "",
  secondaryContactEmail: "",
  secondaryContactPhone: "",
  isSecondaryMarketplaceUser: false,
  agentType: "",
  companyLegalType: "",
  companyIncorporationDate: "",
  onboardingStage: "",
  nextReviewDate: "",
  accountStatus: "",
  accountAgentFeesType: "",
  agentFeeValue: "",
  ageOfMap: "",
};
