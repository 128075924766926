import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import useIsMobile from "hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import AddPartner from "./AddPartner";
const BuyerInfo = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();

  const isMobile = useIsMobile();
  return (
    <Grid item xs={12} marginTop="1em">
      <TableContainer
        component={Paper}
        sx={{
          border: "1px solid rgb(224, 224, 224)",
          borderRadius: "10px",
          margin: "1vh auto",
        }}
      >
        <Table aria-label="buyer-info-table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="h3"
                  fontWeight={"bold"}
                  fontSize={isMobile ? "1em" : "1.5em"}
                  textAlign={isMobile ? "center" : "start"}
                >
                  {t("submit-your-buyer-info")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>
                <AddPartner disabled={disabled} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
export default BuyerInfo;
