import { Typography } from "@mui/material";
import { toSnakeCase } from "helpers/toSnakeCase";
import { Item } from "..";
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ items }: { items: Item[] }) => {
  const menuItems = items?.map((menu, i) => {
    switch (Boolean(menu.items)) {
      case true:
        return (
          <NavCollapse key={toSnakeCase(menu?.title)} menu={menu} level={1} />
        );
      case false:
        return <NavItem key={toSnakeCase(menu?.title)} item={menu} level={1} />;
      default:
        return (
          <Typography
            key={"error-menu-key"}
            variant="h6"
            color="error"
            align="center"
          >
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{menuItems}</>;
};

export default NavGroup;
