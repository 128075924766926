import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SxProps, useTheme } from "@mui/system";
import useIsMobile from "hooks/useIsMobile";
import memoize from "memoize-one";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { VariableSizeList as List } from "react-window";
import NoData from "../NoData";
import DataTableCard from "./Components/Table/Card";
import Column from "./Components/Table/Column";

import TablePagination from "./Components/TablePagination";

import { SearchProps } from "../Search";
import { Header } from "./Components/Table/Header";
import { Toolbar } from "./Components/Toolbar";
import styles from "./datatablev2.module.scss";
import { getColumns, hideColumns } from "./processor";

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;

interface ToolBarOptions {
  showColumns: boolean;
  showFilters: boolean;
  showExport: boolean;
  showSearch: boolean;
}
export interface CustomColumn extends Record<string, any> {
  type?: "text" | "number" | "boolean" | "component";
  renderCell?: (params: Record<string, any>) => JSX.Element;
  valueFormatter?: (value: any) => string;
  sortComparator?: (a: any, b: any) => void;
  boolTitles?: string[];
  minWidth?: number;
  noFilter?: boolean;
  cellSx?: SxProps;
  link?: { href: (params: Record<string, any>) => string; target?: string };
}
export interface DataTablev2Props<T extends Record<string, any>> {
  /**Input data as an array of objects */
  data: T[];
  /**custom properties applied to each column
   *
   * @example
   * type?: "text" | "number" | "boolean" | "component";
   * renderCell?: (params: Record<string, any>) => JSX.Element;
   * valueFormatter?: (value: any) => string;
   * sortComparator?: (a: any, b: any) => void;
   * boolTitles?: string[];
   * minWidth?: number;
   * noFilter?: boolean;
   * cellSx?: SxProps;
   * link?: {href: (params:T)=>string, target: string};
   */
  customColumns?: CustomColumn;
  /**list of fields to be hidden */
  toBeHidden?: string[];
  /**props for searchbar */
  searchBarProps?: SearchProps;
  /**color to apply to field text */
  mainColor?: string;
  /**color to apply to stripe*/
  stripeBgColor?: string;
  /**whether a pointer has to be shown over hover*/
  pointerOnHover?: boolean;
  /**display a toolbar*/
  toolBar?: boolean;
  /**Toolbar options*/
  toolBarOptions?: Partial<ToolBarOptions>;
  /**get clicked field's object*/
  onRowClicked?: (field: T, columnName?: string) => void;
  /**toggle pagination*/
  enablePagination?: boolean;
  sx?: SxProps;
  toolbarSx?: SxProps;
  toolSx?: SxProps;
  headerSx?: SxProps;
  paginationSx?: SxProps;
  /**Custom action filter trigger*/
  onFilterChange?: (filters: Record<string, any>) => void;
  /**Pre-saved filters*/
  initialFilters?: Record<string, any>;
  /**enables checkbox for DataTable*/
  hasCheckBox?: boolean;
  /**Preset hidden columns in DataTable*/
  hideablesPreset?: string[];
  getColumnVisibility?: (columns: T) => void;
  headerTextColor?: string;
  rowsPerPage?: number;
  /**Width of one cell is `#columns * defaultColumnWidth`*/
  defaultColumnWidth?: number;
  tableSx?: CSSProperties;
  CustomToolBar?: () => JSX.Element;
  cellSx?: SxProps;
  collapsible?: string[];
  useKey?: string;
  applyCellStyling?: (column: string, item: T) => SxProps;
}

const DataTablev2 = <T extends Record<string, any>>(
  props: DataTablev2Props<T>
) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const {
    data = [],
    pointerOnHover = true,
    customColumns = {},
    toBeHidden = [],
    searchBarProps,
    mainColor = theme.palette.primary.main,
    stripeBgColor = "rgba(204, 204, 204, 0.3)",
    toolBar = true,
    toolBarOptions = {
      showColumns: true,
      showFilters: true,
      showExport: true,
      showSearch: true,
    },
    onRowClicked = (field) => {},
    enablePagination = true,

    onFilterChange = () => {},
    initialFilters = {},
    hideablesPreset = [],
    getColumnVisibility = () => {},

    hasCheckBox = false,
    toolbarSx,
    toolSx,
    headerSx,
    paginationSx,
    sx,
    headerTextColor,
    rowsPerPage = 10,
    defaultColumnWidth = 100,
    tableSx,
    CustomToolBar = () => <></>,
    cellSx,
    collapsible = [],
    useKey = "id",
    applyCellStyling = () => ({}),
  } = props;

  const [filteredData, setFilteredData] = useState<T[]>(data);
  const [allHideableColumns, setAllHideableColumns] = useState<string[]>([]);
  const [order, setOrder] = useState<Record<string, any>>({});
  const [curr, setCurr] = useState(1);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [paginatedData, setPaginatedData] = useState<T[]>(
    [...data].splice(0, data?.length)
  );
  const [count, setCount] = useState(
    (filteredData?.length < 10 ? filteredData?.length : rowsPerPage) ?? 0
  );
  const columns = getColumns(data);
  const initialHideables = [...toBeHidden, ...hideColumns];
  const headers = columns.filter(
    (item) => ![...allHideableColumns, ...initialHideables].includes(item)
  );
  const handleRowClick = useCallback(
    (field: T, columnName: string) => onRowClicked(field, columnName),
    [onRowClicked]
  );

  const isMobile = useIsMobile();
  const HEIGHT = count * ITEM_HEIGHT; //overall height of the table
  useEffect(() => {
    setCount(data?.length < 10 ? data?.length : rowsPerPage);
    setPaginatedData([...data].splice(0, rowsPerPage));
  }, [rowsPerPage, data]);

  const TableHeader = () => (
    <TableRow
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        boxSizing: "border-box",
        minWidth: "100%",
        width: "100%",
      }}
      component={"div"}
    >
      {headers.map((header, index) => (
        <Header
          key={index}
          defaultColumnWidth={defaultColumnWidth}
          customColumns={customColumns ?? {}}
          item={header}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          headers={columns}
          order={order}
          setOrder={setOrder}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          sx={headerSx}
          headerTextColor={headerTextColor}
        />
      ))}
    </TableRow>
  );
  const createItemData = memoize((classes, columns, data) => ({
    columns,
    classes,
    items: data,
  }));

  const itemData = createItemData({}, headers, paginatedData);

  const itemKey = (index: any, data: any) =>
    `${data.items[index][useKey]}-${index}`;

  if (data.length === 0) {
    return null;
  }

  return (
    <Grid
      container
      justifyContent={"space-between"}
      spacing={1}
      ref={ref}
      id="datatable-ctr"
      maxWidth={"xl"}
      width={"100%"}
    >
      {toolBar ? (
        <Grid item xs={12}>
          <Toolbar
            data={filteredData}
            initialData={data}
            headers={headers}
            setFilteredData={setFilteredData}
            searchBarProps={searchBarProps}
            toolBarOptions={toolBarOptions}
            columnsToHide={initialHideables}
            customColumns={customColumns}
            allHideableColumns={allHideableColumns}
            setAllHideableColumns={setAllHideableColumns}
            onFilterChange={onFilterChange}
            initialFilters={initialFilters}
            hideablesPreset={hideablesPreset}
            getColumnVisibility={getColumnVisibility}
            sx={toolbarSx}
            toolSx={toolSx}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <CustomToolBar />
        </Grid>
      )}

      <Grid
        item
        xs={12}
        sx={sx}
        id="datatable-table-ctr"
        className={styles.tablegrid}
        justifyContent="flex-start"
      >
        {!isMobile ? (
          <TableContainer component={Paper} sx={{ width: "auto" }}>
            <Table
              sx={{
                tableLayout: "auto",
                ...tableSx,
                height: "100%",
                width: "auto",
                flexGrow: 1,
              }}
              aria-label="data-table"
              size="small"
              component="div"
            >
              <TableHead
                component="div"
                sx={{
                  boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.3), 0px -1px 2px rgba(0, 0, 0, 0.3) inset
                `,
                }}
              >
                <TableHeader />
              </TableHead>
              <TableBody component="div" sx={{ width: "100%", height: "100%" }}>
                {Boolean(paginatedData.length) && (
                  <List
                    height={HEIGHT}
                    itemCount={paginatedData.length}
                    itemSize={() => ITEM_HEIGHT}
                    className={styles.datatablelist}
                    width={"100%"}
                    itemKey={itemKey}
                    itemData={{
                      ...itemData,
                      stripeBgColor,
                      pointerOnHover,
                      mainColor,
                      customColumns,
                      defaultColumnWidth,
                      handleRowClick,
                      cellSx,
                      applyCellStyling,
                    }}
                  >
                    {Column}
                  </List>
                )}

                {paginatedData?.length === 0 && (
                  <NoData
                    text="No data to display"
                    sx={{
                      margin: "auto",
                      width: ref?.current?.clientWidth ?? "100%",
                    }}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Stack spacing={2}>
            {paginatedData.length &&
              paginatedData.map((item, i) => (
                <DataTableCard
                  key={i}
                  item={item}
                  collapsible={collapsible}
                  handleRowClick={handleRowClick}
                  columns={columns.filter(
                    (item) =>
                      ![...allHideableColumns, ...initialHideables].includes(
                        item
                      )
                  )}
                  customColumns={customColumns}
                />
              ))}
            {paginatedData?.length === 0 && (
              <NoData
                text="No data to display"
                sx={{
                  margin: "auto",
                  width: ref?.current?.clientWidth ?? "100%",
                }}
              />
            )}
          </Stack>
        )}
      </Grid>

      {enablePagination && (
        <Grid item xs={12} justifyContent="flex-end" sx={paginationSx}>
          <TablePagination
            data={filteredData}
            setPaginatedData={setPaginatedData}
            total={filteredData.length}
            curr={curr}
            count={count}
            setCount={setCount}
            setCurr={setCurr}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default DataTablev2;
