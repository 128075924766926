import { Grid, Link, Stack, Theme, Typography, useTheme } from "@mui/material";
import { useGetMapSummaryQuery } from "api/api-maps";
import { ACCOUNTS, INCOMLEND_INTERNAL_LINK } from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Icons from "components/Company/Registration/SelectorCard/Cards";
import React, { useContext } from "react";
import { MapContext } from ".";

interface DetailsStripProps {
  // Define your component's props here
}

const ProductIcon = ({ product }: { product: string }) => {
  switch (product) {
    case "factoring":
      return (
        <Icons.SupplierIcon active={true} activeColor="white" size="15px" />
      );
    case "reverse_factoring":
      return <Icons.BuyerIcon active={true} activeColor="white" size="15px" />;
    default:
      return <Icons.BuyerIcon active={true} activeColor="white" size="15px" />;
  }
};
const getBackgroundColor = (theme: Theme, product: string) => {
  switch (product) {
    case "factoring":
      return theme.palette.primary.main;
    case "reverse_factoring":
      return theme.palette.error.main;
    default:
      return theme.palette.primary.main;
  }
};
const DetailsStrip: React.FC<DetailsStripProps> = (props) => {
  const theme = useTheme();
  const id = useContext(MapContext);
  const {
    data: mapData,
    refetch: refetchMapSummary,
    isLoading,
  } = useGetMapSummaryQuery(id ?? "", { skip: !id });
  const linkBarStyles = {
    padding: "0.8em 2ch",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: "bold",
    width: "fit-content",
    fontSize: "0.9em",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
      padding: "1em 2ch",
    },
  };

  return (
    <Grid container spacing={{ xs: 2, lg: 1 }} alignItems={"center"}>
      <Grid item>
        {isLoading && <SkeletonLoad bars={1} />}

        {!isLoading && mapData && (
          <Link
            sx={{
              ...linkBarStyles,
            }}
            href={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/${mapData?.buyer_internal_name}`}
            target="_blank"
          >
            {`Buyer: ${mapData?.buyer_name}`}
          </Link>
        )}
      </Grid>
      <Grid item>
        {isLoading && <SkeletonLoad bars={1} />}
        {!isLoading && mapData && (
          <Link
            href={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/${mapData?.seller_internal_name}`}
            target="_blank"
            sx={{
              ...linkBarStyles,
            }}
          >
            {`Seller: ${mapData?.seller_name}`}
          </Link>
        )}
      </Grid>
      <Grid item>
        {isLoading && <SkeletonLoad bars={1} />}
        {!isLoading && mapData && (
          <Stack
            direction={"row"}
            spacing={1}
            alignContent={"center"}
            sx={{
              ...linkBarStyles,
              backgroundColor: getBackgroundColor(theme, mapData?.product),
              border: `1px solid ${getBackgroundColor(theme, mapData?.product)}`,
            }}
          >
            <ProductIcon product={mapData?.product} />
            <Typography
              sx={{
                textTransform: "uppercase",
                color: "#ffffff",
                fontWeight: 700,
              }}
            >
              {mapData?.product}
            </Typography>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default DetailsStrip;
