import SyncIcon from "@mui/icons-material/Sync";
import { Box, Grid, Slide } from "@mui/material";
import { useGetInvoiceStatusesQuery } from "api/api-operations";
import { FUNDER_LINK, INCOMLEND_INTERNAL_LINK } from "codes";
import Breadcrumbs from "components/Common/Breadcrumbs";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useIsMobile from "hooks/useIsMobile";
import { useParams } from "react-router-dom";
import styles from "../funder.module.scss";

interface InvoiceStatus {
  id: string;
  name: string;
  buyer_name: string;
  buyer_id: string;
  color: string;
  requested_amount: number;
  supplier_name: string;
  supplier_id: string;
  supplier_buyer_map: string;
  supplier_buyer_map_name: string;
  real_face_value: number;
  ccy: string;
  fdr_missing: boolean;
  allocation_date_funders: Date;
  internal_validation_notes: string;
  waiting_for_allocations: boolean;
  buyer_confirmed_acceptance: boolean;
  funding_comments: string;
}
interface StatusData extends Record<string, any> {
  [statusName: string]: InvoiceStatus[];
}

const InvoiceStatusPage = () => {
  const isMobile = useIsMobile();
  const {
    data: invoiceStatuses,
    isFetching,
    refetch,
  } = useGetInvoiceStatusesQuery(null);
  const { id } = useParams();
  const formatInvoiceStatuses = (data: StatusData): InvoiceStatus[] => {
    let result: any[] = [];
    for (let value in data) {
      if (Boolean(data[value]?.length)) result.push(...data[value]);
      else
        result.push({
          id: "",
          link: "",
          status: value,
          name: "",
          data: {},
        });
    }
    return result;
  };
  const invoice =
    invoiceStatuses &&
    formatInvoiceStatuses(invoiceStatuses)?.find(
      (invoice) => invoice.name === id
    );

  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={`Invoice Status of ${invoice?.name ?? ""}`}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          backArrow
          backLink={`${INCOMLEND_INTERNAL_LINK}${FUNDER_LINK}`}
          className={styles.layout}
          headerConfig={{
            left: 10,
            mid: 0,
            right: 2,
            xs: {
              left: 6,
              mid: 0,
              right: 6,
            },
            alignItems: "center",
          }}
          secondary={{
            onClick: refetch,
            startIcon: <SyncIcon />,
            children: "Sync",
          }}
          mode="default"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Breadcrumbs
                data={[
                  {
                    name: `Invoice Statuses`,
                    link: `${INCOMLEND_INTERNAL_LINK}${FUNDER_LINK}`,
                  },
                  {
                    name: `${invoice?.name ?? ""}`,
                    link: `${INCOMLEND_INTERNAL_LINK}${FUNDER_LINK}/${id}`,
                    active: true,
                  },
                ]}
              />
            </Grid>
            {isFetching && !invoice && <SkeletonLoad bars={10} />}
            {invoice && invoiceStatuses && (
              <>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"id"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice.id}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Name"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice.name}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Buyer Name"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice?.buyer_name}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Requested Amount"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice?.requested_amount}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Supplier Name"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice?.supplier_name}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Supplier Buyer Map"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice?.supplier_buyer_map}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Supplier Buyer Map Name"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice?.supplier_buyer_map_name}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Real Face Value"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice?.real_face_value}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Currency"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice?.ccy}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Is FDR Missing?"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice?.fdr_missing ? "True" : "False"}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Funder's Allocation Date"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>{`${invoice?.allocation_date_funders}`}</Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Internal Validation Notes"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >{`${invoice?.internal_validation_notes}`}</Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Waiting for Allocations"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice?.waiting_for_allocations ? "True" : "False"}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Has Buyer Confirmed Acceptance?"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice?.buyer_confirmed_acceptance ? "True" : "False"}
                </Grid>
                <Grid item xs={6}>
                  <LabelViewOnly
                    label={"Funding Comments"}
                    labelClassName={styles.labelClass}
                  />
                </Grid>
                <Grid item xs={6}>
                  {invoice?.funding_comments ?? "N/A"}
                </Grid>
              </>
            )}
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};
export default InvoiceStatusPage;
