import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V1_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import toProperCase from "helpers/propercase";
import moment from "moment";
import type { Associate, CompanyUser, User } from "types";
import { users_v2 } from "./api-users-v2";

const formatUsers = (users: any[]) => {
  return users.map((user) => ({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    mobile: user.mobile,
    title: user.title,
    opted_marketing_updates: user.opted_marketing_updates,
    sign_up: moment(user.sign_up).utc().format("YYYY-MM-DD HH:mm:ss"),
    company_id: user.company_id,
    reg_no: user.reg_no,
    last_login: moment(user.last_login).utc().format("YYYY-MM-DD HH:mm:ss"),
  }));
};

const formatCompanyUser = (users: CompanyUser[]): any[] => {
  return users.map((user) => {
    return {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      title: user.title,
      roles: user.roles?.map((role) => toProperCase(role))?.join(", "),
      mobile: user.mobile,
      email: user.email,
      available_role: user.available_role,
      documents: user.documents ?? [],
      is_authorized: user.is_authorized,
    };
  });
};

export const users = createApi({
  reducerPath: "users",
  tagTypes: ["Users", "CompanyUsers"],
  baseQuery: axiosBaseQuery({
    baseUrl: VITE_API_V1_URL,
  }),
  endpoints: (builder) => ({
    getUsers: builder.query<any[], string>({
      query: (id: string) => ({
        url: `/users${id !== "" ? `?company_id=${id}` : ""}`,
        method: "get",
      }),
      // Provides a list of `Users` by `id`.
      // If any mutation is executed that `invalidate`s any of these tags, this query will re-run to be always up-to-date.
      // The `LIST` id is a "virtual id" we just made up to be able to invalidate this query specifically if a new `Posts` element was added.
      providesTags: (result, error, companyId) => [
        companyId !== "" ? "CompanyUsers" : "Users",
      ],
      transformResponse: (response, meta, arg) => {
        if (arg !== "") return formatCompanyUser(response?.data);
        return formatUsers(response?.data);
      },
    }),

    updateUser: builder.mutation<User, Partial<User>>({
      query(payload) {
        const data = payload;
        return {
          url: `/user`,
          method: "PUT",
          data,
        };
      },
      // Invalidates all queries that subscribe to this User `id` only.
      // In this case, `getUser` will be re-run. `getUsers` *might*  rerun, if this id was under its results.
      invalidatesTags: ["CompanyUsers", "Users"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(users_v2.util.invalidateTags(["CompanyUsers", "Users"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    associateUserDocs: builder.mutation<any, Associate>({
      query: (data) => {
        return {
          url: `/associate-documents`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["Users"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(users_v2.util.invalidateTags(["CompanyUsers", "Users"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useUpdateUserMutation,
  useAssociateUserDocsMutation,
} = users;
