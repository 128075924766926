import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define the initial state using that type
const initialState: { value: string[] } = {
  value: [],
};

export const cargoSlice = createSlice({
  name: "cargo",
  // `createSlice` will infer the state type from the `initialState` argument
  // Use the PayloadAction type to declare the contents of `action.payload`
  initialState,
  //CRUD
  reducers: {
    addShipmentId: (state, action: PayloadAction<string>) => {
      state.value = [...state.value, action.payload];
    },

    clearShipments: () => initialState,
  },
});

export const { addShipmentId, clearShipments } = cargoSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectShipmentId = (state: RootState) => state.steps.value;

export default cargoSlice.reducer;
