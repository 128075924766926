import { useEffect, useState } from "react";
import type { Account } from "types";

const useLeadStatus = (account: Account) => {
  const [isLeadRegistrationFilledUp, setisLeadRegistrationFilledUp] =
    useState(false);
  const [isLeadAdditionalFilledUp, setisLeadAdditionalFilledUp] =
    useState(false);

  useEffect(() => {
    setisLeadRegistrationFilledUp(
      account?.name !== "" &&
        account?.name !== undefined &&
        account?.name !== null &&
        account?.reg_no !== "" &&
        account?.reg_no !== undefined &&
        account?.reg_no !== null &&
        account?.address_line1 !== "" &&
        account?.address_line1 !== undefined &&
        account?.address_line1 !== "" &&
        account?.country !== "" &&
        account?.country !== undefined &&
        account?.country !== null &&
        account?.city !== "" &&
        account?.city !== undefined &&
        account?.city !== null &&
        account?.postcode !== "" &&
        account?.postcode !== undefined &&
        account?.postcode !== null &&
        account?.incorporation_date !== null &&
        account?.incorporation_date !== undefined &&
        account?.shipping_address?.address_line1 !== "" &&
        account?.shipping_address?.address_line1 !== undefined &&
        account?.shipping_address?.address_line1 !== null &&
        account?.shipping_address?.city !== "" &&
        account?.shipping_address?.city !== undefined &&
        account?.shipping_address?.city !== null &&
        account?.shipping_address?.postcode !== "" &&
        account?.shipping_address?.postcode !== undefined &&
        account?.shipping_address?.postcode !== null
    );
    setisLeadAdditionalFilledUp(
      account?.industry !== "" &&
        account?.goods_category !== "" &&
        account?.annual_revenue !== undefined &&
        account?.annual_revenue !== 0 &&
        account?.number_of_employees !== "" &&
        account?.number_of_employees !== undefined &&
        account?.number_of_employees !== null &&
        account?.accounting_software !== "" &&
        account?.accounting_software !== undefined &&
        account?.accounting_software !== null
    );
  }, [account]);

  return [isLeadRegistrationFilledUp, isLeadAdditionalFilledUp];
};
export default useLeadStatus;
