import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion, AccordionProps, Grid, styled } from "@mui/material";
import AccordionDetails, {
  AccordionDetailsProps,
} from "@mui/material/AccordionDetails";
import AccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React from "react";

interface AccordionData {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  disabled?: boolean;
}
export interface AccordionCompProps extends Partial<AccordionProps> {
  /**Accordion Data */
  data: AccordionData[];
  accordionSummaryProps?: AccordionSummaryProps;
  accordionDetailsProps?: AccordionDetailsProps;
}
const StyledAccordion = styled(Accordion)<AccordionProps>(() => ({
  border: `1px solid #c8c6c6`,
  borderRadius: "1ch",
  boxShadow: "1px 1px 4px 2px #00000014",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  marginBottom: "5vh",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary": {
    backgroundColor: "#ced4da",
    padding: "1ch 2ch",
    color: "#393939",
    WebkitTextFillColor: "#393939 !important",
  },
  "& .MuiCollapse-entered": {
    borderTop: `1px solid #c8c6c6`,
  },
}));
const AccordionComponent = (props: AccordionCompProps) => {
  const {
    data = [],
    accordionSummaryProps,
    accordionDetailsProps,
    ...accordionProps
  } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        {data &&
          data.map((item, i) => (
            <StyledAccordion
              {...accordionProps}
              key={`${i}-${item.title}`}
              disabled={item.disabled ?? false}
            >
              <AccordionSummary
                expandIcon={item.disabled ? <></> : <ArrowDropDownIcon />}
                aria-controls={`${i}-content`}
                id={`${i}-header`}
                {...accordionSummaryProps}
              >
                {typeof item.title === "string" ? (
                  <Typography>{item.title}</Typography>
                ) : (
                  item.title
                )}
              </AccordionSummary>
              {!item?.disabled && item.content && (
                <AccordionDetails {...accordionDetailsProps}>
                  {typeof item.content === "string" ? (
                    <Typography>{item.content}</Typography>
                  ) : (
                    item.content
                  )}
                </AccordionDetails>
              )}
            </StyledAccordion>
          ))}
      </Grid>
    </Grid>
  );
};

export default AccordionComponent;
