import { Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useIsMobile from "hooks/useIsMobile";
import { Dispatch, SetStateAction } from "react";
import { Fragment } from "react/jsx-runtime";

interface CofaceOptions {
  name: string;
  coface_id: string;
  registration_number: string;
  address: string;
}

interface ICofaceSelect {
  statusType: string;
  cl_id: string;

  buyerName: string;
  insurers?: CofaceOptions[];
  buyerRegNo: string;
  coface_id: string;
  setCofaceId: Dispatch<SetStateAction<string>>;
  isFetching: boolean;
}

const CofaceSelect = ({
  statusType,
  cl_id,

  buyerName,
  insurers,
  buyerRegNo,
  coface_id,
  setCofaceId,
  isFetching,
}: ICofaceSelect) => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  const highlightMatch = (subText: string, sourceName: string) => {
    const matches = match(sourceName, subText);
    const parts = parse(sourceName, matches);

    return (
      <Fragment>
        {matches.length > 0 &&
          parts.map((part, index: number) => (
            <Typography
              key={index}
              sx={{
                fontWeight: part.highlight ? 700 : 400,
                backgroundColor: part.highlight ? "#fff000" : "transparent",
                ml: "2px !important",
                color: theme.palette.primary.main,
              }}
            >
              {part.text}
            </Typography>
          ))}
        {matches.length === 0 && <Typography key={0}>{subText}</Typography>}
      </Fragment>
    );
  };

  return (
    <Stack mt={2} px={1} spacing={1} width={"100%"}>
      <Stack direction={"row"}>
        <Typography>
          {`You are about to ${statusType} the Credit Limit `}
          <strong>{`${cl_id}`}</strong>
          {` of Buyer:  `}
          <strong>{`${buyerName}`}</strong>
        </Typography>
      </Stack>
      {isFetching && <SkeletonLoad bars={4} />}
      {!isFetching && insurers && (
        <Typography textAlign="center" fontStyle={"italic"}>
          Found {insurers?.length} results from <strong>Coface</strong> for this
          credit limit request
        </Typography>
      )}
      {!isFetching && statusType === "request" && (
        <Grid
          item
          id="idenfy-results"
          xs={12}
          maxHeight="40vh"
          sx={{ overflowY: "scroll" }}
          paddingRight="1ch"
        >
          {insurers &&
            insurers?.map((item, i) => (
              <Paper
                key={i}
                elevation={2}
                sx={{
                  padding: isMobile ? "1ch" : "2ch",
                  cursor: "pointer",
                  margin: "1.5ch 0",
                  backgroundColor:
                    coface_id === item?.coface_id
                      ? theme.palette.primary.main
                      : theme.palette.background.default,
                  color:
                    coface_id === item?.coface_id
                      ? theme.palette.background.default
                      : theme.palette.primary.main,
                  ":hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.background.default,
                  },
                }}
                onClick={() => setCofaceId(item.coface_id)}
              >
                <Stack>
                  <Grid item lg={12}>
                    <Stack direction={"row"} spacing={1}>
                      <Typography
                        sx={{ fontSize: "1em", mr: "8px !important" }}
                        fontWeight={"bold"}
                      >
                        {`Name:`}
                      </Typography>
                      {buyerName && highlightMatch(item.name, buyerName)}
                    </Stack>
                  </Grid>
                  <Grid item lg={12}>
                    <Stack direction={"row"} spacing={1}>
                      <Typography
                        sx={{ fontSize: "1em", mr: "8px !important" }}
                        fontWeight={"bold"}
                      >
                        {`Reg.No:`}
                      </Typography>
                      {buyerRegNo &&
                        highlightMatch(item.registration_number, buyerRegNo)}
                    </Stack>
                  </Grid>

                  <Grid item lg={12}>
                    <Stack direction={"row"} spacing={1}>
                      <Typography
                        sx={{ fontSize: isMobile ? "1em" : "1em" }}
                        fontWeight={"bold"}
                      >
                        {`Address:`}
                      </Typography>
                      <Typography sx={{ fontSize: isMobile ? "1em" : "1em" }}>
                        {item.address}
                      </Typography>
                    </Stack>
                  </Grid>
                </Stack>
              </Paper>
            ))}
        </Grid>
      )}
    </Stack>
  );
};
export default CofaceSelect;
