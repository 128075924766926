import { Divider, Grid, Typography, useTheme } from "@mui/material";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import { MapGeneral } from "components/Risk/defaults";
import { useFormikContext } from "formik";
import styles from "../maps.module.scss";

const MapFundingDetails = () => {
  const theme = useTheme();
  const formikProps = useFormikContext<MapGeneral>();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography fontWeight={"bold"}>Summary</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} lg={6}>
        <LabelViewOnly
          label="First Funding Date"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={`${formikProps.values.firstFundingDate ?? "N/A"}`}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <LabelViewOnly
          label="Last Funding Date"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={`${formikProps.values.lastFundingDate ?? "N/A"}`}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <LabelViewOnly
          label="No. of funded invoices"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={`${formikProps.values.noOfFundedInvoices ?? "N/A"}`}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <LabelViewOnly
          label="Average overdue days on funded invoices"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={`${formikProps.values.averageOverdueDays ?? "N/A"}`}
        />
      </Grid>

      <Grid item xs={12} lg={6}>
        <LabelViewOnly
          label="Maximum overdue days on funded invoice"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={`${formikProps.values.maxOverdueOnFunded ?? "N/A"}`}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <LabelViewOnly
          label="Total funded amount since inception"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={`${formikProps.values.totalFundedSinceInception ?? "N/A"}`}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <LabelViewOnly
          label="Maximum limit utilization"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={`${formikProps.values.maxLimitUtilization ?? "N/A"}`}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <LabelViewOnly
          label="Average limit utilization % p.a."
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={`${formikProps.values.avgLimitUtilpa ?? "N/A"}`}
        />
      </Grid>
      <Grid item xs={12} lg={12}>
        <LabelViewOnly
          label="High credit limit utilization months"
          labelClassName={styles.labelClass}
          sx={{ fontSize: "1em", fontWeight: 600 }}
        />
        <LabelViewOnly
          label={`${
            formikProps.values.highCLUtilMonths === ""
              ? "N/A"
              : formikProps.values.highCLUtilMonths
          }`}
        />
      </Grid>
    </Grid>
  );
};
export default MapFundingDetails;
