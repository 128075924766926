export default function splitStringWithCountryCode(
  inputString: string,
  countryCodes: { name: string; code: string }[]
) {
  const countryCodeObject = countryCodes.find((country) =>
    inputString.startsWith(country.code)
  );
  let countryCode = "";
  let remainingString = inputString;

  if (countryCodeObject) {
    countryCode = countryCodeObject.code;
    remainingString = inputString.substring(countryCode.length).trim() ?? "";
  }

  return { countryCode, remainingString };
}
