import { FormHelperText, Grid, useTheme } from "@mui/material";

import { useConfirmAccountMutation } from "api/api-auth";
import { INITIALIZING, IS_LOGIN, IS_VERIFYING_OTP } from "codes";
import LoginHeader from "components/Login/CustomHeader";
import { AuthScreens } from "components/Login/LoginScreens";
import styles from "components/Login/LoginScreens/login.module.scss";
import MfaCodeForm from "components/Login/MfaCodeForm";
import { FormikHelpers } from "formik";
import history from "helpers/history";
import tokenValidate from "helpers/tokenValidate";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const MFA = () => {
  const { location, push } = history;
  const state: Record<string, any> = location;
  const [currentState, setCurrentState] = useState(INITIALIZING);
  const [error, setError] = useState("");

  const { t } = useTranslation();
  const isTokenValid = tokenValidate();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [confirmAccount] = useConfirmAccountMutation();
  useEffect(() => {
    if (!isTokenValid.loggedIn) {
      if (state?.state?.authState === IS_VERIFYING_OTP)
        setCurrentState(IS_VERIFYING_OTP);
    } else history.push("/");
  }, [currentState, state]);

  const formContainerSx = {
    marginTop: !isMobile ? "10vh" : 2,
    maxWidth: isMobile ? "auto" : "100vw",
  };

  const handleAccountConfirmation = (
    values: {
      mfaCode: string;
    },
    formikHelpers: FormikHelpers<{
      mfaCode: string;
    }>
  ) => {
    confirmAccount({
      email: state?.state.email,
      code: values.mfaCode,
    })
      .unwrap()
      .then(() => {
        toast(`${t("proceed-to-login")}`, {
          type: "success",
        });
        push("/login", IS_LOGIN);
        setCurrentState(IS_LOGIN);
      })
      .catch((e) => setError(e?.message));
  };

  return (
    <Grid
      container
      spacing={isMobile ? 0 : 1}
      direction="column"
      margin={"auto"}
      width={"100%"}
      height={"100%"}
    >
      <Grid item xs={12}>
        <LoginHeader setCurrentState={setCurrentState} />
        <Grid
          container
          margin={"auto"}
          spacing={isMobile ? 0 : 1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={isMobile ? 0 : 1}
              direction="column"
              margin={"auto"}
              justifyContent="center"
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={isMobile ? 0 : 2}
                  alignItems={"center"}
                  justifyContent={"center"}
                  direction="column"
                  marginTop="2vh"
                  sx={formContainerSx}
                  maxWidth={isMobile ? "100vw" : "38vw"}
                >
                  <AuthScreens state={currentState} />
                  <Grid item xs={6} margin="auto">
                    <MfaCodeForm
                      onSubmit={handleAccountConfirmation}
                      labelClassName={styles.labelClass}
                      className={styles.container}
                      primaryButtonOptions={{
                        sx: {
                          boxShadow: "none",
                          [theme.breakpoints.down("sm")]: {
                            height: "2.8em",
                            ">div": {
                              height: "2.8em",
                              ">input": {
                                height: "0.8em",
                              },
                            },
                          },
                        },
                      }}
                      inputFieldProps={{
                        labelClassName: styles.labelClass,
                        sx: {
                          borderRadius: "10px",
                          ">div": {
                            borderRadius: "10px",
                          },
                          [theme.breakpoints.down("sm")]: {
                            height: "2.8em",
                            ">div": {
                              height: "2.8em",
                              ">input": {
                                height: "0.8em",
                              },
                            },
                          },
                        },
                      }}
                    />
                  </Grid>
                  {error !== "" && (
                    <Grid item>
                      <FormHelperText error={error !== ""}>
                        {error}
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MFA;
