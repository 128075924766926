import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";

const KnowledgeCentre = () => {
  return (
    <Container>
      <Stack spacing={2}>
        <Container>
          <Typography variant="h3" fontWeight={"bold"}>
            Knowledge Centre
          </Typography>
          <Typography>Learn More About Invoice Financing</Typography>
        </Container>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is Trade Finance?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              In simple terms, trade finance is when an exporter requires an
              importer to prepay for goods shipped. The importer wants to reduce
              risk by asking the exporter to document that the goods have been
              sent as proof. The importer’s bank assists by providing a letter
              of credit to the exporter (or the exporter’s bank), providing
              payment upon presenting certain documents, such as a bill of
              lading. The exporter’s bank may make a loan to the exporter based
              on the export contract.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Why Trade Finance?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                One of the challenges that small and medium enterprises (SMEs)
                face is the cash flow crunch, especially during a pandemic or an
                economic crisis. With trade financing, SMEs can overcome the
                challenge. Trade financing provides the buyer with a revolving
                credit line instrument to pay for the goods, and for the seller,
                it secures the payment of the goods exported.
                <br />
                <br />
                On paper, the importer would improve their inventory and revenue
                turnover cycle with trade financing. Without trade financing
                credit facilities from banks, an SME importer might have to wait
                till customers pay them before having enough funds to procure
                the next shipment from suppliers.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Who are the Trade Finance Providers?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <p>
                Trade finance providers include funding institutions, banks and
                alternative finance providers, who provide capital for the
                physical buying and selling of goods.
                <br />
                <br />
                There are different ways for lending to be structured. It
                depends on the size of the borrowing company and the funder’s
                source of capital, and the restrictions placed on them. This
                will usually dictate the price that they will lend at and how
                they advance funds. In addition, it may also impact what
                security they take, the length of time credit assessment will
                take, and the tenor they will offer funds.
                <br />
                <br />
                The source of capital and structure of a lender is critical as
                this dictates many elements within the facility.
              </p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              What are Some of the Key Documents and Products Used in Trade
              Finance?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <List>
                <ListItem
                  sx={{
                    listStyleType: "decimal",
                    display: "list-item",
                  }}
                >
                  Letter of Credit (LC)
                </ListItem>
                <ListItem
                  sx={{
                    listStyleType: "decimal",
                    display: "list-item",
                  }}
                >
                  Bank Guarantees (BG)
                </ListItem>
                <ListItem
                  sx={{
                    listStyleType: "decimal",
                    display: "list-item",
                  }}
                >
                  Post Shipment (PSFC) and Pre-shipment (PCFC)
                </ListItem>
                <ListItem
                  sx={{
                    listStyleType: "decimal",
                    display: "list-item",
                  }}
                >
                  Discounting of Bills
                </ListItem>
                <ListItem
                  sx={{
                    listStyleType: "decimal",
                    display: "list-item",
                  }}
                >
                  Overdraft Facilities
                </ListItem>
                <ListItem
                  sx={{
                    listStyleType: "decimal",
                    display: "list-item",
                  }}
                >
                  Term Loan
                </ListItem>
                <ListItem
                  sx={{
                    listStyleType: "decimal",
                    display: "list-item",
                  }}
                >
                  Working Capital Loans
                </ListItem>
                <ListItem
                  sx={{
                    listStyleType: "decimal",
                    display: "list-item",
                  }}
                >
                  Buyers and Suppliers Credit
                </ListItem>
                <ListItem
                  sx={{
                    listStyleType: "decimal",
                    display: "list-item",
                  }}
                >
                  Supply Chain Finance
                </ListItem>
              </List>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is a Letter of Credit (LC)?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              A letter of Credit (LC) is a commitment provided by the bank based
              on specific terms and conditions in the form of a letter agreed
              upon between the bank, buyer, and seller. It is widely used as a
              funding tool that promises to pay the specified amount on a
              specific due date to the seller on behalf of the buyer.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is Supply Chain Finance?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              It is a commonly used financing technique by banks in the local
              market within the country. It is financing with or without
              recourse to vendors or dealer’s in advance based on the Bill of
              Exchange.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is Invoice Factoring?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Invoice factoring helps businesses to fund cash flow by selling
              their invoices to a third party (a factor or factoring company) at
              a discount. Invoice factoring can be provided by
              <Link href="https://incomlend.com/exporters/">
                independent finance providers
              </Link>
              such as Incomlend or by banks.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is the Benefit of Invoice Factoring?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Factoring companies can unlock funds tied up in unpaid invoices
              for businesses to receive funds without waiting for customers to
              pay, for a fee. This makes cash flow management easier for the
              companies that use factoring. Most factoring providers will manage
              credit control, meaning that the business no longer needs to chase
              customers for invoice payment, reducing administrative time.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is Non-recourse?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Many factoring facilities include credit insurance and these are
              called
              <Link
                href="https://techxid.com/what-is-recourse-as-it-relates-to-receivables-financing/"
                target="_blank"
              >
                non-recourse
              </Link>
              facilities, which means if the company’s customers default or go
              into insolvency, the funds tied up in unpaid invoices can be
              recovered. There is no credit insurance in recourse facilities, so
              the business will have to pay back any funds previously advanced
              against relevant invoices in the event of a default.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is the Incomlend Platform?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Incomlend provides invoice financing on an intuitive and
              easy-to-use online platform. The platform offers fast and flexible
              working capital solutions, enabling exporters and importers to
              manage their working capital needs. <br />
              <br />
              Through the online platform, accredited and institutional
              investors can finance directly to companies worldwide. Investors
              will earn steady and stable returns while helping the exporters
              and importers access working capital to accelerate their business
              growth.
              <br />
              <br />
              Investors can take a hands-on approach to invoice investing on our
              cross-border platform, directly on the platform with capital held
              on Trustee secured escrow accounts in Singapore. Allocate capital
              to individual invoices or to groups of invoices that meet the
              investors’ preferences. They can build their unique invoice
              trading investment strategy.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is Alternative Lending for SMEs?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Most SMEs are not able to get financing from traditional banks.
              It’s estimated that banks reject a high percentage of loan
              applications that they receive. It’s fortunate that alternative
              lenders have emerged to meet the needs of SMEs today. <br />
              <br />
              In summary, an alternative lender is a broad description of any
              business lender that isn’t associated with a traditional bank.
              These lenders are often considered online lenders or fintech
              companies because they use technology to deliver financial
              products or services. Online lenders are fast, adaptable and
              breaking barriers in the SME financing world. <br />
              <br />
              Most times, banks practise strict standards of what businesses
              they finance to minimise their risk. Hence, they tend to favour
              more prominent companies with a proven track record. Alternative
              lenders provide financing products for SMEs with limited credit
              history, especially when the SMEs could not meet the stringent
              bank criteria. <br />
              <br />
              Alternative lending has become a significant force globally, and
              according to research company Statista, it is projected to reach
              USD200 billion by 2025.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What are the requirements to apply?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Our export/import business should be based in one of the
              geographies we cover, the goods traded should not fall under the
              hazardous or perishable category and there should be an existing
              relationship between the trade partners (seller/buyer). <br />
              Other requirements like the creditworthiness of the buyers will be
              assessed at a later stage during the application process.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </Container>
  );
};

export default KnowledgeCentre;
