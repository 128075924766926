export const VITE_API_V1_URL = `${import.meta.env.VITE_BASE_URL}/api/v1`;
export const VITE_API_V2_URL = `${import.meta.env.VITE_BASE_URL}/api/v2`;
export const HOME = "https://incomlend.com/";
export const URL = "https://app.incomlend.com";
export const SUPPORT_EMAIL = "support@incomlend.com";
export const TNC_URL = `${
  import.meta.env.VITE_BASE_URL
}/static/public/terms_and_conditions.pdf`;
export const ONBOARDING = "ONBOARDING";
export const ONBOARDED = "ONBOARDED";
export const PRESALES = "PRESALES";
//App States
export const NEW_USER = "NEW_USER";

export const INCOMLEND_INTERNAL = "Incomlend";
export const INCOMLEND_INTERNAL_NAME = "Incomlend Pte Ltd";
export const SELECT_ACCOUNT = "SELECT_ACCOUNT";

//ROLES
//--client--
export const SUPPLIER = "Supplier";
export const SELLER = "seller";
export const BUYER = "Buyer";
export const ROLE_ADMIN = "ADMIN";
export const ROLE_MEMBER = "MEMBER";
//API Response states
export const VERIFY_USER = "VERIFY_USER";
export const SUCCESS = "SUCCESS";
//Company States
export const REGISTERED = "REGISTERED";
export const DOCS_UPLOADED = "DOCS_UPLOADED";
export const ADD_PARTNER = "ADD_PARTNER";
export const TP_ADDED = "TP_ADDED";
export const ATRADIUS_PENDING = "ATRADIUS_PENDING";
export const ATRADIUS_REJECTED = "ATRADIUS_REJECTED";
export const CL_APPROVED = "CL_APPROVED";
export const CL_REQUESTED = "CL_REQUESTED";
export const PENDING_SALES = "PENDING_SALES";
export const PENDING_INSURER = "PENDING_INSURER";
export const CL_REJECTED = "CL_REJECTED";
export const REJECTED_BY_SALES = "REJECTED_BY_SALES";
export const REJECTED_BY_INSURER = "REJECTED_BY_INSURER";
export const ICL_APPROVAL = "ICL_APPROVAL";
export const ICL_REJECTED = "ICL_REJECTED";
export const MANUAL = "manual";

//ROUTES
export const INCOMLEND_INTERNAL_LINK = "/incomlend";

export const RISK_LINK = "/cl-requests";
export const MAPS = "/maps";
export const LEGAL_LINK = "/countries";
export const FUNDER_LINK = "/invoice-statuses";
export const COMPLIANCE_LINK = "/compliance-dashboard";
export const CREDIT_LIMITS_LINK = "/credit-limits";
export const IT_LINK = "/tech";
export const DASHBOARD = "/dashboard";
export const COMPANIES = "/companies";
export const LEADS_TABLE = "/leads-table";
export const LEADS = "/leads";
export const ACCOUNTS = "/accounts";
export const CAMPAIGN_TESTING = "/test-campaign";
export const REGISTRATION_FORM_PATH = "/registration-form";
export const TR_LINK = "/trading-relationships";
export const USERS_LINK = "/users";
export const BALANCES_LINK = "/balances";
export const TAX_INVOICES_LINK = "/tax-invoices";
export const TERMSHEETS_LINK = "/termsheets";
export const SUPPORT = "/support";
export const ORG_CHART_LINK = "/org-chart";
export const EMAILS_LINK = "/emails";
// ----PRESALES
export const ADD_COMPANY = "/companies/add";
export const SELECT_COMPANY = "/companies/add/select-company";
export const REGISTER_FOR_FINANCING = "/register-for-financing";
export const REGISTER_COMPANY = "/companies/add/register";
export const UPLOAD_DOCUMENTS = "/companies/add/upload-documents";
export const ADD_PARTNERS = "/companies/add/partners";
export const ADD_CREDIT_LIMITS = "/companies/add/credit-limit";

//LOGIN STATES
export const INITIALIZING = "INITIALIZING";
export const IS_VERIFYING_OTP = "IS_VERIFYING_OTP";
export const IS_VERIFYING_TOTP = "IS_VERIFYING_TOTP";
export const IS_REQUESTING_NEW_PASS = "IS_REQUESTING_NEW_PASS";
export const IS_LOGIN = "IS_LOGIN";
export const IS_VERIFYING_NEW_PASS = "IS_VERIFYING_NEW_PASS";
export const IS_LOGIN_ONBOARDED = "IS_LOGIN_ONBOARDED";
export const ELIGIBILITY_LINK = "eligibility.incomlend.com";

//PRODUCTS
export const PRODUCTS = {
  factoring: "factoring",
  reverse_factoring: "reverse_factoring",
};

//INVOICE: PHASE STATUS ENUM
export const PHASE_STATUS = {
  PRE_LISTING: 0,
  ON_MARKETPLACE: 1,
  IN_PROGRESS: 2,
  OVERDUE: 3,
  PAID: 4,
  PASSTHROUGH_INVOICES: 5,
};

//CURRENCIES
export const CURRENCIES: Record<string, any> = {
  USD: { name: "USD", abb: "US$" },
  SGD: { name: "SGD", abb: "S$" },
  HKD: { name: "HKD", abb: "HK$" },
  GBP: { name: "GBP", abb: "£", disabled: true },
  EUR: { name: "EUR", abb: "€" },
  AUD: { name: "AUD", abb: "A$", disabled: true },
};
export const DEFAULT_HIDEABLES = [
  "supplierRegNo",
  "supplierCountry",
  "buyerRegNo",
  "buyerCountry",
];
//CL Slider indications
export const MIN_CL = 50000; //updated on 21/2/24, src: slack
export const MAX_CL = 5000000; //updated on 21/2/24, src: slack
export const CL_STEP = 250000;
export const CL_DEFAULT = 250000;
export const DEFAULT_ROWS = 50;

export const SHIPMENT_STATUSES: Record<number, string> = {
  0: "Booked",
  1: "Collected from Shipper",
  2: "Not arrived at Port of Load",
  3: "Waiting at Port of Load",
  4: "Shipped on board",
  5: "Underway",
  6: "Underway to a Transhipment Port (to be added soon)",
  7: "Arrival at Transhipment Port (to be added soon)",
  8: "Waiting at Transhipment Port",
  9: "Shipped on board at Transhipment Port",
  10: "Arrived at Port or Discharge",
  11: "Waiting at Destination Port",
  12: "Left the Destination Port",
  13: "Shipment Completed",
};

// Groups and Grouplist
export const ORIGINATION_ADMIN = "ORIGINATION_ADMIN";
export const ORIGINATION_MEMBER = "ORIGINATION_MEMBER";
export const MARKETING_ADMIN = "MARKETING_ADMIN";
export const MARKETING_MEMBER = "MARKETING_MEMBER";
export const IT_ADMIN = "IT_ADMIN";
export const IT_MEMBER = "IT_MEMBER";
export const RISK_ADMIN = "RISK_ADMIN";
export const RISK_MEMBER = "RISK_MEMBER";
export const COMPLIANCE_ADMIN = "COMPLIANCE_ADMIN";
export const COMPLIANCE_MEMBER = "COMPLIANCE_MEMBER";
export const LEGAL_ADMIN = "LEGAL_ADMIN";
export const LEGAL_MEMBER = "LEGAL_MEMBER";
export const FUNDING_ADMIN = "FUNDING_ADMIN";
export const FUNDING_MEMBER = "FUNDING_MEMBER";
export const FINANCE_ADMIN = "FINANCE_ADMIN";
export const FINANCE_MEMBER = "FINANCE_MEMBER";
export const OPERATION_ADMIN = "OPERATION_ADMIN";
export const OPERATION_MEMBER = "OPERATION_MEMBER";
export const ACCOUNT_MEMBER = "ACCOUNT_MEMBER";
export const ACCOUNT_ADMIN = "ACCOUNT_ADMIN";
//--Includes Incomlend Internal--
export const GROUP_LIST = [
  ORIGINATION_ADMIN,
  ORIGINATION_MEMBER,
  MARKETING_ADMIN,
  MARKETING_MEMBER,
  IT_ADMIN,
  IT_MEMBER,
  RISK_ADMIN,
  RISK_MEMBER,
  COMPLIANCE_ADMIN,
  COMPLIANCE_MEMBER,
  LEGAL_ADMIN,
  LEGAL_MEMBER,
  FUNDING_ADMIN,
  FUNDING_MEMBER,
  FINANCE_ADMIN,
  FINANCE_MEMBER,
  OPERATION_ADMIN,
  OPERATION_MEMBER,
  ACCOUNT_MEMBER,
  ACCOUNT_ADMIN,
  INCOMLEND_INTERNAL, // To detect whether its an internal user
];

//Platform Usage

export const PLATFORM_USAGE_CATEGORIES = [
  "Signups",
  "Submitted Companies",
  "Tracked Shipments",
  "Submitted Credit Limits",
  "Approved Credit Limits",
  "Rejected Credit Limits",
  "Started Onboardings",
  "Submitted Onboardings",
];

//Company address
export const COMPANY_ADDRESS = {
  name: "Incomlend Pte Ltd",
  addressLine1: "112 Robinson Road, #12-03A",
  addressLine2: "Singapore 068902",
  phone: "+65 3158 2540",
  registrationNumber: "201600941W",
};
