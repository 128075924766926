import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { Fragment } from "react";
import { MapSummary } from "types";
import CalendarMutiSelector from "./CalenderMultiSelector";

const SeasonalBusiness: React.FC = () => {
  const formikProps = useFormikContext<MapSummary>();
  const { seasonal_business } = formikProps.values;
  const [seasonalBusiness, setSeasonalBusiness] = React.useState(
    seasonal_business?.length > 0
  );

  return (
    <Fragment>
      <Grid item lg={8} xs={12}>
        <FormGroup>
          <FormControl>
            <RadioGroup
              aria-labelledby="seasonal_business"
              name="seasonal_business"
              value={seasonalBusiness}
              onChange={(e) => {
                setSeasonalBusiness(e.target.value === "true");
              }}
              row
            >
              <Grid container spacing={1}>
                <Grid item>
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    sx={{ alignItems: "center" }}
                    label={"Yes"}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    sx={{ alignItems: "center" }}
                    label={"No"}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </FormGroup>
      </Grid>

      {seasonalBusiness && (
        <Grid item xs={12}>
          <CalendarMutiSelector />
        </Grid>
      )}
    </Fragment>
  );
};

export default SeasonalBusiness;
