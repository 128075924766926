import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Grid,
  Link,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import LogoDesktop from "assets/logo.svg?react";
import { COMPANY_ADDRESS } from "codes";
import { Form, Formik } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import valueCleaner from "helpers/valueCleaner";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";

// Validation Schema using Yup
const InvoiceSchema = Yup.object().shape({
  invoiceDate: Yup.date().required("Required"),
  invoiceNo: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  quantity: Yup.number().required("Required"),
  unitPrice: Yup.number().required("Required"),
  gstRate: Yup.number().required("Required"),
  amountExclGST: Yup.number().required("Required"),
});

interface TaxInvoiceFees {
  amount_exclude_gst: number;
  description: string;
  quantity: number;
  unit_price: number;
}

interface Payee {
  customer_name: string;
  customer_address: string;
  city: string;
  postal_code: string;
  country: string;
  contact_name: string;
  contact_email: string;
  contact_phone_number: string;
  alternative_email: string;
}

interface TaxInvoiceData {
  sf_tax_invoice_id: string;
  sf_tax_invoice_name: string;
  invoice_reference_number: string;
  invoice_date: Date;
  transaction_type: string;
  currency: string;
  tax_rate_percentage: number;
  exchange_rate: number;
  invoice_amount: number;
  invoice_amount_sgd_equiv: number;
  total_amount: number;
  total_amount_sgd_equiv: number;
  gst_amount: number;
  gst_amount_sgd_equiv: number;
  tax_invoice_type: string;
  sf_supplier_buyer_map_name: string;
  supplier_invoice_reference_number: string;
  buyer: Payee;
  supplier: Payee;
  tax_invoice_fees: TaxInvoiceFees[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ededed",
    padding: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1.1em",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({}));

const StyledStack = styled(Stack)(({ theme }) => ({
  "@media print": {
    "-webkit-print-color-adjust": "exact",
    "print-color-adjust": "exact",
  },

  background: "#ffffff",
  border: "1px solid #eee",
  padding: theme.spacing(4),
  width: "100%",
  height: "100%",
  borderRadius: theme.shape.borderRadius,
}));

const TaxInvoicePreview = ({
  invoice,

  invoiceId,
  paidAmount = 0,
  balanceDue = 0,
  shrinkFactor = 1,
}: {
  invoice: TaxInvoiceData & {
    payee: string;
    clientDetails: Payee;
  };

  invoiceId: string;
  paidAmount: number;
  balanceDue: number;
  shrinkFactor?: number;
}) => {
  const [previewContainerSize, setPreviewContainerSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const previewContainer = document.getElementById("preview-container");
    if (previewContainer) {
      setPreviewContainerSize({
        width: previewContainer.offsetWidth,
        height: previewContainer.offsetHeight,
      });
    }
  }, []);

  const sizeHuge = `${1.3 * shrinkFactor}em`;
  const sizeLarge = `${1.1 * shrinkFactor}em`;
  const sizeMedium = `${0.9 * shrinkFactor}em`;
  const sizeSmall = `${0.7 * shrinkFactor}em`;
  const captionSize = `${0.7 * shrinkFactor}em`;

  const client: Payee = invoice?.clientDetails;
  const totalAmount = invoice.tax_invoice_fees?.reduce((acc, fee) => {
    return acc + valueCleaner(`${fee.unit_price}`) * fee.quantity;
  }, 0);
  const invoiceAmt = totalAmount;

  const invoiceAmtSgdEquiv = invoice?.invoice_amount * invoice?.exchange_rate;

  const gstAmount = totalAmount * (invoice?.tax_rate_percentage / 100);
  const totalAmountInclGst = totalAmount + gstAmount;
  const totalAmountSgdEquiv = totalAmount * invoice?.exchange_rate;
  const gstAmountSgdEquiv = gstAmount * invoice?.exchange_rate;
  const totalAmountInclGstSgdEquiv =
    totalAmountInclGst * invoice?.exchange_rate;

  const print = () => {
    let printContents = document.getElementById("tax-invoice")?.innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents || "";
    window.print();
    document.body.innerHTML = originalContents;
  };
  // height = 20.6cm
  // width = 14.6cm

  return (
    <Stack
      sx={{
        aspectRatio: "1.414/1 auto",
        resize: "both",
        width: `${100 * shrinkFactor}%`,
        height: previewContainerSize.width * 1.414,
        maxWidth: "595pt",
        maxHeight: "842pt",
      }}
      id="preview-container"
    >
      <Divider
        sx={{
          width: "100%",
          color: "#d74cf0",
          ":before": { borderBottom: "1px solid #d74cf0" },
          ":after": { borderBottom: "1px solid #d74cf0" },
          fontSize: "0.8em",
        }}
      >
        595 pt
      </Divider>
      <Box>
        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <StyledStack id="tax-invoice">
            {/* Header Section */}

            <Stack direction="row" spacing={2} justifyContent="space-between">
              <LogoDesktop width={"30%"} height={"auto"} />
              <Stack>
                <Typography variant="h4" fontSize={sizeLarge}>
                  {COMPANY_ADDRESS.name}
                </Typography>
                <Typography fontSize={sizeLarge}>
                  {COMPANY_ADDRESS.addressLine1}
                </Typography>
                <Typography fontSize={sizeLarge}>
                  {COMPANY_ADDRESS.addressLine2}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ my: 2 }} />
            {/* Invoice To Section */}
            <Stack mb={4} direction={"row"} justifyContent={"space-between"}>
              <Stack>
                <Typography
                  variant="h4"
                  textAlign={"start"}
                  fontSize={sizeHuge}
                >
                  Invoice To:
                </Typography>
                <Typography textAlign={"start"} fontSize={sizeMedium}>
                  Name: {client?.customer_name}
                </Typography>
                <Typography textAlign={"start"} fontSize={sizeMedium}>
                  Address: {client?.customer_address}
                </Typography>
                <Typography textAlign={"start"} mt={2} fontSize={sizeMedium}>
                  Email: {client?.contact_email}
                </Typography>
                <Typography textAlign={"start"} fontSize={sizeMedium}>
                  Attn: {client?.contact_name}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  variant="h3"
                  textAlign="end"
                  my={3}
                  fontWeight={"bolder"}
                  fontSize={sizeHuge}
                >
                  Tax Invoice
                </Typography>
                <Typography fontSize={sizeMedium}>
                  Invoice Date:{" "}
                  {moment(invoice?.invoice_date).format("DD/MM/YYYY")}
                </Typography>
                <Typography fontSize={sizeMedium}>
                  Invoice No.: {invoice?.invoice_reference_number}
                </Typography>
              </Stack>
            </Stack>

            {/* Form Section */}
            <Formik
              initialValues={invoice}
              validationSchema={InvoiceSchema}
              enableReinitialize
              onSubmit={() => {}}
            >
              {({ values, handleChange, handleBlur, setFieldValue }) => (
                <Form>
                  <TableContainer>
                    <Table aria-label="invoice table">
                      <TableHead
                        sx={{
                          backgroundColor: "#f5f5f5",
                          "& .MuiTableCell-root": {
                            fontWeight: "bold",
                          },
                          ".MuiTableCell-head": {
                            fontSize: sizeSmall,
                          },
                        }}
                      >
                        <StyledTableRow>
                          <StyledTableCell
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            Date of Service
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              textAlign: "left",
                            }}
                          >
                            Description of Service
                          </StyledTableCell>
                          <StyledTableCell>Quantity</StyledTableCell>
                          <StyledTableCell
                            sx={{
                              textAlign: "right",
                            }}
                          >
                            Unit Price
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              textAlign: "right",
                            }}
                          >
                            GST Rate
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              textAlign: "right",
                            }}
                          >
                            Amount (Excl. GST)
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {values?.tax_invoice_fees?.map((fee, index) => (
                          <Fragment key={index}>
                            <StyledTableRow key={index}>
                              <StyledTableCell>
                                <Typography
                                  sx={{
                                    fontSize: captionSize,
                                    textAlign: "left",
                                  }}
                                >
                                  {moment(values?.invoice_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  sx={{
                                    fontSize: captionSize,
                                    textAlign: "left",
                                  }}
                                >
                                  {fee.description}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  sx={{
                                    fontSize: captionSize,
                                    textAlign: "right",
                                  }}
                                >
                                  {fee.quantity}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  sx={{
                                    fontSize: captionSize,
                                    textAlign: "right",
                                  }}
                                >
                                  {currencyFormatter({
                                    amount: valueCleaner(`${fee.unit_price}`),
                                  })}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  sx={{
                                    fontSize: captionSize,
                                    textAlign: "right",
                                  }}
                                >
                                  {values?.tax_rate_percentage}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Typography
                                  sx={{
                                    fontSize: captionSize,
                                    textAlign: "right",
                                  }}
                                >
                                  {currencyFormatter({
                                    amount: fee.amount_exclude_gst,
                                  })}
                                </Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                            {/* Invoice & External Reference */}
                            {index === 0 && (
                              <StyledTableRow>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                  >
                                    <Stack>
                                      <Typography
                                        sx={{ fontSize: sizeSmall }}
                                        textAlign={"start"}
                                        fontStyle={"italic"}
                                      >
                                        Invoice:
                                      </Typography>
                                      <Typography
                                        sx={{ fontSize: sizeSmall }}
                                        textAlign={"start"}
                                        fontStyle={"italic"}
                                      >
                                        External Reference:
                                      </Typography>
                                    </Stack>
                                    <Stack>
                                      <Typography
                                        sx={{ fontSize: sizeSmall }}
                                        textAlign={"end"}
                                      >
                                        {invoiceId}
                                      </Typography>
                                      <Typography
                                        sx={{ fontSize: sizeSmall }}
                                        textAlign={"end"}
                                      >
                                        {
                                          values?.supplier_invoice_reference_number
                                        }
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                              </StyledTableRow>
                            )}
                          </Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* Calculation Section */}
                  <Box mt={1}>
                    <Grid container spacing={2} justifyContent="flex-end">
                      <Grid item xs={5}>
                        <Stack spacing={0.6}>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              fontSize={sizeSmall}
                              textAlign={"start"}
                            >
                              Total Amount (Excl. GST):{" "}
                            </Typography>
                            <Typography fontSize={sizeSmall} textAlign={"end"}>
                              {currencyFormatter({
                                amount: totalAmount,
                                useSymbol: "$",
                                decimalPlace: 2,
                              })}
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              fontSize={sizeSmall}
                              textAlign={"start"}
                            >
                              GST Amount:{" "}
                            </Typography>
                            <Typography fontSize={sizeSmall} textAlign={"end"}>
                              {currencyFormatter({
                                amount: gstAmount,
                                useSymbol: "$",
                                decimalPlace: 2,
                              })}
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              fontSize={sizeSmall}
                              textAlign={"start"}
                            >
                              Total Amount (Incl. GST):{" "}
                            </Typography>
                            <Typography fontSize={sizeSmall} textAlign={"end"}>
                              {currencyFormatter({
                                amount: totalAmountInclGst,
                                useSymbol: "$",
                                decimalPlace: 2,
                              })}
                            </Typography>
                          </Stack>

                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              fontSize={sizeSmall}
                              textAlign={"start"}
                            >
                              Paid Amount:{" "}
                            </Typography>
                            <Typography fontSize={sizeSmall} textAlign={"end"}>
                              {currencyFormatter({
                                amount: paidAmount,
                                useSymbol: "$",
                                decimalPlace: 2,
                              })}
                            </Typography>
                          </Stack>
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                            sx={{
                              backgroundColor: "#ededed",
                              p: 0.8,
                            }}
                          >
                            <Typography
                              fontSize={sizeSmall}
                              textAlign={"start"}
                              fontWeight={"bold"}
                            >
                              Balance Due:{" "}
                            </Typography>
                            <Typography
                              fontSize={sizeSmall}
                              textAlign={"end"}
                              fontWeight={"bold"}
                            >
                              {currencyFormatter({
                                amount: balanceDue,
                                useSymbol: "$",
                                decimalPlace: 2,
                              })}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* Goods and Tax */}
                  <Box mt={2}>
                    <Typography
                      fontSize={sizeSmall}
                      textAlign={"start"}
                      fontWeight={"bold"}
                    >
                      For Goods and Services Tax purpose only:
                    </Typography>
                    <Grid container spacing={2} justifyContent="flex-start">
                      <Grid item xs={7}>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography fontSize={sizeSmall} textAlign={"start"}>
                            Total amount payable excluding GST at{" "}
                            {invoice?.tax_rate_percentage}%:
                          </Typography>
                          <Typography fontSize={sizeSmall} textAlign={"end"}>
                            {currencyFormatter({
                              amount: totalAmountSgdEquiv,
                              useSymbol: "SGD $",
                              decimalPlace: 2,
                            })}
                          </Typography>
                        </Stack>

                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography fontSize={sizeSmall} textAlign={"start"}>
                            Total amount payable including GST at{" "}
                            {invoice?.tax_rate_percentage}%:
                          </Typography>
                          <Typography fontSize={sizeSmall} textAlign={"end"}>
                            {currencyFormatter({
                              amount: totalAmountInclGstSgdEquiv,
                              useSymbol: "SGD $",
                              decimalPlace: 2,
                            })}
                          </Typography>
                        </Stack>

                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography fontSize={sizeSmall} textAlign={"start"}>
                            Total GST payable:
                          </Typography>
                          <Typography fontSize={sizeSmall} textAlign={"end"}>
                            {currencyFormatter({
                              amount: gstAmountSgdEquiv,
                              useSymbol: "SGD $",
                              decimalPlace: 2,
                            })}
                          </Typography>
                        </Stack>

                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography fontSize={sizeSmall} textAlign={"start"}>
                            Total amount payable excluding GST at{" "}
                            {invoice?.tax_rate_percentage}%:
                          </Typography>
                          <Typography fontSize={sizeSmall} textAlign={"end"}>
                            {currencyFormatter({
                              amount: totalAmountSgdEquiv,
                              useSymbol: "SGD $",
                              decimalPlace: 2,
                            })}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* Footer Section */}
                  <Stack mt={4} textAlign="center" sx={{ width: "100%" }}>
                    <Alert
                      variant="filled"
                      severity="info"
                      sx={{
                        "&.MuiPaper-root": {
                          width: "100%",
                          backgroundColor: "#ededed",
                          color: "#000",
                          "& .MuiAlert-message": {
                            width: "100%",
                            justifyContent: "start",
                          },
                          ".MuiAlert-icon": {
                            display: "none",
                          },
                        },
                      }}
                    >
                      <AlertTitle
                        textAlign={"start"}
                        fontWeight={"bold"}
                        fontSize={sizeMedium}
                      >
                        Disclaimer
                      </AlertTitle>
                      <Divider />
                      <Stack>
                        <Typography fontSize={captionSize} textAlign={"start"}>
                          This is a computer-generated invoice. No signature is
                          required.
                        </Typography>
                        <Typography fontSize={captionSize} textAlign={"start"}>
                          Please note that any sums owed by you to Incomlend may
                          be directly deducted from your e-Wallet balance, as
                          authorized by you in the [Accounts Receivable Purchase
                          Agreement/Invoice Purchase Agreement].
                        </Typography>
                        <Typography fontSize={captionSize} textAlign={"start"}>
                          {`For General Terms and Conditions, please refer to `}
                          <Link
                            href={
                              "https://prod.backend.incomlend.com/static/public/terms_and_conditions.pdf"
                            }
                            target="_blank"
                          >
                            {"Terms and Conditions"}
                          </Link>
                        </Typography>
                      </Stack>
                    </Alert>
                    <Stack
                      mt={4}
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-evenly"}
                      sx={{ backgroundColor: "#ededed", p: 1, borderRadius: 1 }}
                    >
                      <Typography textAlign={"start"} fontSize={captionSize}>
                        <strong> GST Registration No.: </strong>
                        {COMPANY_ADDRESS.registrationNumber}
                      </Typography>
                      <Typography textAlign={"start"} fontSize={captionSize}>
                        <strong> Phone: </strong>
                        {COMPANY_ADDRESS.phone}
                      </Typography>
                      <Typography textAlign={"start"} fontSize={captionSize}>
                        <strong> Address: </strong>
                        {COMPANY_ADDRESS.addressLine1},{" "}
                        {COMPANY_ADDRESS.addressLine2}
                      </Typography>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </StyledStack>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              color: "#d74cf0",
              ":before": { borderLeft: "1px solid #d74cf0" },
              ":after": { borderLeft: "1px solid #d74cf0" },
              ".MuiDivider-wrapper": { padding: "0 0" },
            }}
          >
            <Typography
              sx={{
                fontSize: "0.8em",
                writingMode: "vertical-lr",
                textOrientation: "sideways",
              }}
            >
              842 pt
            </Typography>
          </Divider>
        </Stack>
      </Box>
    </Stack>
  );
};

export default TaxInvoicePreview;
