import { getFileTypeFromMagicNumber } from "./getFileTypeFromMagicNumber";

const base64ToBlob = (base64String: string, mimeType: string) => {
  const base64WithoutPrefix = base64String.replace(/^data:[^;]+;base64,/, "");
  const decodedData = atob(base64WithoutPrefix);

  const arrayBuffer = new ArrayBuffer(decodedData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  // Using Array.from with a mapping function instead of a for loop
  Array.from(decodedData, (char, i) => (uint8Array[i] = char.charCodeAt(0)));

  return new Blob([arrayBuffer], { type: mimeType });
};

export const downloadDocument = (documentContent: string) => {
  const binaryData = documentContent ?? "";
  const filetype = getFileTypeFromMagicNumber(binaryData);
  const blob = base64ToBlob(binaryData, filetype ?? "");

  const extension = () => {
    switch (filetype) {
      case "image/jpeg":
        return "jpg";
      case "image/png":
        return "png";
      case "application/pdf":
        return "pdf";
      default:
        return "jpg";
    }
  };

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", `download.${extension()}`);
  document.body.appendChild(link);
  link.click();
};
