import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import useIsMobile from "hooks/useIsMobile";
import { useState } from "react";
import { CustomColumn } from "../..";
import RenderCell from "./RenderCell";

interface DataTableCardProps<T> {
  item: any;
  columns: string[];
  customColumns: CustomColumn;
  handleRowClick: (field: T, header: string) => void;
  collapsible?: string[];
}

export default function DataTableCard<T extends Record<string, any> = {}>({
  item,
  columns,
  customColumns,
  handleRowClick,
  collapsible = [],
}: DataTableCardProps<T>) {
  const isMobile = useIsMobile();
  const [collapsed, setCollapsed] = useState(Boolean(collapsible?.length));
  const collapsibleHeaders = columns?.filter((header) =>
    collapsed ? !collapsible?.includes(header) : header
  );

  return (
    <Card
      sx={{
        minWidth: 275,
        border: "1px solid",
        borderColor: "#1d285c",
        borderRadius: "10px",
        boxShadow: "0px 0px 0px 4px #1d285c40",
        pb: 1,
      }}
    >
      <Grid container>
        {collapsibleHeaders.map((header) => (
          <Grid key={header} item xs={collapsed ? 6 : 12}>
            <CardContent
              sx={{
                p: 1,
                pb: "0 !important",
                pt: 0.5,
              }}
              onClick={() => handleRowClick(item, header)}
            >
              <Typography
                sx={{
                  margin: 0,
                  fontSize: "0.75rem",
                  textAlign: "start",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "bold",
                  maxWidth: "76%",
                }}
              >
                {customColumns?.[header]?.[header] ?? header}
              </Typography>
              {item[header] !== null && (
                <RenderCell
                  params={{ value: item[header] }}
                  disabled={item[header]?.disabled}
                  column={header}
                  item={item}
                  customColumns={customColumns}
                />
              )}
            </CardContent>
          </Grid>
        ))}
      </Grid>

      {Boolean(collapsible?.length) && (
        <CardActions>
          <Button
            onClick={() => setCollapsed((prev) => !prev)}
            size="small"
            variant="outlined"
          >
            {collapsed ? "Expand" : "Collapse"}
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
