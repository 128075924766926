import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { SupportTicket, TicketActivityLog } from "types";

interface Ticket {
  title: string;
  content: string;
  priority_code_id?: string;
  assignee_department_id: string;
  estimate_min?: string;
}
interface Priority {
  id: string;
  code: string;
  order: number;
}

export const support = createApi({
  reducerPath: "support",
  tagTypes: [
    "DEPARTMENTS",
    "TICKETS",
    "TICKET_STATUSES",
    "BUSINESS_PRIORITIES",
    "IT_PRIORITIES",
    "TICKET_LOGS",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/support`,
  }),
  endpoints: (builder) => ({
    getDepartmentsAndManagers: builder.query<any, null>({
      query: () => ({
        url: `/departments-and-managers`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["DEPARTMENTS"],
    }),
    getTickets: builder.query<
      { [status: string]: SupportTicket[] },
      string[] | null
    >({
      query: (departments) => {
        const params = departments?.map(
          (department) => `department=${department}`
        );
        const parametersStringList =
          params && params?.length > 1 ? `?${params?.join("&")}` : `?${params}`;
        return {
          url: `/tickets${params ? parametersStringList : ""}`,
          method: "get",
        };
      },
      transformResponse: (response) => response?.data,
      providesTags: ["TICKETS"],
    }),
    getTicketByUser: builder.query<SupportTicket, string>({
      query: (user_id) => ({
        url: `/user/${user_id}/tickets`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TICKETS"],
    }),
    getTicket: builder.query<SupportTicket, string>({
      query: (ticket_id) => ({
        url: `/tickets/${ticket_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TICKETS"],
    }),
    getTicketLogs: builder.query<TicketActivityLog[], string>({
      query: (ticket_name) => ({
        url: `/ticket/${ticket_name}/logs`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TICKET_LOGS"],
    }),
    getTicketStatuses: builder.query<{ id: string; name: string }[], null>({
      query: () => ({
        url: `/tickets/statuses`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["TICKET_STATUSES"],
    }),
    getBusinessPriorities: builder.query<Priority[], null>({
      query: () => ({
        url: `/tickets/business-priorities`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["BUSINESS_PRIORITIES"],
    }),
    getITPriorities: builder.query<Priority[], null>({
      query: () => ({
        url: `/tickets/it-priorities`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["IT_PRIORITIES"],
    }),

    getRequestQualities: builder.query<{ name: string }[], null>({
      query: () => ({
        url: `/request-qualities`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["BUSINESS_PRIORITIES"],
    }),

    createTicket: builder.mutation<SupportTicket, Ticket>({
      query: (payload) => {
        return {
          url: `/tickets`,
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["TICKETS"],
    }),
    editTicket: builder.mutation<
      any,
      Partial<SupportTicket> & { ticket_id: string }
    >({
      query: ({ ticket_id, ...payload }) => {
        return {
          url: `/tickets/${ticket_id}`,
          method: "PUT",
          data: payload,
        };
      },
      invalidatesTags: ["TICKETS"],
    }),
    addDocumentToTicket: builder.mutation<
      SupportTicket,
      { ticket_id: string; files: File[] }
    >({
      query: ({ ticket_id, files }) => {
        const formData = new FormData();
        files.map((file) => formData.append(`files`, file));
        return {
          url: `/tickets/${ticket_id}/files`,
          method: "POST",
          data: formData,
        };
      },
      invalidatesTags: ["TICKETS"],
    }),
    deleteDocumentFromTicket: builder.mutation<
      SupportTicket,
      { ticket_id: string; files: string[] }
    >({
      query: ({ ticket_id, files }) => {
        return {
          url: `/tickets/${ticket_id}/files`,
          method: "DELETE",
          data: files,
        };
      },
    }),
  }),
});

export const {
  useAddDocumentToTicketMutation,
  useCreateTicketMutation,
  useDeleteDocumentFromTicketMutation,
  useEditTicketMutation,
  useGetDepartmentsAndManagersQuery,
  useGetTicketByUserQuery,
  useGetTicketQuery,
  useGetTicketStatusesQuery,
  useGetTicketsQuery,
  useGetBusinessPrioritiesQuery,
  useGetRequestQualitiesQuery,
  useGetITPrioritiesQuery,
  useGetTicketLogsQuery,
} = support;
