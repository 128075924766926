import moment from "moment";

export const getTimeStamp = (send_at: Date) => {
  const currentTime = moment().valueOf() / 1000;
  const dateObj = moment.utc(send_at, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
  const notifTime = dateObj.valueOf() / 1000;
  const seconds = currentTime - notifTime;
  const minutes = seconds / 60;
  const hours = seconds / (60 * 60);
  const days = seconds / (60 * 60 * 24);
  if (days > 1) {
    return [Math.floor(days).toString(), "day"];
    //eslint-disable-next-line no-else-return
  } else if (hours > 1) {
    return [Math.floor(hours).toString(), "hour"];
  } else if (minutes > 1) {
    return [Math.floor(minutes).toString(), "minute"];
  }
  return ["0", "now"];
};
