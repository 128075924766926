import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

import AddPartner from "./AddPartner";

const SellerInfo = ({
  disabled,
  isMobile,
}: {
  isMobile: boolean;
  disabled: boolean;
}) => {
  const theme = useTheme();

  return (
    // Pending Translations
    <Grid item xs={12} marginTop="1em">
      <Grid item xs={12} marginTop="1em">
        <TableContainer
          component={Paper}
          sx={{
            border: "1px solid rgb(224, 224, 224)",
            borderRadius: "10px",
            margin: "1vh auto",
            bgcolor: theme.palette.background.default,
          }}
        >
          <Table aria-label="supplier-info-table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    fontWeight={"bold"}
                    variant={"h4"}
                    fontSize={isMobile ? "1em" : "1.5em"}
                    textAlign={isMobile ? "center" : "start"}
                  >
                    Submit Your Supplier(s) Information
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell>
                  <AddPartner disabled={disabled} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
export default SellerInfo;
