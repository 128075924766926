import LoadingButton from "@mui/lab/LoadingButton";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useModifyEligibilityMutation } from "api/api-legal";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import { CountryEligibility } from "types";
import styles from "./eligibility.module.scss";

const EligibilityClause = ({
  iso3,
  refetch,
}: {
  iso3: string;
  refetch: () => void;
}) => {
  const formikProps = useFormikContext<CountryEligibility>();
  const [modifyEligibility, { isLoading }] = useModifyEligibilityMutation();
  const { values, dirty } = formikProps;

  return (
    <Paper
      variant="outlined"
      elevation={3}
      square={false}
      sx={{
        padding: "3ch",
        borderRadius: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={10} lg={6} xl={6}>
          <LabelViewOnly
            label="Is Supplier Eligible"
            labelClassName={styles.labelClass}
          />
          <FormControl>
            <RadioGroup
              aria-labelledby="seller"
              name="seller"
              value={formikProps.values.seller}
              onChange={(e) => {
                formikProps.setFieldValue("seller", e.target.value, true);
              }}
              row
            >
              <FormControlLabel
                value={"Eligible"}
                control={<Radio />}
                label="Eligible"
              />
              <FormControlLabel
                value={"Not Eligible"}
                control={<Radio />}
                label="Not Eligible"
              />
              <FormControlLabel
                value="Unknown"
                control={<Radio />}
                label="Unknown"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={10} lg={6} xl={6}>
          <LabelViewOnly
            label="Is Buyer Eligible"
            labelClassName={styles.labelClass}
          />
          <FormControl>
            <RadioGroup
              aria-labelledby="buyer"
              name="buyer"
              value={formikProps.values.buyer}
              onChange={(e) => {
                formikProps.setFieldValue("buyer", e.target.value);
              }}
              row
            >
              <FormControlLabel
                value={"Eligible"}
                control={<Radio />}
                label="Eligible"
              />
              <FormControlLabel
                value={"Not Eligible"}
                control={<Radio />}
                label="Not Eligible"
              />
              <FormControlLabel
                value="Unknown"
                control={<Radio />}
                label="Unknown"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Input
            labelClassName={styles.labelClass}
            name={"legal_comments"}
            label={"Legal Remarks"}
            multiline
            sx={{
              ".MuiInputBase-root": {
                height: "20vh",
                backgroundClip: "border-box",
                borderRadius: "10px",
                textarea: {
                  height: "18vh !important",
                  overflow: "scroll !important",
                },
              },
            }}
            style={{ width: "100%" }}
            {...formikProps}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={"end"} alignItems={"center"}>
            <Grid item xs={12} lg={3}>
              <LoadingButton
                variant="contained"
                fullWidth
                disabled={!dirty}
                loading={isLoading}
                onClick={() =>
                  iso3 &&
                  modifyEligibility({
                    country: iso3,
                    legal_comments: formikProps.values.legal_comments ?? "",
                    commit_message: "",
                    buyer: formikProps.values.buyer,
                    seller: formikProps.values.seller,
                  })
                    .unwrap()
                    .then(() => {
                      formikProps.resetForm({ values });
                      refetch();
                      toast("Draft submitted successfully", {
                        type: "success",
                      });
                    })
                }
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default EligibilityClause;
