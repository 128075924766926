import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import Search from "components/Common/Search";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { FormikContextType, useFormikContext } from "formik";
import { RegistrationFormFields } from "helpers/formFields";
import { t } from "i18next";
import { isMobile } from "react-device-detect";
import { SearchCompany } from "types";

const SelectCompanyFromList = ({
  foundCompanies,
  filteredCompanies,
  isLoading,
  registrationNumber,
  onCompanySelect,
  country,
  setFilteredCompanies,
  onCancel,
  isLoadingVendorCall,
}: {
  foundCompanies: SearchCompany[];
  filteredCompanies: SearchCompany[];
  isLoading: boolean;
  registrationNumber: string;
  onCompanySelect: (item: SearchCompany) => void;
  country: string;
  setFilteredCompanies: React.Dispatch<
    React.SetStateAction<SearchCompany[] | undefined>
  >;
  onCancel: () => void;
  isLoadingVendorCall?: boolean;
}) => {
  const formikProps: FormikContextType<RegistrationFormFields> =
    useFormikContext();
  const theme = useTheme();
  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            textAlign="center"
            fontWeight="bold"
            style={{ fontSize: isMobile ? "1em" : "1.5em" }}
            variant="h3"
          >
            {t("select-your-company")}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={8} margin="auto">
          <Typography
            textAlign="center"
            sx={{ fontSize: isMobile ? "1em" : "1.2em" }}
          >
            {t("companies-associated-to-your-criteria", {
              number: foundCompanies?.length,
              type: "",
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        maxWidth={isMobile ? "100%" : "50vw"}
        spacing={2}
        padding={isMobile ? 1 : 2}
        margin="auto"
      >
        <Grid item xs={12}>
          <Grid container alignItems={"end"} justifyContent={"space-between"}>
            <Grid item lg={6} xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: isMobile ? "1em" : "1.5em" }}>
                    {t("your-data")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "bolder",
                      fontSize: isMobile ? "1em" : "1.2em",
                    }}
                  >
                    {`${formikProps.values.companyNameInitial} - ${registrationNumber}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Typography
                sx={{
                  fontWeight: "bolder",
                  fontSize: isMobile ? "1em" : "1.2em",
                }}
              >
                {formikProps.values.country}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!isMobile && (
          <Grid item xs={12} id="idenfy-divider">
            <Divider />
          </Grid>
        )}
        <Grid item xs={12} id="searchbar-company">
          <Search
            onChange={(searchString) => {
              const filtration = foundCompanies?.filter((company) =>
                company?.name
                  ?.toLowerCase()
                  .includes(searchString?.toLowerCase())
              );
              setFilteredCompanies(filtration);
            }}
          />
        </Grid>
        {isLoading && <SkeletonLoad bars={5} />}
        <Grid
          item
          id="idenfy-results"
          xs={12}
          maxHeight="40vh"
          sx={{ overflowY: "scroll" }}
          paddingRight="1ch"
        >
          {filteredCompanies &&
            filteredCompanies?.map((item, i) => (
              <Paper
                key={i}
                elevation={2}
                sx={{
                  padding: isMobile ? "1ch" : "2ch",
                  cursor: "pointer",
                  margin: "1.5ch 0",
                  ":hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.background.default,
                  },
                }}
                onClick={() => onCompanySelect(item)}
              >
                {isLoadingVendorCall ? (
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems="center"
                    spacing={isMobile ? 2 : 0}
                  >
                    <Grid item lg={6} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ fontSize: isMobile ? "1em" : "1em" }}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ fontSize: isMobile ? "1em" : "1em" }}
                          >
                            {item.registration_number}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ fontSize: isMobile ? "1em" : "1em" }}
                          >
                            {item.city}, {country}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ fontSize: isMobile ? "1em" : "1em" }}
                          >
                            {item.address_line_1}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={2} margin={"auto"}>
        <Button color="error" fullWidth variant="contained" onClick={onCancel}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};
export default SelectCompanyFromList;
