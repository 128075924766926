import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";

interface Node {
  id: string;
  name: string;
  title: string;
  level: number;
  color: string;
  member_layout: string[];
  node_id: string;
  tag?: string;
}

interface AddNode {
  name: string;
  title: string;
  level: number;
  color: string;
  member_layout: string[];
  tag?: string;
  department_tag?: string;
  country_tag?: string;
  entity_tag?: string;
  reporting_to?: string;
}
interface ReportingLine {
  [level_x: string]: Node;
}
interface NodeRootTags {
  department: Node;
  country: Node;
  entity: Node;
}

export const human_resources = createApi({
  reducerPath: "human_resources",
  tagTypes: [
    "NODES",
    "REPORTING_LINE",
    "COUNTRY_MEMBERS",
    "DEPARTMENT_MEMBERS",
    "ENTITY_MEMBERS",
    "ROOT_TAGS",
  ],

  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/human-resources`,
  }),
  endpoints: (builder) => ({
    getOrgChart: builder.query<Node[], void>({
      query: () => ({
        url: `/org-chart`,
        method: "get",
      }),
      transformResponse: (response: any) => response.data,
      providesTags: ["NODES"],
    }),
    updateNode: builder.mutation<any, Partial<Node>>({
      query: (payload) => {
        const { id, ...rest } = payload;
        return {
          url: `/nodes/${id}`,
          method: "put",
          data: {
            tag: rest.tag,
            name: rest.name,
            title: rest.title,
            level: rest.level,
            color: rest.color,
            member_layout: rest.member_layout,
          },
        };
      },
      invalidatesTags: ["NODES"],
    }),
    addNode: builder.mutation<any, AddNode>({
      query: (data) => {
        return {
          url: `/nodes`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: [
        "NODES",
        "REPORTING_LINE",
        "DEPARTMENT_MEMBERS",
        "COUNTRY_MEMBERS",
        "ENTITY_MEMBERS",
        "ROOT_TAGS",
      ],
    }),
    getOrgChartPalette: builder.query<string[], void>({
      query: () => ({
        url: `/org-chart/utils/palette`,
        method: "get",
      }),
      transformResponse: (response: any) => response.data,
    }),

    getDepartments: builder.query<Node[], void>({
      query: () => ({
        url: `/org-chart/utils/departments`,
        method: "get",
      }),
      transformResponse: (response: any) => response.data,
    }),
    getCountries: builder.query<Node[], void>({
      query: () => ({
        url: `/org-chart/utils/countries`,
        method: "get",
      }),
      transformResponse: (response: any) => response.data,
    }),
    getEntities: builder.query<Node[], void>({
      query: () => ({
        url: `/org-chart/utils/entities`,
        method: "get",
      }),
      transformResponse: (response: any) => response.data,
    }),
    getDepartmentMembers: builder.query<Node[], string>({
      query: (department_id) => ({
        url: `/org-chart/utils/department-members/${department_id}`,
        method: "get",
      }),
      transformResponse: (response: any) => response.data,
      providesTags: ["DEPARTMENT_MEMBERS"],
    }),
    getCountryMembers: builder.query<Node[], string>({
      query: (country_id) => ({
        url: `/org-chart/utils/country-members/${country_id}`,
        method: "get",
      }),
      transformResponse: (response: any) => response.data,
      providesTags: ["COUNTRY_MEMBERS"],
    }),
    getEntityMembers: builder.query<Node[], string>({
      query: (entity_id) => ({
        url: `/org-chart/utils/entity-members/${entity_id}`,
        method: "get",
      }),
      transformResponse: (response: any) => response.data,
      providesTags: ["ENTITY_MEMBERS"],
    }),
    getNodeRootTags: builder.query<
      NodeRootTags,
      { tag: string; level: number }
    >({
      query: ({ tag, level }) => ({
        url: `/nodes/${tag}/${level}/root-tags`,
        method: "get",
      }),
      transformResponse: (response: any) => response.data,
      providesTags: ["ROOT_TAGS"],
    }),
    getReportingLine: builder.query<
      ReportingLine,
      { deparment_tag: string; tag: string }
    >({
      query: ({ deparment_tag, tag }) => ({
        url: `/nodes/reporting-line/${deparment_tag}/${tag}`,
        method: "get",
      }),
      transformResponse: (response: any) => response.data,
      providesTags: ["REPORTING_LINE"],
    }),
    updateRootNode: builder.mutation<
      any,
      {
        node_id: string;
        data: {
          add_to?: string;
          remove_from?: string;
        };
      }
    >({
      query: ({ node_id, data }) => ({
        url: `/nodes/root/${node_id}`,
        method: "put",
        data,
      }),
      invalidatesTags: [
        "NODES",
        "REPORTING_LINE",
        "COUNTRY_MEMBERS",
        "DEPARTMENT_MEMBERS",
        "ENTITY_MEMBERS",
        "ROOT_TAGS",
      ],
    }),
    deleteNode: builder.mutation<any, string>({
      query: (node_id) => ({
        url: `/nodes/${node_id}/delete`,
        method: "delete",
      }),
      invalidatesTags: ["NODES"],
    }),
  }),
});

export const {
  useGetOrgChartQuery,
  useUpdateNodeMutation,
  useAddNodeMutation,
  useGetOrgChartPaletteQuery,
  useGetDepartmentsQuery,
  useGetCountriesQuery,
  useGetEntitiesQuery,
  useGetDepartmentMembersQuery,
  useGetCountryMembersQuery,
  useGetEntityMembersQuery,
  useGetNodeRootTagsQuery,
  useGetReportingLineQuery,
  useUpdateRootNodeMutation,
  useDeleteNodeMutation,
} = human_resources;
