import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import * as React from "react";
import { useState } from "react";

const SingleRadio = ({
  data,
  header,
  setFilters,
  filters,
  customFieldName,
  boolTitles,
}: {
  data: Record<string, any>[];
  header: string;
  filters: Record<string, any>;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  customFieldName?: string;
  boolTitles: string[];
}) => {
  const [value, setValue] = useState<boolean | undefined>(
    filters[header]?.keywords
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      (event.target as HTMLInputElement).value === boolTitles[0] ? true : false;
    setValue(newValue);
    setFilters((prev) => {
      return {
        ...prev,
        [header]: {
          comparator: "only",
          keywords: newValue,
        },
      };
    });
  };

  const booleanMap = [
    ...new Set(
      data.map((item) => (item[header] ? boolTitles[0] : boolTitles[1]))
    ),
  ];
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">
        {customFieldName ?? header}
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={value ? boolTitles[0] : boolTitles[1]}
        onChange={handleChange}
      >
        {booleanMap.length &&
          booleanMap.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <FormControlLabel
                  value={item}
                  control={<Radio />}
                  label={item}
                />
              </React.Fragment>
            );
          })}
      </RadioGroup>
    </FormControl>
  );
};

export default SingleRadio;
