import { Box, Grid, Slide, Typography } from "@mui/material";
import { useGetAccountsQuery } from "api/api-accounts";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import UsersIcon from "assets/navigationIcons/users.svg?react";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { useEffect, useRef } from "react";

interface AccountList {
  id: string;
  status: string;
  profile: string;
  name: string;
  internal_name: string;
  reg_no: string;
  country: string;
  users: number;
  created_at: string;
}

const Accounts = () => {
  const isMobile = useIsMobile();

  const {
    data: accounts,
    isFetching,
    isLoading,
    refetch,
  } = useGetAccountsQuery(null);

  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();

  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const headerLength = Object.keys(accounts?.[0] ?? {})?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.6 : 200;

  useEffect(() => {}, [isFetching]);
  const initialFilters = {
    status: {
      comparator: "is not",
      keywords: ["LEAD"],
    },
  };
  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={"Accounts"}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            left: 4,
            mid: 0,
            right: 4,
            syncAction: refetch,
            xs: {
              left: 6,
              mid: 0,
              right: 6,
            },
            alignItems: "center",
          }}
          mode="default"
        >
          <Grid item xs={12} margin={"5px"}>
            <Grid item xs={12} ref={ref}>
              {(isFetching || isLoading) && <SkeletonLoad bars={10} />}
              {!isFetching &&
                accounts !== undefined &&
                Boolean(accounts?.length) && (
                  <DataTablev2
                    data={accounts}
                    initialFilters={initialFilters}
                    defaultColumnWidth={defaultColumnWidth}
                    rowsPerPage={rowsPerPage}
                    useKey="internal_name"
                    customColumns={{
                      country: {
                        country: "Country",
                        type: "text",
                      },
                      internal_name: {
                        internal_name: "Account ID",
                        type: "text",
                        minWidth: 230,
                        link: {
                          href: (params: AccountList) =>
                            `${INCOMLEND_INTERNAL_LINK}/accounts/${params.internal_name}`,
                          target: "_self",
                        },
                      },
                      profile: { profile: "Profile", type: "text" },
                      name: {
                        name: "Account",
                        type: "text",
                        minWidth: 230,
                      },
                      reg_no: {
                        reg_no: "Registration Number",
                        type: "text",
                        minWidth: 180,
                      },
                      created_at: {
                        created_at: "Created At",
                        type: "date",
                        minWidth: 190,
                        renderCell: ({ value }: { value: string }) => (
                          <Typography>
                            {value
                              ? moment(value)
                                  .utc()
                                  .format("DD-MM-YYYY HH:mm:ss")
                              : "-"}
                          </Typography>
                        ),
                      },
                      users: {
                        users: "Users",
                        minWidth: 100,
                        type: "number",
                        renderCell: ({ value }: { value: number }) => {
                          return (
                            <Grid container spacing={1}>
                              <Grid item>
                                <UsersIcon />
                              </Grid>
                              <Grid item>
                                <Typography>{value ?? 0}</Typography>
                              </Grid>
                            </Grid>
                          );
                        },
                      },
                    }}
                    sx={{
                      table: {
                        minWidth: "100% !important",
                      },
                    }}
                  />
                )}
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};

export default Accounts;
