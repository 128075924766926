import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type { SearchCompany, ShipmentData } from "types";

interface SubscribeCargo {
  accountId: string;
  scac: string;
  tdId: string;
  tdType: string;
}
interface SubscriptionCargo {
  id: string;
  scac: string;
  transport_document_id: string;
  transport_document_type: string;
  shipment_id: string;
}

export const vendorsv2 = createApi({
  reducerPath: "vendorsv2",
  tagTypes: ["CARGOS", "COMPANYSEARCH"],

  baseQuery: axiosBaseQuery({
    baseUrl: VITE_API_V2_URL,
  }),
  endpoints: (builder) => ({
    trackCargos: builder.query<SubscriptionCargo[], string>({
      query: (accountId) => ({
        url: `/marine-traffic/${accountId}/track-cargos`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CARGOS"],
    }),
    trackCargo: builder.query<ShipmentData, string>({
      query: (shipmentId) => ({
        url: `/marine-traffic/track-cargo/${shipmentId}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["CARGOS"],
    }),
    subscribeCargo: builder.mutation<SubscriptionCargo, SubscribeCargo>({
      query(payload) {
        return {
          url: `/marine-traffic/track-cargo`,
          method: "POST",
          data: payload,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["CARGOS"],
    }),
    searchCompany: builder.mutation<
      SearchCompany[],
      {
        company_iso3: string;
        company_name: string;
        company_registration_number: string;
      }
    >({
      query: (data) => {
        return {
          url: `/search-company`,
          data,
          method: "POST",
        };
      },
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useTrackCargoQuery,
  useSubscribeCargoMutation,
  useTrackCargosQuery,
  useSearchCompanyMutation,
} = vendorsv2;
