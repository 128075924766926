import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useColorScheme, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";

function ColorModeSelector() {
  const theme = useTheme();
  const { mode, setMode } = useColorScheme();

  return (
    <IconButton
      sx={{ ml: 1 }}
      onClick={() => {
        
        setMode(mode === "light" ? "dark" : "light");
      }}
      color="inherit"
    >
      {mode === "light" ? (
        <Brightness4Icon sx={{ color: theme.palette.primary.dark }} />
      ) : (
        <Brightness7Icon sx={{ color: theme.palette.primary.light }} />
      )}
    </IconButton>
  );
}
export default ColorModeSelector;
