import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V1_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import type {
  AddInvoice,
  Invoices,
  OverridableStringUnion,
  SupportingDocument,
} from "types";

interface PickList {
  countries: any[];
  incoterms: any[];
  carriers: any[];
  final_destination: any[];
  ports: any[];
}

interface Stats {
  averageDelay: number;
  averageDiscount?: number;
  averageSupplierCost?: number;
  fundingEfficiency?: number;
  supplierAvgDiscount?: number;
  totalAdvanced?: number;
  totalCreditInsurance?: number;
  totalDiscount?: number;
  totalOverdueCost?: number;
  totalPlatformFee?: number;
}

interface WithdrawalDeposit {
  amount: number;
  bank_account_id: string;
  currency: string;
  notes: string;
  remark: string;
  trustee: string;
  type: string;
}
interface ApproveListing {
  invoice_id: string;
  user_type: string;
  status: string;
  details: string;
}
interface Transaction {
  amount: number;
  currency: string;
  debit_credit: string;
  effective_date: string;
  invoice_finance_deal: string;
  invoice_id: string;
  invoice_name: string;
  invoice_reference: string;
  status: string;
  transaction_id: string;
  type: string;
}
interface Ewallet {
  account_id: string;
  available_balance: number;
  blocked_withdrawal: boolean;
  currency: OverridableStringUnion<
    "USD" | "SGD" | "HKD" | "GBP" | "EUR" | "AUD"
  >;
  ewallet_id: string;
  position: number;
  position_usd_equiv: number;
  trustee: string;
}
interface SupportingDoc {
  id: string;
  name: string;
}
interface BillingAddress {
  city: string;
  country: string;
  geocodeAccuracy: null | string;
  latitude: null | number;
  longitude: null | number;
  postalCode: string;
  state: null | string;
  street: string;
}

interface Buyer {
  AnnualRevenue: number;
  BillingAddress: BillingAddress;
  Id: string;
  Name: string;
  Un_utilised_adv_amt_all_phases_usd_equiv__c: number;
  Year_of_incorporation__c: number;
  attributes: {
    type: string;
    url: string;
  };
  head_office_country__c: string;
  suspended__c: boolean;
}

interface Marketplace {
  Currency__c: string;
  Id: string;
  attributes: {
    type: string;
    url: string;
  };
}

interface IncomlendProductReference {
  CCYs__c: string;
  Id: string;
  Name: string;
  active__c: boolean;
  attributes: {
    type: string;
    url: string;
  };
  max_invoice_value_USD__c: number;
  minimum_face_value__c: number;
}

interface Attributes {
  type: string;
  url: string;
}

interface SupplierBuyerMap {
  Buyer__c: string;
  Buyer__r: Buyer;
  Credit_Limit_Avalaible__c: number;
  Id: string;
  Incomlend_product__c: string;
  Incomlend_product__r: IncomlendProductReference;
  Map_Currency__c: string;
  Marketplace__c: string;
  Marketplace__r: Marketplace;
  Name: string;
  Recommended_Discount_Rate__c: number;
  Total_Financing_value_alltime__c: number;
  attributes: Attributes;
  buyer_onboarding_stage__c: string;
  buyer_supplier_map_approved__c: boolean;
  insurable__c: boolean;
  is_offer_conditional__c: boolean;
  map_onboarding_stage__c: string;
  number_of_invoices_all_time__c: number;
  offer_conditions_met__c: boolean;
  percentage_of_financing__c: number;
}

interface BuyerForMapProduct {
  Id: string;
  Name: string;
  attributes: {
    type: string;
    url: string;
  };
  suspended__c: boolean;
}

interface IncomlendProduct {
  Id: string;
  Name: string;
  active__c: boolean;
  attributes: {
    type: string;
    url: string;
  };
}

interface MapProduct {
  Buyer__c: string;
  Buyer__r: BuyerForMapProduct;
  Id: string;
  Incomlend_product__c: string;
  Incomlend_product__r: IncomlendProduct;
  Marketplace__c: string;
  Marketplace__r: Marketplace;
  Name: string;
  attributes: {
    type: string;
    url: string;
  };
  buyer_supplier_map_approved__c: boolean;
  insurable__c: boolean;
  map_onboarding_stage__c: string;
}
interface AttachmentRecord {
  ContentType: string;
  Id: string;
  LastModifiedDate: string;
  Name: string;
  ParentId: string;
  attributes: {
    type: string;
    url: string;
  };
}

interface Attachment {
  done: boolean;
  records: AttachmentRecord[];
  totalSize: number;
}

interface UploadedDoc {
  Attachments: Attachment;
  Document_Type_auto__c: string;
  Id: string;
  Name: string;
  attributes: {
    type: string;
    url: string;
  };
}
const formatTransactionsOrder = (data: Transaction[]): Transaction[] =>
  data?.map((item) => ({
    invoice_id: item.invoice_id,
    invoice_name: item.invoice_name,
    currency: item.currency,
    amount: item.amount,
    status: item.status,
    effective_date: item.effective_date,
    debit_credit: item.debit_credit,
    invoice_finance_deal: item.invoice_finance_deal,
    invoice_reference: item.invoice_reference,
    transaction_id: item.transaction_id,
    type: item.type,
  }));

export const marketplace = createApi({
  reducerPath: "marketplace",
  tagTypes: [
    "INVOICES",
    "PICKLIST",
    "SUPPORTING_DOCS",
    "EWALLETS",
    "TRANSACTIONS",
    "MAP_DETAILS",
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V1_URL}/marketplace`,
  }),
  endpoints: (builder) => ({
    getInvoiceListByUser: builder.query<
      Invoices,
      { id: string; type: string; ccy: string }
    >({
      query: ({ id, type, ccy }) => ({
        url: `/${id}/invoices?type=${type}&ccy=${ccy}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["INVOICES"],
    }),
    getPicklists: builder.query<PickList, null>({
      query: () => ({ url: `/picklists`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["PICKLIST"],
    }),
    getEwallets: builder.query<Ewallet[], string>({
      query: (id) => ({ url: `/${id}/ewallets`, method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["EWALLETS"],
    }),
    getTransactions: builder.query<Transaction[], string>({
      query: (id) => ({ url: `/${id}/transactions`, method: "get" }),
      transformResponse: (response) => formatTransactionsOrder(response?.data),
      providesTags: ["TRANSACTIONS"],
    }),
    getStats: builder.query<Stats, string>({
      query: (account_id) => ({
        url: `/${account_id}/invoices/stats`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getSupportingDocuments: builder.query<
      SupportingDocument[],
      { accountId: string; partnerId: string; type: string }
    >({
      query: (params) => ({
        url: `/${params.accountId}/supporting-documents/${params.partnerId}?type=${params?.type}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SUPPORTING_DOCS"],
    }),
    getUploadedSupportingDocuments: builder.query<
      UploadedDoc[],
      { accountId: string; invoice_id: string }
    >({
      query: (params) => ({
        url: `/${params.accountId}/documents/${params.invoice_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["SUPPORTING_DOCS"],
    }),
    getInvoiceById: builder.query<
      any,
      { account_id: string; invoice_id: string }
    >({
      query: ({ account_id, invoice_id }) => ({
        url: `/${account_id}/invoices/${invoice_id}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    addInvoice: builder.mutation<
      { invoice_id: string; supporting_documents: SupportingDoc[] },
      AddInvoice
    >({
      query: (data) => {
        const { account_id, ...payload } = data;
        return {
          url: `/${account_id}/invoices`,
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["INVOICES"],
      transformResponse: (response) => response?.data,
    }),
    addInvoiceBatch: builder.mutation<
      { invoice_id: string; supporting_documents: SupportingDoc[] },
      { file: File; partner_id: string; account_id: string }
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("partner_id", data.partner_id);
        const { account_id } = data;
        return {
          url: `/${account_id}/batch-invoices`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      invalidatesTags: ["INVOICES"],
      transformResponse: (response) => response?.data,
    }),
    submitWithdrawal: builder.mutation<
      any,
      { id: string; data: WithdrawalDeposit }
    >({
      query: ({ id, data }) => {
        return {
          url: `/${id}/withdrawaldeposit`,
          method: "POST",
          data,
        };
      },
      invalidatesTags: ["EWALLETS"],
      transformResponse: (response) => response?.data,
    }),
    approveListing: builder.mutation<any, { id: string; data: ApproveListing }>(
      {
        query: ({ id, data }) => {
          return {
            url: `/${id}/approve-listing`,
            method: "POST",
            data,
          };
        },
        invalidatesTags: ["INVOICES"],
        transformResponse: (response) => response?.data,
      }
    ),
    postSupportingDocuments: builder.mutation<
      { invoice_id: string; supporting_documents: [] },
      { file: File; supporting_document_id: string }
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("parent_id", data.supporting_document_id);
        return {
          url: `/invoices/documents`,
          method: "POST",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        };
      },
      invalidatesTags: ["SUPPORTING_DOCS"],
      transformResponse: (response) => response?.data,
    }),
    getMapProducts: builder.query<
      MapProduct,
      { account_id: string; partner_id: string; currency: string }
    >({
      query: ({ account_id, partner_id, currency }) => ({
        url: `/${account_id}/products?partner_id=${partner_id}&currency=${currency}`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getMapDetails: builder.query<
      SupplierBuyerMap,
      { account_id: string; partner_id: string }
    >({
      query: ({ account_id, partner_id }) => ({
        url: `/${account_id}/map-details?partner_id=${partner_id}`,
        method: "get",
      }),
      providesTags: ["MAP_DETAILS"],
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useGetInvoiceListByUserQuery,
  useGetInvoiceByIdQuery,
  useAddInvoiceMutation,
  useAddInvoiceBatchMutation,
  useGetPicklistsQuery,
  useGetSupportingDocumentsQuery,
  usePostSupportingDocumentsMutation,
  useGetStatsQuery,
  useGetEwalletsQuery,
  useGetTransactionsQuery,
  useSubmitWithdrawalMutation,
  useApproveListingMutation,
  useGetUploadedSupportingDocumentsQuery,
  useGetMapProductsQuery,
  useGetMapDetailsQuery,
} = marketplace;
