import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  useDeleteDocMutation,
  useGetContactDocumentsQuery,
  useGetDocumentUrlQuery,
} from "api/api-accounts";
import { useDeleteContactMutation } from "api/api-users-v2";
import DocView from "components/Common/DocView";
import Modal from "components/Common/Modal";
import DocumentUploadController from "components/Documents/DocumentUploadController";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Item } from "../../CreditReport/types";
import EditAuthorizedSignatory from "./EditAuthorizedSignatory";

interface IDVerificationDirectorProps {
  formikProps: FormikProps<any>;
  handleSubmitContactDocument: (
    data: { file: File; year: number | null },
    label: string,
    document_type: string,
    contactIndex: number
  ) => void;
  contact: any;
  index: number;
  accountId: string;
  isLocked: boolean;
}

const IDVerificationDirector: React.FC<IDVerificationDirectorProps> = ({
  formikProps,
  handleSubmitContactDocument,
  contact,
  index,
  accountId,
  isLocked,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [documentId, setDocumentId] = useState<string>("");
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const { data: documentUrl } = useGetDocumentUrlQuery(
    {
      account_id: contact.id,
      document_id: documentId ?? "",
    },
    { skip: !Boolean(documentId) }
  );

  const [deleteContact] = useDeleteContactMutation();
  const [deleteDoc] = useDeleteDocMutation();

  const { data: documents, isLoading: isLoadingDocuments } =
    useGetContactDocumentsQuery({
      contact_id: contact.id,
      account_id: accountId,
    });

  const onOpen = () => setShow(true);
  const onClose = () => setShow(false);

  const fetchDocument = async (id: string) => {
    setDocumentId(id);
    onOpen();
  };

  const deleteDocument = (contact_id: string, id: string) => {
    deleteDoc({
      account_id: contact_id,
      id: id,
    })
      .unwrap()
      .then(() => {
        toast(`Document deleted successfully!`, { type: "success" });
      })
      .catch((error) => {
        const errorFile = error?.message;
        toast(`Something went wrong: ${errorFile}`, {
          type: "error",
        });
      });
  };

  const handleDeleteContact = () => {
    deleteContact({
      account_id: accountId,
      contact_id: contact.id,
    })
      .unwrap()
      .then(() => {
        toast(`Contact deleted successfully!`, { type: "success" });
      })
      .catch((error) => {
        const errorFile = error?.message;
        toast(`Something went wrong: ${errorFile}`, {
          type: "error",
        });
      });
  };

  const items: Item[] = [
    {
      id: "id-verification",
      name: `ID Verification for ${contact?.first_name} ${contact?.last_name}`,
      label: `Upload ID for ${contact?.first_name} ${contact?.last_name}`,
      value: "",
      description: `${contact?.first_name} ${contact?.last_name} ID Verification`,
      items: [],
      yearly_set_count: 1,
      count_unique_uploaded: documents?.length ?? 0,
    },
  ];

  const role =
    contact.roles === "Admin" ? "Primary Contact" : "Authorized Signatory";

  const isPrimaryContact = contact.roles === "Admin";

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 3 }} key={contact.id}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} sm={4}>
          <Stack direction={"column"}>
            <Typography variant="h4">{role}</Typography>
            <Typography variant="h5">
              {contact?.first_name} - {contact?.last_name}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack direction={"row"} spacing={2} justifyContent="flex-end">
            <Grid item>
              <IconButton
                onClick={() => setEditModalOpen(true)}
                disabled={isLocked}
              >
                <ModeEditIcon />
              </IconButton>
            </Grid>
            {!isPrimaryContact && (
              <Grid item>
                <IconButton onClick={handleDeleteContact} disabled={isLocked}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Grid>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          {documents && (
            <DocumentUploadController
              data={items}
              enableCount={false}
              formikProps={formikProps}
              initialData={documents}
              onUpload={(fileName, id, idx) =>
                handleSubmitContactDocument(fileName, id, idx, index)
              }
              onView={(fileName, id, idx) => {
                fetchDocument(id);
              }}
              onDelete={(fileName, id, idx) => {
                deleteDocument(contact.id, id);
              }}
              sx={{ minHeight: "10vh" }}
              containerSx={{ mt: 0 }}
              disabled={isLocked}
              deleteDisabled={isLocked}
            />
          )}
        </Grid>
      </Grid>

      <Modal
        modalOverlaySx={{ borderRadius: "2ch" }}
        width={"30%"}
        height={"100%"}
        closeButton
        message={
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              {documentUrl?.url?.includes(".pdf?") ? (
                <Grid item xs={12} id="modal-docview">
                  <DocView uri={documentUrl?.url} staticView />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <img
                    src={documentUrl?.url ?? ""}
                    alt={documentUrl?.url}
                    width="100%"
                    height="auto"
                    style={{
                      borderRadius: "2ch",
                      margin: "auto",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        }
        open={show}
        onClose={onClose}
      />

      <Dialog
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Edit Director/Authorized Signatory</DialogTitle>
        <DialogContent>
          <EditAuthorizedSignatory
            accountId={accountId}
            contact={contact}
            onEnd={() => setEditModalOpen(false)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditModalOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IDVerificationDirector;
