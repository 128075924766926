export const currencyFormatter = ({
  amount,
  currency = "",
  decimalPlace = 2,
  useSymbol = "",
}: {
  amount: number;
  currency?: string;
  decimalPlace?: number;
  useSymbol?: string;
}) => {
  return `${useSymbol !== "" ? `${useSymbol} ` : ""}${amount?.toLocaleString(
    "en-US",
    {
      minimumFractionDigits: decimalPlace,
      maximumFractionDigits: decimalPlace,
    }
  )}${currency !== "" ? ` ${currency}` : ""}`;
};
