import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useField } from "formik";

import at from "helpers/at";
import React from "react";
import styles from "./checkboxfield.module.scss";

export interface CheckboxFieldProps {
  /**Label for the checkbox */
  label: any;
  /**Custom class */
  className?: string;
  /**Rest of the props */
  getFieldValue?: (value: boolean) => void;
  disabled?: boolean;
  [x: string]: any;
}

export default function CheckboxField(props: CheckboxFieldProps) {
  const { label, name, getFieldValue, disabled, ...rest } = props;
  const [field, meta, helper] = useField(name);
  const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, ["touched", "error"]);
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function _onChange(e: React.ChangeEvent) {
    setValue(!field.value);
  }
  field.checked = field.value;

  return (
    <FormControl {...rest} disabled={disabled}>
      <FormControlLabel
        value={field.value}
        checked={field.value}
        control={
          <Checkbox
            {...field}
            onChange={_onChange}
            color="secondary"
            onClick={() => {
              getFieldValue && getFieldValue(Boolean(!field.checked));
              rest.onClick && rest.onClick();
            }}
            disabled={disabled}
          />
        }
        disabled={disabled}
        label={<div className={styles.label}>{label && `${label}`}</div>}
      />
      {_renderHelperText()}
    </FormControl>
  );
}
