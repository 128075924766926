import { createApi } from "@reduxjs/toolkit/query/react";
import { VITE_API_V2_URL } from "codes";
import { axiosBaseQuery } from "helpers/axiosbaseQuery";
import { formatCL } from "helpers/formatCL";
import { formatCLs } from "helpers/formatCLs";
import { IOpportunity } from "pages/credit-limits-opportunities/types";
import type {
  Account,
  AssociateCompanyDocs,
  CLDetails,
  Comment,
  RegisterLead,
  RegisterLeadPartner,
  RegisterLeadPartnerCreditLimit,
  SalesLead,
} from "types";
import { accountrtk } from "./api-accounts";
import { comments as commentsAPI } from "./api-comments";
import { companiesrtk } from "./api-company";

interface CLOpportunityData {
  factoring: IOpportunity[];
  reverse_factoring: IOpportunity[];
}

interface CofaceOptions {
  name: string;
  coface_id: string;
  registration_number: string;
  address: string;
}
interface OnboardingTableParams {
  seller: string;
  seller_internal_name: string;
  seller_country: string;
  seller_is_identified: boolean | null;
  seller_details_completed: boolean;
  seller_documents_completed: boolean;
  seller_has_bank_account: boolean;
  buyer: string;
  buyer_internal_name: string;
  buyer_details_completed: boolean;
  buyer_documents_completed: boolean;
  buyer_is_identified: boolean | null;
  buyer_country: string;
  onboarding_status: boolean;
}

const formatReps = (reps: any[]) =>
  reps?.map((item) => ({
    ...item,
    name: `${item.first_name} ${item.last_name}`,
  }));

export const origination = createApi({
  reducerPath: "origination",
  tagTypes: ["STATUS", "CREDIT_LIMITS", "CREDIT_LIMITS_REPORTING"],
  baseQuery: axiosBaseQuery({
    baseUrl: `${VITE_API_V2_URL}/origination`,
  }),
  endpoints: (builder) => ({
    getOnboardingStatuses: builder.query<OnboardingTableParams[], null>({
      query: () => ({ url: "/onboardings", method: "get" }),

      transformResponse: (response) => response.data,
      providesTags: ["STATUS"],
    }),
    getCreditLimits: builder.query<CLDetails[], null>({
      query: () => ({ url: "/credit-limits", method: "get" }),
      transformResponse: (response) => formatCLs(response?.data),
      providesTags: ["CREDIT_LIMITS"],
    }),
    getInsurerIdentification: builder.query<CofaceOptions[], string>({
      query: (cl_id) => ({
        url: `/credit-limits/${cl_id}/insurer_identification`,
        method: "get",
      }),
      transformResponse: (response) => response?.data,
    }),
    getCreditLimit: builder.query<CLDetails, string>({
      query: (clr) => ({ url: `/credit-limits/${clr}`, method: "get" }),
      transformResponse: (response) => formatCL(response?.data),
      providesTags: ["CREDIT_LIMITS"],
    }),
    getSalesList: builder.query<SalesLead[], null>({
      query: () => ({ url: `/list-sales`, method: "get" }),
      transformResponse: (response) => formatReps(response?.data),
    }),
    contactCreditLimitCreator: builder.mutation<
      string,
      { credit_limit_id: string; comments: Comment }
    >({
      query({ credit_limit_id, comments }) {
        return {
          url: `/credit-limits/${credit_limit_id}/contact_creator`,
          method: "POST",
          data: { ...comments },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["CREDIT_LIMITS"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(commentsAPI.util.invalidateTags(["CREDIT_LIMIT_COMMENTS"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    requestCreditLimit: builder.mutation<
      string,
      { credit_limit_id: string; coface_id: string; comments: Comment }
    >({
      query({ credit_limit_id, coface_id, comments }) {
        return {
          url: `/credit-limits/${credit_limit_id}/request_indication`,
          method: "POST",
          data: { ...comments, coface_id },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["CREDIT_LIMITS"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(commentsAPI.util.invalidateTags(["CREDIT_LIMIT_COMMENTS"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    rejectCreditLimit: builder.mutation<
      string,
      { credit_limit_id: string; comments: Comment }
    >({
      query({ credit_limit_id, comments }) {
        return {
          url: `/credit-limits/${credit_limit_id}/reject`,
          method: "POST",
          data: { ...comments },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["CREDIT_LIMITS"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(commentsAPI.util.invalidateTags(["CREDIT_LIMIT_COMMENTS"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    resubmitCreditLimit: builder.mutation<
      string,
      { credit_limit_id: string; comments: Comment }
    >({
      query({ credit_limit_id, comments }) {
        return {
          url: `/credit-limits/${credit_limit_id}/resubmit`,
          method: "POST",
          data: { ...comments },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: ["CREDIT_LIMITS"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(commentsAPI.util.invalidateTags(["CREDIT_LIMIT_COMMENTS"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    editCreditLimit: builder.mutation<
      CLDetails,
      {
        id: string;
        status?: string;
        comment?: string;
        sales_lead?: string;
        credit_limit_request_internal_amount?: number;
      }
    >({
      query(payload) {
        const {
          id,
          status,
          comment,
          sales_lead,
          credit_limit_request_internal_amount,
        } = payload;

        return {
          url: `/credit-limits/${id}`,
          method: "PUT",
          data: {
            status,
            comment,
            sales_lead,
            credit_limit_request_internal_amount,
          },
        };
      },
      transformResponse: (response) => formatCL(response?.data),
      invalidatesTags: ["CREDIT_LIMITS"],
    }),
    approveCreditLimit: builder.mutation<
      string,
      { credit_limit_id: string; comments: Comment }
    >({
      query({ credit_limit_id, comments }) {
        return {
          url: `/credit-limits/${credit_limit_id}/approval`,
          method: "POST",
          data: { ...comments },
        };
      },
      invalidatesTags: () => ["CREDIT_LIMITS"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(commentsAPI.util.invalidateTags(["CREDIT_LIMIT_COMMENTS"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    associateDocsInternal: builder.mutation<
      any,
      { id: string; data: AssociateCompanyDocs }
    >({
      query: ({ id, data }) => {
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("document_category", data.document_category);
        formData.append("document_type", data.document_type);
        //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (data.year) formData.append("year", data.year);
        return {
          url: `/accounts/${id}/documents`,
          method: "POST",
          data: formData,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(companiesrtk.util.invalidateTags(["Company"]));
          dispatch(accountrtk.util.invalidateTags(["ACCOUNT_DOCS", "Company"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),

    createLead: builder.mutation<Account, RegisterLead>({
      query: (data) => {
        return {
          url: `/lead`,
          method: "POST",
          data,
        };
      },
      transformResponse: (response) => response?.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(accountrtk.util.invalidateTags(["ACCOUNTS", "Company"]));
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    createLeadPartner: builder.mutation<Account, RegisterLeadPartner>({
      query: ({ lead_id, ...payload }) => {
        return {
          url: `/lead/${lead_id}/partners`,
          method: "POST",
          data: payload,
        };
      },
      transformResponse: (response) => response?.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            accountrtk.util.invalidateTags(["ACCOUNTS", "Company", "LEAD"])
          );
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    createLeadPartnerCreditLimit: builder.mutation<
      Account,
      RegisterLeadPartnerCreditLimit
    >({
      query: ({ lead_id, ...payload }) => {
        return {
          url: `/lead/${lead_id}/credit-limit`,
          method: "POST",
          data: payload,
        };
      },
      transformResponse: (response) => response?.data,
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            accountrtk.util.invalidateTags(["ACCOUNTS", "Company", "LEAD"])
          );
        } catch (err) {
          //eslint-disable-next-line no-console
          console.log(err);
        }
      },
    }),
    getCreditLimitsOpportunities: builder.query<CLOpportunityData, null>({
      query: () => ({ url: "/credit-limits-reporting", method: "get" }),
      transformResponse: (response) => response?.data,
      providesTags: ["CREDIT_LIMITS_REPORTING"],
    }),
  }),
});

export const {
  useGetOnboardingStatusesQuery,
  useGetCreditLimitsQuery,
  useGetInsurerIdentificationQuery,
  useContactCreditLimitCreatorMutation,
  useRequestCreditLimitMutation,
  useRejectCreditLimitMutation,
  useResubmitCreditLimitMutation,
  useApproveCreditLimitMutation,
  useAssociateDocsInternalMutation,
  useGetCreditLimitQuery,
  useEditCreditLimitMutation,
  useGetSalesListQuery,
  useCreateLeadMutation,
  useCreateLeadPartnerMutation,
  useCreateLeadPartnerCreditLimitMutation,
  useGetCreditLimitsOpportunitiesQuery,
} = origination;
