import {
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useGetAccountQuery } from "api/api-accounts";

import {
  useFetchIncomlendPermissionsQuery,
  useFetchUserPermissionsQuery,
  useGetCurrentUserQuery,
} from "api/api-users-v2";
import {
  GROUP_LIST,
  INCOMLEND_INTERNAL,
  INCOMLEND_INTERNAL_NAME,
  SELECT_ACCOUNT,
} from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";
import history from "helpers/history";
import { isUUID, roleRedirect } from "helpers/redirect";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setAppPermission,
  setAppState,
  setProfile,
  setRole,
  setUserPermission,
} from "redux/reducers/appStatesReducer";
import { setCurrentCompany } from "redux/reducers/companyReducer";

const RolesSwitcher = () => {
  const dispatch = useAppDispatch();
  const { data: user, isFetching } = useGetCurrentUserQuery();
  const { data: userPermissions, isFetching: isFetchingUserPermissions } =
    useFetchUserPermissionsQuery(user?.id ?? "", {
      skip: !Boolean(user),
    });
  const {
    data: incomlendPermissions,
    isFetching: isFetchingIncomlendPermissions,
  } = useFetchIncomlendPermissionsQuery(user?.id ?? "", {
    skip: !Boolean(user),
  });

  const USER_ROLE = useAppSelector((state) => state.appState.role);

  const APP_PERMISSION = useAppSelector((state) => state.appState.permission);
  const initialRole = userPermissions?.[0]?.id ?? "";
  const [id, setId] = useState<string>(USER_ROLE?.id ?? initialRole);

  useEffect(() => {
    return () => {
      if (APP_PERMISSION === INCOMLEND_INTERNAL) setId(INCOMLEND_INTERNAL);
      else setId(USER_ROLE?.id);
    };
  });

  const AVAILABLE_ROLE_NAMES: any[] = [];
  if (incomlendPermissions && Boolean(incomlendPermissions?.groups?.length))
    AVAILABLE_ROLE_NAMES.push({
      id: INCOMLEND_INTERNAL,
      name: INCOMLEND_INTERNAL_NAME,
      permission: incomlendPermissions.permissions,
      role: INCOMLEND_INTERNAL,
    });
  if (userPermissions) {
    if (userPermissions?.length > 1)
      AVAILABLE_ROLE_NAMES.push({
        id: "Accounts",
        name: "Accounts",
        permission: [],
        role: "Accounts",
      });
    else if (userPermissions?.length === 1)
      AVAILABLE_ROLE_NAMES.push(userPermissions?.[0]);
  }

  const { data: company } = useGetAccountQuery(id ?? "", {
    skip:
      !Boolean(id) ||
      GROUP_LIST.includes(APP_PERMISSION) ||
      APP_PERMISSION === "Accounts" ||
      id === INCOMLEND_INTERNAL,
  });

  useEffect(() => {
    if (company) {
      dispatch(setCurrentCompany(company));
      company?.profile && dispatch(setProfile(company?.profile));
    }
  }, [company]);

  const handleChange = (event: SelectChangeEvent) => {
    const retreivedId = event.target.value;

    if (retreivedId === "Accounts") {
      dispatch(setAppState(SELECT_ACCOUNT));
      window.open("/accounts", "_self");
    }
    const ROLE = isUUID.test(retreivedId) ? "Client" : retreivedId;

    setId(retreivedId);
    const user_permission =
      userPermissions?.find((role) => role.id === retreivedId)?.permission ??
      [];

    const user_role = userPermissions?.find((role) => role.id === retreivedId);
    dispatch(setAppPermission(ROLE));
    if (retreivedId === INCOMLEND_INTERNAL && incomlendPermissions)
      dispatch(setUserPermission(incomlendPermissions?.permissions));
    else dispatch(setUserPermission(user_permission));
    if (user_role)
      dispatch(
        setRole({
          ...user_role,
          role: user_role.director ? "Director" : "Non-Director",
        })
      );
    roleRedirect(ROLE);
  };
  const handleClick = () => {
    if (id === "Accounts") {
      dispatch(setAppState(SELECT_ACCOUNT));
      history.push("/accounts");
    }
  };

  const IS_MULTI_ACCOUNT =
    userPermissions &&
    AVAILABLE_ROLE_NAMES?.length &&
    userPermissions?.length > 1;

  const IS_MULTI_ROLE =
    userPermissions &&
    AVAILABLE_ROLE_NAMES?.length &&
    AVAILABLE_ROLE_NAMES.length > 1;

  return (
    <Grid item>
      {isFetching &&
        isFetchingUserPermissions &&
        isFetchingIncomlendPermissions && <SkeletonLoad bars={1} />}
      {!isFetching && (IS_MULTI_ACCOUNT || IS_MULTI_ROLE) ? (
        <Select
          value={id !== "" ? id : USER_ROLE?.id}
          onChange={handleChange}
          onClick={handleClick}
          label="Role"
          id="roles-switcher"
          fullWidth
          sx={{
            padding: "1ch",
            border: "1px solid",
            textTransform: "capitalize",
            overflow: "hidden",
            maxWidth: "100%",
            textOverflow: "ellipsis",
            ".MuiSelect-select": {
              padding: "0 0 0 10px",
              fontWeight: 600,
            },
            ">div": {
              borderRadius: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "76%",
              textOverflow: "ellipsis",
            },
          }}
        >
          <MenuItem value={USER_ROLE?.id} disabled>
            {USER_ROLE?.name}
          </MenuItem>
          <Divider />
          {AVAILABLE_ROLE_NAMES?.map((role) => {
            return (
              <MenuItem value={role?.id} key={role?.name}>
                <Typography fontWeight={600} color="primary">
                  {role?.name}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <Typography
          fontWeight={800}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {AVAILABLE_ROLE_NAMES[0]?.name ?? t("welcome")}
        </Typography>
      )}
    </Grid>
  );
};

export default RolesSwitcher;
