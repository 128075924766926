import type { SupportTicket } from "types";
import * as Yup from "yup";
//eslint-disable-next-line prefer-regex-literals
const timeTrackerRegex = new RegExp(/^(\d+w)?\s*(\d+d)?\s*(\d+h)?\s*(\d+m)?$/);

export const emptySupportTicket: SupportTicket = {
  id: "",
  internal_name: "",
  title: "",
  content: "",
  submitted_at: "",
  estimate_min: "",
  remaining_min: "",
  request_quality: "",
  submitted_by: {
    id: "",
    first_name: "",
    last_name: "",
    username: "",
  },
  assignee: {
    id: "",
    first_name: "",
    last_name: "",
    username: "",
  },
  status: "",
  priority: "",
  assignee_department: "",
  assignee_department_manager: {
    id: "",
    first_name: "",
    last_name: "",
    username: "",
  },
  submitter_department: "",
  submitter_department_manager: {
    id: "",
    first_name: "",
    last_name: "",
    username: "",
  },
  attached_file: [],
  assignee_user_id: "",
  business_priority_code_id: "",
  assignee_department_id: "",
  business_priority: {
    label: "",
    level: 0,
  },
  priority_code_id: "",
  it_priority_code_id: "",
  it_priority: {
    label: "",
    level: 0,
  },
  status_id: "",
};

export const issueValidationSchema = Yup.object().shape({
  estimate_min: Yup.string()
    .test({
      name: "estimate_min",
      exclusive: false,
      params: {},
      message:
        "Please enter in the format [number]w [number]d [number]h [number]m",
      test: (value: string) => timeTrackerRegex.test(value),
    } as any)
    .nullable(),
});
