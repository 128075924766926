import { Droppable } from "@hello-pangea/dnd";
import { Grid, Slide, Stack, Tooltip, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { currencyFormatter } from "helpers/currencyFormatter";
import groupBy from "helpers/groupBy";
import memoizeOne from "memoize-one";
import React, { HTMLAttributes } from "react";

import { InvoiceStatus } from "types";
import InvoiceStatusConsolidated from "./TaskTypeListGenerator/InvoiceStatusConsolidated";
import Task from "./task";
import type {
  Column as ColumnType,
  Id,
  TASK_TYPES,
  Task as TaskType,
} from "./types";

type ContainerProps = {
  main?: HTMLAttributes<HTMLDivElement>;
  column?: HTMLAttributes<HTMLDivElement>;
  task?: HTMLAttributes<HTMLDivElement>;
};
type Props = {
  column: ColumnType;
  tasks: TaskType[];
  selectedTaskIds: Id[];
  draggingTaskId: Id | null;
  toggleSelection: (taskId: Id) => void;
  toggleSelectionInGroup: (taskId: Id) => void;
  multiSelectTo: (taskId: Id) => void;
  taskType: TASK_TYPES;
  action?: (taskId: Id) => void;
  isDragDisabled?: boolean;
  noOfColumns: number;
  showSummary?: boolean;
  groupByKey?: string;
  consolidate?: boolean;
  setConsolidate?: React.Dispatch<React.SetStateAction<boolean>>;
  containerProps?: ContainerProps;
};

const Container = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "length" && prop !== "theme" && prop !== "containerProps",
})<{ length: number; containerProps?: ContainerProps }>(
  ({ theme, length, containerProps }) => ({
    minWidth: `${(window.innerWidth * 0.65) / length}px`,
    maxWidth: `${(window.innerWidth * 0.65) / length}px`,
    [theme.breakpoints.down("sm")]: {
      minWidth: `${window.innerWidth - 120}px`,
      maxWidth: `${window.innerWidth - 120}px`,
    },
    margin: "5px",
    borderRadius: `${theme.shape.borderRadius}px`,
    height: "90%",
    display: "flex",
    flexDirection: "column",
    ...containerProps?.column?.style,
  })
);

const TaskList = styled("div", {
  shouldForwardProp: (prop) => prop !== "isDraggingOver" && prop !== "theme",
})<{ isDraggingOver: boolean }>(({ theme, isDraggingOver }) => ({
  minHeight: "200px",
  flexGrow: 1,
  transition: "background-color 0.2s ease",
  marginTop: "10px",
  backgroundColor: isDraggingOver ? theme.palette.background.default : "",
  border: isDraggingOver ? `2px dashed ${theme.palette.inactive.main}` : "",
}));

const ConsolidatedContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "theme",
})(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.main,
  padding: "10px",
  marginBottom: "10px",
  borderRadius: `${theme.shape.borderRadius}px`,
  fontSize: "18px",
  border: `5px solid ${theme.palette.primary.main}`,
  boxShadow: `2px 2px 1px ${theme.palette.secondary.main}26`,
  "&:focus": {
    outline: "none",
    borderColor: theme.palette.error.main,
  },
  "&:hover": {
    boxShadow: `2px 2px 8px 3px ${theme.palette.primary.main}57`,
  },
  height: "26vh",
  [theme.breakpoints.down("sm")]: {
    height: "32vh",
  },
}));

type TaskIdMap = {
  [taskId: Id]: true;
};

const getSelectedMap = memoizeOne((selectedTaskIds: Id[]) =>
  selectedTaskIds.reduce((previous: TaskIdMap, current: Id): TaskIdMap => {
    previous[current] = true;
    return previous;
  }, {})
);

const Column = ({
  column,
  tasks,
  selectedTaskIds,
  draggingTaskId,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  taskType,
  action,
  isDragDisabled,
  noOfColumns,
  showSummary = true,
  groupByKey,
  consolidate = false,
  setConsolidate,
  containerProps,
}: Props) => {
  const theme = useTheme();
  const groupableElementsBag = groupBy(tasks ?? [], groupByKey as any);

  const onClick = (event: any) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== 0) {
      return;
    }

    event.preventDefault();

    setConsolidate && setConsolidate(false);
  };

  return (
    <Container
      length={noOfColumns}
      theme={theme}
      id="kanban-container"
      containerProps={containerProps}
    >
      <Stack spacing={1} flexGrow={1}>
        <Grid
          container
          sx={{
            bgcolor: theme.palette.primary.main,
            borderRadius: 1,
            width: "100%",
          }}
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Tooltip title={column.title}>
            <Grid item xs={12} pt={"0 !important"}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "0.8em",
                  p: 1,
                  color: theme.palette.background.default,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "90%",
                  whiteSpace: "nowrap",
                }}
              >
                {`${column.title}`}
              </Typography>
            </Grid>
          </Tooltip>
          <Grid
            item
            xs={12}
            pt={"0 !important"}
            pl={"0 !important"}
            sx={{ bgcolor: theme.palette.error.main }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              fontWeight={"bold"}
              textAlign="center"
              sx={{ color: theme.palette.background.default }}
            >{`${tasks?.filter((task) => task?.id)?.length}`}</Typography>
          </Grid>
        </Grid>
        {showSummary && (
          <Grid
            container
            sx={{ bgcolor: `#868695`, borderRadius: 0.5, width: "100%" }}
            spacing={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Tooltip
              title={currencyFormatter({
                amount: tasks.reduce((a, b) => Number(a) + Number(b.value), 0),
                currency: "USD",
              })}
            >
              <Grid item xs={12} pt={"0 !important"}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "0.8em",
                    p: 0,
                    color: theme.palette.background.default,
                    overflow: "hidden",
                    maxWidth: "90%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    textAlign: "right",
                  }}
                >
                  {`${currencyFormatter({
                    amount: tasks.reduce(
                      (a, b) => Number(a) + Number(b.value),
                      0
                    ),
                    currency: "USD",
                  })}`}
                </Typography>
              </Grid>
            </Tooltip>
          </Grid>
        )}

        <Droppable droppableId={column?.id}>
          {(provided, snapshot) => (
            <TaskList
              ref={provided.innerRef}
              isDraggingOver={snapshot.isDraggingOver}
              {...provided.droppableProps}
              theme={theme}
            >
              {(consolidate ? Object.values(groupableElementsBag) : tasks).map(
                (task: any, index: number) => {
                  if (consolidate) {
                    if (task.length > 1)
                      return (
                        <Slide
                          key={`${task[0].id}-${index}-consolidated`}
                          direction="up"
                          in={consolidate}
                          mountOnEnter
                          unmountOnExit
                        >
                          <ConsolidatedContainer
                            onClick={onClick}
                            theme={theme}
                          >
                            <InvoiceStatusConsolidated
                              {...(task?.[0]
                                ?.content as unknown as InvoiceStatus)}
                              requested_amount_usd={task.reduce(
                                (a: any, b: { value: any }) =>
                                  Number(a) + Number(b.value),
                                0
                              )}
                              noOfInvoices={task?.length}
                            />
                          </ConsolidatedContainer>
                        </Slide>
                      );
                    else {
                      const isSelected: boolean = Boolean(
                        getSelectedMap(selectedTaskIds)[task[0].id]
                      );
                      const isGhosting: boolean =
                        isSelected &&
                        Boolean(draggingTaskId) &&
                        draggingTaskId !== task[0].id;
                      return (
                        <Task
                          task={task[0]}
                          index={index}
                          key={task[0].id}
                          isSelected={isSelected}
                          isGhosting={isGhosting}
                          selectionCount={selectedTaskIds.length}
                          toggleSelection={toggleSelection}
                          toggleSelectionInGroup={toggleSelectionInGroup}
                          multiSelectTo={multiSelectTo}
                          taskType={taskType}
                          action={action}
                          isDragDisabled={isDragDisabled}
                          containerProps={containerProps}
                        />
                      );
                    }
                  } else {
                    const isSelected: boolean = Boolean(
                      getSelectedMap(selectedTaskIds)[task.id]
                    );
                    const isGhosting: boolean =
                      isSelected &&
                      Boolean(draggingTaskId) &&
                      draggingTaskId !== task.id;

                    return (
                      <Task
                        task={task}
                        index={index}
                        key={task.id}
                        isSelected={isSelected}
                        isGhosting={isGhosting}
                        selectionCount={selectedTaskIds.length}
                        toggleSelection={toggleSelection}
                        toggleSelectionInGroup={toggleSelectionInGroup}
                        multiSelectTo={multiSelectTo}
                        taskType={taskType}
                        action={action}
                        isDragDisabled={isDragDisabled}
                        containerProps={containerProps}
                      />
                    );
                  }
                }
              )}

              {provided.placeholder}
            </TaskList>
          )}
        </Droppable>
      </Stack>
    </Container>
  );
};

export default Column;
