import { Science } from "@mui/icons-material";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
} from "@mui/material";

import React, { Fragment } from "react";
import ScriptButton from "./ScriptButton";

interface Item {
  [key: string]: {
    items: {
      [key: string]: {
        items: {
          icon: any;
        };
      };
    };
  };
}

interface Props {
  item: Item;
}

const SelectScript: React.FC<Props> = ({ item }) => {
  const theme = useTheme();
  const section = `${Object.keys(item)[0]}`;

  return (
    <Fragment>
      <ListItem sx={{ mb: 1, mt: 1 }}>
        <ListItemAvatar sx={{ ml: 1 }}>
          <Avatar
            sx={{
              bgcolor: theme.palette.white.main,
              color: "primary.main",
            }}
          >
            <Science color="error" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={section}
          sx={{
            span: {
              fontWeight: 600,
              fontSize: "1.2em",
              color: "error.main",
            },
          }}
        />
      </ListItem>
      <Divider />
      {/* Subitems */}
      <List dense={true}>
        {Object.entries(Object.values(item)?.[0]?.items)?.map(
          ([key, value]) => (
            <ListItem sx={{ mb: 1, mt: 1 }} key={key}>
              <ListItemAvatar sx={{ ml: 5 }}>
                <Avatar
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: "white.main",
                  }}
                >
                  {value.items.icon}
                </Avatar>
              </ListItemAvatar>
              <ScriptButton section={section} item={key} />
            </ListItem>
          )
        )}
      </List>
      {/* Subitem end */}
    </Fragment>
  );
};

export default SelectScript;
