import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

export interface ISignUp {
  firstName: string;
  lastName: string;
  mobile: string;
  title?: string;
  marketingCheck?: boolean;
}
export const defaultValues: ISignUp = {
  firstName: "",
  lastName: "",
  mobile: "",
  title: "",
  marketingCheck: true,
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter your First Name"),
  lastName: Yup.string().required("Please enter your Surname"),

  mobile: Yup.string()
    .required("Please enter your Phone Number")
    .test({
      name: "mobile",
      exclusive: false,
      params: {},
      message: "Invalid Phone Number",
      test: (value: any) => Boolean(value) && isValidPhoneNumber(value),
    } as any),
  title: Yup.string(),
  code: Yup.number()
    .when("totpRequired", {
      is: true,
      then: (schema) => schema.required("Please enter OTP"),
    })
    .test(
      "code",
      "Must be exactly 6 digits",
      (val) => val?.toString().length === 6
    ),
  marketingCheck: Yup.boolean(),
});
