import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import React, { useState, WheelEvent } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

import { Grid } from "@mui/material";

import useWidth from "helpers/useWidth";
import styles from "./docview.module.scss";

export interface DocViewProps {
  /**Document link */
  uri: string;
  // /**Page width */
  // width?: number;
  /**Toggle Navigation display */
  staticView?: boolean;
  hideNavigation?: boolean;
  ref?: React.RefObject<HTMLDivElement>;
  pageClassName?: string;
  docClassName?: string;
}

const DocView = (props: DocViewProps) => {
  const { uri, hideNavigation = false, ref, staticView = false } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const width = ref ? useWidth(ref) ?? 400 : 400;
  const height = ref?.current?.clientHeight ?? 400;
  const { docClassName = "", pageClassName = "" } = props;
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const handleOnWheel = (e: WheelEvent) => {
    if (scale >= 1) {
      setScale(scale - 0.01 * e.deltaY);
    } else {
      setScale(1);
    }
  };

  const goLeft = () =>
    setPageNumber((page) => {
      if (page != 1) return page - 1;
      return 1;
    });
  const goRight = () =>
    setPageNumber((page) => {
      if (page != numPages) return page + 1;
      return numPages;
    });
  const Navigation = () => (
    <Grid container alignItems={"center"} justifyContent="center">
      <Grid item>
        <Grid container>
          <ChevronLeftIcon onClick={goLeft} />
          Page {pageNumber} of {numPages}
          <ChevronRightIcon onClick={goRight} />
        </Grid>
      </Grid>
      <Grid item>
        <ZoomInIcon onClick={() => setScale((scale) => scale + 0.1)} />
      </Grid>
      <Grid item>
        <ZoomOutIcon onClick={() => setScale((scale) => scale - 0.1)} />
      </Grid>
    </Grid>
  );
  return (
    <Grid container ref={ref}>
      <Grid item xs={12}>
        <div onWheel={staticView ? () => {} : handleOnWheel}>
          <Document
            file={uri}
            className={`${styles.doc} ${docClassName}`}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode="canvas"
          >
            <Page
              width={width}
              pageNumber={pageNumber}
              height={height}
              renderMode="canvas"
              scale={scale}
              className={`${styles.page} ${pageClassName}`}
            />
          </Document>
        </div>
      </Grid>
      <Grid item xs={12}>
        {!hideNavigation && <Navigation />}
      </Grid>
    </Grid>
  );
};

export default DocView;
