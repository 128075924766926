import { Grid } from "@mui/material";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";
import React from "react";
import AgentDetails from "./AgentDetails";
import PrimaryContactDetails from "./PrimaryContactDetails";
import SecondaryContactDetails from "./SecondaryContactDetails";
import { initialValues } from "./constants";

const Agent: React.FC = () => {
  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {(formikProps) => {
        return (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <AgentDetails />
              </Grid>

              <Grid item xs={4}>
                <Input
                  name="companyRegistrationNumber"
                  label="Company Registration Number"
                  value={formikProps.values.companyRegistrationNumber}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  name="website"
                  label="Website"
                  value={formikProps.values.website}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={6}>
                <PrimaryContactDetails />
              </Grid>
              <Grid item xs={6}>
                <SecondaryContactDetails />
              </Grid>

              <Grid item xs={4}>
                <Input
                  fullWidth
                  sx={{ width: "100%" }}
                  name="companyLegalType"
                  label="Company Legal Type"
                  value={formikProps.values.companyLegalType}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  fullWidth
                  sx={{ width: "100%" }}
                  name="companyIncorporationDate"
                  label="Company Incorporation Date"
                  value={formikProps.values.companyIncorporationDate}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Drop
                  name="onboardingStage"
                  label="Onboarding Stage"
                  value={formikProps.values.onboardingStage}
                  keyValue={"name"}
                  data={[
                    {
                      name: "Screening",
                      value: "Screening",
                    },
                    {
                      name: "Approval",
                      value: "Approval",
                    },
                    {
                      name: "Onboarded",
                      value: "Onboarded",
                    },
                  ]}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  fullWidth
                  sx={{ width: "100%" }}
                  name="nextReviewDate"
                  label="Next Review Date"
                  value={formikProps.values.nextReviewDate}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Drop
                  name="accountStatus"
                  label="Account Status"
                  value={formikProps.values.accountStatus}
                  keyValue={"name"}
                  data={[
                    { name: "Active", value: "Active" },
                    { name: "Dormant", value: "Dormant" },
                    { name: "Suspend", value: "Suspend" },
                  ]}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Drop
                  name="accountAgentFeesType"
                  label="Account Agent Fees Type"
                  value={formikProps.values.accountAgentFeesType}
                  keyValue={"name"}
                  data={[
                    {
                      name: "Percentage of SDA Fixed",
                      value: "Percentage of SDA Fixed",
                    },
                    {
                      name: "Percentage of SDA Tiered",
                      value: "Percentage of SDA Tiered",
                    },
                  ]}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  fullWidth
                  sx={{ width: "100%" }}
                  name="agentFeeValue"
                  label="Value of Agent's Fee"
                  value={formikProps.values.agentFeeValue}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  fullWidth
                  sx={{ width: "100%" }}
                  name="ageOfMap"
                  label="Age of the Map in Years"
                  value={formikProps.values.ageOfMap}
                  {...formikProps}
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Agent;
