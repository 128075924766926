import PaymentsIcon from "@mui/icons-material/Payments";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Avatar,
  Badge,
  Box,
  Container,
  Grid,
  LinearProgress,
  LinearProgressProps,
  Link,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import { currencyFormatter } from "helpers/currencyFormatter";
import moment from "moment";
import { PERMISSIONS } from "permissions";
import { useAppSelector } from "redux/hooks";
import { SalesOpportunity } from "types";

const OpportunityContainer = styled(Container)`
  border: 1px solid black;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 10px;
`;
const StatusBox = styled(Avatar)`
  width: 100%;
  border: 1px solid black;
  border-radius: 10px;
  height: auto;
  padding-top: 0.5ch;
  padding-bottom: 0.5ch;
`;

const CreditCommittee = ({
  item,
  showProgress,
}: {
  item: SalesOpportunity;
  showProgress?: boolean;
}) => {
  const theme = useTheme();
  const supplierCreditReportGreenStatus: string[] = [
    "Credit Report Received",
    "Onboarded",
    "Submitted to Amicorp",
  ];
  const creditReportOrangeStatus: string[] = [
    "waiting documents",
    "dropped",
    "underlying structure",
    "Credit Report Ordered",
  ];
  const buyerCreditReportGreenStatus: string[] = [
    "Credit Report Received",
    "Credit Report Requested",
    "Under Review",
    "Onboarded",
  ];

  const VARIANT_COLOR: Record<string, string> = {
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
  };
  const USER_PERMISSION = useAppSelector(
    (state) => state.appState.user_permission
  );
  const daysSinceInception = (tsDate: string) =>
    moment().diff(moment(tsDate), "days");
  const InsurerBlock = ({ item }: { item: any }) => (
    <Stack
      sx={{
        bgcolor: "#fcc473",
        padding: "0.5ch",
        borderRadius: 1,
        border: "1px solid black",
      }}
      alignItems={"center"}
      justifyContent={"flex-start"}
    >
      <Typography
        fontWeight={"bold"}
        fontSize={"0.8em"}
        sx={{ textDecoration: "underline", color: "#001E61" }}
      >
        {item.insurer ?? "Not Defined"}
      </Typography>{" "}
      <Typography
        fontWeight={"bold"}
        fontSize={"0.8em"}
        sx={{ color: "#001E61" }}
      >
        {currencyFormatter({
          amount: item.creditLimitProvidedByInsurer,
          currency: item.insuranceCurrency,
        })}
      </Typography>
    </Stack>
  );

  const getVariant = (variant1: string, variant2: string) => {
    if (variant1 === "success" && variant2 === "success")
      return VARIANT_COLOR["success"];
    else if (variant1 === "warning" || variant2 === "warning")
      return VARIANT_COLOR["warning"];
    else if (variant1 === "error" && variant2 === "error")
      return VARIANT_COLOR["error"];
    else return VARIANT_COLOR["error"];
  };
  const getDealDelayVariant = (days: string) =>
    daysSinceInception(days) < 15
      ? "success"
      : daysSinceInception(days) > 15 && daysSinceInception(days) < 30
        ? "warning"
        : "error";

  const StatusIndicate = ({
    condition1Variant,
    condition2Variant,
    text1,
    text2,
    title,
  }: {
    condition1Variant: string;
    condition2Variant: string;
    text1: string;
    text2: string;
    title: string;
  }) => (
    <Stack direction={"column"}>
      <Typography textAlign="center">
        <WarningIcon
          color="warning"
          sx={{
            visibility: "hidden",
          }}
        />
      </Typography>
      <StatusBox
        variant="rounded"
        sx={{
          bgcolor: getVariant(condition1Variant, condition2Variant),
        }}
      >
        <Typography
          textAlign="center"
          fontSize={"0.7em"}
          fontWeight={"bold"}
          color={"#fff"}
        >
          {title}
        </Typography>
      </StatusBox>
      <Stack
        direction={"row"}
        width={"80%"}
        margin={"10px auto"}
        justifyContent={"space-between"}
      >
        <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
          <Tooltip title={text1}>
            <Avatar
              sx={{
                bgcolor: VARIANT_COLOR[condition1Variant],
                border: "1px solid black",
                height: "1.5em",
                width: "1.5em",
              }}
            >
              <Typography fontWeight={"bold"} color={"#fff"}>
                S
              </Typography>
            </Avatar>
          </Tooltip>
        </Stack>
        <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
          <Tooltip title={text2}>
            <Avatar
              sx={{
                bgcolor: VARIANT_COLOR[condition2Variant],
                border: "1px solid black",
                height: "1.5em",
                width: "1.5em",
              }}
            >
              <Typography fontWeight={"bold"} color={"#fff"}>
                B
              </Typography>
            </Avatar>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const { progress } = item;
  return (
    <Badge
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      color={
        progress === 100
          ? "success"
          : getDealDelayVariant(item?.termsheetSignedDate)
      }
      sx={{ display: "flex", flexGrow: 1 }}
      badgeContent={`${daysSinceInception(item?.termsheetSignedDate)} days`}
    >
      <OpportunityContainer sx={{ pt: 1, flexGrow: 1 }} maxWidth={"xl"}>
        <Stack spacing={1}>
          {showProgress && (
            <Stack direction={"row"} width={"100%"}>
              <Grid item xs={1}>
                <Typography fontSize={"0.7em"}>Overall Progress</Typography>
              </Grid>
              <Grid item xs={11}>
                <LinearProgressWithLabel
                  value={progress}
                  color={
                    progress === 100
                      ? "success"
                      : progress < 100 && progress > 50
                        ? "warning"
                        : "error"
                  }
                />
              </Grid>
            </Stack>
          )}

          <Stack
            spacing={1}
            sx={{
              flexWrap: "wrap",
              alignContent: "flex-start",
              alignItems: "center",
            }}
            justifyContent={{ xs: "center", lg: "center" }}
            alignContent={{ xs: "flex-start", lg: "flex-start" }}
          >
            <Stack
              direction={{ xs: "column", lg: "row" }}
              spacing={{ xs: 1, lg: 1 }}
              alignItems={{ xs: "flex-start", lg: "center" }}
              width={"100%"}
              justifyContent={"start"}
            >
              <Grid container spacing={{ xs: 1, lg: 1 }}>
                <Grid item xs={12} lg={1.5}>
                  <Link
                    sx={{
                      bgcolor: "#9542ee",
                      color: theme.palette.white.main,
                      borderRadius: 1,
                      p: 0.8,
                    }}
                    href={item.salesOpportunityId?.link}
                  >
                    {item.salesOpportunityId?.name}
                  </Link>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <Tooltip title={"Supplier"}>
                      <Avatar
                        sx={{
                          bgcolor: theme.palette.secondary.main,
                          border: "1px solid black",
                          height: "1.5em",
                          width: "1.5em",
                        }}
                        variant={"rounded"}
                      >
                        <Typography fontWeight={"bold"} color={"#fff"}>
                          S
                        </Typography>
                      </Avatar>
                    </Tooltip>
                    <Typography fontWeight={"bold"} lineHeight={"1em"}>
                      {item.supplier}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    justifyContent={"start"}
                  >
                    <Tooltip title={"Buyer"}>
                      <Avatar
                        sx={{
                          bgcolor: theme.palette.secondary.main,
                          border: "1px solid black",
                          height: "1.5em",
                          width: "1.5em",
                        }}
                        variant={"rounded"}
                      >
                        <Typography fontWeight={"bold"} color={"#fff"}>
                          B
                        </Typography>
                      </Avatar>
                    </Tooltip>

                    <Typography fontWeight={"bold"} lineHeight={"1em"}>
                      {item.buyer}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={1}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    justifyContent={"start"}
                    alignItems={"center"}
                  >
                    <PaymentsIcon />
                    <Typography fontWeight={"bold"} fontSize={"0.9em"}>
                      {item?.invoiceCurrency ?? "Undefined"}
                    </Typography>
                  </Stack>
                </Grid>
                {USER_PERMISSION.includes(PERMISSIONS.get_insurer) && (
                  <Grid item xs={12} lg={1.7}>
                    <InsurerBlock item={item} />
                  </Grid>
                )}
                <Grid item xs={12} lg={1.8} marginLeft={"auto"}>
                  <Typography
                    sx={{
                      bgcolor: "#0472ca",
                      color: "#ffffff",
                      padding: "0.8ch",
                      borderRadius: 1,
                      border: "1px solid black",
                      pl: 2,
                    }}
                    fontWeight={"bold"}
                    fontSize={"0.8em"}
                  >
                    {item?.salesOwner}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
          <Stack>
            <Stack
              direction={{ xs: "column", lg: "row" }}
              spacing={{ xs: 0, lg: 1 }}
              alignItems={{ xs: "flex-start", lg: "baseline" }}
              sx={{ width: "100%" }}
            >
              <Grid item xs={12} lg={2} sx={{ width: "100%" }}>
                <Typography textAlign="center">
                  <WarningIcon
                    color="warning"
                    sx={{
                      visibility: Boolean(item?.termsheetSignedDate)
                        ? "hidden"
                        : "visible",
                    }}
                  />
                </Typography>
                <StatusBox
                  variant="rounded"
                  sx={{
                    bgcolor: Boolean(item?.termsheetSignedDate)
                      ? VARIANT_COLOR["success"]
                      : VARIANT_COLOR["error"],
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontSize={"0.7em"}
                    fontWeight={"bold"}
                    color={"#fff"}
                  >
                    Term Sheet
                  </Typography>
                </StatusBox>
                <Typography textAlign="center" fontSize={"0.7em"}>
                  {item?.termsheetSignedDate
                    ? moment(item?.termsheetSignedDate).format("DD/MM/YYYY")
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2} sx={{ width: "100%" }}>
                <Typography
                  textAlign="center"
                  fontSize={"0.7em"}
                  fontWeight={"bold"}
                >
                  <WarningIcon
                    color="warning"
                    sx={{
                      visibility:
                        Boolean(item?.welcomeCallDate) &&
                        Boolean(item?.welcomeCallStatus === "Completed")
                          ? "hidden"
                          : "visible",
                    }}
                  />
                </Typography>
                <StatusBox
                  variant="rounded"
                  sx={{
                    bgcolor: Boolean(item?.welcomeCallStatus === "Completed")
                      ? VARIANT_COLOR["success"]
                      : VARIANT_COLOR["error"],
                  }}
                >
                  <Typography
                    color={"#fff"}
                    textAlign="center"
                    fontSize={"0.7em"}
                    fontWeight={"bold"}
                  >
                    Welcome Call
                  </Typography>
                </StatusBox>
                <Typography textAlign="center" fontSize={"0.7em"}>
                  {item?.welcomeCallDate
                    ? moment(item?.welcomeCallDate).format("DD/MM/YYYY")
                    : "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2} sx={{ width: "100%" }}>
                <StatusIndicate
                  condition1Variant={
                    supplierCreditReportGreenStatus?.includes(
                      item?.supplierCreditReport
                    )
                      ? "success"
                      : creditReportOrangeStatus?.includes(
                            item?.supplierCreditReport
                          )
                        ? "warning"
                        : "error"
                  }
                  condition2Variant={
                    buyerCreditReportGreenStatus?.includes(
                      item?.buyerCreditReport
                    )
                      ? "success"
                      : creditReportOrangeStatus?.includes(
                            item?.buyerCreditReport
                          )
                        ? "warning"
                        : "error"
                  }
                  title={"Credit Report"}
                  text1={
                    item?.supplierCreditReport?.split("Credit Report ")?.[1]
                  }
                  text2={item?.buyerCreditReport?.split("Credit Report ")?.[1]}
                />
              </Grid>
              <Grid item xs={12} lg={2} sx={{ width: "100%" }}>
                <StatusIndicate
                  condition1Variant={
                    item?.onboardingDocuments?.status ? "success" : "error"
                  }
                  condition2Variant={
                    item?.onboardingDocuments?.status ? "success" : "error"
                  }
                  title={"Onboarding Documents"}
                  text1={item?.onboardingDocuments?.stage}
                  text2={item?.onboardingDocuments?.stage}
                />
              </Grid>
              <Grid item xs={12} lg={2} sx={{ width: "100%" }}>
                <StatusIndicate
                  condition1Variant={
                    item?.screening?.status ? "success" : "error"
                  }
                  condition2Variant={
                    item?.screening?.status ? "success" : "error"
                  }
                  title={"Screening"}
                  text1={item?.screening?.stage}
                  text2={item?.screening?.stage}
                />
              </Grid>
              <Grid item xs={12} lg={2} sx={{ width: "100%" }}>
                <StatusIndicate
                  condition1Variant={
                    item?.supplierRiskCall ? "success" : "error"
                  }
                  condition2Variant={
                    item?.buyerRiskCall?.status ? "success" : "error"
                  }
                  title={"Risk Call"}
                  text1={item?.supplierRiskCall ? "Done" : "Pending"}
                  text2={item?.buyerRiskCall?.stage}
                />
              </Grid>
            </Stack>
          </Stack>
          <Grid item xs={12} lg={12}>
            <LabelViewOnly label={"Funding Comments"} />
            <Typography
              color={item?.fundingComments ? "primary.main" : "info.main"}
            >
              {item?.fundingComments ?? "None"}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <LabelViewOnly label={"Origination Comments"} />
            <Typography>{item?.salesComments ?? "None"}</Typography>
          </Grid>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            spacing={1}
          >
            <Grid
              item
              xs={12}
              lg={
                daysSinceInception(item?.termsheetSignedDate) > 60 &&
                progress !== 100
                  ? 9
                  : 12
              }
            ></Grid>

            {/* {daysSinceInception(item?.termsheetSignedDate) > 60 &&
              progress !== 100 && (
                <Grid item xs={12} lg={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    color="error"
                    sx={{ mb: 1 }}
                  >
                    Drop deal
                  </Button>
                </Grid>
              )} */}
            {/* No active SF functions to implement the above yet */}
          </Stack>
        </Stack>
      </OpportunityContainer>
    </Badge>
  );
};
export default CreditCommittee;
