import {
  Button,
  ButtonProps,
  CardActionArea,
  CardActions,
  Grid,
  GridProps,
  SxProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import Card, { CardProps } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { GridTypeMap } from "@mui/system";
import isObjectEmpty from "helpers/isObjectEmpty";
import React from "react";

interface Image {
  height: string;
  src: string;
  alt: string;
}
export interface InfoCardProps extends CardProps {
  /**Card Title */
  title?: string;
  /**Card height */
  height?: React.CSSProperties["height"];
  /**Card maxWidth */
  maxWidth?: React.CSSProperties["maxWidth"];
  borderRadius?: React.CSSProperties["borderRadius"];
  /**Card background `eg: color`*/
  backgroundColor?: React.CSSProperties["backgroundColor"];
  /**Card padding*/
  padding?: React.CSSProperties["padding"];
  /**Card background image url*/
  backgroundImageSrc?: React.CSSProperties["backgroundImage"];
  /**Card Subtitle */
  subtitle?: string | React.ReactNode;
  /**Primary Button */
  primary?: ButtonProps;
  /**Secondary Button */
  secondary?: ButtonProps;
  /**Image Props */
  image?: Image;
  /**Heading Props */
  headingProps?: TypographyProps;
  /**Body Props */
  bodyProps?: TypographyProps;
  /**Custom classname for infoCard */
  className?: string;
  children?: JSX.Element | string;
  /**Title Container styling */
  titleContainerSx?: GridTypeMap["props"]["sx"];
  titleContainerSpacing?: GridTypeMap["props"]["spacing"];
  centralActionButton?: boolean;
  onClick?: () => void;
  horizontal?: boolean;
  sidebarChildren?: React.ReactNode;
  verticalChildren?: React.ReactNode;
  bottomColorBand?: React.CSSProperties["borderBottom"];
  /** Activate the CardActionArea, that allows the action to be on the whole area.
   * It is done to stop the hover effect
   * WARNING : This stop the onClick action !!!
   */
  cardActionArea?: boolean;
  cardProps?: CardProps;
  cardContentSx?: SxProps;
  horizontalGridFormat?: {
    spacing?: GridProps["spacing"];
    leftGrid?: GridProps;
    rightGrid?: GridProps;
  };
}

const CardContentAndAction = (props: InfoCardProps) => {
  const {
    primary,
    secondary,
    title,
    height,
    subtitle = "",
    image,
    headingProps,
    bodyProps,
    children,
    titleContainerSx,
    titleContainerSpacing = 0,
    centralActionButton,
    horizontal = false,
    horizontalGridFormat = {
      spacing: 1,
      leftGrid: { xs: 3 },
      rightGrid: { xs: 9 },
    },
    verticalChildren,
    sidebarChildren,
    bottomColorBand,
    cardContentSx,
  } = props;
  return (
    <>
      {!isObjectEmpty(image as Image) && (
        <CardMedia
          component="img"
          height={image?.height}
          image={image?.src}
          alt={image?.alt}
        />
      )}
      <CardContent sx={{ ...cardContentSx, height }}>
        {horizontal ? (
          <Grid container spacing={horizontalGridFormat.spacing}>
            <Grid
              item
              margin="auto"
              textAlign={"center"}
              {...horizontalGridFormat.leftGrid}
            >
              {sidebarChildren}
            </Grid>
            <Grid item {...horizontalGridFormat.rightGrid}>
              <Grid
                container
                sx={titleContainerSx}
                spacing={titleContainerSpacing}
              >
                <Grid item xs={12}>
                  <Typography {...headingProps}>{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                  {typeof subtitle === "string" ? (
                    <Typography {...bodyProps}>{subtitle}</Typography>
                  ) : (
                    subtitle
                  )}
                </Grid>
              </Grid>
              <Grid container>{children}</Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              container
              sx={titleContainerSx}
              spacing={titleContainerSpacing}
            >
              {verticalChildren && (
                <Grid item xs={12}>
                  {verticalChildren}
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography {...headingProps}>{title}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography {...bodyProps}>{subtitle}</Typography>
              </Grid>
            </Grid>
            <Grid container>{children}</Grid>
          </>
        )}
      </CardContent>
      <CardActions sx={{ borderBottom: bottomColorBand }}>
        {(primary || secondary) && (
          <Grid container spacing={2}>
            {primary && (
              <Grid item margin={centralActionButton ? "auto" : "initial"}>
                <Button {...primary} variant="contained" />
              </Grid>
            )}
            {secondary && (
              <Grid item margin={centralActionButton ? "auto" : "initial"}>
                <Button {...secondary} variant="outlined" />
              </Grid>
            )}
          </Grid>
        )}
      </CardActions>
    </>
  );
};

const InfoCard = (props: InfoCardProps) => {
  const {
    maxWidth,
    borderRadius = "3ch",
    backgroundColor = "white",
    backgroundImageSrc = "",
    padding,
    className,
    onClick,
    cardActionArea = true,
    cardProps,
  } = props;

  return (
    <Card
      className={className}
      sx={{
        width: "100%",
        padding,
        maxWidth,
        borderRadius,
        backgroundColor,
        backgroundImage: `url(${backgroundImageSrc})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      {...cardProps}
    >
      {cardActionArea ? (
        <CardActionArea onClick={onClick}>
          <CardContentAndAction {...props} />
        </CardActionArea>
      ) : (
        <CardContentAndAction {...props} />
      )}
    </Card>
  );
};

export default InfoCard;
