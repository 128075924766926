import moment from "moment";
import type { CLDetails, CreditLimit } from "types";

const initialSalesLeadValue = {
  email: "",
  first_name: "Unassigned",
  id: "",
  last_name: "Unassigned",
  mobile: "",
  opted_marketing_updates: false,
  title: "",
};
export const formatCL = (item: CreditLimit): CLDetails => {
  return {
    reqId: item.name ?? "",
    internalStatus: item.internal_status,
    buyerName: item.buyer?.name ?? "N/A",
    buyerInternalName: item.buyer?.internal_name ?? "N/A",
    // eslint-disable-next-line no-extra-boolean-cast
    buyerSource: Boolean(item.buyer?.is_identified) ? "KYC" : "Manual",
    buyerRegNo: item.buyer?.reg_no ?? "N/A",
    buyerCountry: item.buyer?.country ?? "N/A",
    buyerEmail: "N/A",
    buyerAnnualRevenue: item.buyer?.annual_revenue ?? 0,
    //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    insurerResponse: item.insurer_response_date
      ? moment(item.insurer_response_date).format("YYYY-MM-DD HH:mm:ss")
      : ("-" as any),

    // eslint-disable-next-line no-extra-boolean-cast
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    salesLead: item.sales_lead?.first_name
      ? item.sales_lead
      : initialSalesLeadValue,
    salesName: `${item.sales_lead?.first_name} ${item.sales_lead?.last_name}`,
    clStatus: item.status,
    reqAmount: Number(item.requested_amount),
    // approvedAmount: Number(item.credit_limit_request?.internal_amount) ?? 0,
    credit_limit_request_internal_amount:
      item.credit_limit_request_internal_amount ?? 0,

    currency: item.currency,

    buyerId: item.buyer?.id,

    // buyerContact: item.contact_buyer,
    //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dateRequested: item.requested_date
      ? moment(item.requested_date).utc().format("YYYY-MM-DD HH:mm:ss")
      : "-",
    rating: item?.coface_rating,
    coface_rating_description: item?.coface_rating_description,
    coface_amount: item?.coface_amount,
    coface_currency: item?.coface_currency,
    coface_found: item?.coface_found,
    coface_status: item?.coface_status,
    buyerIsIdentified: Boolean(item.buyer?.is_identified),
    sf_link: item.sf_link,
    insurer: item?.insurer,
    requestedBy: item?.requested_by,
    contact_description: item?.send_message_to?.contact_description,
    email: item?.send_message_to?.email,
    client_name: item?.send_message_to?.name,
    ...item,
  };
};
