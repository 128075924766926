import { getColumns } from "components/Common/DataTablev2/processor";
import isObjectEmpty from "./isObjectEmpty";

const exportToCsv = (
  data: Record<string, any>[],
  columnsToHide?: string[],
  customColumns?: Record<string, any>
) => {
  const headers = getColumns(data).filter(
    //eslint-disable-next-line  @typescript-eslint/strict-boolean-expressions
    (item) => !columnsToHide?.includes(item)
  );

  const customColumnsExist = customColumns && !isObjectEmpty(customColumns);
  let filteredHeaders = headers;
  //eslint-disable-next-line  @typescript-eslint/strict-boolean-expressions
  if (customColumnsExist) {
    filteredHeaders = filteredHeaders
      .map((header) => {
        //eslint-disable-next-line  @typescript-eslint/strict-boolean-expressions
        if (customColumns[header]) {
          if (
            customColumns[header]?.export === undefined ||
            customColumns[header]?.export === true
          )
            return header;
          return "";
        }
        return header;
      })
      .filter((x) => x);
  }
  const formatForExport = (text: string) =>
    text.replaceAll(",", "").replace(/"/g, '""');

  const tableData = data.map((item) => {
    return filteredHeaders
      .map((header) => {
        //eslint-disable-next-line  @typescript-eslint/strict-boolean-expressions
        if (customColumnsExist) {
          const needsFormatting =
            customColumnsExist &&
            Object.prototype.hasOwnProperty.call(
              customColumns[header],
              "valueFormatter"
            );
          if (needsFormatting)
            return customColumns[header].valueFormatter({
              value: formatForExport(`${item[header] ?? " "}`),
            });
        }
        return formatForExport(`${item[header] ?? " "}`);
      })
      .filter((x) => x);
  });
  //eslint-disable-next-line  @typescript-eslint/strict-boolean-expressions
  if (customColumnsExist) {
    filteredHeaders = filteredHeaders
      .map((header) => {
        //eslint-disable-next-line  @typescript-eslint/strict-boolean-expressions
        if (customColumns[header])
          //eslint-disable-next-line  @typescript-eslint/strict-boolean-expressions
          return customColumns[header][header]
            ? customColumns[header][header]
            : header;
        return header;
      })
      .filter((x) => x);
  }
  const csvData = [filteredHeaders, ...tableData];
  const csvContent = csvData.map((e) => e.join(",")).join("\n");
  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  // Create a link element
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "filename.csv");

  // Programmatically trigger a click on the link to initiate the download
  link.click();
};
export default exportToCsv;
