import LoadingButton from "@mui/lab/LoadingButton";
import { Grid } from "@mui/material";
import { useGetCountriesByTypeQuery } from "api/api-misc";
import { useCreateLeadPartnerMutation } from "api/api-origination";
import AutoComplete from "components/Common/AutoComplete";
import Input from "components/Common/Input";
import { Form, Formik } from "formik";
import useIsMobile from "hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import type { Country, RegisterLeadPartner } from "types";
import {
  defaultValuesLeadPartner,
  validationSchemaLeadPartner,
} from "./validationSchema";

const CreateLeadPartner = ({
  onEnd,
  partnerType,
  lead_id,
}: {
  onEnd: () => void;
  partnerType: string;
  lead_id: string;
  lead_internal_name: string;
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [createLeadPartner, { isLoading: isLoadingCreateLead }] =
    useCreateLeadPartnerMutation();

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValuesLeadPartner}
      validationSchema={validationSchemaLeadPartner}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        const { errors, setErrors, values, dirty } = formikProps;
        const { name } = values;

        const { data: countries, isFetching: isFetchingCompanies } =
          useGetCountriesByTypeQuery(partnerType, {
            skip: !Boolean(partnerType),
          });

        const setter = (value: string) => {
          const selected = countries?.find((item) => item.name === value);
          formikProps.setFieldValue("country", selected?.name, true);
          formikProps.setFieldValue("iso3", selected?.id, true);
        };

        const createLeadActions = () => {
          const createleadRequestBody = {
            name: values?.name,
            country: values?.iso3,
            lead_id,
          };
          createLeadPartner(createleadRequestBody as RegisterLeadPartner)
            .unwrap()
            .then((res) => {
              const company_id = res?.id;
              setErrors({});
              formikProps.setFieldValue("companyId", company_id, true);
              toast(`Lead Created: ${name}`, {
                type: "success",
              });
              if (onEnd) onEnd();
            })
            .catch((e) => {
              toast(e?.message ?? "An error occured", {
                type: "error",
              });
            });
        };
        return (
          <Form>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              spacing={2}
              marginBottom={isMobile ? "5vh" : 0}
            >
              <Grid item xs={12} margin="auto">
                <Grid
                  container
                  justifyContent={"center"}
                  alignContent={"center"}
                  spacing={2}
                >
                  <Grid item xs={12} lg={6}>
                    <Input
                      name="name"
                      label={`${t("company-name")}`}
                      placeholder={`${t("company-name")}`}
                      fullWidth
                      style={{ width: "100%" }}
                      type="text"
                      {...formikProps}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <AutoComplete
                      data={countries as Country[]}
                      name={"country"}
                      label={"Country of Incorporation"}
                      labelKey={"name" as any}
                      value={formikProps.values.country}
                      placeholder={`${t("select-country")}`}
                      onChange={(event: any, newValue: any) => {
                        formikProps.setFieldValue(
                          "country",
                          newValue?.name,
                          true
                        );
                        formikProps.setFieldValue("iso3", newValue?.id, true);
                      }}
                      fullWidth
                      {...formikProps}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={10} lg={3} margin={0} ml={"auto"}>
                <LoadingButton
                  fullWidth
                  onClick={() => createLeadActions()}
                  variant="contained"
                  type={"button"}
                  loading={isLoadingCreateLead}
                  disabled={!dirty}
                >
                  {`${t("submit")}`}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
export default CreateLeadPartner;
