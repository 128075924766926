import { Avatar, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import styles from "./thumb.module.scss";

const ThumbnailView = ({ imgSrc = "", fileName = "", onClick = () => {} }) => (
  <label
    htmlFor="contained-button-profile"
    style={{ width: "100%", display: "grid" }}
  >
    <Tooltip title={fileName}>
      <IconButton
        component="span"
        className={styles.container}
        onClick={onClick}
      >
        <Avatar
          src={imgSrc}
          sx={{
            width: "100%",
            height: "6ch",
            margin: "auto",
            borderRadius: "15px",
          }}
        >
          <Typography>{fileName}</Typography>
        </Avatar>

        <Grid
          container
          className={styles.overlay}
          alignItems="flex-end"
          justifyContent={"center"}
        >
          <Grid item xs={12} className={styles.icon}>
            <Grid
              container
              justifyContent={"center"}
              alignItems="center"
              spacing={0.5}
            >
              <Grid item>
                <VisibilityOutlined sx={{ mt: 1 }} />
              </Grid>
              <Grid item>
                <Typography>View</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </IconButton>
    </Tooltip>
  </label>
);
export default ThumbnailView;
