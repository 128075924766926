import valueCleaner from "helpers/valueCleaner";
import moment from "moment";
import type { Account } from "types";
import * as Yup from "yup";
//eslint-disable-next-line prefer-regex-literals
const dateRegex = new RegExp(/\d{1,2}\/\d{1,2}\/\d{2,4}$/);
export interface CompanyDetails extends Partial<Account> {
  shipping_address?: {
    address_line1?: string;
    address_line2?: string;
    city?: string;
    country?: string;
    postcode?: string;
    iso3?: string;
  };
  same_shipping_operation_address?: boolean;
  type?: string;
  other_accounting_software?: string;
  iso3?: string;
  average_payment_terms?: string;
}

export const companyDetailsInitialValues: CompanyDetails = {
  address_line1: "",
  address_line2: "",
  city: "",
  country: "",
  name: "",
  incorporation_date: undefined,
  type: "",
  postcode: "",
  reg_no: "",
  contact_email: "",
  website: "",
  status: "",
  industry: "",
  goods_category: "",
  accounting_software: "",
  annual_revenue: 0,
  number_of_employees: "11-50 employees",

  //shipping address
  shipping_address: {
    address_line1: "",
    address_line2: "",
    city: "",
    country: "",
    postcode: "",
  },
  same_shipping_operation_address: true,
};

export const validationSchemaPresales = Yup.object().shape({
  name: Yup.string()
    .required("Company Name is Required")
    .min(3, "Company Name should be atleast 3 characters")
    .max(100, "Maximum character limit reached!"),
  reg_no: Yup.string()
    .required("Registration Number is Required")
    .matches(
      /^[0-9A-Za-z- (./_\\)]{3,100}$/,
      "Please ensure your Registration Number is of a valid format"
    ),
  address_line1: Yup.string().required("Please enter your Address"),
  address_line2: Yup.string().nullable(),
  incorporation_date: Yup.date()
    .required("Company Incorporation Date is required")
    .test({
      name: "incorporation_date",
      exclusive: false,
      params: {},
      message:
        "Please ensure your Company Incorporation Date is of a valid format",
      test: (value: string) =>
        dateRegex.test(moment(value).format("DD/MM/YYYY")),
    } as any)
    .nullable()
    .typeError("Please enter Company Incorporation Date")
    .max(
      moment().add(100, "years").toDate(),
      () => `Please enter a valid Company Incorporation Date`
    ),
  city: Yup.string().nullable(),
  country: Yup.string().required("Country is Required"),
});

export const validationSchemaOB = Yup.object().shape({
  name: Yup.string()
    .required("Company Name is Required")
    .min(3, "Company Name should be atleast 3 characters")
    .max(100, "Maximum character limit reached!"),
  reg_no: Yup.string()
    .required("Registration Number is Required")
    .matches(
      /^[0-9A-Za-z- .()]{3,100}$/,
      "Please ensure your Registration Number is of a valid format"
    ),
  address_line1: Yup.string().required("Please enter your Address"),
  address_line2: Yup.string().nullable(),
  incorporation_date: Yup.date()
    .required("Company Incorporation Date is required")
    .nullable()
    .test({
      name: "incorporation_date",
      exclusive: false,
      params: {},
      message:
        "Please ensure your Company Incorporation Date is of a valid format",
      test: (value: string) =>
        //eslint-disable-next-line prefer-regex-literals
        dateRegex.test(moment(value).format("DD/MM/YYYY")),
    } as any)
    .typeError("Please enter Company Incorporation Date")
    .max(
      moment().add(100, "years").toDate(),
      () => `Please enter a valid Company Incorporation Date`
    ),
  city: Yup.string().nullable(),
  country: Yup.string().required("Country is Required"),
  industry: Yup.string().required("Industry is Required"),
  goods_category: Yup.string()
    .required("Goods Category is Required")
    .nullable(),
  annual_revenue: Yup.string()
    .required("Annual Revenue is Required")
    .test({
      name: "annual_revenue",
      exclusive: false,
      params: {},
      message: "Annual Revenue must not be zero or a negative number",
      test: (value: string) => valueCleaner(value) > 0,
    } as any)
    .nullable(),
  number_of_employees: Yup.string()
    .required("Number of employees is Required")
    .nullable(),
  postcode: Yup.string().nullable(),
  website: Yup.string().nullable(),
  shipping_address: Yup.object()
    .shape({
      address_line1: Yup.string()
        .required("Please enter your Shipping Address")
        .nullable(),
      address_line2: Yup.string().nullable(),
      city: Yup.string().nullable(),
      country: Yup.string().required("Shipping address country is Required"),
      postcode: Yup.string().nullable(),
    })
    .nullable(),
  same_shipping_operation_address: Yup.boolean(),
});
