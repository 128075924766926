import { Link, Typography } from "@mui/material";
import { CustomColumn } from "../..";

const RenderCell = ({
  item,
  column,
  params,
  disabled,
  customColumns,
}: {
  item: Record<string, any>;
  column: string;
  params: any;
  disabled?: boolean;
  customColumns: CustomColumn;
}) => {
  const onCLickFn = customColumns?.[column]?.headerProps?.onClick;
  return customColumns?.[column]?.renderCell
    ? customColumns[column]?.renderCell({
        ...params,
        disabled,
      })
    : item &&
        typeof item[column] !== "object" &&
        (customColumns?.[column]?.link ? (
          <Link
            sx={{
              overflow: "hidden",
              maxWidth: "76%",
              textOverflow: "ellipsis",
              alignItems: "top",
              display: "flex",
              ...customColumns?.[column]?.headerProps?.sx,
            }}
            href={customColumns?.[column]?.link?.href(item)}
            target={customColumns?.[column]?.link?.target ?? "_blank"}
          >
            {item[column]}
          </Link>
        ) : (
          <Typography
            sx={{
              overflow: "hidden",
              maxWidth: "76%",
              textOverflow: "ellipsis",
              alignItems: "top",
              ...customColumns?.[column]?.headerProps?.sx,
            }}
            onClick={() => onCLickFn && onCLickFn(item[column])}
          >
            {item[column]}
          </Typography>
        ));
};
export default RenderCell;
