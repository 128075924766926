import moment from "moment";
import type { CLDetails, CreditLimit } from "types";

const initialSalesLeadValue = {
  email: "",
  first_name: "Unassigned",
  id: "",
  last_name: "Unassigned",
  mobile: "",
  opted_marketing_updates: false,
  title: "",
};
export const formatCLs = (clr: CreditLimit[]): CLDetails[] => {
  // eslint-disable-next-line no-extra-boolean-cast
  if (Boolean(clr.length))
    return clr?.map((item: CreditLimit, i: number) => {
      const CL_OBJ: any = {
        id: item.id,
        //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        dateRequestedMktg: item.requested_date
          ? moment(item.requested_date).utc().format("YYYY-MM-DD HH:mm:ss")
          : "-",
        recordId: i,
        reqId: item.name ?? "",
        internalStatus: item.internal_status,
        buyerName: item.buyer?.name ?? "N/A",
        buyerInternalName: item.buyer?.internal_name ?? "N/A",
        // eslint-disable-next-line no-extra-boolean-cast
        buyerSource: item.buyer?.source ?? "N/A",
        buyerRegNo: item.buyer?.reg_no ?? "N/A",
        buyerCountry: item.buyer?.country ?? "N/A",
        buyerEmail: "N/A",
        buyerAnnualRevenue: item.buyer?.annual_revenue ?? "N/A",
        //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        insurerResponse: item.insurer_response_date
          ? moment(item.insurer_response_date).format("YYYY-MM-DD HH:mm:ss")
          : "-",

        // eslint-disable-next-line no-extra-boolean-cast
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        salesLead: item.sales_lead?.first_name
          ? item.sales_lead
          : initialSalesLeadValue,
        salesName: `${item.sales_lead?.first_name} ${item.sales_lead?.last_name}`,
        clStatus: item.status,
        reqAmount: Number(item.requested_amount),
        approved_amount: Number(item.approved_amount) ?? 0,
        insuredAmount: Number(item.atradius_amount) ?? 0,
        atradius_comment: item.atradius_comment ?? "N/A",
        currency: item.currency,

        buyerId: item.buyer?.id,
        comment: item.comment,

        // buyerContact: item.contact_buyer,
        //eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        dateRequested: item.requested_date
          ? moment(item.requested_date).utc().format("YYYY-MM-DD HH:mm:ss")
          : "-",
        rating: item?.coface_rating,
        coface_rating_description: item?.coface_rating_description,
        coface_amount: item?.coface_amount,
        coface_currency: item?.coface_currency,
        coface_found: item?.coface_found,
        buyerIsIdentified: item.buyer?.is_identified,
        sf_link: item.sf_link,
        insurer: item?.insurer,
        requestedBy: item?.requested_by,
        item,
      };

      return CL_OBJ;
    });
  return [];
};
