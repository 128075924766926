import { Box, Grid, Slide, Typography, useTheme } from "@mui/material";

import {
  DEFAULT_HIDEABLES,
  DEFAULT_ROWS,
  INCOMLEND_INTERNAL_LINK,
  LEADS,
} from "codes";
import SkeletonLoad from "components/Common/SkeletonLoad";

import { useUpdateUserMutation } from "api/api-users";

import { useGetCurrentUserQuery } from "api/api-users-v2";
import CLDisplay from "components/CLRequest/CLDisplay";
import SourceDisplay from "components/CLRequest/SourceDisplay";

import { useGetCreditLimitsQuery } from "api/api-origination";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import { currencyFormatter } from "helpers/currencyFormatter";
import history from "helpers/history";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { PERMISSIONS } from "permissions";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import { CLDetails } from "types";
import styles from "./sales.module.scss";

const CreditLimits = () => {
  //Functions
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { pathname } = history.location;

  const { data: curr_user } = useGetCurrentUserQuery();
  const [updateUser] = useUpdateUserMutation();

  const APP_PERMISSION = useAppSelector((state) => state.appState.permission);
  const USER_PERMISSION = useAppSelector(
    (state) => state.appState.user_permission
  );

  const {
    data: creditLimits,
    isLoading,
    isFetching,
    refetch,
    startedTimeStamp = 0,
    fulfilledTimeStamp = 0,
    //refetch clrs every 5mins
  } = useGetCreditLimitsQuery(null, {
    pollingInterval: 300000,
    refetchOnReconnect: true,
  });

  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;

  const ComponentLevelSetting = settings?.[APP_PERMISSION]?.[pathname];

  //Local states
  const presets = ComponentLevelSetting?.hideablesPreset ?? DEFAULT_HIDEABLES;

  const toBeHidden = [
    "id",
    "item",
    "recordId",
    "insurerResponse",
    "salesLead",
    "currency",
    "buyerId",
    "comment",
    "buyerEmail",
    "buyerAnnualRevenue",
    "buyerIsIdentified",
    "dateRequestedMktg",
    "clStatus",
    "insurer",
    "atradius_comment",
    "coface_currency",
    "sf_link",
    "requestedBy",
    "rating",
    "buyerInternalName",
    "insuredAmount",
  ];
  const toBeHiddenForRM = [
    "id",
    "item",
    "recordId",
    "insurerResponse",
    "salesLead",
    "currency",
    "buyerId",
    "comment",
    "buyerEmail",
    "buyerAnnualRevenue",
    "buyerIsIdentified",
    "dateRequestedMktg",
    "clStatus",
    "insurer",
    "atradius_comment",
    "coface_currency",
    "sf_link",
    "requestedBy",
    "rating",
    "buyerInternalName",
    "insuredAmount",
    "coface_rating_description",
    "coface_amount",
    "coface_found",
  ];
  const textSx = {
    fontSize: "1em!important",
    lineHeight: "2em",
    height: "1.8em!important",
    overflow: "hidden",
    maxWidth: "76%",
    textOverflow: "ellipsis",
    minWidth: isMobile ? "100%" : "10vw",
  };

  const snapshotValues = [
    "reqId",
    "internalStatus",
    "supplierName",
    "buyerName",
    "clStatus",
    "reqAmount",
    "approved_amount",
    "dateRequested",
  ];
  // {USER_PERMISSION.includes(PERMISSIONS.get_insurer) && (
  //   <Grid item xs={12} lg={1.7}>
  //     <InsurerBlock item={item} />
  //   </Grid>
  // )}

  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;
  const headers = Object.keys(creditLimits?.[0] ?? {});
  const headerLength = headers?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 5 : 200;

  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={`Credit Limits`}
          backArrow={false}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.5em" : "1.5em",
          }}
          headerConfig={{
            syncAction: refetch,
            left: 8,
            mid: 0,
            right: 2,
            xs: {
              left: 12,
              mid: 0,
              right: 0,
            },
            alignItems: "center",
          }}
          className={styles.layout}
          mode="default"
        >
          {(isFetching || isLoading) && <SkeletonLoad bars={10} />}
          {(import.meta.env.VITE_ENV === "local" ||
            import.meta.env.VITE_ENV === "dev") &&
            `Development mode only - Data fetched in: ${
              (fulfilledTimeStamp - startedTimeStamp) / 1000
            }s`}

          {!isFetching && (
            <Grid item xs={12} margin={"5px"} ref={ref}>
              <Typography>
                {" "}
                Last updated on:{" "}
                {moment(fulfilledTimeStamp).format("DD/MM/YYYY HH:mm:ss")}
              </Typography>
              {creditLimits !== undefined && Boolean(creditLimits?.length) ? (
                <DataTablev2
                  defaultColumnWidth={defaultColumnWidth}
                  headerSx={{
                    h4: {
                      fontSize: isMobile ? "0.7em!important" : "1em!important",
                      height: "auto",
                    },
                  }}
                  tableSx={{
                    minWidth: isMobile ? "90vw" : "50vw",
                  }}
                  toBeHidden={
                    USER_PERMISSION.includes(PERMISSIONS.get_insurer)
                      ? toBeHidden
                      : toBeHiddenForRM
                  }
                  collapsible={headers.filter(
                    (key) => !snapshotValues.includes(key)
                  )}
                  hideablesPreset={presets}
                  customColumns={{
                    reqId: {
                      reqId: "CL ID",
                      type: "text",
                      link: {
                        href: (params: CLDetails) =>
                          `${pathname}/${params.reqId}`,
                        target: "_self",
                      },
                    },

                    buyerName: {
                      buyerName: "Buyer Name",
                      type: "text",
                      link: {
                        href: (params: CLDetails) =>
                          `${INCOMLEND_INTERNAL_LINK}${LEADS}/${params.buyerInternalName}`,
                        target: "_self",
                      },
                    },
                    buyerRegNo: {
                      buyerRegNo: "Buyer Reg.No.",
                      type: "text",
                      renderCell: ({ value }: { value: string }) => (
                        <Typography sx={textSx}>{value}</Typography>
                      ),
                    },
                    buyerCountry: {
                      buyerCountry: "Buyer Country",
                      type: "text",
                      renderCell: ({ value }: { value: string }) => (
                        <Typography sx={textSx}>{value}</Typography>
                      ),
                    },

                    dateRequestedMktg: {
                      dateRequestedMktg: "CL Date",
                      type: "date",
                      renderCell: ({ value }: { value: string }) => (
                        <Typography
                          sx={{
                            fontSize: "1em!important",
                            width: isMobile ? "100%" : "10vw",
                          }}
                        >
                          {value}
                        </Typography>
                      ),
                    },
                    dateRequested: {
                      dateRequested: "Date Requested",
                      type: "date",
                      renderCell: ({ value }: { value: string }) => (
                        <Typography
                          sx={{
                            fontSize: "1em!important",
                            width: isMobile ? "100%" : "10vw",
                          }}
                        >
                          {value}
                        </Typography>
                      ),
                    },
                    insurerResponse: {
                      insurerResponse: "Insurer Response Date",
                      type: "date",
                      renderCell: ({ value }: { value: string }) => (
                        <Typography sx={textSx}>{value}</Typography>
                      ),
                    },
                    salesName: {
                      salesName: "Sales Owner",
                      type: "text",
                      renderCell: ({ value }: { value: string }) => (
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography sx={textSx}>{`${value}`}</Typography>
                          </Grid>
                        </Grid>
                      ),
                    },
                    internalStatus: {
                      internalStatus: "Status",
                      renderCell: (params: any) => (
                        <Grid
                          container
                          justifyContent={isMobile ? "left" : "center"}
                        >
                          <Grid item xs={12}>
                            <CLDisplay status={params.value} />
                          </Grid>
                        </Grid>
                      ),
                    },
                    reqAmount: {
                      reqAmount: "Requested Amount",
                      type: "number",
                      renderCell: (params: { value: number }) => {
                        return (
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontSize: "1em!important",
                                  lineHeight: "2em",
                                  height: "1.4em!important",
                                  width: isMobile ? "100%" : "9vw",
                                }}
                                color={params?.value ? "primary" : "grey"}
                              >
                                {params?.value
                                  ? currencyFormatter({
                                      amount: Number(params.value),
                                    })
                                  : "None"}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      },
                    },
                    approved_amount: {
                      approved_amount: "Approved Amount",
                      type: "number",
                      renderCell: (params: { value: number }) => {
                        return (
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  fontSize: "1em!important",
                                  lineHeight: "2em",
                                  height: "1.4em!important",
                                  width: isMobile ? "100%" : "9vw",
                                }}
                                color={params?.value ? "primary" : "grey"}
                              >
                                {params?.value
                                  ? currencyFormatter({
                                      amount: Number(params.value),
                                    })
                                  : "None"}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      },
                    },
                    supplierSource: {
                      supplierSource: "Supplier Source",
                      type: "text",

                      renderCell: (params: any) => (
                        <Grid
                          container
                          justifyContent={isMobile ? "left" : "center"}
                        >
                          <Grid item xs={12}>
                            <SourceDisplay status={params.value} />
                          </Grid>
                        </Grid>
                      ),
                    },
                    buyerSource: {
                      buyerSource: "Buyer Source",
                      type: "text",
                      renderCell: ({ value }: { value: string }) => (
                        <Typography sx={textSx}>{value}</Typography>
                      ),
                    },
                    coface_rating_description: {
                      coface_rating_description: "Coface Rating Description",
                      renderCell: (params: { value: any }) => (
                        <Typography color={params?.value ? "primary" : "grey"}>
                          {params?.value
                            ? currencyFormatter({
                                amount: Number(params.value),
                              })
                            : "None"}
                        </Typography>
                      ),
                    },
                    coface_amount: {
                      coface_amount: "Coface Amount",
                      renderCell: (params: { value: any }) => (
                        <Typography color={params?.value ? "primary" : "grey"}>
                          {params?.value ?? "None"}
                        </Typography>
                      ),
                    },
                    coface_found: {
                      coface_found: "Coface Found",
                      renderCell: (params: { value: any }) => (
                        <Typography color={params?.value ? "primary" : "grey"}>
                          {params?.value ?? "None"}
                        </Typography>
                      ),
                    },
                  }}
                  onRowClicked={() => {}}
                  data={creditLimits}
                  rowsPerPage={rowsPerPage}
                  getColumnVisibility={(columns) => {
                    const PRESET_TO_SET = Object.entries(columns)
                      .map((value: [string, any]) => {
                        if (!value[1]) return value[0];
                        return "";
                      })
                      .filter((x) => x !== "");

                    const toUpdate = {
                      id: curr_user?.id,
                      settings: {
                        ...curr_user?.settings,
                        [APP_PERMISSION]: {
                          [pathname]: {
                            hideablesPreset: PRESET_TO_SET,
                          },
                        },
                      },
                    };

                    if (
                      PRESET_TO_SET?.length !==
                        ComponentLevelSetting?.hideablesPreset?.length &&
                      Boolean(PRESET_TO_SET.length)
                    )
                      updateUser(toUpdate)
                        .unwrap()
                        .catch((err) =>
                          toast(
                            `Unable to save your settings: ${err?.message}`,
                            {
                              type: "error",
                            }
                          )
                        );
                  }}
                  sx={{
                    table: {
                      minWidth: "100% !important",
                    },
                  }}
                />
              ) : (
                <NoData text="No Credit Limits Requests found" />
              )}
            </Grid>
          )}
        </Layout>
      </Box>
    </Slide>
  );
};

export default CreditLimits;
