import { Alert, Grid } from "@mui/material";
import React from "react";

interface Props {
  discrepancies: string[];
}

const Discrepancies: React.FC<Props> = (props) => {
  const { discrepancies } = props;

  return (
    <Grid item xs={12}>
      {discrepancies && discrepancies.length > 0 && (
        <Alert severity="warning">
          Discrepancies found in the following rows: {discrepancies.join(", ")}
        </Alert>
      )}
    </Grid>
  );
};

export default Discrepancies;
