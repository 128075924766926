import InfoIcon from "@mui/icons-material/Info";
import {
  InputLabel,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  TypographyTypeMap,
} from "@mui/material";
import React from "react";

export interface LabelViewOnlyProps {
  /**Display text for the label */
  label: string | React.ReactNode;
  sx?: TypographyTypeMap["props"]["sx"];
  labelClassName?: string;
  labelRequiredClassName?: string;
  required?: boolean;
  helpText?: TooltipProps["title"];
}
export default function LabelViewOnly(props: LabelViewOnlyProps) {
  const {
    label,
    sx,
    labelClassName,
    required,
    labelRequiredClassName,
    helpText,
  } = props;

  if (typeof label === "string")
    return (
      <Stack
        alignItems="center"
        justifyContent={"start"}
        spacing={0.5}
        direction={"row"}
        mb={0.5}
        className={labelClassName ?? ""}
      >
        <Typography fontWeight={"bold"} sx={sx}>
          {label && `${label}`}
        </Typography>
        {required && <Typography color="error">*</Typography>}
        {Boolean(helpText) && (
          <Tooltip title={helpText} enterTouchDelay={0} leaveDelay={200}>
            <InfoIcon color="primary" sx={{ marginTop: "6px" }} />
          </Tooltip>
        )}
        <InputLabel />
      </Stack>
    );
  return <>{label}</>;
}
