import SyncIcon from "@mui/icons-material/Sync";
import { Box, Slide } from "@mui/material";
import { useGetUsersQuery } from "api/api-users-v2";

import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import CompanyUserManagement from "components/Onboard/User";
import AddAccountUser from "components/Onboard/User/AddAccountUser";
import useIsMobile from "hooks/useIsMobile";
import { useState } from "react";
import { useAppSelector } from "redux/hooks";
import { CompanyUser } from "types";

const Users = () => {
  const [additionState, setAdditionState] = useState(false);

  const USER_ROLE = useAppSelector((state) => state.appState.role);

  const {
    data: users,
    isFetching,
    refetch,
  } = useGetUsersQuery(USER_ROLE?.id, {
    skip: !Boolean(USER_ROLE.id),
  });

  const isMobile = useIsMobile();

  return (
    <Slide in direction={"left"}>
      <Box id="users-box" sx={{ width: "100%" }}>
        <Layout
          title={additionState ? "Add User" : "Users"}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            left: 8,
            mid: 0,
            right: additionState ? 2 : 4,
            xs: {
              left: 6,
              mid: 0,
              right: 6,
            },
            alignItems: "center",
          }}
          mode="default"
          primary={
            additionState
              ? undefined
              : {
                  disabled: additionState,
                  children: "Add",
                  onClick: () => setAdditionState(true),
                  fullWidth: true,
                }
          }
          secondary={
            additionState
              ? {
                  onClick: () => setAdditionState(false),
                  children: "Cancel",
                  fullWidth: true,
                  color: "error",
                }
              : {
                  onClick: refetch,
                  startIcon: <SyncIcon />,
                  children: !isMobile && "Sync",
                  fullWidth: true,
                }
          }
        >
          {isFetching && <SkeletonLoad bars={10} />}
          {additionState && !isFetching ? (
            <AddAccountUser
              account_id={USER_ROLE?.id}
              callBack={() => setAdditionState(false)}
            />
          ) : (
            Boolean(users?.length) &&
            !isFetching && (
              <CompanyUserManagement
                companyId={USER_ROLE?.id}
                users={users as CompanyUser[]}
              />
            )
          )}
          {!Boolean(users?.length) && (
            <AddAccountUser
              account_id={USER_ROLE?.id}
              callBack={() => setAdditionState(false)}
            />
          )}
        </Layout>
      </Box>
    </Slide>
  );
};
export default Users;
