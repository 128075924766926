import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Slide,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useGetAllDepartmentsQuery,
  useGetInternalUsersQuery,
} from "api/api-it";
import {
  useAddDocumentToTicketMutation,
  useEditTicketMutation,
  useGetBusinessPrioritiesQuery,
  useGetITPrioritiesQuery,
  useGetRequestQualitiesQuery,
  useGetTicketLogsQuery,
  useGetTicketQuery,
  useGetTicketStatusesQuery,
} from "api/api-support";
import CriticalIcon from "assets/issue-icons/critical.svg?react";
import LowIcon from "assets/issue-icons/low.svg?react";
import MediumIcon from "assets/issue-icons/medium.svg?react";
import { INCOMLEND_INTERNAL_LINK, SUPPORT } from "codes";
import Breadcrumbs from "components/Common/Breadcrumbs";
import Layout from "components/Common/Layout";
import SkeletonLoad from "components/Common/SkeletonLoad";
import ThumbnailView from "components/Common/ThumbnailView";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import DragDropTicketAttachments from "components/Ticket/DragDrop";
import TicketActivityLog from "components/Ticket/TicketActivityLog";
import { Form, Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./issue.module.scss";
import { emptySupportTicket, issueValidationSchema } from "./validationSchema";

const IssuePage = () => {
  const { id } = useParams();
  const { data: ticket, isFetching, refetch } = useGetTicketQuery(id ?? "");

  const { data: ticketStatuses, isFetching: isFetchingTicketStatuses } =
    useGetTicketStatusesQuery(null);
  const theme = useTheme();
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [addDocumentToTicket] = useAddDocumentToTicketMutation();
  const { data: departments, isFetching: isFetchingDepartments } =
    useGetAllDepartmentsQuery(null);
  const name = ticket?.internal_name;
  const [editTicket] = useEditTicketMutation();
  const { data: businessPriorities, isFetching: isFetchingBusinessPriorities } =
    useGetBusinessPrioritiesQuery(null);
  const { data: requestQualities, isFetching: isFetchingRequestQualities } =
    useGetRequestQualitiesQuery(null);
  const {
    data: internalUsers,
    refetch: refetchInternalUsers,
    isFetching: isFetchingInternalUsers,
  } = useGetInternalUsersQuery(null);
  const { data: itPriorities, isFetching: isFetchingitPriorities } =
    useGetITPrioritiesQuery(null);
  const { data: ticketLogs, isFetching: isFetchingTicketLogs } =
    useGetTicketLogsQuery(id ?? "", { skip: !Boolean(id) });

  const Icon = (status: string, style?: React.CSSProperties) => {
    switch (status) {
      case "High":
        return <CriticalIcon style={style} />;
      case "Medium":
        return <MediumIcon style={style} />;
      case "Low":
        return <LowIcon style={style} />;

      default:
        <LowIcon />;
        break;
    }
  };
  return (
    <Slide in direction={"left"}>
      <Box sx={{ display: "flex" }} flexGrow={1}>
        <Layout
          title={`${name ?? ""}${ticket?.title ? ` - ${ticket.title}` : ""}`}
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          backArrow
          backLink={`${INCOMLEND_INTERNAL_LINK}${SUPPORT}`}
          className={styles.layout}
          headerConfig={{
            left: 10,
            mid: 0,
            right: 2,
            xs: {
              left: 6,
              mid: 0,
              right: 6,
            },
            alignItems: "center",
          }}
          secondary={{
            onClick: refetch,
            startIcon: <SyncIcon />,
            children: "Sync",
          }}
          mode="default"
        >
          <Formik
            initialValues={{ ...emptySupportTicket, ...ticket }}
            onSubmit={console.log}
            enableReinitialize
            validationSchema={issueValidationSchema}
          >
            {(formikProps) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} margin="auto">
                    <Breadcrumbs
                      data={[
                        {
                          name: `Support Tickets`,
                          link: `${INCOMLEND_INTERNAL_LINK}${SUPPORT}`,
                        },
                        {
                          name: `${name ?? ""}`,
                          link: `${INCOMLEND_INTERNAL_LINK}${SUPPORT}/${name}`,
                          active: true,
                        },
                      ]}
                    />
                  </Grid>
                  {isFetching && !ticket && <SkeletonLoad bars={10} />}
                  {!isFetching && ticket && (
                    <Grid item xs={12} margin="auto">
                      <Grid
                        container
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={12} lg={8}>
                          <Stack spacing={1}>
                            <VariableInput
                              name={"title"}
                              label={""}
                              value={formikProps.values?.title}
                              sx={{ fontSize: "1.5em", fontWeight: "bold" }}
                              {...formikProps}
                              handleSave={(value) =>
                                ticket &&
                                editTicket({
                                  ticket_id: ticket.id,
                                  title: value as string,
                                })
                                  .unwrap()
                                  .then(refetch)
                              }
                            />
                            <Stack sx={{ mt: "2vh !important" }} width={"100%"}>
                              <VariableInput
                                name={"content"}
                                label={"Description"}
                                value={formikProps.values?.content}
                                {...formikProps}
                                multiline
                                richText
                                handleSave={(value) =>
                                  ticket &&
                                  editTicket({
                                    ticket_id: ticket.id,
                                    content: value as string,
                                  })
                                    .unwrap()
                                    .then(refetch)
                                }
                              />
                              {Boolean(ticket.attached_file?.length) && (
                                <Stack>
                                  <Typography fontWeight={"bold"}>
                                    Attachments
                                  </Typography>
                                  <Stack direction={"row"}>
                                    {ticket.attached_file.map((doc) => (
                                      <Grid item xs={3} key={doc.file_id}>
                                        <ThumbnailView
                                          fileName={doc.filename}
                                          imgSrc={doc.url}
                                          onClick={() => {}}
                                        />
                                      </Grid>
                                    ))}
                                  </Stack>
                                </Stack>
                              )}
                            </Stack>

                            <DragDropTicketAttachments
                              uploadedFiles={uploadedFiles}
                              setUploadedFiles={setUploadedFiles}
                              onClick={() =>
                                id &&
                                addDocumentToTicket({
                                  ticket_id: ticket.id,
                                  files: uploadedFiles,
                                })
                                  .unwrap()
                                  .then(() => {
                                    toast("Successfully uploaded documents", {
                                      type: "success",
                                    });
                                    setUploadedFiles([]);
                                    refetch();
                                  })
                                  .catch((err) => {
                                    toast(
                                      `An error occured : ${err?.message}`,
                                      {
                                        type: "error",
                                      }
                                    );
                                    setUploadedFiles([]);
                                    refetch();
                                  })
                              }
                            />
                          </Stack>
                          <Stack>
                            <TicketActivityLog
                              activities={ticketLogs}
                              minHeight={"20vh"}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          <Stack spacing={2}>
                            {isFetchingTicketStatuses && !ticketStatuses && (
                              <SkeletonLoad bars={1} />
                            )}
                            {!isFetchingTicketStatuses && ticketStatuses && (
                              <VariableDropdown
                                name={"status"}
                                label={""}
                                noHover
                                keyValue="name"
                                value={formikProps.values?.status}
                                data={ticketStatuses}
                                iconPosition={"end"}
                                icon={
                                  <IconButton sx={{ p: 0 }}>
                                    <ExpandMoreIcon sx={{ color: "#fff" }} />
                                  </IconButton>
                                }
                                {...formikProps}
                                sx={{
                                  background: `${theme.palette.primary.main}`,
                                  color: `${theme.palette.background.default} !important`,
                                  borderRadius: 1,
                                  px: 1,
                                  py: 0.5,
                                  width: "15ch",
                                  "&:hover": {
                                    color: `${theme.palette.primary.main} !important`,
                                    background: "transparent",
                                  },
                                }}
                                containerSx={{
                                  width: "12ch",
                                }}
                                setValue={(value) => {
                                  const status_id = ticketStatuses?.find(
                                    (stat) => stat.name === value
                                  )?.id;
                                  formikProps.setFieldValue(
                                    "status_id",
                                    status_id,
                                    true
                                  );
                                }}
                                handleSave={(value) =>
                                  ticket &&
                                  editTicket({
                                    ticket_id: ticket.id,
                                    status_id: formikProps.values
                                      ?.status_id as any,
                                  })
                                    .unwrap()
                                    .then(refetch)
                                }
                              />
                            )}
                            <Paper
                              sx={{
                                width: { xs: "100%", lg: "100%" },
                                margin: "auto",

                                padding: { xs: 2, lg: 2 },
                                borderRadius: { xs: 2, lg: 2 },
                                border: `1px solid ${theme.palette.secondary.main}80`,
                              }}
                            >
                              <Stack spacing={1.5}>
                                <Typography fontWeight={"bold"}>
                                  Details
                                </Typography>
                                <Divider />
                                {isFetchingInternalUsers && !internalUsers && (
                                  <SkeletonLoad bars={1} />
                                )}
                                {!isFetchingInternalUsers && internalUsers && (
                                  <VariableDropdown
                                    name={"assignee"}
                                    label={"Assignee"}
                                    keyValue="name"
                                    value={`${formikProps.values?.assignee?.first_name ? `${formikProps.values?.assignee?.first_name} ${formikProps.values?.assignee?.last_name}` : "Unassigned"}`}
                                    sx={{ maxWidth: "100%" }}
                                    setValue={(value) => {
                                      const assignee_user_id =
                                        internalUsers?.find(
                                          (user) =>
                                            `${user.first_name} ${user.last_name}` ===
                                            value
                                        )?.contact_id;
                                      formikProps.setFieldValue(
                                        "assignee_user_id",
                                        assignee_user_id,
                                        true
                                      );
                                      formikProps.setFieldValue(
                                        "assignee",
                                        value,
                                        true
                                      );
                                    }}
                                    data={internalUsers.map((user) => ({
                                      ...user,
                                      name: `${user.first_name} ${user.last_name}`,
                                    }))}
                                    {...formikProps}
                                    handleSave={(value) =>
                                      ticket &&
                                      editTicket({
                                        ticket_id: ticket.id,
                                        assignee: formikProps.values
                                          ?.assignee_user_id as any,
                                      })
                                        .unwrap()
                                        .then(() => {
                                          toast.success(
                                            "Successfully updated assignee"
                                          );
                                          refetch();
                                        })
                                        .catch((err) => {
                                          toast.error(
                                            `An error occured: ${err.message}`
                                          );
                                        })
                                    }
                                  />
                                )}

                                {isFetchingBusinessPriorities && (
                                  <SkeletonLoad bars={1} />
                                )}
                                {!isFetchingBusinessPriorities &&
                                  businessPriorities && (
                                    <VariableDropdown
                                      name={"business_priority.label"}
                                      label={"Priority"}
                                      keyValue="code"
                                      value={
                                        formikProps.values?.business_priority
                                          ?.label
                                      }
                                      icon={
                                        <IconButton sx={{ p: 0 }}>
                                          {Icon(
                                            formikProps.values
                                              ?.business_priority?.label,
                                            {
                                              width: "2ch",
                                              height: "auto",
                                            }
                                          )}
                                        </IconButton>
                                      }
                                      data={businessPriorities}
                                      setValue={(value) => {
                                        const priority_code_id =
                                          businessPriorities?.find(
                                            (prio) => prio.code === value
                                          )?.id;
                                        formikProps.setFieldValue(
                                          "priority_code_id",
                                          priority_code_id,
                                          true
                                        );
                                      }}
                                      {...formikProps}
                                      handleSave={(value) => {
                                        ticket &&
                                          editTicket({
                                            ticket_id: ticket.id,
                                            business_priority_code_id:
                                              formikProps.values
                                                ?.priority_code_id as any,
                                          })
                                            .unwrap()
                                            .then(refetch);
                                      }}
                                    />
                                  )}
                                {isFetchingitPriorities && (
                                  <SkeletonLoad bars={1} />
                                )}
                                {!isFetchingitPriorities && itPriorities && (
                                  <VariableDropdown
                                    name={"it_priority.label"}
                                    label={"Priority - IT"}
                                    keyValue="code"
                                    value={
                                      formikProps.values?.it_priority?.label
                                    }
                                    data={itPriorities}
                                    setValue={(value) => {
                                      const it_priority_code_id =
                                        itPriorities?.find(
                                          (prio) => prio.code === value
                                        )?.id;

                                      formikProps.setFieldValue(
                                        "it_priority_code_id",
                                        it_priority_code_id,
                                        true
                                      );
                                    }}
                                    {...formikProps}
                                    handleSave={(value) => {
                                      ticket &&
                                        editTicket({
                                          ticket_id: ticket.id,
                                          it_priority_code_id: formikProps
                                            .values?.it_priority_code_id as any,
                                        })
                                          .unwrap()
                                          .then(refetch);
                                    }}
                                  />
                                )}
                                {isFetchingDepartments && (
                                  <SkeletonLoad bars={1} />
                                )}
                                {!isFetchingDepartments && departments && (
                                  <VariableDropdown
                                    name={"department_id"}
                                    label={"Department"}
                                    keyValue="name"
                                    data={departments}
                                    value={
                                      formikProps.values?.assignee_department
                                    }
                                    setValue={(value) => {
                                      const department_id = departments?.find(
                                        (dep) => dep.name === value
                                      )?.id;
                                      formikProps.setFieldValue(
                                        "assignee_department_id",
                                        department_id,
                                        true
                                      );
                                      formikProps.setFieldValue(
                                        "assignee_department",
                                        value,
                                        true
                                      );
                                    }}
                                    {...formikProps}
                                    handleSave={(value) => {
                                      ticket &&
                                        editTicket({
                                          ticket_id: ticket.id,
                                          assignee_department_id:
                                            formikProps.values
                                              ?.assignee_department_id,
                                        })
                                          .unwrap()
                                          .then(refetch);
                                    }}
                                  />
                                )}

                                <VariableInput
                                  name={"estimate_min"}
                                  label={"Estimate"}
                                  placeholder="Use the format: 2w 4d 6h 45m"
                                  helpText="Use the format: 2w 4d 6h 45m"
                                  value={formikProps.values?.estimate_min}
                                  {...formikProps}
                                  handleSave={(value) =>
                                    ticket &&
                                    editTicket({
                                      ticket_id: ticket.id,
                                      estimate_min: value as string,
                                    })
                                      .unwrap()
                                      .then(refetch)
                                  }
                                />

                                <VariableInput
                                  name={"remaining_min"}
                                  label={"Remaining"}
                                  value={formikProps.values?.remaining_min}
                                  {...formikProps}
                                  handleSave={(value) =>
                                    ticket &&
                                    editTicket({
                                      ticket_id: ticket.id,
                                      remaining_min: value as string,
                                    })
                                      .unwrap()
                                      .then(refetch)
                                  }
                                />

                                {isFetchingRequestQualities && (
                                  <SkeletonLoad bars={1} />
                                )}
                                {!isFetchingRequestQualities &&
                                  requestQualities && (
                                    <VariableDropdown
                                      name={"request_quality"}
                                      label={"Request Quality"}
                                      keyValue="name"
                                      value={
                                        formikProps.values?.request_quality
                                      }
                                      data={requestQualities}
                                      {...formikProps}
                                      handleSave={(value) =>
                                        ticket &&
                                        editTicket({
                                          ticket_id: ticket.id,
                                          request_quality: value as string,
                                        })
                                          .unwrap()
                                          .then(refetch)
                                      }
                                    />
                                  )}
                              </Stack>
                            </Paper>
                            <Stack alignItems={"center"} justifyContent={"end"}>
                              <Typography
                                sx={{ color: theme.palette.secondary.main }}
                                fontSize={"0.8em"}
                                textAlign="right"
                              >
                                {`Submitted at: ${moment(ticket.submitted_at)
                                  .local()
                                  .format("D MMM, yyyy [at] h:mm A z")}`}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </Layout>
      </Box>
    </Slide>
  );
};
export default IssuePage;
