import { Grid, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { useGetEligibilityByIsoQuery } from "api/api-legal";
import { useGetCountriesQuery } from "api/api-misc";
import MainCard from "components/Common/Cards/MainCard";
import NoData from "components/Common/NoData";
import groupBy from "helpers/groupBy";
import countrycodes from "helpers/phone-lib.json";
import toProperCase from "helpers/propercase";
import { PERMISSIONS } from "permissions";
import { useAppSelector } from "redux/hooks";
import { MapSummary } from "types";

const SummaryGoverningLaws = () => {
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );

  const disabled = !USER_PERMISSIONS.includes(PERMISSIONS.map_admin);
  const formikProps = useFormikContext<MapSummary>();
  const governingLawCountry =
    formikProps.values.governing_law ?? formikProps?.values?.buyer_country_iso3;

  const {
    data: countryLegalDetails,
    isFetching: isFetchingCountryLegalDetails,
    refetch: refetchCountryLegalDetails,
  } = useGetEligibilityByIsoQuery(governingLawCountry ?? "", {
    skip: !governingLawCountry,
  });

  const {
    data: countries,
    isFetching: isFetchingCountries,
    refetch,
  } = useGetCountriesQuery();

  const countryName = countries?.find(
    (country) => country.id === governingLawCountry
  )?.name;
  const countryFlag = countryName
    ? countrycodes
        .find((country) => country.name?.endsWith(countryName))
        ?.name?.split(" ")[0]
    : "";

  return (
    <MainCard
      title={`Governing Laws : ${countryFlag} ${countryName}`}
      headerSx={{ py: 2, px: 3 }}
      content
      contentSX={{ py: 2, px: 3 }}
      children={
        <Stack justifyContent={"start"} alignItems={"center"} spacing={1}>
          {countryLegalDetails?.conditions?.length === 0 && (
            <NoData
              text="-- No Legal Guidelines --"
              style={{
                height: "5vh",
              }}
            />
          )}
          {countryLegalDetails?.conditions &&
            countryLegalDetails?.conditions?.length > 0 &&
            Object.entries(
              groupBy(countryLegalDetails?.conditions, "party")
            )?.map(([party, conditions]) => {
              return (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography fontWeight={"bold"}>
                      {`As a ${toProperCase(party)}`}
                    </Typography>
                  </Grid>
                  {conditions?.map((condition) => (
                    <>
                      <Grid item xs={12}>
                        <Typography>{condition?.condition}</Typography>
                      </Grid>
                      {Boolean(condition?.documents?.length) && (
                        <li>
                          {condition.documents?.map((doc) => (
                            <li>{doc.display_name}</li>
                          ))}
                        </li>
                      )}
                    </>
                  ))}
                </Grid>
              );
            })}
        </Stack>
      }
      sx={{ width: "100%" }}
    />
  );
};
export default SummaryGoverningLaws;
