import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";

export interface IAddUser {
  is_authorized: boolean;
  id: string;
  first_name: string;
  last_name: string;
  mobile: string;
  title: string;
  email: string;
  roles: string;
  to_invite: boolean;
}
export const defaultValues: IAddUser = {
  id: "",
  first_name: "",
  last_name: "",
  mobile: "",
  title: "",
  email: "",
  roles: "Member",
  is_authorized: false,
  to_invite: false,
};

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter your First Name"),
  last_name: Yup.string().required("Please enter your Surname"),
  mobile: Yup.string()
    .required("Please enter your Phone Number")
    .test({
      name: "mobile",
      exclusive: false,
      params: {},
      message: "Invalid Phone Number",
      test: (value: any) => Boolean(value) && isValidPhoneNumber(value),
    } as any),
  title: Yup.string(),
  email: Yup.string()
    .required("Please enter your email")
    .matches(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email format, please ensure it is name@company.com"
    )
    .min(6, "Email should have atleast 6 characters")
    .max(120, "Email can only have maximum 120 characters"),
  roles: Yup.string(),
  is_authorized: Yup.boolean(),
});
