export const PRODUCTS = [
  {
    name: "Factoring with Single Buyer",
    value: "Factoring with Single Buyer",
  },
  {
    name: "Factoring with Multiple Buyers",
    value: "Factoring with Multiple Buyer",
  },
  {
    name: "Reverse-Factoring with Single Supplier",
    value: "Reverse-Factoring with Single Supplier",
  },
  {
    name: "Reverse-Factoring with Multiple Suppliers",
    value: "Reverse-Factoring with Multiple Suppliers",
  },
];
export const getProduct = (product: string) => {
  switch (product) {
    case "factoring":
      return "Factoring with Single Buyer";
    case "reverse_factoring":
      return "Reverse-Factoring with Single Supplier";
    default:
      return "Factoring with Single Buyer";
  }
};
export const ADVANCE_RATIOS = [
  { name: 60, value: 60 },
  { name: 65, value: 65 },
  { name: 70, value: 70 },
  { name: 75, value: 75 },
  { name: 80, value: 80 },
  { name: 85, value: 85 },
  { name: 90, value: 90 },
  { name: 95, value: 95 },
];

export const PAYMENT_TERMS = [
  {
    name: "30 days from Invoice date",
    value: "30 days from Invoice date",
  },
  {
    name: "30 days from BL date",
    value: "30 days from BL date",
  },
  {
    name: "45 days from Invoice date",
    value: "45 days from Invoice date",
  },
  {
    name: "45 days from BL date",
    value: "45 days from BL date",
  },
  {
    name: "60 days from Invoice date",
    value: "60 days from Invoice date",
  },
  {
    name: "60 days from BL date",
    value: "60 days from BL date",
  },
  {
    name: "75 days from Invoice date",
    value: "75 days from Invoice date",
  },
  {
    name: "75 days from BL date",
    value: "75 days from BL date",
  },
  {
    name: "90 days from Invoice date",
    value: "90 days from Invoice date",
  },
  {
    name: "90 days from BL date",
    value: "90 days from BL date",
  },
  {
    name: "120 days from Invoice date",
    value: "120 days from Invoice date",
  },
  {
    name: "120 days from BL date",
    value: "120 days from BL date",
  },
];
export const COMMITTEE_DECISIONS = [
  {
    name: "Approved",
    value: "Approved",
  },
  {
    name: "Partially Approved",
    value: "Partially Approved",
  },
  {
    name: "Rejected",
    value: "Rejected",
  },
];
export const INSURERS = [
  { name: "Atradius", value: "Atradius" },
  { name: "Coface", value: "Coface" },
  { name: "Credendo", value: "Credendo" },
  {
    name: "Oman Insurance",
    value: "Oman Insurance",
  },
  { name: "ICIEC", value: "ICIEC" },
  { name: "Mercury", value: "Mercury" },
  {
    name: "Funder's Insurance",
    value: "Funder's Insurance",
  },
  {
    name: "Client Insurance",
    value: "Client Insurance",
  },
  {
    name: "Partner Insurance",
    value: "Partner Insurance",
  },
];
