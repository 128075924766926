// material-ui
import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useUpdateMarkAsReadMutation } from "api/api-notifications";
import { getTimeStamp } from "helpers/getTimeStamp";
import history from "helpers/history";

import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { INotification } from "types";

// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  padding: 16,
  background: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  marginBottom: 8,
  "&:hover": {
    background: theme.palette.primary.light,
  },
  "& .MuiListItem-root": {
    padding: 0,
  },
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({
  notifications,
}: {
  notifications: INotification[];
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [markAsRead] = useUpdateMarkAsReadMutation();

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 330,
        py: 0,
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down("md")]: {
          maxWidth: 300,
        },
      }}
    >
      {notifications?.map((notif) => (
        <Fragment key={notif?.id}>
          <ListItemWrapper>
            <ListItem alignItems="center">
              <ListItemText primary={t(notif.title)} />
              <ListItemSecondaryAction>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      {getTimeStamp(notif.send_at)[1] === "now"
                        ? t("now")
                        : t("notif-delai", {
                            number: getTimeStamp(notif.send_at)[0],
                            unity: t(getTimeStamp(notif.send_at)[1]),
                          })}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItemButton
              sx={{ borderRadius: `${theme.shape.borderRadius}px` }}
              onClick={() => {
                markAsRead(notif.id)
                  .unwrap()
                  .then(() => {
                    history.push(notif.category);
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              <Grid container className="list-container">
                <Grid item xs={12} sx={{ pb: 2 }}>
                  <Typography variant="subtitle2">
                    {notif.context
                      ? t(notif.content, notif.context).toString()
                      : t(notif.content).toString()}
                  </Typography>
                </Grid>
                {!notif.notif_is_read && (
                  <Grid item xs={12}>
                    <Chip
                      label="Unread"
                      color="error"
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                )}
              </Grid>
            </ListItemButton>
          </ListItemWrapper>
        </Fragment>
      ))}
    </List>
  );
};

export default NotificationList;
