import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import {
  Badge,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useState } from "react";

const PermissionChanger = ({
  permissions,
  group_name,
  onChange,
}: {
  permissions: Record<string, boolean>;
  group_name: string;
  onChange: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    permissionName: string
  ) => void;
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const activePermissions = Object.entries(permissions).filter(
    ([_, value]) => value
  ).length;
  return (
    <Grid item xs={12} lg={3} key={group_name}>
      <Grid container>
        <Grid
          item
          xs={12}
          onClick={handleClick}
          sx={{
            borderRadius: 1,
            border: `1px solid ${theme.palette.primary.main}`,
            cursor: "pointer",
          }}
        >
          <Badge
            color="error"
            badgeContent={activePermissions}
            sx={{ width: "100%" }}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1em",
                  p: 2,
                  width: "100%",
                }}
              >
                {group_name}
              </Typography>
              <PanToolAltIcon />
            </Stack>
          </Badge>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ pl: 2 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {Object.entries(permissions).map(
                ([permissionName, permissionValue]) => {
                  return (
                    <Grid item xs={12} key={permissionName}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={`allGroups.${group_name}.${permissionName}`}
                            value={permissionValue}
                            checked={permissionValue}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>,
                              checked: boolean
                            ) => onChange(event, checked, permissionName)}
                          />
                        }
                        label={permissionName}
                      />
                    </Grid>
                  );
                }
              )}
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PermissionChanger;
