export function getFileTypeFromMagicNumber(binaryData: string): string | null {
  // Check for common magic numbers
  const jpgMagicNumber = "/9j/";
  const pngMagicNumber = "iVBORw";
  const pdfMagicNumber = "JVBERi0";

  // Check for each file type
  if (binaryData.startsWith(jpgMagicNumber)) {
    return "image/jpeg";
    //eslint-disable-next-line  no-else-return
  } else if (binaryData.startsWith(pngMagicNumber)) {
    return "image/png";
  } else if (binaryData.startsWith(pdfMagicNumber)) {
    return "application/pdf";
  }

  return null;
}
