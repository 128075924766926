import { Box, Button, Grid, Slide, Typography } from "@mui/material";
import { DASHBOARD, ONBOARDING } from "codes";
import history from "helpers/history";
import useIsMobile from "hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "redux/hooks";
import { setAppState } from "redux/reducers/appStatesReducer";

const CompletionScreen = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <Slide in direction={"left"}>
      <Box flexGrow={1} id="completion-box" display={"flex"}>
        <Grid
          container
          spacing={isMobile ? 2 : 5}
          id="completion-container"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Grid item xs={12}></Grid>
          <Grid item xs={12} lg={8} margin="auto">
            <Typography textAlign="center">
              {t("thank-you-for-clr")}
              <br />
              {t("incomlend-team-review")}
            </Typography>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item lg={4} xs={12} margin="auto">
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                dispatch(setAppState(ONBOARDING));
                history.push(DASHBOARD);
              }}
            >
              {`${t("go-to-dashboard")}`}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Slide>
  );
};
export default CompletionScreen;
