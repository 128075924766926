import { Switch as SwitchComponent, SwitchProps } from "@mui/material";

// Switch component
const Switch = (props: SwitchProps) => {
  const { value, checked, defaultChecked, onChange, ...rest } = props;

  return (
    <SwitchComponent
      checked={Boolean(value ?? checked)}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

export default Switch;
