import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Stack, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function ShowUserGroups({
  items,
  title,
  aria,
}: {
  items: string[];
  title: string;
  aria: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `${aria}-popover` : undefined;
  const theme = useTheme();

  return (
    <div style={{ width: "100%" }}>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        fullWidth
        startIcon={<PlayArrowIcon />}
        sx={{ justifyContent: "start" }}
      >
        {title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack spacing={1} p={2}>
          {items?.map((item, i) => (
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "1em",
                p: 1,
                borderRadius: 1,
                border: `1px solid ${theme.palette.primary.main}`,
              }}
              key={`${i}-u-${aria}`}
            >
              {item}
            </Typography>
          ))}
        </Stack>
      </Popover>
    </div>
  );
}
