import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import EditIcon from "@mui/icons-material/Edit";
import ForumIcon from "@mui/icons-material/Forum";
import { Avatar, Divider, Grid, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";

const ActivityLog = ({
  type = "comment",
  timestamp = "9:30 am",
  title = "Somebody did something",
  content,
}: {
  type: string;
  timestamp: string;
  title: string;
  content: ReactNode;
}) => {
  const ACTIVITY_ICON: {
    [type: string]: ReactNode;
  } = {
    comment: <ForumIcon color="primary" />,
    edit: <EditIcon color="secondary" />,
    approval: <CheckCircleIcon color="success" />,
    reject: <DoDisturbIcon color="error" />,
  };
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item xs={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Avatar sx={{ bgcolor: theme.palette.primary.main }}>{`${title
              ?.split(" ")?.[0]
              ?.charAt(0)}${title?.split(" ")?.[1]?.charAt(0)}`}</Avatar>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={"0.8em"} fontWeight={"bold"}>
              {timestamp}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Divider orientation="vertical">{ACTIVITY_ICON[type]}</Divider>
      </Grid>
      <Grid item xs={7}>
        <Grid container>
          <Grid item xs={12}>
            <Typography fontWeight={"bold"}>{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            {content}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ActivityLog;
