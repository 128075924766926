//eslint-disable-next-line space-before-function-paren
export default function at(
  object: Record<string, any>,
  paths: string[]
): any[] {
  const result: any[] = [];

  paths.forEach((path) => {
    const keys = path.split(/[.[\]]+/).filter(Boolean);

    const value = keys.reduce((acc, key) => {
      if (acc !== undefined && key in acc) return acc[key];
      return undefined;
    }, object);

    result.push(value);
  });

  return result;
}
