import { CL_DEFAULT } from "codes";
import valueCleaner from "helpers/valueCleaner";
import type { Partner } from "types";
import * as Yup from "yup";

export interface TradingRelationStep {
  list: any[];
  registrationNumber: string;
  companyType: string;
  iso3: string;
  requested_amount: number;
  companyId: string;
  clStatus: string;
  accept1: boolean;
  accept2: boolean;
  accept3: boolean;
}

export const tradingRelationStepValues: TradingRelationStep = {
  list: [],
  registrationNumber: "",
  companyType: "",
  iso3: "",
  companyId: "",
  requested_amount: 0,
  clStatus: "",
  accept1: false,
  accept2: false,
  accept3: false,
};

export const validationSchemaTradingRelationStep = Yup.object().shape({
  list: Yup.array()
    .of(
      Yup.object().shape({
        registration_number: Yup.string().required(),
        name: Yup.string().required(),
        country: Yup.string().required(),
        iso3: Yup.string(),
        requested_amount: Yup.number().positive(),
        annual_revenue: Yup.string()
          .test({
            name: "annual_revenue",
            exclusive: false,
            params: {},
            message: "Annual Revenue must not be zero or a negative number",
            test: (value: string) => valueCleaner(value) > 0,
          } as any)
          .required(),
        number_of_past_paid_transaction: Yup.number().required(),
        average_invoice_amount: Yup.string()
          .test({
            name: "average_invoice_amount",
            exclusive: false,
            params: {},
            message:
              "Average Invoice Amount must not be zero or a negative number",
            test: (value: string) => valueCleaner(value) > 0,
          } as any)
          .required(),
        average_payment_terms: Yup.string().required(),
        any_delays_of_payment_with_buyer: Yup.string().required(),
        reason_of_any_delays_of_payment_with_buyer: Yup.string()
          .nullable()
          .when("any_delays_of_payment_with_buyer", {
            is: true,
            then: (schema) => schema.required("Must enter a reason"),
          }),
        any_credit_note: Yup.string().required(),
        reason_for_credit_note: Yup.string()
          .nullable()
          .when("any_credit_note", {
            is: true,
            then: (schema) => schema.required("Must enter a reason"),
          }),
      })
    )
    .min(1, "Cannot proceed with an empty list"),
  registrationNumber: Yup.string().required(),
  companyType: Yup.string().required(),
  iso3: Yup.string().required(),
  isUploaded: Yup.boolean(),
  companyId: Yup.string(),
});
export const validationSchemaAddPartners = Yup.object().shape({
  registration_number: Yup.string().required("Registration Number is required"),
  name: Yup.string().required("Company Name is required"),
  country: Yup.string().required("Country is required"),
  iso3: Yup.string(),
  requested_amount: Yup.number().positive(),
  accept1: Yup.boolean().required("This agreement is required."),
  accept2: Yup.boolean().required("This agreement is required."),
  accept3: Yup.boolean().required("This agreement is required."),
});
export const validationSchemaAddPartner = Yup.object().shape({
  registration_number: Yup.string().required("Registration Number is required"),
  name: Yup.string().required("Company Name is required"),
  country: Yup.string().required(),
  iso3: Yup.string(),
  requested_amount: Yup.number().positive(),
  annual_revenue: Yup.string()
    .required("Annual Revenue is Required")
    .test({
      name: "annual_revenue",
      exclusive: false,
      params: {},
      message: "Annual Revenue must not be zero or a negative number",
      test: (value: string) => valueCleaner(value) > 0,
    } as any),
  number_of_past_paid_transaction: Yup.number()
    .typeError("Number of past paid transactions is Required")
    .required("Number of past paid transactions is Required")
    .min(0, "Please enter a valid number of past paid transactions"),
  average_invoice_amount: Yup.string()
    .required("Average Invoice Amount is Required")
    .test({
      name: "average_invoice_amount",
      exclusive: false,
      params: {},
      message: "Average Invoice Amount must not be zero or a negative number",
      test: (value: string) => valueCleaner(value) > 0,
    } as any),
  average_payment_terms: Yup.string()
    .required("Average Payment Terms is Required")
    .nullable(),

  reason_of_any_delays_of_payment_with_buyer: Yup.string()
    .nullable()
    .when("any_delays_of_payment_with_buyer", {
      is: true,
      then: (schema) => schema.required("Must enter a reason"),
    }),
  reason_for_credit_note: Yup.string()
    .nullable()
    .when("any_credit_note", {
      is: true,
      then: (schema) => schema.required("Must enter a reason"),
    }),
  industry: Yup.string().required("Industry is Required"),
  goods_category: Yup.string().required("Goods Category is Required"),
});

export interface AddPartnerType {
  name: string;
  registration_number: string;
  country: string;
}
export const addPartnerDefaults = {
  name: "",
  registration_number: "",
  country: "",
};

export const validationSchemaBuyerAdditionalDetails = Yup.object().shape({
  annual_revenue: Yup.string()
    .required("Annual Revenue is Required")
    .test({
      name: "annual_revenue",
      exclusive: false,
      params: {},
      message: "Annual Revenue must not be zero or a negative number",
      test: (value: string) => valueCleaner(value) > 0,
    } as any),
  number_of_past_paid_transaction: Yup.number()
    .typeError("Number of past paid transactions is Required")
    .required("Number of past paid transactions is Required")
    .min(0, "Please enter a valid number of past paid transactions"),
  average_invoice_amount: Yup.string()
    .required("Average Invoice Amount is Required")
    .test({
      name: "average_invoice_amount",
      exclusive: false,
      params: {},
      message: "Average Invoice Amount must not be zero or a negative number",
      test: (value: string) => valueCleaner(value) > 0,
    } as any),
  average_payment_terms: Yup.string()
    .required("Average Payment Terms is Required")
    .nullable(),
  reason_of_any_delays_of_payment_with_buyer: Yup.string()
    .nullable()
    .when("any_delays_of_payment_with_buyer", {
      is: true,
      then: (schema) => schema.required("Must enter a reason"),
    }),
  reason_for_credit_note: Yup.string()
    .nullable()
    .when("any_credit_note", {
      is: true,
      then: (schema) => schema.required("Must enter a reason"),
    }),
  industry: Yup.string().required("Industry is Required"),
  goods_category: Yup.string().required("Goods Category is Required"),
});

export interface IBuyerAdditionalDetails {
  annual_revenue: number;
  number_of_past_paid_transaction: number;
  average_invoice_amount: number;
  average_payment_terms: string;
  any_delays_of_payment_with_buyer: boolean;
  reason_of_any_delays_of_payment_with_buyer?: string;
  reason_for_credit_note?: string;
  any_credit_note: boolean;
  industry: string;
  goods_category: string;
}

export const BuyerAdditionalDetailsDefaults = {
  annual_revenue: "0.00",
  number_of_past_paid_transaction: 0,
  average_invoice_amount: "0.00",
  average_payment_terms: "",
  any_delays_of_payment_with_buyer: false,
  reason_of_any_delays_of_payment_with_buyer: "",
  reason_for_credit_note: "",
  any_credit_note: false,
  industry: "",
  goods_category: "",
};
export const addPartnerInitialValues: Partner = {
  registration_number: "",
  name: "",
  country: "",
  requested_amount: CL_DEFAULT,
  iso3: "",
  annual_revenue: 0,
  number_of_past_paid_transaction: 0,
  average_invoice_amount: 0,
  average_payment_terms: "",
  any_delays_of_payment_with_buyer: false,
  reason_of_any_delays_of_payment_with_buyer: "",
  reason_for_credit_note: "",
  any_credit_note: false,
  industry: "",
  goods_category: "",
};
