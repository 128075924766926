import { Stack, Typography, useTheme } from "@mui/material";
import { useEditSummariesMutation } from "api/api-compliance";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import { MapContext } from "pages/maps/map";
import { useContext } from "react";

const Summary = ({
  title,
  subject,
  data,
}: {
  title: string;
  subject: string;
  data: any;
}) => {
  const theme = useTheme();
  const mapName = useContext(MapContext);

  const [editSummaries] = useEditSummariesMutation();

  const initialValues = {
    summary: data,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      enableReinitialize
    >
      {(formikProps) => {
        const { summary } = formikProps.values;
        return (
          <Form>
            <Stack spacing={1}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "1em",
                }}
              >
                {title}
              </Typography>
              <VariableInput
                sx={{
                  ".MuiInputBase-root": {
                    height: "15vh",
                    backgroundClip: "border-box",
                    borderRadius: "10px",
                    textarea: {
                      height: "13vh !important",
                      overflow: "scroll !important",
                    },
                  },
                }}
                // labelClassName={styles.labelClass}
                name={"summary"}
                placeholder={"Enter summary..."}
                label={""}
                handleSave={(value) => {
                  mapName &&
                    editSummaries({
                      map_name: mapName,
                      data: {
                        summary: value as string,
                        subject: subject,
                      },
                    });
                }}
                value={summary ?? ""}
                multiline
                style={{ width: "100%" }}
                fullWidth
              />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Summary;
